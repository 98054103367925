import { create } from "zustand";
import { createAccountSlice } from "./account/account-slice";
import { createActivitiesSlice, createFoldersSlice, createSelectedActivitySlice } from "./activities/activities-slice";
import { createClassActivitySlice } from './classActivity/classActivity.slice';
import { createClassRoomsSlice } from "./classroom/classrooms-slice";
import { createCountriesSlice } from "./country/countries-slice";
import { createLevelsSlice } from "./level/level-slice";
import { createMaterialsSlice } from "./materials/materials-slice";
import { createModesSlice } from "./modes/modes.slice";
import { createThemeSlice } from "./themes/theme-slice";

export const useAppStore = create((...a) => ({
	...createLevelsSlice(...a),
	...createCountriesSlice(...a),
	...createMaterialsSlice(...a),
	...createModesSlice(...a),
	...createClassRoomsSlice(...a),
	...createAccountSlice(...a),
	...createThemeSlice(...a),
	...createActivitiesSlice(...a),
	...createFoldersSlice(...a),
	...createSelectedActivitySlice(...a),
	...createClassActivitySlice(...a)
}));
