import { createSlice } from "@reduxjs/toolkit";
import { registerParentAction, registerCompletParentAction } from "./actions";

const initialState = {
	registerStatus: "",
	registerError: "",
};

const registerParentSlice = createSlice({
	name: "parents",
	initialState,
	reducers: {},
	extraReducers: {
		[registerParentAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				registerStatus: "pending",
				registerError: "",
			};
		},
		[registerParentAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				registerStatus: "success",
				registerError: "",
			};
		},
		[registerParentAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				registerStatus: "rejected",
				registerError: payload.errors,
			};
		},

		[registerCompletParentAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				registerStatus: "pending",
				registerError: "",
			};
		},
		[registerCompletParentAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				registerStatus: "success",
				registerError: "",
			};
		},
		[registerCompletParentAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				registerStatus: "rejected",
				registerError: payload,
			};
		},
	},
});

export default registerParentSlice.reducer;
