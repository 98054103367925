import "./DeleteAccount.scss";

export function DeleteElementModal({ onCancel, onDelete, label }) {
	return (
		<div className='DeleteAccount'>
			<div className="DeleteAccount-content">
				<p>{label}</p>
				<div className="Actions">
					<button className="DeleteAccount-cancel" onClick={onCancel}>
						Non
					</button>
					<button className="DeleteAccount-apply" onClick={onDelete}>
						Oui
					</button>
				</div>
			</div>
		</div>
	);
}
