import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react"
import { useState } from "react"
import { BiCheckCircle } from "react-icons/bi"
import { useAppStore } from "../../store/store"

export default function ClassActivity() {
  const [selectedClass, setSelectedClass] = useState([])
  const classRooms = useAppStore(state => state.classRooms)
  const classesOfActivityId = useAppStore(state => state.classesOfActivity)
  const setClassesOfActivityId = useAppStore(state => state.setClassesOfActivity)

  const addClass = (id) => setSelectedClass([...new Set([...selectedClass, id])])
  const removeClass = (id) => setSelectedClass(selectedClass.filter(item => item !== id))

  const toggleClass = (id) => {
    const newSet = new Set(selectedClass)
    newSet.has(id) ? newSet.delete(id) : newSet.add(id)
    setSelectedClass([...newSet])
  }

  const close = () => setClassesOfActivityId(undefined)
  return <Modal
    isOpen={Boolean(classesOfActivityId)}
    onClose={close}
    isCentered
    size="xl"
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Liste des classes</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <div className="flex flex-wrap gap-2">
          {classRooms.map(item => <Button key={item.id}
            leftIcon={selectedClass.includes(item.id)
              ? <BiCheckCircle className="text-iwingreen" />
              : undefined}
            onClick={() => toggleClass(item.id)}>{item.name}</Button>)
          }
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="outline" colorScheme="gray" onClick={close}>Annuler</Button>
        <Button variant="solid" colorScheme="green" className="ml-3">Enregistrer</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}