import React, { useState, useEffect } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { TiPlus } from "react-icons/ti";
import { FaRegTrashAlt } from "react-icons/fa";
import { RiFileCopyLine } from "react-icons/ri";
import randomColor from "randomcolor";
import { BiImageAdd } from "react-icons/bi";
import { FiVolume1 } from "react-icons/fi";

import TIcon from "../../../assets/images/icons/TextT.png";
import MediaIcon from "../../../assets/images/icons/MediaIcon.png";

const Index = ({ etape, index, actionEtape, setActionEtape }) => {
	useEffect(() => {}, []);

	return (
		<div
			className={`w-full relative rounded-lg flex flex-col justify-center items-center px-16 py-2 mb-3 ${
				false ? "bg-amber-50/[0.5] border border-slate-500/[0.3]" : ""
			}`}
		>
			<div className='w-full flex justify-between items-center gap-4'>
				<div className='w-full flex justify-start items-start h-[18vh] border rounded-md bg-white border border-iwingreen/[.7]'>
					<div className='w-10 flex flex-col justify-start items-center gap-2 px-2 py-3'>
						<img src={TIcon} className='' alt="" />
						<label htmlFor='resImg'>
							<img src={MediaIcon} className='cursor-pointer' alt="" />
							<input
								accept='image/*'
								onChange={(e) => {}}
								id="resImg"
								name="resImg"
								type="file"
								className="hidden"
							/>
						</label>
					</div>
					<div className='w-full h-full min-h-full max-h-full '>
						<textarea
							id="question"
							required
							name="question"
							className='w-full border  rounded-r-md border-white bg-white p-2 focus:outline-none text-sm max-h-full h-full min-h-full'
							value={""}
							placeholder="Saisir le texte..."
							onChange={(e) => {}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Index;
