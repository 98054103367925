import React from "react";
import { BiSlideshow, BiTachometer } from "react-icons/bi";
import { RiCalendarLine, RiLogoutBoxLine, RiMessage2Line } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import Logo from "../../../assets/images/logo-iwin.png";
import "./style.css";

const SideBarContainer = styled.div`
width: 215px;
min-height: 100vh;
 background: #009688 no-repeat center center fixed; 
    -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: #fff;
`;

const Index = () => {
	const location = useLocation();
	return (
		<SideBarContainer className='flex flex-col justify-between items-start pl-4 py-5 '>
			<div className='w-full flex flex-col justify-start items-start'>
				<Link to={"/students"} className='w-full flex flex-row justify-center items-center px-1'>
					<img src={Logo} alt="" className='h-9 w-full' />
				</Link>
				<nav className='w-full mt-4'>
					<ul className='w-full flex flex-col justify-start items-start gap-1'>
						<li
							className={`font-medium w-full py-[11px] px-4 text-[13px] ${
								location.pathname === "/students" || location.pathname === "/children"
									? "active"
									: ""
							}`}
						>
							<Link to="/students" className="w-full flex flex-row justify-start items-center">
								<BiTachometer className="w-6 h-6 mr-3" />
								Dashboard
							</Link>
						</li>
						<li
							className={`font-medium w-full py-[12px] px-4 text-[13px] ${
								location.pathname === "/students/activites" ? "active" : ""
							}`}
						>
							<Link
								to="/students/activites"
								className="w-full flex flex-row justify-start items-center"
							>
								<RiCalendarLine className="w-6 h-6 mr-3" />
								Activités
							</Link>
						</li>
						<li
							className={`font-medium w-full py-[12px] px-4 text-[13px] ${
								location.pathname === "/students/create-activity" ? "active" : ""
							}`}
						>
							<Link
								to="/students/create-activity"
								className="w-full flex flex-row justify-start items-center"
							>
								<RiCalendarLine className="w-6 h-6 mr-3" />
								Créer
							</Link>
						</li>
						<li
							className={`font-medium w-full py-[12px] px-4 text-[13px] ${
								location.pathname === "/students/bu" ? "active" : ""
							}`}
						>
							<Link to="/students/bu" className="w-full flex flex-row justify-start items-center">
								<RiCalendarLine className="w-6 h-6 mr-3" />
								Bibliothèque
							</Link>
						</li>
						<li
							className={`font-medium w-full py-[12px] px-4 text-[13px] ${
								location.pathname === "/students/game" ? "active" : ""
							}`}
						>
							<Link to="/students/game" className="w-full flex flex-row justify-start items-center">
								<RiCalendarLine className="w-6 h-6 mr-3" />
								Jouer
							</Link>
						</li>
						<li
							className={`font-medium w-full py-3 px-4 text-[13px] ${
								location.pathname === "/students/tutorials" ? "active" : ""
							}`}
						>
							<Link
								to="/students/tutorials"
								className="w-full flex flex-row justify-start items-center"
							>
								<BiSlideshow className="w-6 h-6 mr-3" />
								Tutoriels
							</Link>
						</li>
						<li
							className={`font-medium w-full py-[12px] px-4 text-[13px] ${
								location.pathname === "/students/contact" ? "active" : ""
							}`}
						>
							<Link
								to="/students/contact"
								className="w-full flex flex-row justify-start items-center"
							>
								<RiMessage2Line className="w-6 h-6 mr-3" />
								Contacts
							</Link>
						</li>
						<li className={"font-medium w-full py-[12px] px-4 text-[13px]"}>
							<Link to="/logout" className="w-full flex flex-row justify-start items-center">
								<RiLogoutBoxLine className="w-6 h-6 mr-3" />
								Déconnexion
							</Link>
						</li>
					</ul>
				</nav>
			</div>
			<div className="w-full flex flex-col justify-center items-center mt-28 -ml-3">
				<p className="text-[10px] font-bold mb-3">iWinSciences - Jeu educatif</p>
				<p className="text-[10px]">
					<span>
						Tous droits réservés - <span>O</span> {new Date().getFullYear()}
					</span>
				</p>
			</div>
		</SideBarContainer>
	);
};

export default Index;
