import { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";

export default function Input(props) {
	if (props.type === 'password') return <PasswordInput {...props} />
	return <SimpleInput {...props} />
}

function PasswordInput({ label, ...props }) {
	const [show, setShow] = useState(false);
	const toggle = () => setShow((v) => !v);

	return (
		<div className="w-full flex flex-col justify-start items-start gap-2 mt-4 relative">
			{label && (
				<label className='text-[14px] font-medium'>
					{label} {props.required && <span className='text-red-500'>*</span>}
				</label>
			)}
			<input
				className='focus:border-inputbg/[0.47] px-3 py-2'
				{...props}
				type={show ? "text" : "password"}
			/>
			{show ? (
				<FiEyeOff
					className="absolute right-4 top-[2.6rem] text-iwingreencursor-pointer"
					onClick={toggle}
				/>
			) : (
				<FiEye
					className="absolute right-4 top-[2.6rem] text-iwinblue cursor-pointer"
					onClick={toggle}
				/>
			)}
		</div>
	);
}


function SimpleInput({ label, ...props }){
	return <div className="w-full flex flex-col justify-start items-start gap-2 mt-4 relative">
	{label && (
		<label className='text-[12px]'>
			{label} {props.required && <span className='text-red-500'>*</span>}
		</label>
	)}
	<input
		className='focus:border-inputbg/[0.47] px-3 py-2'
		{...props}
	/>
</div>
}