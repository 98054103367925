import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { logoutAction } from "../../store/auth/login/actions";

const Logout = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(logoutAction());
		navigate("/login", { replace: true });
	}, [navigate, dispatch]);

	return <></>;
};

export default Logout;
