export const reactSelectTheme = {
	styles: {
		control(defaultStyles) {
			return {
				...defaultStyles,
				borderColor: "#009688",
				padding: "0.3rem",
				borderRadius: "0.25rem",
			};
		},
	},
	theme: (theme) => ({
		...theme,
		borderRadius: 0,
		borderColor: "#009688",

		colors: {
			...theme.colors,
			primary25: "#009688",
			primary: "#009688",
		},
	}),
};
