import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSubjects, getSubjectsByTeacher } from "../../helpers/backend_helper";

export const getSubjectsAction = createAsyncThunk(
	"subjects/getSubjectsAction",
	async (id = null, { rejectWithValue }) => {
		try {
			const response = await getSubjects();
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);

export const getSubjectsByTeacherAction = createAsyncThunk(
	"subjects/getSubjectsByTeacherAction",
	async (teacherId, { rejectWithValue }) => {
		try {
			const response = await getSubjectsByTeacher(teacherId);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);
