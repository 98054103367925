import { useEffect, useState } from "react";
import Select from "react-select";
import { addMaterials, getTeacherMaterials } from "../../helpers/backend_helper";
import { useAppStore } from "../../store/store";

export default function MaterialListInput({ profilId, onChange }) {
	const materialsOneState = useAppStore((state) => state.materials);

	const [isLoading, setIsLoading] = useState(false);
	const [inSync, setInSync] = useState(true);
	const [values, setValues] = useState([]);
	const [list, setList] = useState([]);

	const syncLevels = () => {
		setList(materialsOneState.map((item) => ({ value: item.id, label: item.name })));
	};

	const syncUserLevels = async () => {
		const materials = await getTeacherMaterials(profilId);
		if (Array.isArray(materials?.data)) {
			setValues(materials.data.map((item) => ({ value: item.id, label: item.name })));
		}
	};

	const syncDatas = async () => {
		syncLevels();
		await syncUserLevels();
		setInSync(false);
	};

	const handleChange = async (materials) => {
		try {
			setIsLoading(true);
			const payload = {
				teacherId: profilId,
				materials: materials.map((item) => ({ id: item.value, name: item.label })),
			};

			await addMaterials(payload);
			await syncUserLevels();
			onChange?.(materials);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		syncDatas().catch(console.error);
	}, []);

	if (inSync || materialsOneState.length === 0) return <p>Chargement ...</p>;

	return (
		<Select
			menuPlacement="auto"
			isMulti
			placeholder="Cliquez ici pour sélectionner une matière"
			isLoading={isLoading}
			value={values}
			options={list}
			onChange={(values) => {
				handleChange(values);
			}}
			styles={{
				control(defaultStyles) {
					return {
						...defaultStyles,
						borderColor: "#009688",
						padding: "0.3rem",
						borderRadius: "0.25rem",
					};
				},
			}}
			theme={(theme) => ({
				...theme,
				borderRadius: 0,
				borderColor: "#009688",

				colors: {
					...theme.colors,
					primary25: "#009688",
					primary: "#009688",
				},
			})}
			className="w-full"
		/>
	);
}
