import { createSlice } from "@reduxjs/toolkit";
import { getLevelsAction } from "./actions";

const initialState = {
	getLevelsStatus: "",
	getLevelsError: "",
	levels: [],
};

const levelSlice = createSlice({
	name: "levels",
	initialState,
	reducers: {},
	extraReducers: {
		[getLevelsAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				getLevelsStatus: "pending",
				getLevelsError: "",
			};
		},
		[getLevelsAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				levels: payload,
				getLevelsStatus: "success",
				getLevelsError: "",
			};
		},
		[getLevelsAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				getLevelsStatus: "rejected",
				getLevelsError: payload,
			};
		},
	},
});

export default levelSlice.reducer;
