import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { AiFillCaretLeft } from "react-icons/ai";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import * as Yup from "yup";

import "./style.css";

// imports Assets
import Logo from "../../../../assets/images//logo-iwin.png";
import SigUpBgImg from "../../../../assets/images/sigup-student.png";
import SquareTopRight from "../../../../assets/images/square-top-right.png";

import { registerStudentAction } from "../../../../store/auth/register/actions";
import { getCountriesAction } from "../../../../store/country/actions";
import { getLevelsAction } from "../../../../store/level/actions";

const FormContainer = styled.form`
    width: 460px;
    padding: 15px 15px;
     background: url(${SigUpBgImg}) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 16px
`;

const Index = () => {
	const { levels, registerStatus, countries } = useSelector((state) => ({
		levels: state.levelsState.levels,
		registerStatus: state.registerStudentState.registerStatus,
		registerError: state.registerStudentState.registerError,
		countries: state.countryState.countries,
	}));
	let navigate = useNavigate();
	let location = useLocation();
	const dispatch = useDispatch();
	const age = location.state.age;
	const [formSubmited, setFormSubmited] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const customStyles = {
		control: (base) => ({
			...base,
			border: "1px solid rgba(50, 202,188, 0.47)",
			background: "rgba(50, 202,188, 0.02)",
			color: "#303972",
			height: "40px",
			boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
			filter: "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
			borderRadius: "0.375rem",
			"&:hover": {
				border: "1px solid rgba(50, 202,188, 0.47)",
			},
		}),
		option: (provided, state) => ({
			...provided,
			fontSize: "16px",
		}),
		placeholder: (defaultStyles) => {
			return {
				...defaultStyles,
				color: "#303972",
				fontSize: "16px",
			};
		},
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = "opacity 300ms";
			const fontSize = "16px";
			const color = "#303972";
			return { ...provided, color, fontSize, opacity, transition };
		},
		input: (styles, { data }) => ({
			...styles,
			outline: "none",
			border: "none",
			focus: "none",
			color: "#303972",
		}),
		indicatorSeparator: (base) => ({
			...base,
			display: "none",
		}),
		dropdownIndicator: (base) => ({
			...base,
			color: "#303972",
			"&:hover": {
				color: "#303972",
			},
		}),
		menuList: (base) => ({
			...base,
			"::-webkit-scrollbar": {
				width: "4px",
				height: "0px",
				color: "#303972",
			},
			"::-webkit-scrollbar-track": {
				background: "#f1f1f1",
			},
			"::-webkit-scrollbar-thumb": {
				background: "#888",
			},
			"::-webkit-scrollbar-thumb:hover": {
				background: "#555",
			},
		}),
	};

	const filterLevel = () => {
		let l = [];
		if (levels) {
			if (age >= 13) {
				levels.map((level) => {
					if (
						level.label.toLowerCase() === "6ème" ||
						level.label.toLowerCase() === "5ème" ||
						level.label.toLowerCase() === "4ème" ||
						level.label.toLowerCase() === "3ème" ||
						level.label.toLowerCase() === "autre"
					) {
						l.push(level);
					}
				});
			} else {
				levels.map((level) => {
					if (
						level.label.toLowerCase() === "cp" ||
						level.label.toLowerCase() === "ce1" ||
						level.label.toLowerCase() === "ce2" ||
						level.label.toLowerCase() === "cm1" ||
						level.label.toLowerCase() === "cm2" ||
						level.label.toLowerCase() === "6ème"
					) {
						l.push(level);
					}
				});
			}
		}
		return l;
	};

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			firstName: "",
			lastName: "",
			username: "",
			email: "",
			emailParent: "",
			password: "",
			confirmPassword: "",
			level: "",
			birthDay: location?.state?.birthDay || "",
			age: location?.state?.age || "",
			country: "",
			accepteTerm: false,
			rememberMe: false,
		},
		validationSchema: Yup.object({
			firstName: Yup.string().required("Veuillez remplir le prénom !"),
			lastName: Yup.string().required("Veuillez remplir le nom !"),
			age: Yup.number().required(),
			birthDay: Yup.date().required(),
			username: Yup.string().when("age", {
				is: (age) => age < 13,
				then: Yup.string().required("Veuillez remplir un nom d'utilisateur !"),
			}),
			email: Yup.string().when("age", {
				is: (age) => age >= 13,
				then: Yup.string()
					.required("Veuillez remplir l'email !")
					.email("Veuillez entrer un email valid !"),
			}),
			emailParent: Yup.string().when("age", {
				is: (age) => age < 13,
				then: Yup.string()
					.required("Veuillez remplir l'email !")
					.email("Veuillez entrer un email valid !"),
			}),
			password: Yup.string().required("Veuillez remplir le mot de passe !"),
			confirmPassword: Yup.string()
				.required("Veuillez confirmer le le mot de passe !")
				.when("password", {
					is: (val) => (val && val.length > 0 ? true : false),
					then: Yup.string().oneOf(
						[Yup.ref("password")],
						"Les mot de passe ne correspondent pas !",
					),
				}),
			level: Yup.object().required("Veuillez sélectionner le niveau !"),
			country: Yup.object().required("Veuillez sélectionner le pays !"),
			accepteTerm: Yup.boolean()
				.required("Veuillez accepter nos CGU  et politiques de confidentialité !")
				.oneOf([true], "Veuillez accepter nos CGU  et politiques de confidentialité !"),
			rememberMe: Yup.boolean(),
		}),
		onSubmit: (values) => {
			setFormSubmited(true);
			dispatch(registerStudentAction(values));
		},
	});

	useEffect(() => {
		if (levels && levels.length === 0) {
			dispatch(getLevelsAction());
		}
		if (countries && countries.length === 0) {
			dispatch(getCountriesAction());
		}
		if (formSubmited && registerStatus === "success") {
			setFormSubmited(false);
			navigate("/account-created", { replace: true, state: { type: "Student" } });
		}
	}, [dispatch, registerStatus]);
	return (
		<CSSTransition in={true} appear={true} timeout={300} classNames="fade">
			<div className="flex flex-col justify-center items-center">
				<div className="absolute z-10 top-5">
					<img src={Logo} alt="LOGO" className="h-8" />
				</div>

				<div className="absolute z-10 top-5 flex justify-start items-center left-5">
					<Link
						to={"/register"}
						className="px-4 py-2 text-white text-sm rounded-md bg-iwingreen flex flex-row justify-start items-center"
					>
						<AiFillCaretLeft className="w-5 h-5 -ml-1" /> Retour
					</Link>
				</div>
				<FormContainer
					onSubmit={(e) => {
						e.preventDefault();
						validation.handleSubmit();
						return false;
					}}
					className={`flex flex-col justify-center items-center relative ${age < 13 ? "mt-3" : ""}`}
				>
					<img src={SquareTopRight} className="absolute top-0 right-0 rounded-tr-[16px]" alt="" />
					<Row className="w-full">
						{age < 13 ? (
							<Col
								xs={12}
								sm={12}
								md={12}
								className="flex flex-col justify-start items-start gap-1 mb-4"
							>
								<label htmlFor='emailParent' className='text-[14px] text-iwinblue font-medium'>
									Adresse email de votre parent <span className='text-red-500'>*</span>
								</label>
								<input
									type="email"
									id='emailParent'
									name='emailParent'
									className='w-full text-[14px] drop-shadow-md outline-none border bg-inputbg/[0.02] border-inputbg/[0.47]  text-iwinblue rounded-md focus:border-inputbg/[0.47] px-3 py-2'
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.emailParent}
									autoComplete={""}
									invalid={validation.touched.emailParent && validation.errors.emailParent}
								/>
								{validation.touched.emailParent && validation.errors.emailParent ? (
									<small className="text-[9px] text-red-400">{validation.errors.emailParent}</small>
								) : null}
								{registerStatus === "rejected" ? (
									<small className="text-[9px] text-red-400">
										{"Cette addresse email a déjà été utilisée !"}
									</small>
								) : null}
							</Col>
						) : null}
						<Col
							xs={12}
							sm={12}
							md={6}
							className="flex flex-col justify-start items-start gap-1 mb-4"
						>
							<label htmlFor='lastName' className='text-[14px] text-iwinblue font-medium'>
								Nom <span className='text-red-500'>*</span>
							</label>
							<input
								type="text"
								id='lastName'
								name='lastName'
								className='w-full text-[14px] drop-shadow-md outline-none border bg-inputbg/[0.02]  border-inputbg/[0.47] text-iwinblue rounded-md focus:border-inputbg[0.47]  px-3 py-2'
								onChange={validation.handleChange}
								onBlur={validation.handleBlur}
								value={validation.values.lastName}
								autoComplete={""}
								invalid={validation.touched.lastName && validation.errors.lastName}
							/>
							{validation.touched.lastName && validation.errors.lastName ? (
								<small className="text-[9px] text-red-400">{validation.errors.lastName}</small>
							) : null}
						</Col>
						<Col
							xs={12}
							sm={12}
							md={6}
							className="flex flex-col justify-start items-start gap-1 mb-4"
						>
							<label htmlFor='firstName' className='text-[14px] text-iwinblue font-medium'>
								Prénom <span className='text-red-500'>*</span>
							</label>
							<input
								type="text"
								id='firstName'
								name='firstName'
								className='w-full text-[14px] drop-shadow-md outline-none border bg-inputbg/[0.02] border-inputbg/[0.47]  text-iwinblue rounded-md focus:border-inputbg/[0.47] px-3 py-2'
								onChange={validation.handleChange}
								onBlur={validation.handleBlur}
								value={validation.values.firstName}
								autoComplete={""}
								invalid={validation.touched.firstName && validation.errors.firstName}
							/>
							{validation.touched.firstName && validation.errors.firstName ? (
								<small className="text-[9px] text-red-400">{validation.errors.firstName}</small>
							) : null}
						</Col>
						{age >= 13 ? (
							<Col
								xs={12}
								sm={12}
								md={12}
								className="flex flex-col justify-start items-start gap-1 mb-4"
							>
								<label htmlFor='email' className='text-[14px] text-iwinblue font-medium'>
									Adresse email <span className='text-red-500'>*</span>
								</label>
								<input
									type='email'
									id='email'
									name='email'
									className='w-full text-[14px] drop-shadow-md outline-none border bg-inputbg/[0.02] border-inputbg/[0.47]  text-iwinblue rounded-md focus:border-inputbg/[0.47] px-3 py-2'
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.email}
									autoComplete={""}
									invalid={validation.touched.email && validation.errors.email}
								/>
								{validation.touched.email && validation.errors.email ? (
									<small className="text-[9px] text-red-400">{validation.errors.email}</small>
								) : null}
								{registerStatus === "rejected" ? (
									<small className="text-[9px] text-red-400">
										{"Cette addresse email a déjà été utilisée !"}
									</small>
								) : null}
							</Col>
						) : (
							<Col
								xs={12}
								sm={12}
								md={12}
								className="flex flex-col justify-start items-start gap-1 mb-4"
							>
								<label htmlFor='username' className='text-[14px] text-iwinblue font-medium'>
									Choisir un nom utilisateur <span className='text-red-500'>*</span>
								</label>
								<input
									type='text'
									id='username'
									name='username'
									className='w-full text-[14px] drop-shadow-md outline-none border bg-inputbg/[0.02] border-inputbg/[0.47]  text-iwinblue rounded-md focus:border-inputbg/[0.47] px-3 py-2'
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									autoComplete={""}
									value={validation.values.username}
									invalid={validation.touched.username && validation.errors.username}
								/>
								{validation.touched.username && validation.errors.username ? (
									<small className="text-[9px] text-red-400">{validation.errors.username}</small>
								) : null}
								{registerStatus === "rejected" ? (
									<small className="text-[9px] text-red-400">
										{"Cette addresse email a déjà été utilisée !"}
									</small>
								) : null}
							</Col>
						)}

						<Col
							xs={12}
							sm={12}
							md={6}
							className="flex flex-col justify-start items-start gap-1 mb-4 relative"
						>
							<label htmlFor='password' className='text-[14px] text-iwinblue font-medium'>
								Mot de passe <span className='text-red-500'>*</span>
							</label>
							<input
								type={showPassword ? "text" : "password"}
								id='password'
								name='password'
								className='w-full pr-7 text-[14px] drop-shadow-md outline-none border bg-inputbg/[0.02] border-inputbg/[0.47]  text-iwinblue rounded-md focus:border-inputbg/[0.47] px-3 py-2'
								onChange={validation.handleChange}
								onBlur={validation.handleBlur}
								autoComplete={""}
								value={validation.values.password}
								invalid={validation.touched.password && validation.errors.password}
							/>
							{showPassword ? (
								<FiEyeOff
									className="absolute right-4 top-[2.35rem]  text-iwinblue cursor-pointer"
									onClick={() => setShowPassword(!showPassword)}
								/>
							) : (
								<FiEye
									className="absolute right-4 top-[2.35rem]  text-iwinblue cursor-pointer"
									onClick={() => setShowPassword(!showPassword)}
								/>
							)}
							{validation.touched.password && validation.errors.password ? (
								<small className="text-[9px] text-red-400">{validation.errors.password}</small>
							) : null}
						</Col>
						<Col
							xs={12}
							sm={12}
							md={6}
							className="flex flex-col justify-start items-start gap-1 mb-4 relative"
						>
							<label htmlFor='confirmPassword' className='text-[14px] text-iwinblue font-medium'>
								Confirmer le mot de passe <span className='text-red-500'>*</span>
							</label>
							<input
								type={showConfirmPassword ? "text" : "password"}
								id='confirmPassword'
								name='confirmPassword'
								className='w-full text-[14px] drop-shadow-md outline-none border bg-inputbg/[0.02] border-inputbg/[0.47]  text-iwinblue rounded-md focus:border-inputbg/[0.47] px-3 py-2'
								onChange={validation.handleChange}
								autoComplete={""}
								onBlur={validation.handleBlur}
								value={validation.values.confirmPassword}
								invalid={validation.touched.confirmPassword && validation.errors.confirmPassword}
							/>
							{showConfirmPassword ? (
								<FiEyeOff
									className="absolute right-4 top-[2.35rem]  text-iwinblue cursor-pointer"
									onClick={() => setShowConfirmPassword(!showConfirmPassword)}
								/>
							) : (
								<FiEye
									className="absolute right-4 top-[2.35rem]  text-iwinblue cursor-pointer"
									onClick={() => setShowConfirmPassword(!showConfirmPassword)}
								/>
							)}
							{validation.touched.confirmPassword && validation.errors.confirmPassword ? (
								<small className="text-[9px] text-red-400">
									{validation.errors.confirmPassword}
								</small>
							) : null}
						</Col>
						<Col md={6} className="flex flex-col justify-start items-start gap-1 mb-4">
							<label htmlFor='level' className='text-[14px] text-iwinblue font-medium'>
								Choisir votre niveau <span className='text-red-500'>*</span>{" "}
							</label>
							<Select
								id="level"
								name="level"
								styles={customStyles}
								options={filterLevel()}
								placeholder="Niveau..."
								className="w-full"
								classNamePrefix="select"
								onChange={(e) => {
									validation.setFieldValue("level", e);
								}}
								onBlur={validation.handleBlur}
								value={validation.values.level}
								invalid={validation.touched.level && validation.errors.level}
							/>
							{validation.touched.level && validation.errors.level ? (
								<small className="text-[9px] text-red-400">{validation.errors.level}</small>
							) : null}
						</Col>
						<Col md={6} className="flex flex-col justify-start items-start gap-1 mb-4">
							<label htmlFor='country' className='text-[14px] text-iwinblue font-medium'>
								Pays <span className='text-red-500'>*</span>{" "}
							</label>
							<Select
								id="country"
								name="country"
								styles={customStyles}
								options={countries}
								placeholder="Pays..."
								className="w-full"
								classNamePrefix="select"
								onChange={(e) => {
									validation.setFieldValue("country", e);
								}}
								onBlur={validation.handleBlur}
								value={validation.values.country}
								invalid={validation.touched.country && validation.errors.country}
							/>
							{validation.touched.country && validation.errors.country ? (
								<small className="text-[9px] text-red-400">{validation.errors.country}</small>
							) : null}
						</Col>

						<Col lg={12} className="flex flex-row justify-start items-center mb-4">
							<input
								type="checkbox"
								id="rememberMe"
								className='mr-2'
								name="rememberMe"
								value={validation.values.rememberMe}
								onChange={validation.handleChange}
								onBlur={validation.handleBlur}
								checked={validation.values.rememberMe}
							/>
							<label htmlFor="rememberMe" className="text-[11px] text-iwinblue">
								Se souvenir de moi
							</label>
						</Col>
						<Col
							xs={12}
							sm={12}
							md={12}
							className="flex flex-col justify-start items-start gap-1 mb-4"
						>
							<div className="flex justify-start items-center pl-0">
								<input
									type="checkbox"
									id="accepteTerm"
									className='mr-2'
									name="accepteTerm"
									value={validation.values.accepteTerm}
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									checked={validation.values.accepteTerm}
									invalid={validation.touched.accepteTerm && validation.errors.accepteTerm}
								/>
								<label htmlFor="accepteTerm" className="text-[11px] text-iwinblue">
									Accepter nos{" "}
									<Link to="/" className="text-cyan-500">
										CGU{" "}
									</Link>{" "}
									et{" "}
									<Link to="/" className="text-cyan-500">
										{" "}
										politiques de confidentialité
									</Link>
									<span className='text-red-500'>*</span>
								</label>
							</div>
							{validation.touched.accepteTerm && validation.errors.accepteTerm ? (
								<small className="text-[9px] text-red-400">{validation.errors.accepteTerm}</small>
							) : null}
						</Col>
						<Col lg={12} className='w-full flex justify-center items-center mt-3'>
							<button
								type="submit"
								className="text-sm bg-iwingreen px-5 py-2 text-white rounded-md font-medium"
							>
								Créer mon compte
							</button>
						</Col>
					</Row>
				</FormContainer>
			</div>
		</CSSTransition>
	);
};

export default Index;
