import { API_URL } from "../context";

const baseUrl = API_URL;
export const AUTH_URL = `${baseUrl}/auth`;
export const COUNTRY_URL = `${baseUrl}/countries`;
export const SUBJECT_URL = `${baseUrl}/subjects`;
export const LEVEL_URL = `${baseUrl}/levels`;
export const CLASSROOM_URL = `${baseUrl}/classrooms`;
export const GROUP_URL = `${baseUrl}/groups`;
export const STUDENT_URL = `${baseUrl}/students`;
export const PARENT_URL = `${baseUrl}/parents`;
export const TEACHER_URL = `${baseUrl}/teachers`;
export const MODULES_URL = `${baseUrl}/modules`;
export const THEMES_URL = `${baseUrl}/themes`;
export const COMPETENCE_JEU_URL = `${baseUrl}/competences-jeu`;
export const COMPETENCE_PROGRAM_URL = `${baseUrl}/competences-program`;
export const NOMBRE_MOT_URL = `${baseUrl}/nombre-mot`;
export const SUB_THEMES_URL = "/subthemes";
export const SUB_THEMES_METIER_URL = `${baseUrl}/sub-theme-metier`;
export const SUB_THEMES_INCLU_URL = `${baseUrl}/sub-theme-inclusion`;
export const TYPE_LECTEUR_URL = `${baseUrl}/type-lecteurs`;
export const CATEGORY_ARTICLE_URL = `${baseUrl}/category-articles`;
export const DASHBOARD_URL = `${baseUrl}/dashboard`;
export const TYPE_REPONSE_URL = `${baseUrl}/type-responses`;
export const TEMPS_REPONSE_URL = `${baseUrl}/time-responses`;
export const POINT_URL = `${baseUrl}/points`;
export const ACTIVITY_URL = `${baseUrl}/activities`;
export const MATERIALS_URL = `${baseUrl}/materials`;
