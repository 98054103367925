import React from "react";
import { Col, Row } from "react-flexbox-grid";
import Select from "react-select";
import { CSSTransition } from "react-transition-group";

import { useState } from "react";
import { AiFillCaretLeft } from "react-icons/ai";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../../assets/images/logo-iwin.png";
import SquareTopRight from "../../../assets/images/square-top-right.png";
import { teacherResponses } from "../../../constants";
import LevelListInput from "../../LevelListInput/LevelListInput";
import MaterialListInput from "../../MaterialListInput/MaterialListInput";
import { FormContainer } from "../../common/FormContainer";
import { customStyles } from "../../common/react-select-styles";

export default function CreateTeacherSkills() {
	const navigate = useNavigate();
	const [skills, setSkills] = useState({ levels: [], materials: [], response: undefined });

	const { state } = useLocation();

	if (!state) return <Navigate to="/register/teacher" />;

	const handleChange = (key, value) => {
		setSkills({ ...skills, [key]: value });
	};

	const handleSubmit = () => {
		let message = "Veuillez sélectionner au moins un niveau enseigné!";
		if (skills.levels.length === 0) return toast.error(message);
		message = "Veuillez sélectionner au moins une matière enseignée!";
		if (skills.materials.length === 0) return toast.error(message);
		message = "Veuillez répondre à la question d'où avez-vous connu iWinSciences svp!";
		if (!skills.response) return toast.error(message);
		navigate("/register/teacher-completed/school", { state });
	};

	return (
		<>
			<div className="absolute z-10 top-5 flex justify-start items-center left-5">
				<Link
					to="/register/teacher"
					state={state}
					className="px-4 py-2 text-white text-sm rounded-md bg-iwingreen flex flex-row justify-start items-center"
				>
					<AiFillCaretLeft className="w-5 h-5 -ml-1" /> Retour
				</Link>
			</div>
			<CSSTransition in={true} appear={true} timeout={300} classNames="fade">
				<FormContainer
					onSubmit={(e) => e.preventDefault()}
					className="flex flex-col justify-center items-center mt-10"
				>
					<div className="absolute z-10 top-5">
						<img src={Logo} alt="LOGO" className="h-8" />
					</div>
					<div className="flex flex-col justify-center items-center relative z-30 mb-5 mt-3">
						<img src={SquareTopRight} className="absolute top-0 right-0 rounded-tr-[16px]" alt="" />
						<Row className="w-full">
							<Col md={12} className="mt-3 mb-5">
								<h2 className='text-iwingreen font-medium'>
									Bienvenue <span className='font-bold'>{state.firstName}</span>
								</h2>
							</Col>

							<Col md={12} className='flex flex-col justify-start items-start gap-3 mb-4'>
								<label htmlFor="subjects">
									Matières enseignées <span className='text-red-500'>*</span>
								</label>
								<MaterialListInput
									profilId={state.id}
									onChange={(values) => handleChange("materials", values)}
								/>
							</Col>
							<Col md={12} className='flex flex-col justify-start items-start gap-3 mb-4'>
								<label htmlFor="subjects">
									Niveaux enseignés <span className='text-red-500'>*</span>
								</label>
								<LevelListInput
									profilId={state.id}
									onChange={(values) => handleChange("levels", values)}
								/>
							</Col>
							<Col
								xs={12}
								sm={12}
								md={12}
								className='flex flex-col justify-start items-start gap-3 mb-4'
							>
								<label htmlFor="rep">
									Comment avez-vous connu iWinSciences? <span className='text-red-500'>*</span>
								</label>
								<Select
									id="rep"
									name="rep"
									styles={customStyles}
									options={teacherResponses}
									placeholder="Choisir Réponse..."
									className="w-full"
									classNamePrefix="select"
									onChange={(value) => {
										handleChange("response", value);
									}}
								/>
							</Col>
							<Col lg={12} className='w-full flex justify-center items-center mt-3'>
								<button
									type="button"
									className="text-sm bg-iwingreen px-5 py-2 text-white rounded-md font-medium"
									onClick={handleSubmit}
								>
									Continuer
								</button>
							</Col>
						</Row>
					</div>
				</FormContainer>
			</CSSTransition>
		</>
	);
}
