import { createSlice } from "@reduxjs/toolkit";
import { verifyEmailAction } from "./actions";

const initialState = {
	verifyEmailStatus: "",
	verifyEmailError: "",
};

const verifyEmailTeacherSlice = createSlice({
	name: "verifyemail",
	initialState,
	reducers: {},
	extraReducers: {
		[verifyEmailAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				verifyEmailStatus: "pending",
				verifyEmailError: "",
			};
		},
		[verifyEmailAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				verifyEmailStatus: "success",
				verifyEmailError: "",
			};
		},
		[verifyEmailAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				verifyEmailStatus: "rejected",
				verifyEmailError: payload,
			};
		},
	},
});

export default verifyEmailTeacherSlice.reducer;
