import React from "react";

import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from "@chakra-ui/react";
import FolderBackgroundHead from "../../assets/images/folder-background-head.png";
import FolderBackground from "../../assets/images/folder-background.png";
import ActivityCard from "./ActivityCard/ActivityCard";
import ActivityMenu from "./ActivityMenu";

export default function FolderCard({ activity }) {
	const { isOpen, onToggle } = useDisclosure();
	const { id } = activity;

	return (
		<>
			<div className='relative'>
				{/* rome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
				<img onClick={onToggle} src={FolderBackgroundHead} className="absolute" alt="" />

				<ActivityMenu
					id={id}
					activity={activity}
					isFolder={true}
					className='absolute right-1 top-3 text-white w-7 h-7 cursor-pointer'
				/>
				{/* rome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
				<img onClick={onToggle} src={FolderBackground} alt="" />

				<button onClick={onToggle} type="button" className='absolute top-[45px] w-full'>
					<h3 className='text-[14px] font-bold text-center'>{activity.name}</h3>
				</button>
			</div>

			<Modal onClose={onToggle} isOpen={isOpen} isCentered size="6xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{activity.name}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<div className="flex flex-wrap gap-3">
							{activity.activities.map((activity) => (
									<ActivityCard key={activity.id} activity={activity} />
							))}
						</div>
					</ModalBody>
					<ModalFooter>
						<Button onClick={onToggle}>Fermer</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
