import styled from "styled-components";
import bgImage from "../../assets/images/sigup-student.png";
export const FormContainer = styled.form`
    width: 470px;
    padding: 15px;
     background: url(${bgImage}) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 16px;
`;
