import { createSlice } from "@reduxjs/toolkit";
import { getTeacherDashboardAction } from "./actions";

const initialState = {
	dashboardStatus: "",
	dashboardType: "",
	dashboardError: "",
	dashboard: "",
};

const dashboardSlice = createSlice({
	name: "dashboard",
	initialState,
	reducers: {},
	extraReducers: {
		[getTeacherDashboardAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				dashboardStatus: "pending",
				dashboardType: "",
				dashboard: "",
				dashboardError: "",
			};
		},
		[getTeacherDashboardAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				dashboard: payload,
				dashboardStatus: "success",
				dashboardType: "get",
				dashboardError: "",
			};
		},
		[getTeacherDashboardAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				dashboardStatus: "rejected",
				dashboardType: "get",
				dashboardError: payload,
				dashboard: "",
			};
		},
	},
});

export default dashboardSlice.reducer;
