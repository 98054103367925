import { useFormik } from "formik";
import { map } from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { AiFillCaretLeft } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { RiCloseLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import * as Yup from "yup";

import "./style.css";

import Logo from "../../../../assets/images//logo-iwin.png";
import SigUpBgImg from "../../../../assets/images/sigup-student.png";
import SquareTopRight from "../../../../assets/images/square-top-right.png";

const FormContainer = styled.form`
    width: 470px;
    padding: 30px 15px;
     background: url(${SigUpBgImg}) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 16px;
`;

const Index = () => {
	const { registerStatus, registerError } = useSelector((state) => ({
		registerStatus: state.registerStudentState.registerStatus,
		registerError: state.registerStudentState.registerError,
	}));
	let location = useLocation();
	const state = location.state;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [formSubmited, setFormSubmited] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [hobby, setHobby] = useState({ id: "", label: "" });
	const [existHobby, setExistHobby] = useState(false);

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			firstName: state.firstName,
			lastName: state.lastName,
			username: state.username || "",
			email: state.email || "",
			emailParent: state.emailParent || "",
			password: state.password,
			confirmPassword: state.confirmPassword,
			level: state.level,
			birthDay: state.birthDay,
			age: state.age,
			country: state.country,
			accepteTerm: state.accepteTerm,
			rememberMe: state.rememberMe,
			hobbies: [],
			rep: "",
		},
		validationSchema: Yup.object({
			firstName: Yup.string().required("Veuillez remplir le prénom !"),
			lastName: Yup.string().required("Veuillez remplir le nom !"),
			age: Yup.number().required(),
			birthDay: Yup.date().required(),
			username: Yup.string().when("age", {
				is: (age) => age < 13,
				then: Yup.string().required("Veuillez remplir un nom d'utilisateur !"),
			}),
			email: Yup.string().when("age", {
				is: (age) => age >= 13,
				then: Yup.string()
					.required("Veuillez remplir l'email !")
					.email("Veuillez entrer un email valid !"),
			}),
			emailParent: Yup.string().when("age", {
				is: (age) => age < 13,
				then: Yup.string()
					.required("Veuillez remplir l'email !")
					.email("Veuillez entrer un email valid !"),
			}),
			password: Yup.string().required("Veuillez remplir le mot de passe !"),
			confirmPassword: Yup.string()
				.required("Veuillez confirmer le le mot de passe !")
				.when("password", {
					is: (val) => (val && val.length > 0 ? true : false),
					then: Yup.string().oneOf(
						[Yup.ref("password")],
						"Les mot de passe ne correspondent pas !",
					),
				}),
			level: Yup.object().required("Veuillez sélectionner le niveau !"),
			country: Yup.object().required("Veuillez sélectionner le pays !"),
			accepteTerm: Yup.boolean()
				.required("Veuillez accepter nos CGU  et politiques de confidentialité !")
				.oneOf([true], "Veuillez accepter nos CGU  et politiques de confidentialité !"),
			rememberMe: Yup.boolean(),
			rep: Yup.object().required("Veuillez sélectionner votre réponse !"),
			hobbies: Yup.array()
				.of(
					Yup.object()
						.shape({
							id: Yup.string().required("Veuillez saisir vos centres d'intérêt !"),
							label: Yup.string().required("Veuillez saisir vos centres d'intérêt !"),
						})
						.required("Veuillez saisir vos centres d'intérêt !"),
				)
				.required("Veuillez saisir vos centres d'intérêt !"),
		}),
		onSubmit: (values) => {
			console.info(values);
			//setFormSubmited( true );
			//dispatch( registerStudentAction( values ) );
		},
	});

	const customStyles = {
		control: (base) => ({
			...base,
			height: "42px",
			border: "1px solid rgba(50, 202,188, 0.47)",
			background: "rgba(50, 202,188, 0.02)",
			color: "#303972",
			boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
			filter: "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
			borderRadius: "0.375rem",
			"&:hover": {
				border: "1px solid rgba(50, 202,188, 0.47)",
			},
		}),
		option: (provided, state) => ({
			...provided,
			fontSize: "16px",
		}),
		placeholder: (defaultStyles) => {
			return {
				...defaultStyles,
				color: "#000000",
				fontSize: "16px",
			};
		},
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = "opacity 300ms";
			const fontSize = "16px";
			const color = "#000000";
			const textTransform = "capitalize";
			return { ...provided, color, fontSize, opacity, transition, textTransform };
		},
		input: (styles, { data }) => ({
			...styles,
			outline: "none",
			border: "none",
			focus: "none",
			color: "#000000",
			textTransform: "capitalize",
		}),
		indicatorSeparator: (base) => ({
			...base,
			display: "none",
		}),
		dropdownIndicator: (base) => ({
			...base,
			color: "#303972",
			"&:hover": {
				color: "#303972",
			},
		}),
		menuList: (base) => ({
			...base,
			"::-webkit-scrollbar": {
				width: "4px",
				height: "0px",
				color: "#303972",
				textTransform: "capitalize",
			},
			"::-webkit-scrollbar-track": {
				background: "#f1f1f1",
			},
			"::-webkit-scrollbar-thumb": {
				background: "#888",
			},
			"::-webkit-scrollbar-thumb:hover": {
				background: "#555",
			},
		}),
	};

	const resps = [
		{ value: "un ami", label: "Un ami" },
		{ value: "une personne de ma famille", label: "Une personne de ma famille" },
		{ value: "un professeur", label: "Un professeur" },
		{ value: "une publicité pour iWinSciences", label: "Une publicité pour iWinSciences" },
		{
			value: "une publicité pour le jeu Scibot's Sciences Labs",
			label: "Une publicité pour le jeu Scibot's Sciences Labs",
		},
		{ value: "une recherche Google", label: "Une recherche Google" },
		{ value: "autre", label: "Autre" },
	];

	const hobbyModal = () => {
		return showModal ? (
			<CSSTransition in={true} appear={true} timeout={300} classNames="fade">
				<div className="fixed inset-0 z-50 overflow-y-auto">
					<div
						className="fixed inset-0 w-full h-full bg-black opacity-70"
						onClick={() => setShowModal(false)}
					/>
					<div className="flex items-center min-h-screen px-4">
						<div className="relative w-full max-w-xs px-4 py-5 mx-auto bg-white rounded-md shadow-lg">
							<div className="w-full flex flex-col justify-center items-start gap-3">
								<p className="text-3lg font-bold text-iwingreen gap-4"> Ajouter</p>
								<input
									type="text"
									className='focus:border-inputbg[0.47] px-3 py-2'
									value={hobby.label}
									onChange={(e) => setHobby({ id: 0, label: e.target.value })}
									id="hobby"
									name="hobby"
								/>
								{existHobby ? (
									<small className="text-[12px] text-red-400">Cet élément existe déjà !</small>
								) : null}
								<button
									type='button'
									onClick={() => handleAddHobby()}
									className='bg-iwingreen rounded px-3 py-2 text-white font-semibold'
								>
									Ajouter
								</button>
							</div>
						</div>
					</div>
				</div>
			</CSSTransition>
		) : null;
	};

	const handleAddHobby = () => {
		const h = validation.values.hobbies.filter(
			(hob) => hob.label.toLowerCase() === hobby.label.toLowerCase(),
		);
		if (h.length > 0) {
			setExistHobby(true);
		} else {
			let hs = [...validation.values.hobbies, hobby];
			validation.setFieldValue("hobbies", hs);
			setExistHobby(false);
			setHobby({ id: "", label: "" });
			setShowModal(false);
		}
	};

	const handleRemoveHobby = (index) => {
		if (index > -1) {
			let hs = validation.values.hobbies;
			hs.splice(index, 1);
			validation.setFieldValue("hobbies", hs);
		}
	};

	useEffect(() => {
		if (formSubmited && registerStatus === "success") {
			navigate("/account-created", { replace: true, state: { type: "Student" } });
		}
	}, [dispatch, registerStatus]);

	return (
		<CSSTransition in={true} appear={true} timeout={300} classNames="fade">
			<div className="flex flex-col justify-center items-center mt-10">
				<div className="absolute z-10 top-5">
					<img src={Logo} alt="LOGO" className="h-8" />
				</div>

				<div className="absolute z-10 top-5 flex justify-start items-center left-5">
					<Link
						to={"/register"}
						className="px-4 py-2 text-white text-sm rounded-md bg-iwingreen flex flex-row justify-start items-center"
					>
						<AiFillCaretLeft className="w-5 h-5 -ml-1" /> Retour
					</Link>
				</div>
				<FormContainer
					onSubmit={(e) => {
						e.preventDefault();
						validation.handleSubmit();
						return false;
					}}
					className={`flex flex-col justify-center items-center relative z-30 mb-5 mt-3 ${validation.errors.length > 0 ? "pb-10" : ""
						}`}
				>
					<img src={SquareTopRight} className="absolute top-0 right-0 rounded-tr-[16px]" alt="" />
					<Row className="w-full">
						<Col md={12} className="mt-3 mb-5">
							<h2 className='text-iwingreen font-medium'>
								Bienvenue <span className='font-bold'>{state?.firstName}</span>
							</h2>
						</Col>
						<Col
							xs={12}
							sm={12}
							md={12}
							className='flex flex-col justify-start items-start gap-3 mb-4'
						>
							<label htmlFor="rep">
								Comment avez-vous connu iWinSciences? <span className='text-red-500'>*</span>
							</label>
							<Select
								id="rep"
								name="rep"
								styles={customStyles}
								options={resps}
								placeholder="Choisir Réponse..."
								className="w-full"
								classNamePrefix="select"
								onChange={(e) => {
									validation.setFieldValue("rep", e);
								}}
								onBlur={validation.handleBlur}
								value={validation.values.rep}
								invalid={validation.touched.rep && validation.errors.rep}
							/>
							{validation.touched.rep && validation.errors.rep ? (
								<small className="text-[9px] text-red-400">{validation.errors.rep}</small>
							) : null}
						</Col>

						<Col md={12} className='flex flex-col justify-start items-start gap-3 mb-4'>
							<label htmlFor="rep">
								Quels sont vos centres d'intérêt ? <span className='text-red-500'>*</span>
							</label>
							<div className='w-full p-2 border border-iwingreen/[.6] rounded flex justify-between items-center gap-2'>
								<div className='grid grid-cols-4 gap-2'>
									{map(validation.values.hobbies, (hobby, index) => (
										<div>
											<div
												key={`hobby-${index}`}
												className='w-full flex justify-between items-center rounded bg-iwingreen text-[12px] text-white font-bold px-2 py-1'
											>
												<span className=''>{hobby.label}</span>
												<RiCloseLine
													onClick={() => handleRemoveHobby(index)}
													className="w-4 h-4 cursor-pointer"
												/>
											</div>
										</div>
									))}
								</div>
								<button
									onClick={() => setShowModal(!showModal)}
									type="button"
									className='p-2 rounded bg-gray-300'
								>
									<FaPlus className='text-gray-500 w-3 h-3' />
								</button>
							</div>
							{validation.touched.hobbies && validation.errors.hobbies ? (
								<small className="text-[9px] text-red-400">{validation.errors.hobbies}</small>
							) : null}
						</Col>
						<Col lg={12} className='w-full flex justify-center items-center mt-3'>
							<button
								type="submit"
								className="text-sm bg-iwingreen px-5 py-2 text-white rounded-md font-medium"
							>
								S'inscrire
							</button>
						</Col>
					</Row>
				</FormContainer>
				{hobbyModal()}
			</div>
		</CSSTransition>
	);
};

export default Index;
