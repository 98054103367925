import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import "./style.css";

import MaskImg from "../../assets/images/SCIBOT.png";
import CheckImg from "../../assets/images/icon-check.png";
import SigUpBgImg from "../../assets/images/sigup-student.png";

const DefaultContainer = styled.div`
    max-width: 450px;
    padding: 40px 60px;
    background: url(${SigUpBgImg}) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 16px;
`;

const AlertContainer = styled.div`
    max-width: 450px;
    padding: 10px 15px;
    background: #F4DC62 no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 10px;
    margin-bottom: 20px;
`;

const Index = () => {
	const location = useLocation();
	const type = location?.state?.type;

	const getMessage = () => {
		switch (type) {
			case "Parent":
				return (
					<div>
						<p>
							Félicitations , tu as bien rejoins la communauté des <strong>parents</strong> sur{" "}
							<strong>iwinSciences</strong>
						</p>
						<p>
							Consulte ta boîte mail pour
							<a href='https://mail.google.com/mail' className="underline-offset-1">
								<u>valider ton compte.</u>
							</a>
						</p>
						<p>
							Cliquez <Link to="/">ici</Link>pour vous connecter
						</p>
					</div>
				);
			case "Student":
				return (
					<div>
						<p>
							Félicitations , tu as bien rejoins la communauté des <strong>élèves</strong> sur{" "}
							<strong>iwinSciences</strong>
						</p>
						<p>
							Consulte ta boîte mail pour
							<a href='https://mail.google.com/mail' className="underline-offset-1">
								{" "}
								<u>valider ton compte.</u>
							</a>
						</p>

						<p>
							Cliquez <Link to="/">ici</Link>pour vous connecter
						</p>
					</div>
				);
			case "Teacher":
				return (
					<div>
						<p>Félicitations , votre compte a été créé avec succès !</p>
						<p>
							Consulte ta boîte mail pour
							<a href='https://mail.google.com/mail' className="underline-offset-1">
								{" "}
								<u>valider ton compte.</u>
							</a>
						</p>

						<p>
							Pour vous connecter, cliquez{" "}
							<Link to="/login">
								<u>ici</u>
							</Link>
						</p>
					</div>
				);
		}
	};

	useEffect(() => {}, []);
	return (
		<CSSTransition in={true} appear={true} timeout={300} classNames="fade">
			<div className="flex flex-col justify-center items-center -mt-40">
				<AlertContainer className="md:w-[450px]">
					<div className="w-full flex flex-row justify-start items-center text-[12px] bg-notifbg text-notiftext">
						<img src={CheckImg} alt="" className="mr-5" />
						{getMessage()}
					</div>
				</AlertContainer>

				<DefaultContainer className="flex flex-col justify-center items-center md:w-[450px]">
					<img src={MaskImg} alt="" className="w-52" />
				</DefaultContainer>
			</div>
		</CSSTransition>
	);
};

export default Index;
