import { createSlice } from "@reduxjs/toolkit";
import {
	checkClassroomExistAction,
	resetCheckClassroomExistAction,
	editClassroomAction,
	addClassroomAction,
	getClassroomsAction,
	getClassroomAction,
	addStudentClassAction,
} from "./actions";

const initialState = {
	classroomStatus: "",
	classroomType: "",
	classroom: "",
	classroomError: "",
	existClassroom: false,
	classrooms: [],
};

const classroomSlice = createSlice({
	name: "classrooms",
	initialState,
	reducers: {},
	extraReducers: {
		[getClassroomsAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				classroomStatus: "pending",
				classroomType: "",
				classroom: "",
				classroomError: "",
				classrooms: [],
			};
		},
		[getClassroomsAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				classrooms: payload,
				classroomStatus: "success",
				classroomType: "get",
				classroomError: "",
			};
		},
		[getClassroomsAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				classroomStatus: "rejected",
				classroomType: "get",
				classroomError: payload,
				classrooms: [],
			};
		},

		[getClassroomAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				classroomStatus: "pending",
				classroomType: "",
				classroom: "",
				classroomError: "",
			};
		},
		[getClassroomAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				classroom: payload,
				classroomStatus: "success",
				classroomType: "getOne",
				classroomError: "",
			};
		},
		[getClassroomAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				classroomStatus: "rejected",
				classroomType: "getOne",
				classroomError: payload,
				classroom: "",
			};
		},

		[addClassroomAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				classroomStatus: "pending",
				classroomType: "",
				classroom: "",
				classroomError: "",
			};
		},
		[addClassroomAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				classroom: payload,
				classroomStatus: "success",
				classroomType: "add",
				classroomError: "",
			};
		},
		[addClassroomAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				classroomStatus: "rejected",
				classroomType: "add",
				classroomError: payload,
				classroom: "",
			};
		},

		[editClassroomAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				classroomStatus: "pending",
				classroomType: "",
				classroom: "",
				classroomError: "",
			};
		},
		[editClassroomAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				classroom: payload,
				classroomStatus: "success",
				classroomType: "edit",
				classroomError: "",
			};
		},
		[editClassroomAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				classroomStatus: "rejected",
				classroomType: "edit",
				classroomError: payload,
				classroom: "",
			};
		},

		[addStudentClassAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				classroomStatus: "pending",
				classroomType: "",
				classroomError: "",
			};
		},
		[addStudentClassAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				classroomStatus: "success",
				classroomType: "addStudent",
				classroomError: "",
			};
		},
		[addStudentClassAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				classroomStatus: "rejected",
				classroomType: "addStudent",
				classroomError: payload,
				classroom: "",
			};
		},

		[checkClassroomExistAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				classroomStatus: "pending",
				classroomType: "",
				classroomError: "",
			};
		},
		[checkClassroomExistAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				existClassroom: payload,
				classroomStatus: "success",
				classroomType: "check",
				classroomError: "",
			};
		},
		[checkClassroomExistAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				classroomStatus: "rejected",
				classroomType: "check",
				classroomError: payload,
			};
		},

		[resetCheckClassroomExistAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				classroomStatus: "pending",
				classroomError: "",
				classroomType: "",
				existClassroom: false,
			};
		},
		[resetCheckClassroomExistAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				existClassroom: "",
				classroomStatus: "success",
				classroomType: "reset",
				classroomError: "",
			};
		},
		[resetCheckClassroomExistAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				classroomStatus: "rejected",
				classroomType: "reset",
				classroomError: "",
			};
		},
	},
});

export default classroomSlice.reducer;
