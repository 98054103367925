import { map } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { TiPlus } from "react-icons/ti";
import Select from "react-select";
import { getFistLetter } from "../../../helpers/common";
import { reactSelectTheme } from "../../common/react-select";

export default function ClassGroups(props) {
	const [groupes, setGroupes] = useState([]);
	const [groupeId, setGroupId] = useState();

	const syncGroupe = () =>
		setGroupes(props.groups.map((item) => ({ label: item.name, value: item.id })));
	const findSelectedGroup = useCallback(() => {
		if (!groupeId) return;
		const groupe = props.groups.find((item) => item.id === groupeId);
		if (!groupe) throw new Error(`can't find groupe ${groupeId}`);
		return groupe;
	}, [groupeId]);

	useEffect(syncGroupe, [props]);

	return (
		<div className='w-full bg-white iwin-shadow-2 rounded-xl flex flex-col justify-start items-center p-6 overscroll-auto'>
			<div className='w-full flex justify-between items-center gap-2'>
				<div className='flex justify-between items-center gap-5'>
					<span className='text-[18px] text-iwinblue font-bold'>Mes groupes</span>
					<Select
						id="group"
						name="group"
						{...reactSelectTheme}
						styles={customStyles}
						options={groupes}
						placeholder="Choisir Groupe..."
						className="w-[200px]"
						onChange={(group) => setGroupId(group.value)}
					/>
				</div>
				<div className='flex justify-end items-center gap-2'>
					<button className='flex justify-start items-center px-3 py-2 rounded-md border-[.14rem] border-iwingreen text-sm text-iwingreen font-medium gap-2'>
						<span>Ajouter des élèves</span>
						<TiPlus className='' />
					</button>
				</div>
			</div>
			<div className='w-full py-5'>
				<table className='w-full'>
					<tbody className='mt-5'>
						{map(findSelectedGroup()?.students ?? [], (student, index) => (
							<tr key={`tb-${index}`} className=''>
								<td className='flex justify-start items-center gap-2 text-sm  text-iwinblue font-bold py-3'>
									<div className="inline-flex overflow-hidden relative justify-center items-center w-10 h-10 border-2 border-iwingreen bg-gray-100 rounded-full dark:bg-gray-600">
										<span className="font-black text-gray-600 dark:text-gray-300">
											{getFistLetter(student.firstName)} {getFistLetter(student.lastName)}
										</span>
									</div>
									<span className='capitalize'>
										{student.firstName} {student.lastName}
									</span>
								</td>
								<td className='py-3'>
									<div className='text-iwinblue font-bold text-sm flex justify-start items-center gap-2'>
										<span>00/100</span>
										<div className='w-[20px] px-4 py-2 bg-iwingreen rounded-xl' />
									</div>
								</td>
								<td className='py-3 flex justify-end items-center'>
									<BiDotsVerticalRounded className='w-6 h-6 text-gray-400 cursor-pointer' />
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

const customStyles = {
	control: (base) => ({
		...base,
		border: "1px solid rgba(50, 202,188, 0.47)",
		background: "rgba(50, 202,188, 0.02)",
		paddingBottom: "2px",
		paddingTop: "2px",
		boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
		borderRadius: "0.375rem",
		"&:hover": {
			border: "1px solid rgba(50, 202,188, 0.47)",
		},
	}),

	indicatorSeparator: (base) => ({
		...base,
		display: "none",
	}),
	dropdownIndicator: (base) => ({
		...base,
		color: "#303972",
		"&:hover": {
			color: "#303972",
		},
	}),

	theme: (theme) => ({
		colors: {
			...theme.colors,
			primary25: "#009688",
			primary: "#009688",
		},
	}),
};
