import { useFormik } from "formik";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect, useState } from "react";
import { BsEye } from "react-icons/bs";
import { MdOutlineModeEdit } from "react-icons/md";
import { TiPlus } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Select from "react-select";

import { getSubThemesByTheme } from "../../../helpers/backend_helper";

import CategorieComponent from "../components/paire-type-categorie";
import GlisserComponent from "../components/paire-type-glisser";
import PiegeComponent from "../components/paire-type-piege";
import RelierComponent from "../components/paire-type-relier";
const Index = ({ themesList }) => {
	let navigate = useNavigate();
	const [actionIndex, setActionIndex] = useState(0);
	const [activePaire, setActivePaire] = useState(0);
	const [subThemesList, setSubThemesList] = useState([]);

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			title: "",
			theme: "",
			typeAssociation: { value: "relier", label: "RELIER" },
			paires: [
				{
					paireOne: {
						type: "text",
						text: "",
						img: "",
					},
					paireTwo: {
						type: "text",
						text: "",
						img: "",
					},
					point: {},
					paireVisibility: {},
				},
			],
			subtheme: "",
			feedback: "",
		},
		validationSchema: Yup.object({
			title: Yup.string().required("Veuillez saisir le titre !"),
			theme: Yup.object().required("Veuillez sélectionner un thème !"),
			subtheme: Yup.object().required("Sélectionner un sous thème !"),
			typeAssociation: Yup.object().required("Sélectionner le type d'association !"),
		}),
		onSubmit: (values) => {},
	});
	const customStyles = {
		control: (base) => ({
			...base,
			border: "1px solid rgba(50, 202,188, 0.47)",
			background: "rgba(50, 202,188, 0.02)",
			color: "#303972",
			paddingBottom: "1px",
			paddingTop: "1px",
			height: "40px",
			boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
			filter: "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
			borderRadius: "0.375rem",
			"&:hover": {
				border: "1px solid rgba(50, 202,188, 0.47)",
			},
		}),
		option: (provided, state) => ({
			...provided,
			fontSize: "12px",
		}),
		placeholder: (defaultStyles) => {
			return {
				...defaultStyles,
				color: "#303972",
				fontSize: "12px",
				padding: "1px",
			};
		},
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = "opacity 300ms";
			const fontSize = "12px";
			const color = "#303972";
			return { ...provided, color, fontSize, opacity, transition };
		},
		input: (styles, { data }) => ({
			...styles,
			outline: "none",
			border: "none",
			focus: "none",
			color: "#303972",
			fontSize: "12px",
		}),
		indicatorSeparator: (base) => ({
			...base,
			display: "none",
		}),
		dropdownIndicator: (base) => ({
			...base,
			color: "#303972",
			"&:hover": {
				color: "#303972",
			},
		}),
		menuList: (base) => ({
			...base,
			"::-webkit-scrollbar": {
				width: "4px",
				height: "0px",
				color: "#303972",
			},
			"::-webkit-scrollbar-track": {
				background: "#f1f1f1",
			},
			"::-webkit-scrollbar-thumb": {
				background: "#888",
			},
			"::-webkit-scrollbar-thumb:hover": {
				background: "#555",
			},
		}),
	};

	const customMultiStyles = {
		control: (base) => ({
			...base,
			border: "1px solid rgba(50, 202,188, 0.47)",
			background: "rgba(50, 202,188, 0.02)",
			color: "#303972",
			boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
			filter: "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
			borderRadius: "0.375rem",
			"&:hover": {
				border: "1px solid rgba(50, 202,188, 0.47)",
			},
		}),
		option: (provided, state) => ({
			...provided,
			fontSize: "12px",
		}),
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = "opacity 300ms";
			const fontSize = "12px";
			const color = "#303972";
			return { ...provided, color, fontSize, opacity, transition };
		},
		placeholder: (defaultStyles) => {
			return {
				...defaultStyles,
				color: "#303972",
				fontSize: "10px",
			};
		},
		multiValue: (styles, { data }) => {
			return {
				...styles,
				backgroundColor: "#009688",
				borderRadius: "50px",
				color: "#FFFFFF",
				padding: "2px 7px",
				textTransform: "uppercase",
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "#FFFFFF",
			fontWeight: "bold",
			fontSize: "10px",
		}),
		input: (styles, { data }) => ({
			...styles,
			outline: "none",
			border: "none",
			color: "#303972",
			focus: "none",
		}),
		menuList: (base) => ({
			...base,
			"::-webkit-scrollbar": {
				width: "4px",
				height: "0px",
			},
			"::-webkit-scrollbar-track": {
				background: "#f1f1f1",
			},
			"::-webkit-scrollbar-thumb": {
				background: "#888",
			},
			"::-webkit-scrollbar-thumb:hover": {
				background: "#555",
			},
		}),
	};

	const handleChangeTheme = (e) => {
		validation.setFieldValue("subtheme", { value: "", label: "" });
		validation.setFieldValue("theme", e);
		if (e.value !== validation.values.theme) {
			getSubThemesByTheme(e.value).then((data) => {
				setSubThemesList(data.data);
			});
		}
	};

	const handleChangeSubTheme = (e) => {
		validation.setFieldValue("subtheme", e);
	};
	/** =================== TYPE RELIER ================= */
	const handleAddRelierEtape = () => {
		let paire = {
			paireOne: {
				type: "text",
				text: "",
				img: "",
			},
			paireTwo: {
				type: "text",
				text: "",
				img: "",
			},
			point: {},
			paireVisibility: {},
		};
		validation.setFieldValue("paires", [...validation.values.paires, paire]);
		setActivePaire(validation.values.paires.length);
	};

	/** =================== TYPE CATEGORY ================= */
	const handleAddCategorieEtape = () => {
		let etape = {
			etape: {
				img: "",
				etape: "",
			},
			elements: [
				{
					img: "",
					etape: "",
				},
			],
		};
		validation.setFieldValue("etapes", [...validation.values.etapes, etape]);
	};

	/** =================== TYPE GLISSER ================= */
	const handleAddGlisserEtape = () => {
		let etape = {
			etapeOne: {
				img: "",
				etape: "",
			},
			etapeTwo: {
				img: "",
				etape: "",
			},
		};
		validation.setFieldValue("etapes", [...validation.values.etapes, etape]);
		setActivePaire(validation.values.paires.length);
	};

	const handleAddPiegeEtape = () => {
		validation.setFieldValue("pieges", [
			{
				img: "",
				etape: "",
			},
		]);
	};

	const handleDeletePaire = (index) => {
		let data = validation.values.paires.filter((c, i) => i !== index);
		if (data.length > 0) {
			validation.setFieldValue("paires", data);
			if (index > 0) {
				setActivePaire(index - 1);
			} else {
				setActivePaire(0);
			}
		}
	};

	const handleCopyPaire = (index) => {
		let paire = validation.values.paires[index];
		if (paire) {
			if (paire.paireOne.type === "text") {
				paire = { ...paire, paireOne: { ...paire.paireOne, text: `${paire.paireOne.text} copy` } };
			}
			if (paire.paireTwo.type === "text") {
				paire = { ...paire, paireTwo: { ...paire.paireTwo, text: `${paire.paireOne.text} copy` } };
			}
			validation.setFieldValue("paires", [...validation.values.paires, paire]);
			setActivePaire(validation.values.paires.length);
		}
	};

	const initGlisser = () => {
		let etape = {
			etapeOne: {
				img: "",
				etape: "",
			},
			etapeTwo: {
				img: "",
				etape: "",
			},
		};
		let piege = {
			img: "",
			etape: "",
		};
		validation.setFieldValue("pieges", [...validation.values.pieges, piege]);
		validation.setFieldValue("etapes", [...validation.values.etapes, etape]);
	};

	const handleChangePointPaire = (e) => {};
	const handleChangePaireVisibility = (e) => {};
	const handleChangeCardOrderPaire = (e) => {};
	const handleChangePositionPaire = (e) => {};

	const handleChangeInputTypePaireOne = (type, index) => {
		let data = [...validation.values.paires];
		let paire = data[index];
		if (type !== paire.type) {
			if (type === "text") {
				paire = { ...paire, paireOne: { ...paire.paireOne, img: "", type: type } };
			} else {
				paire = { ...paire, paireOne: { ...paire.paireOne, text: "", type: type } };
			}
			data[index] = paire;
			validation.setFieldValue("paires", data);
		}
	};

	const handleChangeInputTypePaireTwo = (type, index) => {
		let data = [...validation.values.paires];
		let paire = data[index];
		if (type !== paire.type) {
			if (type === "text") {
				paire = { ...paire, paireTwo: { ...paire.paireTwo, img: "", type: type } };
			} else {
				paire = { ...paire, paireTwo: { ...paire.paireTwo, text: "", type: type } };
			}
			data[index] = paire;
			validation.setFieldValue("paires", data);
		}
	};

	const handlePaireOnePhoto = (e, index) => {
		const file = e.target.files[0];
		let data = [...validation.values.paires];
		let paire = data[index];
		paire = { ...paire, paireOne: { ...paire.paireOne, text: "", img: file, type: "img" } };
		data[index] = paire;
		validation.setFieldValue("paires", data);
	};

	const handlePaireTwoPhoto = (e, index) => {
		const file = e.target.files[0];
		let data = [...validation.values.paires];
		let paire = data[index];
		paire = { ...paire, paireTwo: { ...paire.paireTwo, text: "", img: file, type: "img" } };
		data[index] = paire;
		validation.setFieldValue("paires", data);
	};

	useEffect(() => {
		if (validation.values.typeAssociation === "relier") {
			handleAddRelierEtape();
		} else if (validation.values.typeAssociation === "catégorie") {
			handleAddCategorieEtape();
		} else if (validation.values.typeAssociation === "glisser") {
			initGlisser();
		}
	}, [validation.values.typeAssociation]);

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				validation.handleSubmit();
				return false;
			}}
			className='w-full'
		>
			<div className='w-full flex justify-between items-center'>
				<div className='flex justify items-center'>
					<div className='w-56 flex flex-col justify-start items-start gap-1'>
						<label className=''>Titre</label>
						<input
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.title}
							invalid={validation.touched.title && validation.errors.title}
							type="text"
							id="title"
							name="title"
							placeholder="Saisir le titre de l'activité"
							className='text-[12px] w-20 px-2 py-2 border border-iwingreen/[.4] rounded-lg'
						/>
						{validation.touched.title && validation.errors.title ? (
							<small className="text-[9px] text-red-400">{validation.errors.title}</small>
						) : null}
					</div>
					<div className='w-36 ml-2 flex flex-col justify-start items-start gap-1'>
						<label className=''>Thème</label>
						<Select
							id="theme"
							name="theme"
							styles={customStyles}
							options={themesList}
							placeholder="---"
							className="w-full"
							classNamePrefix="select"
							onChange={(e) => {
								handleChangeTheme(e);
							}}
							value={validation.values.theme}
							invalid={validation.touched.theme && validation.errors.theme}
						/>
						{validation.touched.theme && validation.errors.theme ? (
							<small className="text-[9px] text-red-400">{validation.errors.theme}</small>
						) : null}
					</div>
					<div className='w-36 ml-2 flex flex-col justify-start items-start gap-1'>
						<label className=''>Sous-thème</label>
						<Select
							id="mode"
							name="mode"
							styles={customStyles}
							options={subThemesList}
							placeholder="---"
							className="w-full"
							classNamePrefix="select"
							onChange={(e) => {
								handleChangeSubTheme(e);
							}}
							value={validation.values.subtheme}
							invalid={validation.touched.subtheme && validation.errors.subtheme}
						/>
						{validation.touched.subtheme && validation.errors.subtheme ? (
							<small className="text-[9px] text-red-400">{validation.errors.subtheme}</small>
						) : null}
					</div>
					<div className='w-32 ml-2 flex flex-col justify-start items-start gap-1'>
						<label className=''>Arrière-plan</label>
						<div className='w-[30px] px-5 py-[6px] shadow-in border border-gray-300 bg-gray-400 rounded-lg cursor-pointer'>
							<input type="color" className='w-full h-full invisible' />
						</div>
					</div>
				</div>
				<div className='flex justify-end items-center gap-10 pt-3'>
					<div className='p-1 rounded-3xl border border-gray-300 bg-gray-200 flex justify-between items-center gap-4'>
						<div
							className={`flex justify-center items-center p-1  rounded-full cursor-pointer ${
								actionIndex === 0 ? "bg-iwingreen text-white" : "text-gray-400"
							}`}
							onClick={() => setActionIndex(0)}
						>
							<MdOutlineModeEdit className='w-4 h-4' />
						</div>
						<div
							className={`flex justify-center items-center p-1 rounded-full cursor-pointer ${
								actionIndex === 1 ? "bg-iwingreen text-white" : "text-gray-400"
							}`}
							onClick={() => setActionIndex(1)}
						>
							<BsEye className='w-4 h-4' />
						</div>
					</div>
					<button
						type='submit'
						className='px-10 py-3 text-sm font-semibold bg-gray-500 rounded-lg text-white'
					>
						Créer
					</button>
				</div>
			</div>
			<div className={"w-full bg-gray-100 rounded-lg px-3 py-2} mt-3"}>
				<div className="w-full scroller2 flex justify-start items-center gap-3">
					<div className='w-[22%] h-[62vh] flex flex-col justify-between items-center gap-3 px-0 py-1 border-r-2 border-gray-300 pr-4 '>
						<div className='w-full flex flex-col justify-between items-start gap-2'>
							<div className='w-full flex flex-col justify-between items-start gap-1'>
								<label htmlFor='typeAssociation' className='text-xs font-medium'>
									Type d'association
								</label>
								<Select
									id="typeAssociation"
									name="typeAssociation"
									styles={customStyles}
									options={[
										{ value: "relier", label: "RELIER" },
										{ value: "catégorie", label: "CATEGORIE" },
										{ value: "glisser", label: "GLISSER" },
									]}
									className="w-full"
									classNamePrefix="select"
									onChange={(e) => validation.setFieldValue("typeAssociation", e)}
									value={validation.values.typeAssociation}
								/>
							</div>
							{validation.values.typeAssociation.value === "relier" && (
								<div className='w-full flex flex-col justify-between items-start gap-2'>
									<div className='w-full flex flex-col justify-between items-start gap-1'>
										<label htmlFor='point' className='text-xs font-medium'>
											Points
										</label>
										<Select
											id="point"
											name="point"
											styles={customStyles}
											options={[]}
											className="w-full"
											classNamePrefix="select"
											onChange={(e) => handleChangePointPaire(e)}
											value={validation.values.paires[activePaire].paireOne}
										/>
									</div>
									<div className='w-full flex flex-col justify-between items-start gap-1'>
										<label htmlFor='visible' className='text-xs font-medium'>
											Paires aossociées
										</label>
										<Select
											id="visible"
											name="visible"
											styles={customStyles}
											options={[
												{ value: "visibles", label: "Visibles" },
												{ value: "cachées", label: "Cachées" },
											]}
											className="w-full"
											classNamePrefix="select"
											onChange={(e) => handleChangePaireVisibility(e)}
											value={validation.values.paires[activePaire].paireVisibility}
										/>
									</div>
								</div>
							)}
							{validation.values.typeAssociation.value === "catégorie" && (
								<div className='w-full flex flex-col justify-between items-start gap-2'>
									<div className='w-full flex flex-col justify-between items-start gap-1'>
										<label htmlFor='point' className='text-xs font-medium'>
											Points
										</label>
										<Select
											id="point"
											name="point"
											styles={customStyles}
											options={[]}
											className="w-full"
											classNamePrefix="select"
											onChange={(e) => handleChangePointPaire(e)}
											value={validation.values.paires[activePaire].pointPaire}
										/>
									</div>
									<div className='w-full flex flex-col justify-between items-start gap-1'>
										<label htmlFor='orderCartes' className='text-xs font-medium'>
											Order des cartes
										</label>
										<Select
											id="orderCartes"
											name="orderCartes"
											styles={customStyles}
											options={[{ value: "aléatoire", label: "Aléatoire" }]}
											className="w-full"
											classNamePrefix="select"
											onChange={(e) => handleChangeCardOrderPaire(e)}
											value={validation.values.paires[activePaire].orderPaire}
										/>
									</div>
									<div className='w-full flex flex-col justify-between items-start gap-1'>
										<label
											htmlFor={`position-paire-${activePaire}`}
											className='text-xs font-medium'
										>
											Affichage des cartes
										</label>
										<Select
											id={`position-paire-${activePaire}`}
											name={`position-paire-${activePaire}`}
											styles={customStyles}
											options={[{ value: "coteacote", label: "Côte à côte" }]}
											className="w-full"
											classNamePrefix="select"
											onChange={(e) => handleChangePositionPaire(e)}
											value={validation.values.paires[activePaire].positionPaire}
										/>
									</div>
								</div>
							)}
							{validation.values.typeAssociation.value === "glisser" && (
								<div className='w-full flex flex-col justify-between items-start gap-2'>
									<div className='w-full flex flex-col justify-between items-start gap-1'>
										<label htmlFor='point' className='text-xs font-medium'>
											Points
										</label>
										<Select
											id="point"
											name="point"
											styles={customStyles}
											options={[]}
											className="w-full"
											classNamePrefix="select"
											onChange={(e) => handleChangePointPaire(e)}
											value={validation.values.paires[activePaire].pointPaire}
										/>
									</div>
									<div className='w-full flex flex-col justify-between items-start gap-1'>
										<label htmlFor='orderCartes' className='text-xs font-medium'>
											Order des cartes
										</label>
										<Select
											id="orderCartes"
											name="orderCartes"
											styles={customStyles}
											options={[{ value: "aléatoire", label: "Aléatoire" }]}
											className="w-full"
											classNamePrefix="select"
											onChange={(e) => handleChangeCardOrderPaire(e)}
											value={validation.values.paires[activePaire].orderPaire}
										/>
									</div>
									<div className='w-full flex flex-col justify-between items-start gap-1'>
										<label htmlFor='affichage' className='text-xs font-medium'>
											Affichage des cartes
										</label>
										<Select
											id="affichage"
											name="affichage"
											styles={customStyles}
											options={[{ value: "coteacote", label: "Côte à côte" }]}
											className="w-full"
											classNamePrefix="select"
											onChange={(e) => handleChangePositionPaire(e)}
											value={validation.values.paires[activePaire].positionPaire}
										/>
									</div>
								</div>
							)}
						</div>
						<div className='w-full flex flex-col justify-between items-start gap-1'>
							<label className='text-xs font-medium'>Feedback final</label>
							<InputTextarea
								id="feedback"
								name="feedback"
								onChange={validation.handleChange}
								onBlur={validation.handleBlur}
								value={validation.values.feedback}
								className='w-full border border-iwingreen/[0.4] rounded-md bg-white p-2 focus:outline-none text-sm'
								rows={4}
								autoResize
							/>
						</div>
					</div>
					<div className='w-full py-5 scroller2 flex flex-col justify-between items-center'>
						<div className='w-full'>
							{validation.values.paires.map((paire, index) => (
								<div className='w-full'>
									{validation.values.typeAssociation.value === "relier" && (
										<RelierComponent
											paire={paire}
											index={index}
											activePaire={activePaire}
											setActivePaire={setActivePaire}
											handleCopyPaire={handleCopyPaire}
											handleDeletePaire={handleDeletePaire}
											handleChangeInputTypePaireOne={handleChangeInputTypePaireOne}
											handleChangeInputTypePaireTwo={handleChangeInputTypePaireTwo}
											handlePaireTwoPhoto={handlePaireTwoPhoto}
											handlePaireOnePhoto={handlePaireOnePhoto}
										/>
									)}

									{validation.values.typeAssociation.value === "catégorie" && (
										<CategorieComponent
											paire={paire}
											index={index}
											activePaire={activePaire}
											setActivePaire={setActivePaire}
											handleCopyPaire={handleCopyPaire}
											handleDeletePaire={handleDeletePaire}
										/>
									)}

									{validation.values.typeAssociation.value === "glisser" && (
										<GlisserComponent
											paire={paire}
											index={index}
											activePaire={activePaire}
											setActivePaire={setActivePaire}
											handleCopyPaire={handleCopyPaire}
											handleDeletePaire={handleDeletePaire}
										/>
									)}

									{validation.values.typeAssociation.value === "glisser" && (
										<div className='grid grid-cols-2 gap-4'>
											{validation.values.pieges.map((piege, i) => (
												<PiegeComponent piege={piege} index={i} />
											))}
											<div className='p-2' />
										</div>
									)}
								</div>
							))}
						</div>

						{validation.values.typeAssociation.value === "relier" && (
							<button
								onClick={handleAddRelierEtape}
								className='border border-gray-400 rounded-md bg-white text-center text-xs px-3 py-2 flex justify-between items-center gap-2'
							>
								<TiPlus className='w-4 h-4' />
								<span>Nouvelle paire</span>
							</button>
						)}

						{validation.values.typeAssociation.value === "catégorie" && (
							<button
								onClick={handleAddCategorieEtape}
								className='border border-gray-400 rounded-md bg-white text-center text-xs px-3 py-2 flex justify-between items-center gap-2'
							>
								<TiPlus className='w-4 h-4' />
								<span>Nouvelle paire</span>
							</button>
						)}

						{validation.values.typeAssociation.value === "glisser" && (
							<div className='w-full flex justify-center items-center gap-3'>
								<button
									onClick={handleAddGlisserEtape}
									className='border border-gray-400 rounded-md bg-white text-center text-xs px-3 py-2 flex justify-between items-center gap-2'
								>
									<TiPlus className='w-4 h-4' />
									<span>Nouvelle paire</span>
								</button>
								<button
									onClick={handleAddPiegeEtape}
									className='border border-red-400 rounded-md bg-white text-center text-xs px-3 py-2 flex justify-between items-center gap-2'
								>
									<TiPlus className='w-4 h-4' />
									<span>Nouvelle piège</span>
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</form>
	);
};

export default Index;
