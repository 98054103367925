import {
	AccountConfirmed,
	AccountCreated,
	ChangePasswordStudent,
	ConfirmEmail,
	ForgetPassword,
	Login,
	LoginParentTeacher,
	LoginStudent,
	Logout,
	ParentCompletedRegister,
	ParentRegister,
	Profile,
	ProfileParent,
	ProfileStudent,
	Register,
	StudentCompletedRegister,
	StudentRegister,
} from "../pages/Auth";

import { DashboardParent, DashboardStudent, DashboardTeacher } from "../pages/Dashboard";

import { CreateActivite } from "../pages/Activites";

import { BU, Children, Contact, DetailsChildren, Tutorial } from "../pages/parent";

import Activities from "../components/Activities/Activities";
import ClassDetails from "../components/ClassDetails/ClassDetails";
import ClassList from "../components/ClassList/ClassList";
import CreateClass from "../components/CreateClass/CreateClass";
import CreateClassStudent from "../components/CreateClassStudent/CreateClassStudent";
import EditClass from "../components/EditClass/EditClass";
import ResetPassword from "../components/ResetPassword/ResetPassword";
import CreateTeacher from "../components/Teacher/CreateTeacher/CreateTeacher";
import CreateTeacherSkills from "../components/Teacher/CreateTeacherSkills/CreateTeacherSkills";
import EtablissementForm from "../components/Teacher/EtablissementForm/EtablissementForm";
import { StudentActivite, StudentGame } from "../pages/student";

const publicRoutes = [
	{ path: "/login", component: Login },
	{ path: "/logout", component: Logout },
	{ path: "/register", component: Register },
	{ path: "/forget-password", component: ForgetPassword },
	{ path: "/account-created", component: AccountCreated },
	{ path: "/account-confirmed", component: AccountConfirmed },
	{ path: "/verify-email/:token", component: ConfirmEmail },
	{ path: "/reset-password/:token", component: ResetPassword },

	{ path: "/login/parent-teacher", component: LoginParentTeacher },
	{ path: "/register/teacher", component: CreateTeacher },
	{ path: "/register/teacher-completed/skills", component: CreateTeacherSkills },
	{ path: "/register/teacher-completed/school", component: EtablissementForm },
	{ path: "/register/parent", component: ParentRegister },
	{ path: "/register/parent-completed", component: ParentCompletedRegister },

	{ path: "/login/student", component: LoginStudent },
	{ path: "/change-password/student", component: ChangePasswordStudent },
	{ path: "/register/student", component: StudentRegister },
	{ path: "/register/student-completed", component: StudentCompletedRegister },
];

const teacherRoutes = [
	{ path: "/", component: DashboardTeacher },
	{ path: "/profile", component: Profile },
	{ path: "/classes", component: ClassList },
	{ path: "/classes/:id", component: ClassDetails },
	{ path: "/add-class", component: CreateClass },
	{ path: "/edit-class/:id", component: EditClass },
	{ path: "/game", component: Tutorial },
	{ path: "/add-student-to-class", component: CreateClassStudent },
	{ path: "/activites", component: Activities },
	{ path: "/create-activity", component: CreateActivite },
];

const studentRoutes = [
	{ path: "/students", component: DashboardStudent },
	{ path: "/students/profile", component: ProfileStudent },
	{ path: "/students/bu", component: BU },
	{ path: "/students/activites", component: StudentActivite },
	{ path: "/students/create", component: Tutorial },
	{ path: "/students/game", component: StudentGame },
	{ path: "/students/tutorials", component: Tutorial },
	{ path: "/students/contact", component: Contact },
];

const parentRoutes = [
	{ path: "/parent", component: DashboardParent },
	{ path: "/parent/bu", component: BU },
	{ path: "/parent/children", component: Children },
	{ path: "/parent/tutorials", component: Tutorial },
	{ path: "/parent/contact", component: Contact },
	{ path: "/parent/profile", component: ProfileParent },
	{ path: "/parent/children/details", component: DetailsChildren },
];

const authProtectedRoutes = [];

export { authProtectedRoutes, parentRoutes, publicRoutes, studentRoutes, teacherRoutes };
