import {
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
	useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { toast } from "react-toastify";

export default function DeleteStudent({ handleRemoveStudent, student }) {
	const [loading, setLoading] = useState(false);
	const [confirm, setConfirm] = useState();
	const { onOpen, onClose, isOpen } = useDisclosure();
	const onSubmit = async () => {
		if (confirm === "CONFIRMER") {
			setLoading(true);
			await handleRemoveStudent(student.id);
			setLoading(false);
			onClose();
			return;
		}
		toast.error(`Saisissez "CONFIRMER" pour supprimer l’élève.`);
	};
	return (
		<>
			<li>
				<button className='text-white hover:text-gray-300' onClick={onOpen} type='button'>
					Supprimer
				</button>
			</li>

			<Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<p>
							Supprimer l’élève {student.firstName} {student.lastName}
						</p>
					</ModalHeader>
					<ModalBody>
						<form
							id="deleteStudentForm"
							onSubmit={async (event) => {
								event.preventDefault();
								await onSubmit();
							}}
						>
							<label htmlFor="confirm" className="text-[18px]">
								Saisissez "CONFIRMER" pour supprimer l’élève.
							</label>
							<input
								type="text"
								name="confirm"
								id="confirm"
								className="px-3 py-2"
								// rome-ignore lint/a11y/noAutofocus: <explanation>
								autoFocus
								onChange={(event) => setConfirm(event.target.value)}
							/>
						</form>
					</ModalBody>
					<ModalFooter>
						{loading ? (
							<Spinner />
						) : (
							<button
								type="submit"
								form="deleteStudentForm"
								className="bg-[#E0471E] text-white pt-3 pb-3 pl-6 pr-6 rounded-[10px]"
							>
								Supprimer l’élève
							</button>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
