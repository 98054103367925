import { useFormik } from "formik";
import React, { useState } from "react";
import { BsEye } from "react-icons/bs";
import { MdOutlineModeEdit } from "react-icons/md";
import { TiPlus } from "react-icons/ti";
import * as Yup from "yup";

import Select from "react-select";

import { getSubThemesByTheme } from "../../../helpers/backend_helper";

import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";

import CardMotMeleComponent from "../components/card-mot-mele";

const Index = ({ themesList }) => {
	const [actionIndex, setActionIndex] = useState(0);
	const [activeMot, setActiveMot] = useState(0);
	const [subThemesList, setSubThemesList] = useState([]);
	const [title, setTitle] = useState("");
	const [selectedTheme, setSelectedTheme] = useState({ value: "", label: "" });
	const [selectedSubTheme, setSelectedSubTheme] = useState({ value: "", label: "" });

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			title: "",
			theme: "",
			subtheme: "",
			feedback: "",
			responseTime: {},
			strSearch: {},
			diagonale: true,
			lowercase: false,
			mots: [{ mot: "" }],
		},
		validationSchema: Yup.object({
			title: Yup.string().required("Veuillez saisir le titre !"),
			theme: Yup.string().required("Veuillez sélectionner un théme !"),
			subtheme: Yup.string().required("Veuillez sélectionner un sous théme !"),
		}),
		onSubmit: (values) => {},
	});

	const customStyles = {
		control: (base) => ({
			...base,
			border: "1px solid rgba(50, 202,188, 0.47)",
			background: "rgba(50, 202,188, 0.02)",
			color: "#303972",
			paddingBottom: "1px",
			paddingTop: "1px",
			height: "40px",
			boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
			filter: "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
			borderRadius: "0.375rem",
			"&:hover": {
				border: "1px solid rgba(50, 202,188, 0.47)",
			},
		}),
		option: (provided, state) => ({
			...provided,
			fontSize: "12px",
		}),
		placeholder: (defaultStyles) => {
			return {
				...defaultStyles,
				color: "#303972",
				fontSize: "12px",
				padding: "1px",
			};
		},
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = "opacity 300ms";
			const fontSize = "12px";
			const color = "#303972";
			return { ...provided, color, fontSize, opacity, transition };
		},
		input: (styles, { data }) => ({
			...styles,
			outline: "none",
			border: "none",
			focus: "none",
			color: "#303972",
			fontSize: "12px",
		}),
		indicatorSeparator: (base) => ({
			...base,
			display: "none",
		}),
		dropdownIndicator: (base) => ({
			...base,
			color: "#303972",
			"&:hover": {
				color: "#303972",
			},
		}),
		menuList: (base) => ({
			...base,
			"::-webkit-scrollbar": {
				width: "4px",
				height: "0px",
				color: "#303972",
			},
			"::-webkit-scrollbar-track": {
				background: "#f1f1f1",
			},
			"::-webkit-scrollbar-thumb": {
				background: "#888",
			},
			"::-webkit-scrollbar-thumb:hover": {
				background: "#555",
			},
		}),
	};

	const handleChangeTheme = (e) => {
		setSelectedTheme(e);
		setSelectedSubTheme({ value: "", label: "" });
		if (e.value !== selectedTheme.value) {
			getSubThemesByTheme(e.value).then((data) => {
				setSubThemesList(data.data);
			});
		}
	};

	const handleChangeSubTheme = (e) => {
		setSelectedSubTheme(e);
	};

	const handleAddMot = () => {
		validation.setFieldValue("mots", [...validation.values.mots, { mot: "" }]);
		setActiveMot(validation.values.mots.length);
	};

	const handleChangeMotText = (e, index) => {
		const parts = e.target.value.split(/(\[.*?\])/);
		let data = [...validation.values.mots];
		data[index] = { ...data[index], mot: e.target.value };
		validation.setFieldValue("mots", data);
	};

	const deleteCarte = (index) => {
		let data = validation.values.mots.filter((c, i) => i !== index);
		if (data.length > 0) {
			validation.setFieldValue("mots", data);
			if (index > 0) {
				setActiveMot(index - 1);
			} else {
				setActiveMot(0);
			}
		}
	};

	function highlightWord(text, word) {
		const highlightedWord = <span style={{ color: "red" }}>{word}</span>;
		const regex = new RegExp(word, "g");
		const highlightedText = text.replace(regex, highlightedWord);
		return highlightedText;
	}

	return (
		<div className='w-full flex flex-col justify-start items-center '>
			<div className='w-full flex justify-between items-center'>
				<div className='flex justify items-center'>
					<div className='w-56 flex flex-col justify-start items-start gap-1'>
						<label className=''>Titre</label>
						<input
							onChange={(e) => setTitle(e.target.value)}
							value={title}
							type="text"
							placeholder="Saisir le titre de l'activité"
							className='text-[12px] w-20 px-2 py-2 border border-iwingreen/[.4] rounded-lg'
						/>
					</div>
					<div className='w-36 ml-2 flex flex-col justify-start items-start gap-1'>
						<label className=''>Thème</label>
						<Select
							id="mode"
							name="mode"
							styles={customStyles}
							options={themesList}
							placeholder="---"
							className="w-full"
							classNamePrefix="select"
							onChange={(e) => {
								handleChangeTheme(e);
							}}
							value={selectedTheme}
						/>
					</div>
					<div className='w-36 ml-2 flex flex-col justify-start items-start gap-1'>
						<label className=''>Sous-thème</label>
						<Select
							id="mode"
							name="mode"
							styles={customStyles}
							options={subThemesList}
							placeholder="---"
							className="w-full"
							classNamePrefix="select"
							onChange={(e) => {
								handleChangeSubTheme(e);
							}}
							value={selectedSubTheme}
						/>
					</div>
					<div className='w-32 ml-2 flex flex-col justify-start items-start gap-1'>
						<label className=''>Arrière-plan</label>
						<div className='w-[30px] px-5 py-[6px] shadow-in border border-gray-300 bg-gray-400 rounded-lg cursor-pointer'>
							<input type="color" className='w-full h-full invisible' />
						</div>
					</div>
				</div>
				<div className='flex justify-end items-center gap-10 pt-3'>
					<div className='p-1 rounded-3xl border border-gray-300 bg-gray-200 flex justify-between items-center gap-4'>
						<div
							className={`flex justify-center items-center p-1  rounded-full cursor-pointer ${
								actionIndex === 0 ? "bg-iwingreen text-white" : "text-gray-400"
							}`}
							onClick={() => setActionIndex(0)}
						>
							<MdOutlineModeEdit className='w-4 h-4' />
						</div>
						<div
							className={`flex justify-center items-center p-1 rounded-full cursor-pointer ${
								actionIndex === 1 ? "bg-iwingreen text-white" : "text-gray-400"
							}`}
							onClick={() => setActionIndex(1)}
						>
							<BsEye className='w-4 h-4' />
						</div>
					</div>
					<button
						type='submit'
						className='px-10 py-3 text-sm font-semibold bg-gray-500 rounded-lg text-white'
					>
						Créer
					</button>
				</div>
			</div>
			<div className={"w-full bg-gray-100 rounded-lg px-3 py-2} mt-3"}>
				<div className="w-full flex justify-start items-center gap-3">
					<div className='w-[22%] h-[62vh] flex flex-col justify-between items-center gap-3 px-0 py-1 border-r-2 border-gray-300 pr-4 '>
						<div className='w-full flex flex-col justify-between items-start gap-4'>
							<div className='w-full flex flex-col justify-between items-start gap-1'>
								<label className='text-xs font-medium'>Temps de réponse</label>
								<Select
									id="responseTime"
									name="responseTime"
									styles={customStyles}
									options={[]}
									placeholder="---"
									className="w-full"
									classNamePrefix="select"
									onChange={(e) => validation.setFieldValue("responseTime", e)}
									value={validation.values.responseTime}
									invalid={validation.touched.responseTime && validation.errors.responseTime}
								/>
							</div>
							<div className='w-full flex flex-col justify-between items-start gap-1'>
								<label className='text-xs font-medium'>Mots recherchés</label>
								<Select
									id="strSearch"
									name="strSearch"
									styles={customStyles}
									options={[]}
									placeholder="---"
									className="w-full"
									classNamePrefix="select"
									onChange={(e) => validation.setFieldValue("strSearch", e)}
									value={validation.values.strSearch}
									invalid={validation.touched.strSearch && validation.errors.strSearch}
								/>
							</div>
							<label htmlFor='diagonale' className='w-full flex justify-start items-center gap-2'>
								<span className='text-[12px]'>Mots en diagonale </span>
								<Checkbox
									inputId="diagonale"
									value={validation.values.diagonale}
									className=''
									onChange={() =>
										validation.setFieldValue("diagonale", !validation.values.diagonale)
									}
									checked={validation.values.diagonale}
								/>
							</label>
							<label htmlFor='lowercase' className='w-full flex justify-start items-center gap-2'>
								<span className='text-[12px]'>Mots en miniscule </span>
								<Checkbox
									inputId="lowercase"
									value={validation.values.lowercase}
									className=''
									onChange={() =>
										validation.setFieldValue("lowercase", !validation.values.lowercase)
									}
									checked={validation.values.lowercase}
								/>
							</label>
						</div>
						<div className='w-full flex flex-col justify-between items-start gap-1'>
							<label htmlFor='feedback' className='text-xs font-medium'>
								Feedback final
							</label>
							<InputTextarea
								id="feedback"
								className='w-full border border-iwingreen/[0.4] rounded-md bg-white p-2 focus:outline-none text-sm'
								value={validation.values.feedback}
								onChange={(e) => validation.setFieldValue("feedback", e.target.value)}
								rows={4}
								autoResize
							/>
						</div>
					</div>
					<div className='scroller2 w-full py-2 flex flex-col justify-between items-center gap-2'>
						<div className='w-full grid grid-cols-2 gap-1'>
							{validation.values.mots.map((mot, index) => (
								<CardMotMeleComponent
									mot={mot}
									index={index}
									activeMot={activeMot}
									setActiveMot={setActiveMot}
									handleChangeMotText={handleChangeMotText}
									deleteCarte={deleteCarte}
								/>
							))}
						</div>
						<button
							onClick={handleAddMot}
							className='border border-gray-400 rounded-md bg-white text-center text-xs px-3 py-2 flex justify-between items-center gap-2'
						>
							<TiPlus className='w-4 h-4' />
							<span>Nouvelle question</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Index;
