import React from "react";
import { BsCheck } from "react-icons/bs";

const Index = ({ icon, title, i, setTabIndex, active }) => {
	return (
		// rome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
		<div
			className={`relative w-full flex flex-col justify-center items-center border border-iwingreen/[.6]  rounded-lg p-1 min-h-24 gap-2 cursor-pointer ${
				active ? "bg-iwingreen/[.08]" : ""
			}`}
			onClick={() => setTabIndex(i)}
		>
			{active && (
				<div className='absolute top-1 right-1 flex justify-center items-center rounded-full bg-iwingreen text-white'>
					<BsCheck className="w-5 h-5" />
				</div>
			)}
			<img src={icon} alt="" className='w-[50px]' />
			<h6 className='text-gray-500 text-center text-xs'>{title}</h6>
		</div>
	);
};

export default Index;
