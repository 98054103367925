import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import Logo from "../../assets/images//logo-iwin.png";
import iwinRobo from "../../assets/images/SCIBOT.png";
import BtnTerminer from "../../assets/images/btn-send.png";
import SquareTopRight from "../../assets/images/square-top-right.png";
import { AlertContainer } from "../../components/common/AlertContainer/AlertContainer";
import { DefaultContainer } from "../../components/common/DefaultContainer/DefaultContainer";
import { FormContainer } from "../../components/common/FormContainer";
import { resetPassword } from "../../helpers/backend_helper";
import { getLevelsAction } from "../../store/level/actions";
import Input from "../Input/Input";
export default function ResetPassword() {
	const { token } = useParams();
	const [success, setSuccess] = useState(false);
	const { levels } = useSelector((state) => ({
		levels: state.levelsState.levels,
	}));
	const dispatch = useDispatch();

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			password: "",
			confirm: "",
		},
		validationSchema: Yup.object({
			password: Yup.string()
				.required("Veuillez saisir un mot de passe!")
				.min(8, "Veuillez saisir un mot de passe d'au moins 8 caractères"),
			confirm: Yup.string()
				.oneOf([Yup.ref("password"), null], "Les mots de passe ne correspondent pas")
				.required("La confirmation du mot de passe est obligatoire"),
		}),
		onSubmit: async ({ confirm, ...values }) => {
			const id = toast.loading("Réinitialisation de mot de passe ...");
			try {
				const response = await resetPassword({ ...values, token });
				if (response.success) {
					toast.success(response.message);
					setSuccess(true);
				}
			} catch (error) {
			} finally {
				toast.done(id);
			}
		},
	});
	useEffect(() => {
		if (levels && levels.length === 0) {
			dispatch(getLevelsAction());
		}
	}, [dispatch]);

	return (
		<>
			{!success ? (
				<div className="flex flex-col justify-center items-center">
					<div className="absolute z-10 top-5">
						<img src={Logo} alt="LOGO" className="h-8" />
					</div>

					<FormContainer
						onSubmit={(e) => {
							e.preventDefault();
							validation.handleSubmit();
						}}
						className="flex flex-col justify-center items-center relative"
					>
						<img src={SquareTopRight} className="absolute top-0 right-0 rounded-tr-[16px]" alt="" />
						<Row className="w-full mt-5">
							<Col
								xs={12}
								sm={12}
								md={12}
								className="flex flex-col justify-start items-start gap-1 mb-4"
							>
								<Input
									required={true}
									type="password"
									name='password'
									className='w-full text-[13px] drop-shadow-md outline-none border border-gray-100 text-gray-600 rounded-md focus:border-gray-100 px-3 py-2'
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.password}
									invalid={validation.touched.password && validation.errors.password}
									label="Nouveau mot de passe"
								/>
								{validation.touched.password && validation.errors.password ? (
									<small className="text-[9px] text-red-400">{validation.errors.password}</small>
								) : null}
							</Col>

							<Col
								xs={12}
								sm={12}
								md={12}
								className="flex flex-col justify-start items-start gap-1 mb-4"
							>
								<Input
									required={true}
									type="password"
									name='confirm'
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.confirm}
									invalid={validation.touched.confirm && validation.errors.confirm}
									label="Confirmer le nouveau mot de passe"
								/>
								{validation.touched.confirm && validation.errors.confirm ? (
									<small className="text-[9px] text-red-400">{validation.errors.confirm}</small>
								) : null}
							</Col>

							<Col lg={12} className='w-full flex justify-center items-center mt-3'>
								<button type="submit">
									<img src={BtnTerminer} alt="" className='h-10' />
								</button>
							</Col>
						</Row>
					</FormContainer>
				</div>
			) : (
				<CSSTransition in={true} appear={true} timeout={300} classNames="fade">
					<div className="flex flex-col justify-start items-center -mt-40">
						<AlertContainer className="md:w-[400px]">
							<div className="w-full flex flex-row justify-start items-center text-[12px] bg-notifbg text-notiftext">
								<div>
									<p>Félicitation, votre mot de passe a bien été réinitialisé.</p>
									<p>
										Cliquez{" "}
										<Link to="/login">
											{" "}
											<strong>ici</strong>{" "}
										</Link>{" "}
										pour vous connecter
									</p>
								</div>
							</div>
						</AlertContainer>
						<DefaultContainer className="flex flex-col justify-center items-center md:w-[400px]">
							<img src={iwinRobo} alt="" className="w-52" />
						</DefaultContainer>
					</div>
				</CSSTransition>
			)}
		</>
	);
}
