import React, { useCallback, useState } from "react";

import { BiEdit, BiFilter } from "react-icons/bi";
import { GoSearch } from "react-icons/go";
import { MdOutlineCalendarToday } from "react-icons/md";
import { RiDownload2Fill, RiFilter3Fill } from "react-icons/ri";
import { Link } from "react-router-dom";

import { Dialog } from "primereact/dialog";

// import components
import { ScrollContainer } from "../../components/common";
import Profile from "../../components/common/Profile";

import BtnRessourceActivity from "../../assets/images/buressourceBtn.png";
import BtnCreateActivity from "../../assets/images/createActivityBtn.png";
import BtnImportActivity from "../../assets/images/importActivityBtn.png";
import { ImportActivity } from "../../components/ImportActivity/ImportActivity";
import { useAppStore } from "../../store/store";

import { useDisclosure } from "@chakra-ui/react";
import dayjs from "dayjs";
import { createFolder } from "../../helpers/backend_helper";
import { getUser } from "../../helpers/common";
import { useFolders } from "../../hooks/useActivities";
import ClassActivity from "../ClassActivity/ClassActivity";
import Spinner from "../Spinner/Spinner";
import { UpdateActivity } from "../UpdateActivity/UdpateActivity";
import ActivityCard from "./ActivityCard/ActivityCard";
import FolderCard from "./FolderCard";

const filters = [
	{
		status: "all",
		name: "Toutes les activités",
	},

	{
		status: "created",
		name: "Mes créations",
	},

	{
		status: "imported",
		name: "Activités importées",
	},

	{
		status: "archived",
		name: "Activités archivées",
	},
];

export default function Activities() {
	const activities = useAppStore((state) => state.activities);
	const folders = useAppStore(state => state.folders)
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [status, setStatus] = useState("all");
	const [displayActivityModal, setDisplayActivityModal] = useState(false);

	const handleFiltre = (name) => setStatus(name);

	const HeaderActivityModal = (
		<div className='w-full flex justify-center items-center'>
			<h2 className=' text-iwingreen text-lg font-bold flex justify-between items-center gap-5'>
				<MdOutlineCalendarToday className="w-6 h-6" /> Ajouter une activité
			</h2>
		</div>
	);
	const handleCancelAddActivity = () => {
		setDisplayActivityModal(!displayActivityModal);
	};

	const availableActivities = useCallback(() => {
		if (status === "all") return activities.filter((item) => item.status !== "archived");
		if (status === "created") return activities.filter((item) => item.teacherId === getUser()?.id);
		if (status === "news")
			return activities.filter((item) => {
				const limitDate = dayjs().add(-3, "day").toDate();
				return (
					new Date(item.createdAt).getTime() >= new Date(limitDate).getTime() &&
					item.status !== "archived"
				);
			});
		return activities.filter((item) => item.status === status);
	}, [activities, status]);


	const availableFolders = useCallback(() => {
		if (status === "all") return folders.filter((item) => item.status !== "archived");
		if (status === "created") return folders.filter((item) => item.teacherId === getUser()?.id);
		if (status === "news")
			return folders.filter((item) => {
				const limitDate = dayjs().add(-3, "day").toDate();
				return (
					new Date(item.createdAt).getTime() >= new Date(limitDate).getTime() &&
					item.status !== "archived"
				);
			});
		return folders.filter((item) => item.status === status);
	}, [folders, status]);


	return (
		<div className='w-full flex flex-col justify-start items-center min-h-screen'>
			<div className="w-full flex justify-between items-center">
				<h1 className='text-iwinblue font-bold text-left mt-3 text-[24px] mx-5'>Activités</h1>
				<div className="flex justify-end items-center">
					<div className="relative w-[300px] mt-3 mr-2">
						<input
							type="text"
							placeholder='Trouver une ressource ...'
							className="bg-white rounded-[46px] border-white bottom-0 px-[50px] py-[10px]"
							style={{ border: "none", borderRadius: "40px" }}
						/>
						<GoSearch className="w-5 h-5 absolute top-[15px] left-[20px] text-asprimary" />
						<BiFilter className="w-7 h-7 absolute top-[10px] right-[15px] text-gray-700" />
					</div>
					<Profile />
				</div>
			</div>
			<ScrollContainer>
				<div className='w-full mt-4 px-5 h-full'>
					<div className='w-full  bg-white shadow-md rounded-xl flex flex-col justify-start items-center p-6'>
						<div className='w-full flex flex-col justify-center items-center px-2'>
							<div className='w-full flex justify-end items-center'>
								<div className='flex justify-between items-end gap-3'>
									<CreateFolder />
									<button
										onClick={onOpen}
										type="button"
										className='flex justify-bitween items-center gap-2 border-2 border-iwingreen text-iwingreen rounded-lg px-3 py-2'
									>
										<RiDownload2Fill className='w-5 h-5' /> Importer document
									</button>
									<button
										onClick={() => setDisplayActivityModal(true)}
										type="button"
										className='flex justify-bitween items-center gap-2 border-2 border-iwingreen text-iwingreen rounded-lg px-3 py-2'
									>
										Créer une activité
										<BiEdit className='w-5 h-5' />
									</button>
								</div>
							</div>
							<div className='w-full border-b border-gray-200 mt-2' />
						</div>
						<div className="w-full flex justify-start items-center gap-3 py-1">
							{filters.map((filtre) => (
								<button
									key={filtre.status}
									onClick={() => handleFiltre(filtre.status)}
									className={`px-3 py-1 text-sm ${filtre.status === status
										? "border border-iwingreen rounded-xl text-iwingreen"
										: ""
										}`}
								>
									{filtre.name}
								</button>
							))}

							<button
								onClick={() => handleFiltre("news")}
								className={`px-3 py-1 text-sm flex justify-start items-center gap-2 ml-10 bg-gray-200  rounded-xl ${status === "news" ? "bg-iwingreen text-white" : ""
									}`.trim()}
							>
								<span>Récents</span>
								<RiFilter3Fill className="w-5 h-5" />
							</button>
						</div>
						<div className='w-full mt-5'>
							<div className="flex flex-wrap gap-3">
								{availableActivities().map((fileActivity) => (
									<ActivityCard key={fileActivity.id} activity={fileActivity} />
								))}
							</div>

							<div className="h-[50px]" />

							<div className="flex flex-wrap gap-3">
								{availableFolders().map((folderActivity) => (
									<FolderCard key={folderActivity.id} activity={folderActivity} />
								))}
							</div>
						</div>
					</div>
				</div>
			</ScrollContainer>
			<Dialog
				dismissableMask={true}
				resizable={false}
				style={{ width: "50vw" }}
				closable={false}
				onHide={() => handleCancelAddActivity()}
				header={HeaderActivityModal}
				icons={""}
				visible={displayActivityModal}
			>
				<div className='w-full flex flex-col justify-start items-start gap-7 pb-4'>
					<div className='w-full flex flex-col justify-center items-center gap-2'>
						<h3 className='text-lg font-normal'>Consulter la liste des activités créées</h3>
						<Link to="/ressources" className='w-4/5 px-4 py-2 font-semibold text-sm rounded-md'>
							<img src={BtnRessourceActivity} className="" alt="" />
						</Link>
					</div>
					<div className='w-full flex flex-col justify-center items-center gap-2'>
						<h3 className='text-lg font-normal'>Importation d'activité</h3>
						<button
							className='w-4/5 px-4 py-2 font-semibold text-sm rounded-md'
							onClick={() => {
								handleCancelAddActivity();
								onOpen();
							}}
						>
							<img src={BtnImportActivity} className="" alt="" />
						</button>
					</div>
					<div className='w-full flex flex-col justify-center items-center gap-2'>
						<h3 className='text-lg font-normal'>Création d'une nouvelle activité</h3>
						<Link
							to="/create-activity"
							className='w-4/5 px-4 py-2 font-semibold text-sm rounded-md'
						>
							<img src={BtnCreateActivity} className="" alt="" />
						</Link>
					</div>
				</div>
			</Dialog>

			<ImportActivity isOpen={isOpen} onClose={onClose} />
			<UpdateActivity />
			<ClassActivity />
		</div>
	);
}

function CreateFolder() {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const { refresh } = useFolders()
	const [folderName, setFolderName] = useState("");

	const handleSubmit = async () => {
		onOpen();
		const response = await createFolder({ name: folderName, teacherId: getUser()?.id });
		if (response.success) await refresh();
		setFolderName("");
		onClose();
	};
	return (
		<form
			className="p-inputgroup w-[420px]"
			onSubmit={async (event) => {
				event.preventDefault();
				await handleSubmit();
			}}
		>
			<input
				className="h-11 Input  rounded-tr-none rounded-br-none"
				placeholder="Nouveau dossier"
				value={folderName}
				onChange={(event) => setFolderName(event.target.value)}
				required
			/>
			{isOpen ? (
				<Spinner />
			) : (
				<button className="p-inputgroup-addon h-11 bg-iwingreen text-white font-semibold cursor-pointer text-sm">
					Créer
				</button>
			)}
		</form>
	);
}
