import React from "react";
import { AiOutlineShop } from "react-icons/ai";
import { BiSlideshow, BiTachometer } from "react-icons/bi";
import { HiOutlinePuzzle } from "react-icons/hi";
import { RiCalendarLine, RiLogoutBoxLine, RiMessage2Line } from "react-icons/ri";
import { TbDeviceGamepad2 } from "react-icons/tb";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import Logo from "../../../assets/images/logo-iwin.png";
import "./style.css";

const SideBarContainer = styled.div`
width: 30vw;
max-width: 13vw;
min-width: 13vw;
min-height: 100vh;
 background: #009688 no-repeat center center fixed; 
    -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: #fff;
`;

const Index = () => {
	const location = useLocation();
	return (
		<SideBarContainer className='flex flex-col justify-start items-start py-5 pl-4'>
			<Link to={"/"} className='flex flex-row justify-center items-center px-1 w-full'>
				<img src={Logo} alt="" className='w-full h-9' />
			</Link>
			<nav className='mt-4 w-full'>
				<ul className='flex flex-col gap-1 justify-start items-start w-full'>
					<li
						className={`font-medium w-full py-[11px] px-4 text-[13px] ${
							location.pathname === "/" || location.pathname === "/profile" ? "active" : ""
						}`}
					>
						<Link to="/" className="flex flex-row justify-start items-center w-full">
							<BiTachometer className="mr-3 w-6 h-6" />
							Dashboard
						</Link>
					</li>
					<li
						className={`font-medium w-full py-[12px] px-4 text-[13px] ${
							location.pathname.includes("class") ? "active" : ""
						}`}
					>
						<Link to="/classes" className="flex flex-row justify-start items-center w-full">
							<AiOutlineShop className="mr-3 w-6 h-6" />
							Mes Classes
						</Link>
					</li>
					<li
						className={`font-medium w-full py-[12px] px-4 text-[13px] ${
							location.pathname.includes("activites") ? "active" : ""
						}`}
					>
						<Link to="/activites" className={"flex flex-row justify-start items-center w-full"}>
							<RiCalendarLine className="mr-3 w-6 h-6" />
							Activités
						</Link>
					</li>
					<li
						className={`font-medium w-full py-[12px] px-4 text-[13px] ${
							location.pathname.includes("create-activity") ? "active" : ""
						}`}
					>
						<Link
							to="/create-activity"
							className={"flex flex-row justify-start items-center w-full"}
						>
							<RiCalendarLine className="mr-3 w-6 h-6" />
							Créer
						</Link>
					</li>
					<li className={"px-4 w-full font-medium py-[12px] text-[13px]"}>
						<Link to="#" className="flex flex-row justify-start items-center w-full">
							<HiOutlinePuzzle className="mr-3 w-6 h-6" />
							Ressources
						</Link>
					</li>
					<li className={"px-4 w-full font-medium py-[12px] text-[13px]"}>
						<Link to="/game" className="flex flex-row justify-start items-center w-full">
							<TbDeviceGamepad2 className="mr-3 w-6 h-6" />
							Jouer
						</Link>
					</li>
					<li className={"px-4 py-3 w-full font-medium text-[13px]"}>
						<Link to="#" className="flex flex-row justify-start items-center w-full">
							<BiSlideshow className="mr-3 w-6 h-6" />
							Tutoriels
						</Link>
					</li>
					<li className={"px-4 w-full font-medium py-[12px] text-[13px]"}>
						<Link to="#" className="flex flex-row justify-start items-center w-full">
							<RiMessage2Line className="mr-3 w-6 h-6" />
							Contacts
						</Link>
					</li>
					<li className={"px-4 w-full font-medium py-[12px] text-[13px]"}>
						<Link to="/logout" className="flex flex-row justify-start items-center w-full">
							<RiLogoutBoxLine className="mr-3 w-6 h-6" />
							Déconnexion
						</Link>
					</li>
				</ul>
			</nav>
			<div className="flex flex-col justify-center items-center mt-10 -ml-3 w-full">
				<p className="text-[10px] font-bold mb-3">iWinSciences - Jeu educatif</p>
				<p className="text-[10px]">
					<span>
						Tous droits réservés - <span>O</span> {new Date().getFullYear()}
					</span>
				</p>
			</div>
		</SideBarContainer>
	);
};

export default Index;
