import * as Yup from "yup";

export const initialValues = {
	firstName: "",
	lastName: "",
	email: "",
	password: "",
	confirmPassword: "",
	country: "",
	city: "",
	accepteTerm: false,
	subscribed: false,
	rememberMe: false,
};

export const validationSchema = Yup.object({
	firstName: Yup.string().required("Veuillez remplir le prénom !"),
	lastName: Yup.string().required("Veuillez remplir le nom !"),
	email: Yup.string()
		.required("Veuillez remplir l'email !")
		.email("Veuillez entrer un email valid !"),
	password: Yup.string().required("Veuillez remplir le mot de passe !"),
	confirmPassword: Yup.string()
		.required("Veuillez confirmer le mot de passe !")
		.when("password", {
			is: (val) => (val && val.length > 0 ? true : false),
			then: Yup.string().oneOf([Yup.ref("password")], "Les mot de passe ne correspondent pas !"),
		}),
	city: Yup.string().required("Veuillez saisir la ville !"),
	country: Yup.object().required("Veuillez sélectionner le pays !"),
	accepteTerm: Yup.boolean()
		.required("Veuillez accepter nos CGU  et politiques de confidentialité !")
		.oneOf([true], "Veuillez accepter nos CGU  et politiques de confidentialité !"),
	subscribed: Yup.boolean(),
	rememberMe: Yup.boolean(),
});
