import { createSlice } from "@reduxjs/toolkit";
import { getCountriesAction } from "./actions";

const initialState = {
	getCountriesStatus: "",
	getCountriesType: "",
	getCountriesError: "",
	countries: [],
};

const Countrieslice = createSlice({
	name: "countries",
	initialState,
	reducers: {},
	extraReducers: {
		[getCountriesAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				getCountriesStatus: "pending",
				getCountriesType: "",
				getCountriesError: "",
				countries: [],
			};
		},
		[getCountriesAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				countries: payload,
				getCountriesStatus: "success",
				getCountriesType: "get",
				getCountriesError: "",
			};
		},
		[getCountriesAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				getCountriesStatus: "rejected",
				getCountriesType: "get",
				getCountriesError: payload,
				countries: [],
			};
		},
	},
});

export default Countrieslice.reducer;
