import { useDisclosure } from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { BiFilter } from "react-icons/bi";
import { GoSearch } from "react-icons/go";
import { MdOutlinePhotoCamera } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import Profile from "../../components/common/Profile";
import { editClassroom, getClassroom } from "../../helpers/backend_helper";
import { getUser } from "../../helpers/common";
import { useClassRooms } from "../../hooks/useClassRooms";
import { useAppStore } from "../../store/store";
import { initialValues, validationSchema } from "../CreateClass/yup-data";
import Spinner from "../Spinner/Spinner";
import { ScrollContainer } from "../common";

export default function EditClass() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { id } = useParams();
	const { refetch } = useClassRooms();
	const navigate = useNavigate();
	const levelsOneState = useAppStore((state) => state.levels);
	const materialsOneState = useAppStore((state) => state.materials);
	const modesOneState = useAppStore((state) => state.modes);
	const [classRoom, setClassRoom] = useState();

	const [levels, setLevels] = useState();
	const [materials, setMaterials] = useState();
	const [modes, setModes] = useState();

	const [modeToAdd, setModeToAdd] = useState();
	const [materialsToAdd, setMaterialsToAdd] = useState();
	const [levelsToAdd, setLevelsToAdd] = useState();

	const syncDatas = () => {
		setLevels(levelsOneState.map((item) => ({ value: item.id, label: item.name })));
		setMaterials(materialsOneState.map((item) => ({ value: item.id, label: item.name })));
		setModes(modesOneState.map((item) => ({ value: item.id, label: item.name })));
	};

	const syncClass = async () => {
		const res = await getClassroom(id);
		if (res.success && res.data) {
			const data = res.data;
			validation.setFieldValue("name", data.name);
			validation.setFieldValue("imagePath", data.imagePath);
			setClassRoom(data);
		}
	};

	const syncInitialValues = () => {
		if (modes && materials && levels && classRoom) {
			const defaultMaterialsIds = new Set(classRoom.materials.map((item) => item.id));
			const defaultMaterials = materials.filter((item) => defaultMaterialsIds.has(item.value));

			const defaultLevelsIds = new Set(classRoom.levels.map((item) => item.id));
			const defaultLevels = levels.filter((item) => defaultLevelsIds.has(item.value));

			const defaultMode = modes.find((item) => item.value === classRoom.classMode.id);

			setModeToAdd(defaultMode);
			setMaterialsToAdd(defaultMaterials);
			setLevelsToAdd(defaultLevels);

			validation.setFieldValue("modeId", classRoom.classMode.id);
			validation.setFieldValue("levels", [...defaultLevelsIds]);
			validation.setFieldValue("materials", [...defaultMaterialsIds]);
		}
	};

	const validation = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		onSubmit: async (values) => {
			onOpen();
			const payload = { ...values, teacherId: getUser().id, id: classRoom.id };
			const res = await editClassroom(payload);
			if (res.success) {
				toast.success("Les changements ont été enregistrés !");
				await refetch();
				onClose();
				navigate(-1);
			}
		},
	});

	useEffect(() => {
		syncDatas();
	}, [levelsOneState, materialsOneState, modesOneState]);

	useEffect(() => {
		syncClass().catch(console.error);
	}, [id]);

	useEffect(() => {
		syncInitialValues();
	}, [levels, modes, materials, classRoom]);

	if (!classRoom || !levels || !materials || !modes) {
		return (
			<div className="flex justify-center items-center h-screen">
				<p className="text-center">Veuillez patienter ...</p>
			</div>
		);
	}

	return (
		<div className="w-full flex flex-row justify-between items-start">
			<div className="w-full flex flex-col justify-start items-start mt-4 px-5">
				<div className="w-full flex justify-between items-center">
					<div className="flex flex-row justify-start items-center">
						<Link to="/classes" className="text-iwinblue font-bold text-left mr-1">
							{"Mes Classes > "}{" "}
						</Link>
						<span className="text-iwinblue font-bold text-left text-[24px]">
							Modifier une classe
						</span>
					</div>
					<div className="relative w-[300px] mt-3">
						<input
							type="text"
							placeholder="Trouver une ressource ..."
							className="bg-white rounded-[46px] border-white bottom-0 px-[50px] py-[10px]"
							style={{ border: "none", borderRadius: "40px" }}
						/>
						<GoSearch className="w-5 h-5 absolute top-[15px] left-[20px] text-asprimary" />
						<BiFilter className="w-7 h-7 absolute top-[10px] right-[15px] text-gray-700" />
					</div>
					<Profile />
				</div>
				<ScrollContainer>
					<div className="w-full flex flex-col justify-start items-center p-5 shadow-lg bg-white rounded-lg mt-5">
						<form
							onSubmit={(e) => {
								e.preventDefault();
								validation.handleSubmit();
								return false;
							}}
							className="w-full"
						>
							<Row className="w-full">
								<Col
									xs={12}
									sm={12}
									md={6}
									className="flex flex-col justify-start items-start gap-3 mb-4"
								>
									<label htmlFor="name">
										Nom de la classe <span className='text-red-500'>*</span>
									</label>
									<input
										type="text"
										className="px-3 py-3"
										id="name"
										name="name"
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										value={validation.values.name}
										invalid={validation.touched.name && validation.errors.name}
									/>
									{validation.touched.name && validation.errors.name ? (
										<small className="text-[12px] text-red-400">{validation.errors.name}</small>
									) : null}
								</Col>
								<Col
									xs={12}
									sm={12}
									md={6}
									className="flex flex-col justify-start items-start gap-3 mb-4"
								>
									<label htmlFor="level">
										Niveau(x) <span className='text-red-500'>*</span>
									</label>
									<Select
										isMulti
										value={levelsToAdd}
										options={levels}
										placeholder="Choisir le(s) niveau(x)"
										className="w-full"
										onChange={(levels) => {
											setLevelsToAdd(levels);
											validation.setFieldValue(
												"levels",
												levels.map((item) => item.value),
											);
										}}
										onBlur={validation.handleBlur}
										invalid={validation.touched.levels && validation.errors.levels}
										styles={{
											control(defaultStyles) {
												return {
													...defaultStyles,
													borderColor: "#009688",
													padding: "0.3rem",
													borderRadius: "0.25rem",
												};
											},
										}}
										theme={(theme) => ({
											...theme,
											borderRadius: 0,
											borderColor: "#009688",

											colors: {
												...theme.colors,
												primary25: "#009688",
												primary: "#009688",
											},
										})}
									/>
									{validation.touched.levels && validation.errors.levels ? (
										<small className="text-[9px] text-red-400">{validation.errors.levels}</small>
									) : null}
								</Col>
								<Col
									xs={12}
									sm={12}
									md={6}
									className="flex flex-col justify-start items-start gap-3 mb-4"
								>
									<label>Photo</label>
									<div className="w-full">
										<div
											className={` w-[456px] h-[325px] rounded-[15px] relative ${
												!validation.values.imagePath && "border-iwingreen bg-activebg border"
											}`}
										>
											{validation.values.imagePath && (
												<label
													htmlFor="photo"
													className=" absolute rounded h-[50px] w-[50px] top-2 left-[400px] flex justify-center items-center border-2  border-gray-300 border-dashed cursor-pointer bg-gray-100"
												>
													<div className="flex flex-col justify-center items-center pt-5">
														<MdOutlinePhotoCamera className="mb-3 w-10 h-10 text-gray-700" />
													</div>
													<input
														accept="image/*"
														onChange={(e) =>
															validation.setFieldValue("imagePath", e.currentTarget.files[0])
														}
														onBlur={validation.handleBlur}
														id="photo"
														name="imagePath"
														invalid={validation.touched.imagePath && validation.errors.imagePath}
														type="file"
														className="hidden"
													/>
												</label>
											)}

											{validation.values.imagePath ? (
												<img
													src={
														typeof validation.values.imagePath === "string"
															? validation.values.imagePath
															: URL.createObjectURL(validation.values.imagePath)
													}
													alt=""
													className="rounded-[15px] mb-3 w-full h-full"
												/>
											) : (
												<label
													htmlFor="photo"
													className=" rounded-[15px] m-[40px] w-[375px] h-[241.43px] flex flex-col justify-center items-center border-2  border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
												>
													<div className="flex flex-col justify-center items-center pt-5 pb-6">
														<MdOutlinePhotoCamera className="mb-3 w-10 h-10 text-gray-700" />
														<p className="mb-2 text-sm text-gray-500 dark:text-gray-400 px-10">
															Choisir une photo de profil
														</p>
													</div>
													<input
														accept="image/*"
														onChange={(e) =>
															validation.setFieldValue("imagePath", e.currentTarget.files[0])
														}
														onBlur={validation.handleBlur}
														id="photo"
														name="imagePath"
														invalid={validation.touched.imagePath && validation.errors.imagePath}
														type="file"
														className="hidden"
													/>
												</label>
											)}
										</div>
									</div>
									{validation.touched.imagePath && validation.errors.imagePath ? (
										<small className="text-[12px] text-red-400">
											{validation.errors.imagePath}
										</small>
									) : null}
								</Col>
								<Col
									xs={12}
									sm={12}
									md={6}
									className="flex flex-col justify-start items-start gap-3 mb-4"
								>
									<div className="w-full flex flex-col justify-start items-start mb-4 gap-3">
										<label htmlFor="subjects">
											Matière(s) enseignée(s) <span className='text-red-500'>*</span>
										</label>
										<Select
											isMulti
											value={materialsToAdd}
											options={materials}
											placeholder="Choisir la(les) matière(s) enseignée(s)"
											className="w-full"
											onChange={(materials) => {
												setMaterialsToAdd(materials);
												validation.setFieldValue(
													"materials",
													materials.map((item) => item.value),
												);
											}}
											onBlur={validation.handleBlur}
											invalid={validation.touched.materials && validation.errors.materials}
											styles={{
												control(defaultStyles) {
													return {
														...defaultStyles,
														borderColor: "#009688",
														padding: "0.3rem",
														borderRadius: "0.25rem",
													};
												},
											}}
											theme={(theme) => ({
												...theme,
												borderRadius: 0,
												borderColor: "#009688",

												colors: {
													...theme.colors,
													primary25: "#009688",
													primary: "#009688",
												},
											})}
										/>
										{validation.touched.materials && validation.errors.materials ? (
											<small className="text-[9px] text-red-400">
												{validation.errors.materials}
											</small>
										) : null}
									</div>
									<div className="w-full flex flex-col justify-start items-start gap-3">
										<label
											htmlFor="mode"
											className="w-full flex flex-row justify-start items-center gap-4"
										>
											Mode <span className='text-red-500'>*</span>
										</label>
										<Select
											value={modeToAdd}
											options={modes}
											placeholder="Choisir le mode"
											className="w-full"
											onChange={(mode) => {
												setModeToAdd(mode);
												validation.setFieldValue("modeId", mode.value);
											}}
											onBlur={validation.handleBlur}
											invalid={validation.touched.modeId && validation.errors.modeId}
											styles={{
												control(defaultStyles) {
													return {
														...defaultStyles,
														borderColor: "#009688",
														padding: "0.3rem",
														borderRadius: "0.25rem",
													};
												},
											}}
											theme={(theme) => ({
												...theme,
												borderRadius: 0,
												borderColor: "#009688",

												colors: {
													...theme.colors,
													primary25: "#009688",
													primary: "#009688",
												},
											})}
										/>
										{validation.touched.modeId && validation.errors.modeId ? (
											<small className="text-[9px] text-red-400">{validation.errors.modeId}</small>
										) : null}
									</div>
								</Col>
								<Col className="w-full flex flex-row justify-end items-center gap-3" md={12}>
									<Link
										to="/add-student-to-class"
										state={classRoom}
										type="button"
										className="px-5 py-2 text-sm rounded-md bg-iwinyellow text-white"
									>
										Modifier les élèves
									</Link>

									{isOpen ? (
										<Spinner />
									) : (
										<button
											type="submit"
											className="px-5 py-2 text-sm rounded-md bg-iwincolor3 text-white"
										>
											Enregistrer
										</button>
									)}
								</Col>
							</Row>
						</form>
					</div>
				</ScrollContainer>
			</div>
		</div>
	);
}
