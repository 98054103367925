import { useEffect, useState } from "react";
import Select from "react-select";
import { addLevels, getTeacherLevels } from "../../helpers/backend_helper";
import { useAppStore } from "../../store/store";

export default function LevelListInput({ profilId, onChange }) {
	const levelsOneState = useAppStore((state) => state.levels);

	const [isLoading, setIsLoading] = useState(false);
	const [inSync, setInSync] = useState(true);
	const [values, setValues] = useState([]);
	const [list, setList] = useState([]);

	const syncLevels = () => {
		setList(levelsOneState.map((item) => ({ value: item.id, label: item.name })));
	};

	const syncUserLevels = async () => {
		const userLevels = await getTeacherLevels(profilId);
		if (Array.isArray(userLevels?.data)) {
			setValues(userLevels.data.map((item) => ({ value: item.id, label: item.name })));
		}
	};

	const syncDatas = async () => {
		syncLevels();
		await syncUserLevels();
		setInSync(false);
	};

	const handleChange = async (levels) => {
		try {
			setIsLoading(true);
			const payload = {
				teacherId: profilId,
				levels: levels.map((item) => ({ id: item.value, name: item.label })),
			};

			await addLevels(payload);
			await syncUserLevels();
			onChange?.(levels);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		syncDatas().catch(console.error);
	}, []);

	if (inSync | (levelsOneState.length === 0)) return <p>Chargement ...</p>;

	return (
		<Select
			menuPlacement="auto"
			isLoading={isLoading}
			value={values}
			isMulti
			placeholder="Cliquez ici pour sélectionner un niveau"
			options={list}
			onChange={(values) => {
				handleChange(values);
			}}
			styles={{
				control(defaultStyles) {
					return {
						...defaultStyles,
						borderColor: "#009688",
						padding: "0.3rem",
						borderRadius: "0.25rem",
					};
				},
			}}
			theme={(theme) => ({
				...theme,
				borderRadius: 0,
				borderColor: "#009688",

				colors: {
					...theme.colors,
					primary25: "#009688",
					primary: "#009688",
				},
			})}
			className="w-full"
		/>
	);
}
