import { useFormik } from "formik";
import { Col, Row } from "react-flexbox-grid";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { createClassRoomsStudent } from "../../../helpers/backend_helper";
import { initialValues, validationSchema } from "./yup-data";

export default function CreateClassStudentForm({ handleSubmit }) {
	const validation = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		onSubmit: async (values, { resetForm }) => {
			const id = toast.loading("Veuillez patienter ...");
			const res = await createClassRoomsStudent(values);
			if (res.success) {
				await handleSubmit(res.data, id);
				resetForm();
			}
		},
	});
	return (
		<form
			onSubmit={(event) => {
				event.preventDefault();
				validation.handleSubmit();
			}}
			className="w-full"
		>
			<Col xs={12} sm={12} md={12} className="flex flex-row justify-start items-start">
				<Row className="w-full">
					<Col md={12} className="mb-2 w-full">
						<Row className="w-full">
							<Col md={5} className="w-full">
								<label htmlFor="firstName">
									Prénom de l'élève <span className='text-red-500'>*</span>
								</label>
							</Col>
							<Col md={5} className="w-full">
								<label htmlFor="lastName">
									Nom de l'élève <span className='text-red-500'>*</span>
								</label>
							</Col>
						</Row>
					</Col>
					<Col sx={12} md={10} className="w-full flex flex-col justify-start items-start">
						<Row className="w-full">
							<Col md={6} className="w-full flex flex-col justify-start items-start">
								<input
									name="firstName"
									type="text"
									className="px-3 py-2 w-full"
									placeholder="Saisir le prénom..."
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									invalid={validation.touched.firstName && validation.errors.firstName}
									value={validation.values.firstName}
								/>
								{validation.touched.firstName && validation.errors.firstName ? (
									<small className="text-[12px] text-red-400">{validation.errors.firstName}</small>
								) : null}
							</Col>
							<Col md={6} className="w-full">
								<input
									name="lastName"
									type="text"
									className="px-3 py-2 w-full"
									placeholder="Saisir le nom..."
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									invalid={validation.touched.lastName && validation.errors.lastName}
									value={validation.values.lastName}
								/>
								{validation.touched.lastName && validation.errors.lastName ? (
									<small className="text-[12px] text-red-400">{validation.errors.lastName}</small>
								) : null}
							</Col>
						</Row>
					</Col>
					<Col md={2} className="w-full flex flex-row justify-end items-end">
						<button
							type="submit"
							className="flex flex-row justify-center items-center border-iwingreen border rounded-md px-4 py-3 shadow-md text-iwingreen text-xs"
						>
							<span>Ajouter</span>
							<FaPlus className="ml-3" />
						</button>
					</Col>
				</Row>
			</Col>
		</form>
	);
}
