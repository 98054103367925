import * as Yup from "yup";

export const initialValues = {
	firstName: "",
	lastName: "",
};

export const validationSchema = Yup.object({
	firstName: Yup.string().required("Veuillez saisir le prénom !"),
	lastName: Yup.string().required("Veuillez saisir le nom !"),
});
