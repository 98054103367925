import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { AiFillCaretLeft } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { RiGoogleFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import * as Yup from "yup";

import { resetCheckClassroomExistAction } from "../../../../store/classroom/actions";

import Logo from "../../../../assets/images//logo-iwin.png";
import SigUpBgImg from "../../../../assets/images/sigup-student.png";
import SquareTopRight from "../../../../assets/images/square-top-right.png";

const FormContainer = styled.form`
    width: 460px;
    padding: 20px 15px;
     background: url(${SigUpBgImg}) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 16px
`;

const AlertContainer = styled.div`
    max-width: 400px;
    padding: 10px 15px;
    background: #F4DC62 no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 10px;
    margin-bottom: 20px;
`;

const Index = () => {
	const { existClassroom } = useSelector((state) => ({
		existClassroom: state.classroomState.existClassroom,
	}));
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [showPassword, setShowPassword] = useState(false);

	const state = location.state;

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.required("Veuillez remplir l'email !")
				.email("Veuillez entrer un email valid !"),
			password: Yup.string().required("Veuillez remplir le mot de passe !"),
		}),
		onSubmit: (values) => {
			console.info(values);
		},
	});

	const goToBack = () => {
		dispatch(resetCheckClassroomExistAction());
		navigate("/login", { replace: true });
	};

	useEffect(() => {
		if (!existClassroom) {
			navigate("/login", { replace: true });
		}
	}, [existClassroom]);


	return (
		<CSSTransition in={true} appear={true} timeout={300} classNames="fade">
			<div className="flex flex-col justify-center items-center">
				<div className="absolute z-10 top-5">
					<img src={Logo} alt="LOGO" className="h-8" />
				</div>

				<div className="absolute z-10 top-5 flex justify-start items-center left-5">
					<button
						onClick={() => goToBack()}
						className="px-4 py-2 text-white text-sm rounded-md bg-iwingreen flex flex-row justify-start items-center"
					>
						<AiFillCaretLeft className="w-5 h-5 -ml-1" /> Retour
					</button>
				</div>
				<div>
					<AlertContainer className="w-full">
						<div className="w-full flex flex-row justify-start items-center text-[12px] bg-notifbg text-notiftext">
							<div>
								<p>
									Félicitations ! Compte validé avec <strong>succès !</strong>
								</p>
							</div>
						</div>
					</AlertContainer>

					<FormContainer
						onSubmit={(e) => {
							e.preventDefault();
							validation.handleSubmit();
							return false;
						}}
						className="flex flex-col justify-center items-center relative"
					>
						<img src={SquareTopRight} className="absolute top-0 right-0 rounded-tr-[16px]" alt="" />
						<Row className="w-full mt-5">
							<Col
								xs={12}
								sm={12}
								md={12}
								className="flex flex-col justify-start items-start gap-1 mb-4"
							>
								<label htmlFor='email' className='text-[14px] font-medium text-iwinblue'>
									Identifiant <span className='text-red-500'>*</span>
								</label>
								<input
									type="text"
									id='email'
									name='email'
									className='w-full text-[14px] drop-shadow-md rounded-md outline-none border bg-inputbg/[0.02] border-inputbg/[0.47]  text-iwinblue focus:border-inputbg[0.47]  px-3 py-2'
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.email}
									invalid={validation.touched.email && validation.errors.email}
								/>
								{validation.touched.email && validation.errors.email ? (
									<small className="text-[9px] text-red-400">{validation.errors.email}</small>
								) : null}
							</Col>

							<Col
								xs={12}
								sm={12}
								md={12}
								className="flex flex-col justify-start items-start gap-1 mb-4 relative"
							>
								<label htmlFor='password' className='text-[14px] text-iwinblue font-medium'>
									Mot de passe <span className='text-red-500'>*</span>
								</label>
								<input
									type={showPassword ? "text" : "password"}
									id='password'
									name='password'
									className='w-full pr-7 text-[14px] drop-shadow-md outline-none border  rounded-md bg-inputbg/[0.02] border-inputbg/[0.47]  text-iwinblue focus:border-inputbg[0.47]   px-3 py-2'
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.password}
									invalid={validation.touched.password && validation.errors.password}
								/>
								{showPassword ? (
									<FiEyeOff
										className="absolute right-4 top-[2.35rem] text-iwingreencursor-pointer"
										onClick={() => setShowPassword(!showPassword)}
									/>
								) : (
									<FiEye
										className="absolute right-4 top-[2.35rem] text-iwinblue cursor-pointer"
										onClick={() => setShowPassword(!showPassword)}
									/>
								)}
								{validation.touched.password && validation.errors.password ? (
									<small className="text-[9px] text-red-400">{validation.errors.password}</small>
								) : null}
							</Col>

							<Col lg={12} className="flex flex-row justify-center items-center px-0 mx-0 mb-3">
								<Row className="w-full px-0">
									<Col
										sm={12}
										md={6}
										className="flex justify-start items-center"
										style={{ padding: "0px" }}
									>
										<div className="flex justify-start items-center px-0">
											<input type="checkbox" id="rememberMe" className='mr-2' name="rememberMe" />
											<label
												htmlFor="rememberMe"
												className="text-[12px] text-iwinblue flex flex-row justify-center items-center"
											>
												Se souvenir de moi
											</label>
										</div>
									</Col>
									<Col
										sm={12}
										md={6}
										className="flex justify-end utems-center"
										style={{ padding: "0px" }}
									>
										<Link
											to="/forget-password/student"
											className="text-[12px] text-iwinblue flex flex-row justify-center items-center"
										>
											<u>Mot de passe oublié? </u>
										</Link>
									</Col>
								</Row>
							</Col>
							<Col lg={12} className='w-full flex justify-center items-center mt-3'>
								<button
									type="submit"
									className="text-sm bg-iwingreen px-5 py-2 text-white rounded-md font-medium"
								>
									Se connecter
								</button>
							</Col>
							<Col lg={12} className='flex flex-row justify-center items-center gap-1 mt-5 '>
								<h6 className='text-[13px] font-semibold text-iwinblue'>Se connecter avec :</h6>
							</Col>
							<Col lg={12} className='flex flex-row justify-center items-center gap-1 mt-2 mb-7'>
								<Link to="/#" className="p-2 bg-blue-600 rounded-full mx-1 text-white">
									<FaFacebookF className="w-3 h-3" />
								</Link>
								<Link to="/#" className="p-2 bg-red-600 rounded-full mx-1 text-white">
									<RiGoogleFill className="w-3 h-3" />
								</Link>
							</Col>
						</Row>
					</FormContainer>
				</div>
			</div>
		</CSSTransition>
	);
};

export default Index;
