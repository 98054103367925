import React, { useState, useEffect } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { TiPlus } from "react-icons/ti";
import { FaRegTrashAlt } from "react-icons/fa";
import { RiFileCopyLine } from "react-icons/ri";

import ResponseComponent from "./response";

import Img1 from "../../../assets/images/quiz/Image.png";
import Img2 from "../../../assets/images/quiz/MonitorPlay.png";
import Img3 from "../../../assets/images/quiz/Rectangle1.png";
import Img4 from "../../../assets/images/quiz/Rectangle2.png";
import Img5 from "../../../assets/images/quiz/Rectangle3.png";

const Index = ({
	question,
	index,
	removeQuestion,
	handleAddResponse,
	setOptions,
	handleQuestion,
	activeQuestion,
	setActiveQuestion,
	handlePhoto,
	handleChangeResponseText,
	handleChangeResponseSwitch,
}) => {
	return (
		<div
			key={index}
			className={`w-full relative rounded-lg flex flex-col justify-center items-center px-16 py-2 mb-3 ${
				activeQuestion === index ? "bg-amber-50/[0.5] border border-slate-500/[0.3]" : ""
			}`}
		>
			<span className='flex flex-col justify-end items-center absolute top-2 right-3 gap-3'>
				<FaRegTrashAlt
					onClick={() => {
						removeQuestion(index);
						setActiveQuestion(index);
					}}
					className="w-4 h-4 cursor-pointer"
				/>
				<RiFileCopyLine
					onClick={() => {
						setActiveQuestion(index);
					}}
					className="w-4 h-4 cursor-pointer"
				/>
			</span>
			<div
				className='w-full flex justify-between items-center gap-4'
				onClick={() => setActiveQuestion(index)}
			>
				<div className='w-[45%] flex max-h-[20vh] h-[20vh] min-h-[20vh] border border-iwingreen/[0.4] rounded-md bg-white'>
					{question?.img === "" ? (
						<div className='w-full min-w-full h-full min-h-full bg-black/[0.01] border-black/[0.1] border p-3'>
							<label
								htmlFor={`photo-${index}`}
								className='w-full min-w-full h-full min-h-full flex justify-center items-center cursor-pointer rounded-md border-dashed border border-indigo-300'
							>
								<div className='flex flex-col justify-center items-center'>
									<div className='flex justify-center itens-center'>
										<img src={Img1} className="w-[36px]" alt="" />
										<img src={Img2} className="w-[38px] -ml-2" alt="" />
									</div>
									<div className='flex justify-center itens-center'>
										<img src={Img3} className="h-4 w-[.12rem] -mt-1 " alt="" />
										<img src={Img4} className="h-5 w-[.12rem] mx-2 -mb-1" alt="" />
										<img src={Img5} className="h-5 w-[.12rem] -mt-1" alt="" />
									</div>
								</div>
								<input
									onChange={(e) => handlePhoto(e, index)}
									accept='image/*'
									id={`photo-${index}`}
									name={"photo"}
									type="file"
									className="hidden"
								/>
							</label>
						</div>
					) : (
						<div className='h-full w-full rounded-md'>
							<label
								htmlFor={`photo-2-${index}`}
								className='w-full min-w-full h-full min-h-full cursor-pointer rounded-md'
							>
								<img
									src={URL.createObjectURL(question.img)}
									className="h-full w-full rounded-md"
									alt=""
								/>
								<input
									accept='image/*'
									id={`photo-2-${index}`}
									onChange={(e) => handlePhoto(e, index)}
									name={`photo-2-${index}`}
									type="file"
									className="hidden"
								/>
							</label>
						</div>
					)}
				</div>
				<textarea
					id="question"
					required
					name="question"
					className='w-[55%] border border-iwingreen/[0.4] rounded-md bg-white p-2 focus:outline-none text-sm max-h-[20vh] h-[20vh] min-h-[20vh]'
					value={question.question}
					placeholder="Saisir la question..."
					onChange={(e) => handleQuestion(e, index)}
				/>
			</div>
			<div
				className='w-full grid grid-cols-2 flex gap-2'
				onClick={() => {
					setActiveQuestion(index);
					setOptions(question.options);
				}}
			>
				{question.responses.map((input, i) => {
					return (
						<ResponseComponent
							response={input}
							handleChangeResponseText={handleChangeResponseText}
							handleChangeResponseSwitch={handleChangeResponseSwitch}
							questionIndex={index}
							responseIndex={i}
						/>
					);
				})}

				<div
					onClick={() => {
						handleAddResponse(index);
					}}
					className='w-full cursor-pointer border-[2px] min-h-[62px] h-[62px] mt-2 border-dashed shadow-lg border-iwingreen/[0.6] bg-white rounded-lg px-3 py-2 flex justify-center items-center gap-2'
				>
					<TiPlus className="w-4 h-4 text-iwingreen" />
					<span className='text-xs font-medium text-iwingreen'>Nouvelle réponse</span>
				</div>
			</div>
		</div>
	);
};

export default Index;
