import { del, get, patch, patchWithFile, post, postWithFile, put, putWithFile } from "./api_helper";
import { getUser } from "./common";
import * as url from "./url_helper";

function findUserBaseUrl() {
	const user = getUser();
	if (!user) throw new Error("Missing user");
	let endpoint;
	if (user.account.role === "TEACHER") endpoint = url.TEACHER_URL;
	if (user.account.role === "PARENT") endpoint = url.PARENT_URL;
	if (!endpoint) throw new Error("Invalid user");
	return endpoint;
}

//AUTHENTIFICTION
export const login = (data) => post(`${url.AUTH_URL}/login`, data);
export const getRefreshToken = (data) => post(`${url.AUTH_URL}/refresh`, data);
export const logout = (data) => post(`${url.AUTH_URL}/logout`, data);
export const verifyEmail = (token) => get(`${url.AUTH_URL}/verify-email/${token}`);
export const changePassword = (data) => patch(`${url.AUTH_URL}/change-password`, data);
export const sendResetPasswordEmail = async (data) =>
	await post(`${url.AUTH_URL}/generate-token-reset-password`, data);

export const resetPassword = async (data) => await post(`${url.AUTH_URL}/reset-password`, data);

// STUDENTS
export const studentRegister = (data) => put(`${url.STUDENT_URL}`, data);
export const activeStudent = (id) => get(`${url.STUDENT_URL}/active/${id}`);
export const createClassRoomsStudent = async (data) => await post(url.STUDENT_URL, data);
export const deleteStudent = async (id) => await del(`${url.STUDENT_URL}/${id}`);
export const changeClassroom = async (data) =>
	await post(`${url.STUDENT_URL}/change-classroom`, data);

// TEACHERS
export const teacherRegister = (data) => put(`${url.TEACHER_URL}`, data);
export const teacherCompletRegister = (data) => post(`${url.TEACHER_URL}/complet`, data);
export const addSkills = async (data) => await post(`${url.TEACHER_URL}/add-skills`, data);
export const addLevels = async (data) => await post(`${url.TEACHER_URL}/add-levels`, data);
export const addMaterials = async (data) => await post(`${url.TEACHER_URL}/add-materials`, data);
export const getTeacherLevels = async (id) => await get(`${url.TEACHER_URL}/levels/${id}`);
export const getTeacherMaterials = async (id) => await get(`${url.TEACHER_URL}/materials/${id}`);
export const editAccountProfil = async (data) => {
	const formData = new FormData();
	formData.append("firstName", data.firstName);
	formData.append("lastName", data.lastName);
	formData.append("phone", data.phone);
	formData.append("city", data.city);
	formData.append("countryId", data.country.value);
	if (data.imagePath) formData.append("profile_path", data.imagePath);
	formData.append("scoolName", data.scoolName);

	return await patchWithFile(findUserBaseUrl(), formData);
};

export const getTeacherClassRooms = async (id) => await get(`${url.TEACHER_URL}/classrooms/${id}`);

export const setTeacherSchool = async (data) => await post(`${url.TEACHER_URL}/add-schools`, data);
export const getTeacherDashboard = () => get(`${`${url.TEACHER_URL}/dashboard`}`);
export const deleteAccount = async (id) => await del(`${findUserBaseUrl()}/${id}`);

// PARENTS
export const parentCompletRegister = (data) => post(`${url.PARENT_URL}/complet`, data);
export const parentRegister = (data) => put(`${url.PARENT_URL}`, data);
export const getFamily = () => get(`${url.PARENT_URL}/family`);

// CLASSROOM
export const addClassroom = async (data) => {
	const formData = new FormData();
	for (const key in data) {
		const value = data[key];
		if (value) formData.append(key, Array.isArray(value) ? JSON.stringify(value) : value);
	}

	return await postWithFile(url.CLASSROOM_URL, formData);
};
export const getModes = async () => await get(`${url.CLASSROOM_URL}/modes`);

export const editClassroom = (data) => {
	const formData = new FormData();
	formData.append("id", data.id);
	formData.append("modeId", data.modeId);
	formData.append("name", data.name);
	formData.append("levels", JSON.stringify(data.levels));
	formData.append("materials", JSON.stringify(data.materials));
	formData.append("imagePath", data.imagePath);

	return putWithFile(url.CLASSROOM_URL, formData);
};

export const addStudentToClass = async (data) =>
	await post(`${url.CLASSROOM_URL}/add-students`, data);
export const getClassrooms = () => get(`${url.CLASSROOM_URL}`);
export const getClassroom = (id) => get(`${url.CLASSROOM_URL}/${id}`);
export const checkClassroom = (code) =>
	get(`${url.CLASSROOM_URL}/verify-if-exist-classroom/${code}`);

export const deleteClassRoom = async (id) => await del(`${url.CLASSROOM_URL}/${id}`);
// ACTIVITIES
export const createActivityDescJeu = (data) => {
	let formData = new FormData();
	return put(`${url.ACTIVITY_URL}/desc-jeu`, data);
};
export const createActivityArticle = (data) => put(`${url.ACTIVITY_URL}/articles`, data);
export const createActivityFlashCard = (data) => {
	let formData = new FormData();
	formData.append("title", data.title);
	formData.append("theme", data.theme);
	formData.append("subtheme", data.subtheme);

	return putWithFile(`${url.ACTIVITY_URL}/flashcard`, formData);
};
export const createActivityMotMele = (data) => put(`${url.ACTIVITY_URL}/mot-mele`, data);
export const createActivityMotCroise = (data) => {
	let formData = new FormData();
	formData.append("title", data.title);
	formData.append("theme", data.theme);
	formData.append("subtheme", data.subtheme);

	return putWithFile(`${url.ACTIVITY_URL}/mot-croise`, formData);
};
export const createActivityQuiz = (data) => {
	let formData = new FormData();
	formData.append("title", data.title);
	formData.append("theme", data.theme);
	formData.append("subtheme", data.subtheme);

	return putWithFile(`${url.ACTIVITY_URL}/quiz`, formData);
};
export const createActivityAssociation = (data) => {
	let formData = new FormData();
	formData.append("title", data.title);
	formData.append("theme", data.theme);
	formData.append("subtheme", data.subtheme);

	return putWithFile(`${url.ACTIVITY_URL}/association`, formData);
};
export const createActivityMemory = (data) => {
	let formData = new FormData();
	formData.append("title", data.title);
	formData.append("theme", data.theme);
	formData.append("subtheme", data.subtheme);

	return putWithFile(`${url.ACTIVITY_URL}/memory`, formData);
};
export const createActivitySeqPedag = (data) => {
	let formData = new FormData();
	formData.append("title", data.title);
	formData.append("theme", data.theme);
	formData.append("subtheme", data.subtheme);

	return putWithFile(`${url.ACTIVITY_URL}/seq-pedagogique`, formData);
};

export const importActivityDocument = async (data) => {
	const files = [];
	try {
		if (Array.isArray(data.files)) {
			for (const file of data.files) {
				const formData = new FormData();
				formData.set("file", file.file);
				const uploadResponse = await postWithFile(`${url.ACTIVITY_URL}/upload/file`, formData);
				if (uploadResponse.success) {
					file.url = uploadResponse.data.url;
				}
				files.push(file);
			}
		}
		data.files = files;
		return await post(`${url.ACTIVITY_URL}/import-documents`, data);
	} catch (error) {
		console.error(error);
	}
};



export const getTeacherActivities = async (id) => await get(`${url.ACTIVITY_URL}/teacher/${id}`);

// SUBJECTS
export const getSubjects = () => get(url.SUBJECT_URL);
export const getSubjectsByTeacher = (teacherId) =>
	get(`${url.TEACHER_URL}/with-subjects/${teacherId}`);

// LEVELS
export const getLevels = async () => await get(url.LEVEL_URL);

// COUNTRIES
export const getCountries = () => get(url.COUNTRY_URL);

// THEMES
export const getThemes = async () => await get(url.THEMES_URL);

// SUB THEMES
export const getSubThemesByTheme = (themeId) => get(`${url.SUB_THEMES_URL}/bytheme/${themeId}`);

// SUB THEMES METISER
export const getSubThemeMetiers = () => get(`${url.SUB_THEMES_METIER_URL}`);

// SUB THEMES INCLUSION
export const getSubThemeInclusions = () => get(`${url.SUB_THEMES_INCLU_URL}`);

// TYPES LECTEURS
export const getTypeLecteurs = () => get(`${url.TYPE_LECTEUR_URL}`);

// TYPES REPONSES
export const getTypeReponses = () => get(`${url.TYPE_REPONSE_URL}`);

// TEMPS REPONSES
export const getTimeReponses = () => get(`${url.TEMPS_REPONSE_URL}`);

// POINTS REPONSES
export const getPointReponses = () => get(`${url.POINT_URL}`);

// CATEGORY ARTICLES
export const getCategoryArticles = () => get(`${url.CATEGORY_ARTICLE_URL}`);

// COMPETENCES
export const getCompetencesJeu = () => get(url.COMPETENCE_JEU_URL);
export const getCompetencesProgram = () => get(url.COMPETENCE_PROGRAM_URL);
export const getNombreMot = () => get(url.NOMBRE_MOT_URL);

// MODULES
export const getModules = () => get(url.MODULES_URL);

// GROUPES

export const getGroupsByClass = (id) => get(`${url.GROUP_URL}/by-classroom/${id}`);
export const getGroup = (id) => get(`${url.GROUP_URL}/${id}`);
export const createGroup = (data) => post(`${url.GROUP_URL}`, data);

// MATERIALS
export const getMaterials = () => get(url.MATERIALS_URL);

export const createFolder = (data) => post(`${url.ACTIVITY_URL}/folder`, data);
export const duplicateActivity = (data) => post(`${url.ACTIVITY_URL}/duplicate`, data);
export const changeStatus = (data) => post(`${url.ACTIVITY_URL}/status`, data);
export const deleteActivity = (id, isFolder) => del(`${url.ACTIVITY_URL}/${id}/${isFolder}`);
export const createActivity = (data) => post(url.ACTIVITY_URL, data)
export const getTeacherFolders = (id) => get(`${url.ACTIVITY_URL}/folder/teacher/${id}`)
export const changeFolder = (data) => put(`${url.ACTIVITY_URL}/folder/change`, data)
export const updateActivity = (id, data) => put(`${url.ACTIVITY_URL}/${id}`, data)
export const updateFolder = (id, data) => put(`${url.ACTIVITY_URL}/folder/${id}`, data)
export async function uploadActivityPdf(file) {
	const formData = new FormData();
	formData.set("file", file);
	formData.set("name", file.name);
	const uploadResponse = await postWithFile(`${url.ACTIVITY_URL}/upload/file`, formData);
	if (uploadResponse.success && uploadResponse.data) return uploadResponse.data
	return undefined
}

export async function updateActivityPdf(file, id) {
	const formData = new FormData();
	formData.set("file", file);
	formData.set("id", id);
	const uploadResponse = await putWithFile(`${url.ACTIVITY_URL}/upload/file`, formData);
	if (uploadResponse.success && uploadResponse.data) return uploadResponse.data
}