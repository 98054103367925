import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { BiFilter } from "react-icons/bi";
import { GoSearch } from "react-icons/go";
import { Link } from "react-router-dom";

// imports store

// import components
import { ScrollContainer } from "../../components/common";
import Profile from "../../components/common/Profile";

//import assets
import AddClassIcon from "../../assets/images/iconAddClass.png";
import ClassIcon from "../../assets/images/iconClass.png";
import { useAppStore } from "../../store/store";
import ClassCard from "../ClassCard/ClassCard";

export default function ClassList() {
	const classRooms = useAppStore((state) => state.classRooms);
	return (
		<div className='w-full flex flex-col justify-start items-center'>
			<div className="w-full flex justify-between items-center">
				<h1 className='text-iwinblue font-bold text-left mt-3 text-[24px] mx-5'>Mes classes</h1>
				<div className="flex justify-end items-center">
					<div className="relative w-[300px] mt-3 mr-2">
						<input
							type="text"
							placeholder='Trouver une ressource ...'
							className="bg-white rounded-[46px] border-white bottom-0 px-[50px] py-[10px]"
							style={{ border: "none", borderRadius: "40px" }}
						/>
						<GoSearch className="w-5 h-5 absolute top-[15px] left-[20px] text-asprimary" />
						<BiFilter className="w-7 h-7 absolute top-[10px] right-[15px] text-gray-700" />
					</div>
				</div>
				<div className="m-3">
					<Profile />
				</div>
			</div>
			<ScrollContainer>
				<div className='w-full mt-4 px-5'>
					<div className='w-full  bg-white shadow-md rounded-xl flex flex-col justify-start items-center p-6 overscroll-auto'>
						<div className='w-full flex flex-col justify-center items-center px-2'>
							<div className='w-full flex justify-end items-center'>
								<Link
									to='/add-class'
									className='bg-iwingreen text-[12px] rounded-md px-4 py-2 text-white font-bold flex justify-between items-center gap-2'
								>
									<span>Créer une classe</span> <img src={ClassIcon} className="w-4 h-4" alt="" />
								</Link>
							</div>
							<div className='w-full border-b border-gray-200 mt-2' />
						</div>
						<div className='w-full mt-5 flex flex-row justify-center items-center'>
							<Row className='w-full'>
								<Col xs={12} sm={12} md={3} className="w-full">
									<Link
										to='/add-class'
										className='w-full flex flex-col justify-center items-center py-[75px] px-10 rounded-xl border-2 text-iwingreen border-iwingreen'
									>
										<img src={AddClassIcon} alt="" className='w-24' />
										<p className='w-full text-center text-[12px] uppercase font-medium'>
											Créer une nouvelle classe
										</p>
									</Link>
								</Col>
								{classRooms.map((classroom, index) => (
									// rome-ignore lint/suspicious/noArrayIndexKey: <explanation>
									<Col xs={12} sm={12} md={3} className="w-full" key={index}>
										<ClassCard classroom={classroom} />
									</Col>
								))}
							</Row>
						</div>
					</div>
				</div>
			</ScrollContainer>
		</div>
	);
}
