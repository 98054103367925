import { createSlice } from "@reduxjs/toolkit";
import { getSubjectsAction, getSubjectsByTeacherAction } from "./actions";

const initialState = {
	getSubjectsStatus: "",
	getSubjectsError: "",
	subjects: [],
	teachersSubjects: [],
	teachersLevels: [],
};

const subjectSlice = createSlice({
	name: "subjects",
	initialState,
	reducers: {},
	extraReducers: {
		[getSubjectsAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				getSubjectsStatus: "pending",
				getSubjectsError: "",
			};
		},
		[getSubjectsAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				subjects: payload,
				getSubjectsStatus: "success",
				getSubjectsError: "",
			};
		},
		[getSubjectsAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				getSubjectsStatus: "rejected",
				getSubjectsError: payload,
			};
		},
		[getSubjectsByTeacherAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				getSubjectsStatus: "pending",
				getSubjectsError: "",
			};
		},
		[getSubjectsByTeacherAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				teachersSubjects: payload.subjects,
				teachersLevels: payload.levels,
				getSubjectsStatus: "success",
				getSubjectsError: "",
			};
		},
		[getSubjectsByTeacherAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				getSubjectsStatus: "rejected",
				getSubjectsError: payload,
			};
		},
	},
});

export default subjectSlice.reducer;
