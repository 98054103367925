import React from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { CgMenuLeft } from "react-icons/cg";

const Index = ({ setActiveEtape, activeEtape, index, etape, removeEtape }) => {
	return (
		<div
			className={`w-full relative rounded-lg border ${
				activeEtape === index
					? "bg-amber-50/[0.5] border border-slate-500/[0.3]"
					: "border-gray-400/[0]"
			} flex flex-col justify-center items-center px-8 py-2 mb-3 hover:bg-amber-50/[0.5] hover:border-slate-500/[0.3]`}
		>
			<span className='flex flex-col justify-end items-center absolute top-2 right-3 gap-3'>
				<FaRegTrashAlt onClick={() => removeEtape(index)} className="w-4 h-4 cursor-pointer" />
			</span>
			<div
				onClick={() => setActiveEtape(index)}
				className='w-full border border-iwingreen/[0.5] rounded-lg p-2 bg-white flex justify-between items-center gap-3 cursor-pointer'
			>
				<div className='flex justify-start items-center gap-4'>
					<div className='px-2 py-2 rounded-md bg-iwingreen/[0.5] border border-iwingreen/[0.8]'>
						<CgMenuLeft className='w-7 h-7 text-gray-800' />
					</div>
					<span className='text-md font-medium text-gray-600'>Etape 1</span>
				</div>
			</div>
		</div>
	);
};

export default Index;
