import React, { useState, useEffect } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { TiPlus } from "react-icons/ti";
import { FaRegTrashAlt } from "react-icons/fa";
import { RiFileCopyLine } from "react-icons/ri";
import randomColor from "randomcolor";
import { BiImageAdd } from "react-icons/bi";
import { FiVolume1 } from "react-icons/fi";

const Index = ({ deleteCarte, copyCarte, setActionCarte, actionCarte, index }) => {
	useEffect(() => {}, []);

	return (
		<div
			key={index}
			className={`w-full relative rounded-lg flex flex-col justify-center items-center px-10 py-2 mb-3 ${
				actionCarte === index ? "bg-amber-50/[0.5] border border-slate-500/[0.3]" : ""
			}`}
		>
			<span className='flex flex-col justify-end items-center absolute top-2 right-3 gap-3'>
				<FaRegTrashAlt onClick={() => deleteCarte(index)} className="w-4 h-4 cursor-pointer" />
				<RiFileCopyLine onClick={copyCarte} className="w-5 h-5 cursor-pointer" />
			</span>
			<div
				className='w-full flex justify-between items-center gap-4'
				onClick={() => setActionCarte(index)}
			>
				<div className='flex-1 flex justify-start items-center'>
					<div className='flex flex-col justify-between items-center gap-3  mr-2'>
						<div className='flex-1 w-[4vw] min-h-[8vh] rounded-md border-iwingreen/[0.6] border bg-gray-100 flex justify-center items-center cursor-pointer'>
							<BiImageAdd className='w-7 h-7 text-gray-500' />
						</div>
						<div className='flex-1 w-[4vw] min-h-[8vh] rounded-md border-iwingreen/[0.6] border bg-gray-100 flex justify-center items-center cursor-pointer'>
							<FiVolume1 className='w-7 h-7 text-gray-500' />
						</div>
					</div>
					<div className='w-full flex justify-start items-center h-[18vh] border rounded-md bg-white' />
				</div>
				<div className='flex-1 flex justify-start items-center'>
					<div className='flex flex-col justify-between items-center gap-3  mr-2'>
						<div className='flex-1 w-[4vw] min-h-[8vh] rounded-md border-iwingreen/[0.6] border bg-gray-100 flex justify-center items-center cursor-pointer'>
							<BiImageAdd className='w-7 h-7 text-gray-500' />
						</div>
						<div className='flex-1 w-[4vw] min-h-[8vh] rounded-md border-iwingreen/[0.6] border bg-gray-100 flex justify-center items-center cursor-pointer'>
							<FiVolume1 className='w-7 h-7 text-gray-500' />
						</div>
					</div>
					<div className='w-full flex justify-start items-center h-[18vh] border rounded-md bg-white' />
				</div>
			</div>
		</div>
	);
};

export default Index;
