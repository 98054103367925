import "./Offres.scss";
export default function Offres() {
	return (
		<div className="Offres">
			<div className="Offres-container">
				<div className="Offres-head">
					<h2>Nos Offres</h2>
					<button type="button">Utiliser un code d’activation</button>
				</div>
				<div className="Offres-body">
					{OFFERS.map((item) => (
						<Offre offre={item} />
					))}
				</div>
			</div>
		</div>
	);
}

function Offre({ offre }) {
	return (
		<div className="Offres-item">
			<div className={`Offres-item-head ${offre.header}`}>
				<h3>{offre.title}</h3>
				{offre.price && <p>{offre.price}</p>}
				<p>{offre.subtitle}</p>
				{offre.callToActions && (
					<button className={`${offre.callToActions.className}`}>
						{offre.callToActions.label}
					</button>
				)}
			</div>
		</div>
	);
}

const OFFERS = [
	{
		id: 1,
		title: "Découvert",
		subtitle: "Pour un enseignant",
		header: "outline",
		price: "",
		items: ["Accès à 12 histoires", "Jusqu'à 2 histoires par élève", "Espace correction"],
	},
	{
		id: 2,
		title: "Premium",
		subtitle: "Abonnement iWS valable jusqu'au 15 Juillet 2023",
		header: "primary",
		price: "39,90€",
		items: [
			"Histoire originales et litterature classique en illimité",
			"Nombre d’histoires par élève illimité",
			"Espace correction",
			"Plusieurs classes",
			"Meilleure gestion de l’hétérogéneité",
			"Utilisation à distance optimisée",
		],
		callToActions: {
			label: "Souscrire",
			className: "Offres-buttonPrimary",
		},
	},
	{
		id: 3,
		title: "Offre classe",
		subtitle: "Pour 3 enseignants et plus",
		header: "outline",
		items: [
			"Tous les avantages de l’offre premium",
			"Des accés dédiés à chaque enseignant de votre classe",
			"Des tarifs préférentiels adaptés à vos besoins",
			"Une continuité pédagogique entre les classes",
		],
		callToActions: {
			label: "Je demande un devis",
			className: "Offres-buttonOutline",
		},
	},
];
