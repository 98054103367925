import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { AiFillCaretLeft } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";

import Logo from "../../../../assets/images//logo-iwin.png";
import BtnTerminer from "../../../../assets/images/btn-send.png";
import SigUpBgImg from "../../../../assets/images/sigup-student.png";
import SquareTopRight from "../../../../assets/images/square-top-right.png";
import { getLevelsAction } from "../../../../store/level/actions";

const FormContainer = styled.form`
    width: 460px;
    padding: 20px 15px;
     background: url(${SigUpBgImg}) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 16px
`;

const Index = () => {
	const { levels } = useSelector((state) => ({
		levels: state.levelsState.levels,
	}));
	const dispatch = useDispatch();
	const [showPassword, setShowPassword] = useState(false);

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: "",
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.required("Veuillez saisir votre adresse email !")
				.email("Veuillez entrer un email valid !"),
		}),
		onSubmit: (values) => {
			console.info(values);
		},
	});
	useEffect(() => {
		if (levels && levels.length === 0) {
			dispatch(getLevelsAction());
		}
	}, [dispatch]);
	return (
		<div className="flex flex-col justify-center items-center">
			<div className="absolute z-10 top-5">
				<img src={Logo} alt="LOGO" className="h-8" />
			</div>

			<div className="absolute z-10 top-5 flex justify-start items-center left-5">
				<Link
					to={"/login/student"}
					className="px-4 py-2 text-white text-sm rounded-md bg-iwingreen flex flex-row justify-start items-center"
				>
					<AiFillCaretLeft className="w-5 h-5 -ml-1" /> Retour
				</Link>
			</div>
			<FormContainer
				onSubmit={(e) => {
					e.preventDefault();
					validation.handleSubmit();
					return false;
				}}
				className="flex flex-col justify-center items-center relative"
			>
				<img src={SquareTopRight} className="absolute top-0 right-0 rounded-tr-[16px]" alt="" />
				<Row className="w-full mt-5">
					<Col
						xs={12}
						sm={12}
						md={12}
						className="flex flex-col justify-start items-start gap-1 mb-4"
					>
						<label htmlFor='email' className='text-[14px] font-medium'>
							Adresse email <span className='text-red-500'>*</span>
						</label>
						<input
							type="email"
							id='email'
							name='email'
							className='w-full text-[13px] drop-shadow-md outline-none border border-gray-100 text-gray-600 rounded-md focus:border-gray-100 px-3 py-2'
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.email}
							invalid={validation.touched.email && validation.errors.email}
						/>
						{validation.touched.email && validation.errors.email ? (
							<small className="text-[9px] text-red-400">{validation.errors.email}</small>
						) : null}
					</Col>

					<Col lg={12} className='w-full flex justify-center items-center mt-3'>
						<button type="submit">
							<img src={BtnTerminer} alt="" className='h-10' />
						</button>
					</Col>
				</Row>
			</FormContainer>
		</div>
	);
};

export default Index;
