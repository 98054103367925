export const teacherResponses = [
	{ value: "un ami", label: "Un ami" },
	{ value: "une personne de ma famille", label: "Une personne de ma famille" },
	{ value: "un professeur", label: "Un professeur" },
	{ value: "une publicité pour iWinSciences", label: "Une publicité pour iWinSciences" },
	{
		value: "une publicité pour le jeu Scibot's Sciences Labs",
		label: "Une publicité pour le jeu Scibot's Sciences Labs",
	},
	{ value: "une recherche Google", label: "Une recherche Google" },
	{ value: "autre", label: "Autre" },
];
