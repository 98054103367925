import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { AiFillCaretLeft } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import Logo from "../../assets/images//logo-iwin.png";
import iwinRobo from "../../assets/images/SCIBOT.png";
import BtnTerminer from "../../assets/images/btn-send.png";
import SquareTopRight from "../../assets/images/square-top-right.png";
import { AlertContainer } from "../../components/common/AlertContainer/AlertContainer";
import { DefaultContainer } from "../../components/common/DefaultContainer/DefaultContainer";
import { FormContainer } from "../../components/common/FormContainer";
import { sendResetPasswordEmail } from "../../helpers/backend_helper";
import { getLevelsAction } from "../../store/level/actions";
const Index = () => {
	const [success, setSuccess] = useState(false);
	const { levels } = useSelector((state) => ({
		levels: state.levelsState.levels,
	}));
	const dispatch = useDispatch();

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: "",
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.required("Veuillez saisir votre adresse email !")
				.email("Veuillez entrer un email valid !"),
		}),
		onSubmit: async (values) => {
			const id = toast.loading("Envoi de votre mail de réinitialisation de mot de passe ...");
			try {
				const response = await sendResetPasswordEmail(values);
				toast.done(id);
				if (response.data) {
					toast.success(response.data.message);
					setSuccess(true);
				}
			} catch (error) {
				toast.done(id);
			}
		},
	});
	useEffect(() => {
		if (levels && levels.length === 0) {
			dispatch(getLevelsAction());
		}
	}, [dispatch]);

	return (
		<>
			{!success ? (
				<div className="flex flex-col justify-center items-center">
					<div className="absolute z-10 top-5">
						<img src={Logo} alt="LOGO" className="h-8" />
					</div>

					<div className="absolute z-10 top-5 flex justify-start items-center left-5">
						<Link
							to={"/login/student"}
							className="px-4 py-2 text-white text-sm rounded-md bg-iwingreen flex flex-row justify-start items-center"
						>
							<AiFillCaretLeft className="w-5 h-5 -ml-1" /> Retour
						</Link>
					</div>
					<FormContainer
						onSubmit={(e) => {
							e.preventDefault();
							validation.handleSubmit();
						}}
						className="flex flex-col justify-center items-center relative"
					>
						<img src={SquareTopRight} className="absolute top-0 right-0 rounded-tr-[16px]" alt="" />
						<Row className="w-full mt-5">
							<Col
								xs={12}
								sm={12}
								md={12}
								className="flex flex-col justify-start items-start gap-1 mb-4"
							>
								<label htmlFor='email' className='text-[14px] font-medium'>
									Adresse email <span className='text-red-500'>*</span>
								</label>
								<input
									type="email"
									id='email'
									name='email'
									className='w-full text-[13px] drop-shadow-md outline-none border border-gray-100 text-gray-600 rounded-md focus:border-gray-100 px-3 py-2'
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.email}
									invalid={validation.touched.email && validation.errors.email}
								/>
								{validation.touched.email && validation.errors.email ? (
									<small className="text-[9px] text-red-400">{validation.errors.email}</small>
								) : null}
							</Col>

							<Col lg={12} className='w-full flex justify-center items-center mt-3'>
								<button type="submit">
									<img src={BtnTerminer} alt="" className='h-10' />
								</button>
							</Col>
						</Row>
					</FormContainer>
				</div>
			) : (
				<CSSTransition in={true} appear={true} timeout={300} classNames="fade">
					<div className="flex flex-col justify-start items-center -mt-40">
						<AlertContainer className="md:w-[400px]">
							<div className="w-full flex flex-row justify-start items-center text-[12px] bg-notifbg text-notiftext">
								{/* <img src={CheckImg} alt="" className="mr-5" /> */}
								<div>
									<p>
										Un lien de réinitialisation a été envoyé à votre adresse mail{" "}
										{validation.values.email}
									</p>
								</div>
							</div>
						</AlertContainer>
						<DefaultContainer className="flex flex-col justify-center items-center md:w-[400px]">
							<img src={iwinRobo} alt="" className="w-52" />
						</DefaultContainer>
					</div>
				</CSSTransition>
			)}
		</>
	);
};

export default Index;
