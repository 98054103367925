export function createActivitiesSlice(set) {
	return {
		activities: [],
		setActivities: (activities) => set({ activities }),
	};
}

export function createFoldersSlice(set) {
	return {
		folders: [],
		setFolders: (folders) => set({ folders }),
	};
}

export function createSelectedActivitySlice(set) {
	return {
		activity: undefined,
		setActivity: (activity) => set({ activity })
	}
}
