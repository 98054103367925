import React from "react";
import styled from "styled-components";

import BackgroundImg from "../../../assets/images/default.png";

const DefaultConatiner = styled.div`
    background: url(${BackgroundImg}) no-repeat center center fixed; 
    -webkit-background-size: 100vw 100vh;
    -moz-background-size: 100vw 100vh;
    -o-background-size: 100vw 100vh;
    background-size: 100vw 100vh;
    height: 100vh;
`;

const Default = ({ children }) => {
	return (
		<DefaultConatiner className=" flex flex-col justify-center items-center">
			{children}
		</DefaultConatiner>
	);
};

export default Default;
