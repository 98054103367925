import { createAsyncThunk } from "@reduxjs/toolkit";
import { setUserSession } from "../../../helpers//common";
import {
	editAccountProfil as editTeacher,
	parentCompletRegister,
	parentRegister,
	studentRegister,
	teacherCompletRegister,
	teacherRegister,
	verifyEmail,
} from "../../../helpers/backend_helper";

export const registerTeacherAction = createAsyncThunk(
	"teachers/registerTeacherAction",
	async (teacher, { rejectWithValue }) => {
		try {
			const response = await teacherRegister(teacher);
			return response.data;

		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);

export const editTeacherAction = createAsyncThunk(
	"teachers/editTeacherAction",
	async (teacher, { rejectWithValue }) => {
		try {
			const response = await editTeacher(teacher);
			const localData = JSON.parse(localStorage.getItem("_iws_user"));
			if (localData) {
				localData.user = response.data;
			}
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);

export const registerCompletTeacherAction = createAsyncThunk(
	"teachers/registerCompletTeacherAction",
	async (teacher, { rejectWithValue }) => {
		try {
			const response = await teacherCompletRegister(teacher);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);

export const registerParentAction = createAsyncThunk(
	"parents/registerParentAction",
	async (parent, { rejectWithValue }) => {
		try {
			const response = await parentRegister(parent);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);
export const registerCompletParentAction = createAsyncThunk(
	"teachers/registerCompletParentAction",
	async (parent, { rejectWithValue }) => {
		try {
			const response = await parentCompletRegister(parent);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);

export const registerStudentAction = createAsyncThunk(
	"students/registerStudentAction",
	async (student, { rejectWithValue }) => {
		try {
			const response = await studentRegister(student);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);

export const verifyEmailAction = createAsyncThunk(
	"register/verifyEmailAction",
	async (token, { rejectWithValue }) => {
		try {
			const response = await verifyEmail(token);
			setUserSession(response.data);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);
