import React, { useState } from "react";

import { BiFilter } from "react-icons/bi";
import { CgHashtag } from "react-icons/cg";
import { GoSearch } from "react-icons/go";
import { MdPlayArrow } from "react-icons/md";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { ScrollContainer } from "../../components/common";
// import components
import Profile from "../../components/common/Profile";

const Index = () => {
	const [activeIndex, setActiveIndex] = useState(0);

	const HashBodyTemplate = () => {
		return (
			<div className='flex justify-center items-center text-gray-400 rounded-md bg-gray-200'>
				<CgHashtag className='w-5 h-5 ' />
			</div>
		);
	};

	return (
		<div className="w-full flex flex-row justify-between items-start">
			<div className="w-full flex flex-col justify-start items-start mt-5 px-5">
				<div className="w-full flex justify-between items-center">
					<h1 className='text-iwinblue font-bold text-left text-[26px]'>Activités </h1>
					<div className="relative w-[300px]">
						<input
							type="text"
							placeholder='Trouver une ressource ...'
							className="w-full bg-white rounded-[46px] border-white bottom-0 px-[50px] py-3"
							style={{ border: "none", borderRadius: "40px" }}
						/>
						<GoSearch className="w-6 h-6 absolute top-[15px] left-[20px] text-asprimary" />
						<BiFilter className="w-8 h-8 absolute top-[10px] right-[15px] text-gray-700" />
					</div>
				</div>
				<ScrollContainer className='w-full mt-4 px-2'>
					<div className='w-full flex flex-col justify-start items-start px-5 py-3 shadow-lg bg-white rounded-lg'>
						<div className='flex justify-start items-center gap-7'>
							<button
								onClick={() => setActiveIndex(0)}
								className={`text-lg text-iwinblue ${
									activeIndex === 0 ? "border-b-4 border-iwingreen" : "border-b-0"
								}`}
							>
								Activités en attentes
							</button>
							<button
								onClick={() => setActiveIndex(1)}
								className={`text-lg text-iwinblue ${
									activeIndex === 1 ? "border-b-4 border-iwingreen" : "border-b-0"
								}`}
							>
								Activités terminées
							</button>
						</div>
						<div className='w-full mt-4'>
							<DataTable value={[]} emptyMessage="Aucune donnée">
								<Column field="image" header={HashBodyTemplate} />
								<Column field="fisrtName" header="Statut" />
								<Column field="code" header="Titre de l'activité" />
								<Column field="code" header="Thème" />
								<Column field="action" header="Date" />
							</DataTable>
						</div>
						<div className='w-full flex justify-center items-center gap-3 mt-3'>
							<MdPlayArrow className='w-7 h-7 text-gray-400 rotate-180' />
							<div className='w-9 h-9 rounded-full bg-iwingreen flex justify-center items-center'>
								<span className='font-semibold text-white text-sm'>1</span>
							</div>
							<div className='w-9 h-9 rounded-full border border-gray-400 flex justify-center items-center'>
								<span className='font-semibold text-gray-400 text-sm'>2</span>
							</div>
							<div className='w-9 h-9 rounded-full border border-gray-400 flex justify-center items-center'>
								<span className='font-semibold text-gray-400 text-sm'>3</span>
							</div>
							<MdPlayArrow className='w-7 h-7 text-gray-400' />
						</div>
					</div>
				</ScrollContainer>
			</div>
			<div className="w-[340px] bg-white px-7 pt-5 h-screen">
				<Profile />
				<div className='border-b border-gray-200 -mt-1' />

				<div className='w-full flex justify-end items-center'>
					<button className='text-[10px] font-medium text-iwingreen px-2 py-1 border border-iwingreen rounded-lg mt-4 flex justify-start items-center'>
						Tous les tutoriels <MdPlayArrow className='w-4 h-4 ml-1' />{" "}
					</button>
				</div>
			</div>
		</div>
	);
};

export default Index;
