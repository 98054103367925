import React from "react";
import "./not-sidebar.css";
import DefaultConatiner from "./container/DefaultConatiner";
const NotSideBar = ({ children }) => {
	return (
		<DefaultConatiner className="">
			<main className=''> {children}</main>
		</DefaultConatiner>
	);
};

export default NotSideBar;
