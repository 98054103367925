import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { AiFillCaretLeft } from "react-icons/ai";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";

import Logo from "../../../../assets/images//logo-iwin.png";
import BtnTerminer from "../../../../assets/images/btn-send.png";
import SigUpBgImg from "../../../../assets/images/sigup-student.png";
import SquareTopRight from "../../../../assets/images/square-top-right.png";
import { getLevelsAction } from "../../../../store/level/actions";

const FormContainer = styled.form`
    width: 460px;
    padding: 20px 15px;
     background: url(${SigUpBgImg}) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 16px
`;

const Index = () => {
	const { levels } = useSelector((state) => ({
		levels: state.levelsState.levels,
	}));
	const dispatch = useDispatch();
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			newPassword: "",
			confirmNewPassword: "",
		},
		validationSchema: Yup.object({
			newPassword: Yup.string().required("Veuillez remplir le mot de passe !"),
			confirmNewPassword: Yup.string()
				.required("Veuillez confirmer le le mot de passe !")
				.when("newPassword", {
					is: (val) => (val && val.length > 0 ? true : false),
					then: Yup.string().oneOf(
						[Yup.ref("newPassword")],
						"Les mot de passe ne correspondent pas !",
					),
				}),
		}),
		onSubmit: (values) => {
			console.info(values);
		},
	});
	useEffect(() => {
		if (levels && levels.length === 0) {
			dispatch(getLevelsAction());
		}
	}, [dispatch]);
	return (
		<div className="flex flex-col justify-center items-center">
			<div className="absolute z-10 top-5">
				<img src={Logo} alt="LOGO" className="h-8" />
			</div>

			<div className="absolute z-10 top-5 flex justify-start items-center left-5">
				<Link
					to={"/login"}
					className="px-4 py-2 text-white text-sm rounded-md bg-iwingreen flex flex-row justify-start items-center"
				>
					<AiFillCaretLeft className="w-5 h-5 -ml-1" /> Retour
				</Link>
			</div>
			<FormContainer
				onSubmit={(e) => {
					e.preventDefault();
					validation.handleSubmit();
					return false;
				}}
				className="flex flex-col justify-center items-center relative"
			>
				<img src={SquareTopRight} className="absolute top-0 right-0 rounded-tr-[16px]" alt="" />
				<h4 className="text-[16px] font-semibold text-iwinblue">Changer mon mot de passe</h4>
				<Row className="w-full mt-5">
					<Col
						xs={12}
						sm={12}
						md={12}
						className="flex flex-col justify-start items-start gap-1 mb-4 relative"
					>
						<label htmlFor='newPassword' className='text-[13px] text-iwinblue font-medium'>
							Nouveau mot de passe <span className='text-red-500'>*</span>
						</label>
						<input
							type={showPassword ? "text" : "password"}
							id='newPassword'
							name='newPassword'
							className='w-full pr-7 text-[13px] drop-shadow-md outline-none border border-gray-100 text-gray-600 rounded-md focus:border-gray-100 px-3 py-2'
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.newPassword}
							invalid={validation.touched.newPassword && validation.errors.newPassword}
						/>
						{showPassword ? (
							<FiEyeOff
								className="absolute right-4 top-[2.35rem] text-gray-600 cursor-pointer"
								onClick={() => setShowPassword(!showPassword)}
							/>
						) : (
							<FiEye
								className="absolute right-4 top-[2.35rem] text-gray-600 cursor-pointer"
								onClick={() => setShowPassword(!showPassword)}
							/>
						)}
						{validation.touched.newPassword && validation.errors.newPassword ? (
							<small className="text-[9px] text-red-400">{validation.errors.newPassword}</small>
						) : null}
					</Col>
					<Col
						xs={12}
						sm={12}
						md={12}
						className="flex flex-col justify-start items-start gap-1 mb-4 relative"
					>
						<label htmlFor='confirmNewPassword' className='text-[13px] text-iwinblue font-medium'>
							Confirmer le mot de passe <span className='text-red-500'>*</span>
						</label>
						<input
							type={showConfirmPassword ? "text" : "password"}
							id='confirmNewPassword'
							name='confirmNewPassword'
							className='w-full pr-7 text-[13px] drop-shadow-md outline-none border border-gray-100 text-gray-600 rounded-md focus:border-gray-100 px-3 py-2'
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.confirmNewPassword}
							invalid={
								validation.touched.confirmNewPassword && validation.errors.confirmNewPassword
							}
						/>
						{showConfirmPassword ? (
							<FiEyeOff
								className="absolute right-4 top-[2.35rem] text-gray-600 cursor-pointer"
								onClick={() => setShowConfirmPassword(!showConfirmPassword)}
							/>
						) : (
							<FiEye
								className="absolute right-4 top-[2.35rem] text-gray-600 cursor-pointer"
								onClick={() => setShowConfirmPassword(!showConfirmPassword)}
							/>
						)}
						{validation.touched.confirmNewPassword && validation.errors.confirmNewPassword ? (
							<small className="text-[9px] text-red-400">
								{validation.errors.confirmNewPassword}
							</small>
						) : null}
					</Col>
					<Col lg={12} className='w-full flex justify-center items-center mt-3'>
						<button type="submit">
							<img src={BtnTerminer} alt="" className='h-10' />
						</button>
					</Col>
				</Row>
			</FormContainer>
		</div>
	);
};

export default Index;
