import React from "react";
import styled from "styled-components";

import { BiFilter } from "react-icons/bi";
import { CgHashtag } from "react-icons/cg";
import { GoSearch } from "react-icons/go";
import { MdPlayArrow } from "react-icons/md";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { ScrollContainer } from "../../components/common";
// import components
import Profile from "../../components/common/Profile";

import Medal from "../../assets/images/icons/Medal.png";
import Icon0 from "../../assets/images/icons/icon-0.png";
import Icon1 from "../../assets/images/icons/icon-1.png";
import Icon2 from "../../assets/images/icons/icon-2.png";
import Icon3 from "../../assets/images/icons/icon-3.png";
import BackgroundImg from "../../assets/images/jumbotronbg.png";
import LogoGame from "../../assets/images/logo-jeux-23.png";

const JumbotronConatiner = styled.div`
    background: url(${BackgroundImg}) no-repeat center center fixed; 
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
    height: 100%;
`;

const Index = () => {
	const HashBodyTemplate = (rowData) => {
		return (
			<div className='flex justify-center items-center text-gray-400 rounded-md bg-gray-200'>
				<CgHashtag className='w-5 h-5 ' />
			</div>
		);
	};

	return (
		<div className="w-full flex flex-row justify-between items-start">
			<div className="w-full flex flex-col justify-start items-start mt-5 px-5">
				<div className="w-full flex justify-between items-center">
					<h1 className='text-iwinblue font-bold text-left text-[26px]'>
						Bienvenue, <i className='text-iwingreen'>Abdoulaye</i> !
					</h1>
					<div className="relative w-[300px]">
						<input
							type="text"
							placeholder='Trouver une ressource ...'
							className="w-full bg-white rounded-[46px] border-white bottom-0 px-[50px] py-3"
							style={{ border: "none", borderRadius: "40px" }}
						/>
						<GoSearch className="w-6 h-6 absolute top-[15px] left-[20px] text-asprimary" />
						<BiFilter className="w-8 h-8 absolute top-[10px] right-[15px] text-gray-700" />
					</div>
				</div>
				<ScrollContainer className='w-full mt-4 px-2'>
					<div className='w-full flex flex-col justify-start items-start'>
						<JumbotronConatiner className="w-full mt-5 rounded-lg px-9 flex flex-row justify-start items-center">
							<img src={LogoGame} className="h-[110px] -my-3" alt="" />
							<div className='w-full flex flex-col justify-center items-center font-bold ml-5 mb-2 py-3'>
								<h4 className="w-full text-[18px]">
									{" "}
									Des niveaux de jeu passionnants t’attendent !
								</h4>
								<p className="w-full font-medium text-[12px]">
									Dans l’univers « Le mirroir de l’étranger » deux nouvelles histoires à découvrir
								</p>
							</div>
							<button className='px-7 py-2 uppercase bg-iwingreen rounded-lg text-white text-md'>
								Jouer
							</button>
						</JumbotronConatiner>
						<h2 className='text-lg font-black text-iwinblue my-5 capitalize'>
							Statistiques générales
						</h2>
						<div className='w-full flex flex-row justify-between items-center gap-3'>
							<div className='flex justify-between items-center px-3 py-2 shadow-lg bg-white rounded-lg'>
								<img src={Icon0} className="w-11 h-11" alt="" />
								<div className='w-full flex flex-col justify-center items-start gap-1 px-3 py-1'>
									<h2 className='font-semibold text-sm'>Niveaus atteint</h2>
									<b className='text-xl font-black text-iwinblue -my-1'>14</b>
									<p className='text-sm font-thin text-gray-600'>
										<span className='text-iwingreen font-semibold'>+10%</span> progression
									</p>
								</div>
							</div>
							<div className='flex justify-between items-center px-3 py-2 shadow-lg bg-white rounded-lg'>
								<img src={Icon1} className="w-11 h-11" alt="" />
								<div className='w-full flex flex-col justify-center items-start gap-1 px-3 py-1'>
									<h2 className='font-semibold text-sm'>Classement Général</h2>
									<b className='text-xl font-black text-iwinblue -my-1'>37</b>
									<p className='text-sm font-thin text-gray-600'>
										<span className={"text-red-500 font-semibold"}>-5%</span> progression
									</p>
								</div>
							</div>
							<div className='flex justify-between items-center px-3 py-2 shadow-lg bg-white rounded-lg'>
								<img src={Icon2} className="w-11 h-11" alt="" />
								<div className='w-full flex flex-col justify-center items-start gap-1 px-3 py-1'>
									<h2 className='font-semibold text-sm'>Mes étoiles</h2>
									<b className='text-xl font-black text-iwinblue -my-1'>
										3.5<span className='text-[14px] font-bold'>/5</span>
									</b>
									<p className='text-sm font-thin text-gray-600'>
										<span className={"text-red-500 font-semibold"}>-5</span> cette semaine
									</p>
								</div>
							</div>
							<div className='flex justify-between items-center px-3 py-2 shadow-lg bg-white rounded-lg'>
								<img src={Icon3} className="w-11 h-11" alt="" />
								<div className='w-full flex flex-col justify-center items-start gap-1 px-3 py-1'>
									<h2 className='font-semibold text-sm'>Temps de jeu</h2>
									<b className='text-xl font-black text-iwinblue -my-1'>42 min.</b>
									<p className='text-sm font-thin text-gray-600'>
										<span className={"text-iwingreen font-semibold"}>+25%</span> cette semaine
									</p>
								</div>
							</div>
						</div>
						<div className='w-full flex flex-col justify-start items-start px-5 py-3 mt-5 bg-white rounded-lg shadow-lg'>
							<h1 className='text-iwinblue font-bold'>Niveau par compétence</h1>
							<div className='w-full flex justify-between items-center mt-2 gap-5'>
								<div className='flex-1 border-2 border-iwingreen rounded-lg'>
									<div className='border-[3px] border-white text-white bg-iwingreen rounded-lg flex flex-col justify-start items-start py-2 pr-4 pl-2'>
										<p className='flex justify-start items-center gap-1 '>
											<img src={Medal} className='w-5 h-5' alt='Medal' />
											<span className='font-bold text-2xl'>08</span>
										</p>
										<p className='border-l-4 border-iwinyellow px-1 text-md '>Connaissances</p>
									</div>
								</div>
								<div className='flex-1 border-2 border-iwingreen rounded-lg'>
									<div className='border-[3px] border-white text-white bg-iwingreen rounded-lg flex flex-col justify-start items-start py-2 pr-4 pl-2'>
										<p className='flex justify-start items-center gap-1 '>
											<img src={Medal} className='w-5 h-5' alt='Medal' />
											<span className='font-bold text-2xl'>17</span>
										</p>
										<p className='border-l-4 border-iwincolor2 px-1 text-md '>Observations</p>
									</div>
								</div>
								<div className='flex-1 border-2 border-iwingreen rounded-lg'>
									<div className='border-[3px] border-white text-white bg-iwingreen rounded-lg flex flex-col justify-start items-start py-2 pr-4 pl-2'>
										<p className='flex justify-start items-center gap-1 '>
											<img src={Medal} className='w-5 h-5' alt='Medal' />
											<span className='font-bold text-2xl'>06</span>
										</p>
										<p className='border-l-4 border-iwinpurple px-1 text-md '>Expérimentations</p>
									</div>
								</div>
								<div className='flex-1 border-2 border-iwingreen rounded-lg'>
									<div className='border-[3px] border-white text-white bg-iwingreen rounded-lg flex flex-col justify-start items-start py-2 pr-4 pl-2'>
										<p className='flex justify-start items-center gap-1 '>
											<img src={Medal} className='w-5 h-5' alt='Medal' />
											<span className='font-bold text-2xl'>12</span>
										</p>
										<p className='border-l-4 border-green-500 px-1 text-md '>Réflexion</p>
									</div>
								</div>
							</div>
						</div>
						<div className='w-full mt-3 flx flex-col justify-start items-start gap-3 bg-white shadow-lg rounded-lg p-3'>
							<h2 className='flex capitalize justify-start items-center gap-3 text-iwinblue font-bold text-xl py-2'>
								Niveaux de jeu
							</h2>
							<DataTable headerClassName="bg-white" emptyMessage="Aucune donnée" value={[]}>
								<Column field="image" header={HashBodyTemplate} />
								<Column field="fisrtName" header="Notion" />
								<Column field="code" header="Titre" />
								<Column field="code" header="Temps de jeu" />
								<Column field="action" header="Niveau par compétence" />
							</DataTable>
						</div>
					</div>
				</ScrollContainer>
			</div>
			<div className="w-[340px] bg-white px-7 pt-5 h-screen">
				<Profile />
				<div className='border-b border-gray-200 -mt-1' />

				<div className='w-full flex justify-end items-center'>
					<button className='text-[10px] font-medium text-iwingreen px-2 py-1 border border-iwingreen rounded-lg mt-4 flex justify-start items-center'>
						Tous les tutoriels <MdPlayArrow className='w-4 h-4 ml-1' />{" "}
					</button>
				</div>
			</div>
		</div>
	);
};

export default Index;
