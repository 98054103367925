import * as Yup from "yup";

export const initialValues = {
	id: "",
	firstName: "",
	lastName: "",
	imagePath: "",
	classroom: { value: "", label: "" },
	password: "",
};

export const validationSchema = Yup.object({
	id: Yup.string(),
	firstName: Yup.string().required("Veuillez saisir le prénom !"),
	lastName: Yup.string().required("Veuillez saisir le prénom !"),
	password: Yup.string(),
	imagePath: Yup.mixed(),
});
