import React from "react";
import "./ActivityCard.scss";

import ActivityImg from "../../../assets/images/activity/activity-placeholder.png";
import ActivityMenu from "../ActivityMenu";


export default function ActivityCard({ activity }) {
	const { id } = activity;

	return (
		<div className='ActivityCard flex flex-col'>
			<a href={activity.file.url ?? "#"} target="_blank" rel="noreferrer">
				<img src={ActivityImg} className='rounded-t-lg object-cover' alt="" />
			</a>
			<ActivityMenu
				id={id}
				activity={activity}
				isFolder={false}
				className='absolute right-1 text-gray-600 w-7 h-7 cursor-pointer'
			/>
			<a
				href={activity.file.url ?? "#"}
				target="_blank"
				rel="noreferrer"
				className='p-3 flex flex-col justify-center items-start'
			>
				<h3 className='text-[14px] font-bold'>{activity.name}</h3>
				<p>{activity.description}</p>
			</a>
		</div>
	);
}
