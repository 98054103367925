import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTeacherDashboard } from "../../helpers/backend_helper";

export const getTeacherDashboardAction = createAsyncThunk(
	"classrooms/getTeacherDashboardAction",
	async (_id, { rejectWithValue }) => {
		try {
			const response = await getTeacherDashboard();
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);
