import { createAsyncThunk } from "@reduxjs/toolkit";
import { getGroupsByClass, createGroup, getGroup } from "../../helpers/backend_helper";

export const getGroupsByClassAction = createAsyncThunk(
	"group/getGroupsByClassAction",
	async (id, { rejectWithValue }) => {
		try {
			const response = await getGroupsByClass(id);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);

export const getGroupAction = createAsyncThunk(
	"group/getGroupClassAction",
	async (id, { rejectWithValue }) => {
		try {
			const response = await getGroup(id);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);

export const addGroupAction = createAsyncThunk(
	"classrooms/addGroupAction",
	async (data, { rejectWithValue }) => {
		try {
			const response = await createGroup(data);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);
