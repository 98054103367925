export const customStyles = {
	control: (base) => ({
		...base,
		height: "42px",
		border: "1px solid rgba(50, 202,188, 0.47)",
		background: "rgba(50, 202,188, 0.02)",
		color: "#303972",
		boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
		filter: "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
		borderRadius: "0.375rem",
		"&:hover": {
			border: "1px solid rgba(50, 202,188, 0.47)",
		},
	}),
	option: (provided, state) => ({
		...provided,
		fontSize: "16px",
	}),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: "#000000",
			fontSize: "16px",
		};
	},
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";
		const fontSize = "16px";
		const color = "#000000";
		const textTransform = "capitalize";
		return { ...provided, color, fontSize, opacity, transition, textTransform };
	},
	input: (styles, { data }) => ({
		...styles,
		outline: "none",
		border: "none",
		focus: "none",
		color: "#000000",
		textTransform: "capitalize",
	}),
	indicatorSeparator: (base) => ({
		...base,
		display: "none",
	}),
	dropdownIndicator: (base) => ({
		...base,
		color: "#303972",
		"&:hover": {
			color: "#303972",
		},
	}),
	menuList: (base) => ({
		...base,
		"::-webkit-scrollbar": {
			width: "4px",
			height: "0px",
			color: "#303972",
			textTransform: "capitalize",
		},
		"::-webkit-scrollbar-track": {
			background: "#f1f1f1",
		},
		"::-webkit-scrollbar-thumb": {
			background: "#888",
		},
		"::-webkit-scrollbar-thumb:hover": {
			background: "#555",
		},
	}),
};
