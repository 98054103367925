import React, { useEffect, useState } from "react";

import { BiFilter } from "react-icons/bi";
import { GoSearch } from "react-icons/go";

import { Divider } from "primereact/divider";

import Profile from "../../components/common/Profile";
import {
	ArticleModele,
	AssociationModele,
	DescJeuModele,
	EmptyModele,
	FlashCardModele,
	MemoryModele,
	MotMeleModele,
	MotcroisModele,
	QuizModele,
	SeqPedagModele
} from "./modeles";

import CardActivityTheme from "./card-activity-theme";

import Icon2 from "../../assets/images/activity/img2.png";
import Icon3 from "../../assets/images/activity/img3.png";
import Icon4 from "../../assets/images/activity/img4.png";
import Icon5 from "../../assets/images/activity/img5.png";
import Icon6 from "../../assets/images/activity/img6.png";

import { getThemes } from "../../helpers/backend_helper";

const Indx = () => {
	const [tabIndex, setTabIndex] = useState(0);
	const [themesList, setThemesList] = useState([]);

	const changeTheme = (i) => {
		setTabIndex(i);
	};

	useEffect(() => {
		getThemes().then((data) => {
			setThemesList(data.data);
		});
	}, []);

	return (
		<div className='w-full flex flex-col justify-start items-center'>
			<div className="w-full flex justify-between items-center">
				<h1 className='text-iwinblue font-bold text-left mt-3 text-[24px] mx-5'>
					Créer une activité
				</h1>
				<div className="flex justify-end items-center">
					<div className="relative w-[300px] mt-3 mr-2">
						<input
							type="text"
							placeholder='Trouver une ressource ...'
							className="bg-white rounded-[46px] border-white bottom-0 px-[50px] py-[10px]"
							style={{ border: "none", borderRadius: "40px" }}
						/>
						<GoSearch className="w-5 h-5 absolute top-[15px] left-[20px] text-asprimary" />
						<BiFilter className="w-7 h-7 absolute top-[10px] right-[15px] text-gray-700" />
					</div>
						<Profile />
					
				</div>
			</div>
			<div className='w-full flex justify-between items-center gap-4 px-3 mt-4'>
				<div className='w-36 flex flex-col justify-start items-center bg-white rounded-lg shadow-xl px-2 pt-3 pb-4'>
					<h2 className='w-full text-[11px] text-iwinblue font-semibold text-center mt-3'>
						Choisir un modèle
					</h2>
					<Divider className='m-0' />
					<div className="scroller4 w-full">
						<div className='w-full min-h-full flex flex-col justify-start items-center gap-4'>
							<CardActivityTheme
								icon={Icon2}
								title="Description niveau de jeu"
								active={tabIndex === 1 ? true : false}
								setTabIndex={changeTheme}
								i={1}
							/>
							<CardActivityTheme
								icon={Icon3}
								title="Article"
								active={tabIndex === 2 ? true : false}
								setTabIndex={changeTheme}
								i={2}
							/>
							<CardActivityTheme
								icon={Icon5}
								title="Quiz"
								active={tabIndex === 3 ? true : false}
								setTabIndex={changeTheme}
								i={3}
							/>
							<CardActivityTheme
								icon={Icon6}
								title="Séquence péagogique"
								active={tabIndex === 4 ? true : false}
								setTabIndex={changeTheme}
								i={4}
							/>
							<CardActivityTheme
								icon={Icon2}
								title="FlashCard"
								active={tabIndex === 5 ? true : false}
								setTabIndex={changeTheme}
								i={5}
							/>
							<CardActivityTheme
								icon={Icon2}
								title="Mots-croisés"
								active={tabIndex === 6 ? true : false}
								setTabIndex={changeTheme}
								i={6}
							/>
							<CardActivityTheme
								icon={Icon2}
								title="Mots-mélés"
								active={tabIndex === 7 ? true : false}
								setTabIndex={changeTheme}
								i={7}
							/>
							<CardActivityTheme
								icon={Icon2}
								title="Memory"
								active={tabIndex === 8 ? true : false}
								setTabIndex={changeTheme}
								i={8}
							/>
							<CardActivityTheme
								icon={Icon2}
								title="Association"
								active={tabIndex === 9 ? true : false}
								setTabIndex={changeTheme}
								i={9}
							/>
							<CardActivityTheme
								icon={Icon4}
								title="Trouver su l'image"
								active={tabIndex === 12 ? true : false}
								setTabIndex={changeTheme}
								i={10}
							/>
							<CardActivityTheme
								icon={Icon4}
								title="Ordonner"
								active={tabIndex === 11 ? true : false}
								setTabIndex={changeTheme}
								i={11}
							/>
							<CardActivityTheme
								icon={Icon4}
								title="Expérience ou démarche scientifique"
								active={tabIndex === 12 ? true : false}
								setTabIndex={changeTheme}
								i={3}
							/>
							<CardActivityTheme
								icon={Icon4}
								title="Legender"
								active={tabIndex === 13 ? true : false}
								setTabIndex={changeTheme}
								i={12}
							/>
							<CardActivityTheme
								icon={Icon4}
								title="Text à trous"
								active={tabIndex === 14 ? true : false}
								setTabIndex={changeTheme}
								i={13}
							/>
							<CardActivityTheme
								icon={Icon4}
								title="Test"
								active={tabIndex === 15 ? true : false}
								setTabIndex={changeTheme}
								i={14}
							/>
						</div>
					</div>
				</div>
				<div className='w-full h-[80vh] p-3 min-h-[80vh] max-h-[80vh] rounded-md bg-white'>
					{tabIndex === 0 ? <EmptyModele themesList={themesList} /> : null}
					{tabIndex === 1 ? <DescJeuModele themesList={themesList} /> : null}
					{tabIndex === 2 ? <ArticleModele themesList={themesList} /> : null}
					{tabIndex === 3 ? <QuizModele themesList={themesList} /> : null}
					{tabIndex === 4 ? <SeqPedagModele themesList={themesList} /> : null}
					{tabIndex === 5 ? <FlashCardModele themesList={themesList} /> : null}
					{tabIndex === 6 ? <MotcroisModele themesList={themesList} /> : null}
					{tabIndex === 7 ? <MotMeleModele themesList={themesList} /> : null}
					{tabIndex === 8 ? <MemoryModele themesList={themesList} /> : null}
					{tabIndex === 9 ? <AssociationModele themesList={themesList} /> : null}
				</div>
			</div>
		</div>
	);
};

export default Indx;
