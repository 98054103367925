import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { GoogleLogin } from "react-google-login";
import { BsApple } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { FiEye, FiEyeOff, FiInfo } from "react-icons/fi";
import { RiGoogleFill } from "react-icons/ri";
import { TbInfoCircle } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import * as Yup from "yup";

// imports Components

//imports Actions
import { checkClassroomExistAction } from "../../store/classroom/actions.js";

import { loginAction } from "../../store/auth/login/actions.js";

import { getUserRoles } from "../../helpers/common.js";

import Logo from "../../assets/images//logo-iwin.png";
import GarImg from "../../assets/images/gar-img.png";
import LogoJeux from "../../assets/images/logo-jeux-2.svg";
import MobileImg from "../../assets/images/mobile-img.png";
import WebImg from "../../assets/images/web-img.png";
import { useAppStore } from "../../store/store.js";
//imports Assets

const Index = () => {
	const setAccount = useAppStore((state) => state.setAccount);
	const clientId = "47272990424-vtgu3719qnt9a633eqrm2eq020dinond.apps.googleusercontent.com";
	const { existClassroom, classroomType, classroomStatus, loginStatus, loginError } = useSelector(
		(state) => ({
			classroomType: state.classroomState.classroomType,
			classroomStatus: state.classroomState.classroomStatus,
			existClassroom: state.classroomState.existClassroom,
			loginStatus: state.authState.loginStatus,
			loginError: state.authState.loginError,
		}),
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [formSubmited, setFormSubmited] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [code, setCode] = useState("");
	const [codeError, setCodeError] = useState("");

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.required("Veuillez remplir l'email !")
				.email("Veuillez entrer un email valid !"),
			password: Yup.string().required("Veuillez remplir le mot de passe !"),
		}),
		onSubmit: (values) => {
			setFormSubmited(true);
			dispatch(loginAction(values)).then((value) => {
				if (value?.payload?.user) setAccount(value.payload.user);
			});
		},
	});

	const responseSuccessGoogle = (response) => { };

	const responseErrorGoogle = (error) => { };

	const JouerModal = () => {
		return showModal ? (
			<CSSTransition in={true} appear={true} timeout={300} classNames="fade">
				<div className="fixed inset-0 z-10 overflow-y-auto">
					{/* rome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
					<div
						className="fixed inset-0 w-full h-full bg-black opacity-70"
						onClick={() => setShowModal(false)}
					/>
					<div className="flex items-center min-h-screen px-4 py-8">
						<div className="relative w-full max-w-lg px-4 py-7 mx-auto bg-white rounded-md shadow-lg">
							<div className="w-full flex flex-col justify-center items-center">
								<p className="w-full flex flex-row justify-center items-center text-sm text-gray-600 gap-4">
									<FiInfo /> Continuer avec la version
								</p>
								<div className="w-full flex flex-row justify-center items-center mt-7 gap-4">
									<Link to={""}>
										<img src={MobileImg} alt="" className="h-40" />
									</Link>
									<Link to={""}>
										<img src={WebImg} alt="" className="h-40" />
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</CSSTransition>
		) : null;
	};

	useEffect(() => {
		if (classroomStatus === "success" && classroomType === "check") {
			navigate("/login/student", { replace: true, state: { ...existClassroom } });
		}
	}, [classroomStatus]);

	useEffect(() => {
		if (loginStatus === "success") {
			let url = "/";
			if (getUserRoles() === "STUDENT") {
				url = "/";
			} else if (getUserRoles() === "TEACHER") {
				url = "/";
			} else if (getUserRoles() === "PARENT") {
				url = "/parent";
			} else if (getUserRoles() === "ADMIN") {
				url = "/admin";
			}
			navigate(url, { replace: true });
		}
	}, [loginStatus]);

	return (
		<CSSTransition in={true} appear={true} timeout={300} classNames="fade">
			<div className="flex flex-col justify-center items-center">
				<div className="absolute z-10 top-3">
					<img src={Logo} alt="LOGO" className="h-10" />
				</div>
				<Link
					to="/register"
					className='px-4 py-2 rounded-md bg-white text-iwingreen text-sm absolute top-3 right-7'
				>
					S'inscrire
				</Link>
				<EnpdoitContainer className="max-w-[850px] md:w-[850px] flex flex-col justify-start items-center ">
					<h4 className="text-[16px] font-bold text-white">CONNEXION</h4>
					<div className="w-full flex flex-row justify-between items-start gap-3 my-5">
						<FormContainer
							onSubmit={(e) => {
								e.preventDefault();
								validation.handleSubmit();
								return false;
							}}
							className="flex-1 flex flex-col justify-between items-center relative"
						>
							<div className="w-full flex flex-col justify-start items-start gap-2 mt-4">
								<label htmlFor='email' className='text-[12px]'>
									Adresse email <span className='text-red-500'>*</span>
								</label>
								<input
									type="text"
									id='email'
									name='email'
									className='focus:border-inputbg[0.47] px-3 py-2'
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.email}
									invalid={validation.touched.email && validation.errors.email}
								/>
								{validation.touched.email && validation.errors.email ? (
									<small className="text-[9px] text-red-400">{validation.errors.email}</small>
								) : null}
							</div>
							<div className="w-full flex flex-col justify-start items-start gap-2 mt-4 relative">
								<label htmlFor='password' className='text-[12px]'>
									Mot de passe <span className='text-red-500'>*</span>
								</label>
								<input
									type={showPassword ? "text" : "password"}
									id='password'
									name='password'
									className='focus:border-inputbg/[0.47] px-3 py-2'
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.password}
									invalid={validation.touched.password && validation.errors.password}
								/>
								{showPassword ? (
									<FiEyeOff
										className="absolute right-4 top-[2.4rem] text-iwingreencursor-pointer"
										onClick={() => setShowPassword(!showPassword)}
									/>
								) : (
									<FiEye
										className="absolute right-4 top-[2.4rem] text-iwinblue cursor-pointer"
										onClick={() => setShowPassword(!showPassword)}
									/>
								)}
								{validation.touched.password && validation.errors.password ? (
									<small className="text-[9px] text-red-400">{validation.errors.password}</small>
								) : null}
							</div>
							<div className="w-full flex flex-row justify-between items-center gap-2 mt-4">
								<div className="flex justify-start items-center px-0 cursor-pointer">
									<input type="checkbox" id="rememberMe" className='mr-2' name="rememberMe" />
									<label
										htmlFor="rememberMe"
										className="text-[12px] text-iwinblue flex flex-row justify-center items-center"
									>
										Se souvenir de moi
									</label>
								</div>
								<Link
									to="/forget-password"
									className="text-[12px] text-iwinblue flex flex-row justify-center items-center"
								>
									<u>Mot de passe oublié? </u>
								</Link>
							</div>
							<button
								type="submit"
								className="text-sm bg-iwingreen px-5 py-3 text-white rounded-md font-medium mt-5 mb-2"
							>
								Se connecter
							</button>
							{loginError !== "" && (
								<p className="w-full flex flex-row justify-center items-center text-red-500 text-[12px] m-0">
									Email ou mot de passe incorrect !
								</p>
							)}
							<div className='flex flex-row justify-center items-center gap-1 mt-3 '>
								<h6 className='text-[13px] font-semibold text-iwinblue'>Se connecter avec :</h6>
							</div>
							<div className='flex flex-row justify-center items-center gap-1 mt-2 my-6'>
								<Link to="/#" className="p-2 bg-blue-600 rounded-full mx-1 text-white">
									<FaFacebookF className="w-3 h-3" />
								</Link>
								<GoogleLogin
									clientId={clientId}
									render={(renderProps) => (
										<button
											onClick={renderProps.onClick}
											className="p-2 bg-red-600 rounded-full mx-1 text-white"
										>
											<RiGoogleFill className="w-3 h-3" />
										</button>
									)}
									onSuccess={responseSuccessGoogle}
									onFailure={responseErrorGoogle}
									cookiePolicy={"single_host_origin"}
								/>
								<Link to="/#" className="p-2 bg-gray-300 rounded-full mx-1 text-white">
									<BsApple className="w-3 h-3" />
								</Link>
							</div>
						</FormContainer>
						<div className="flex-1 flex flex-col justify-start items-start gap-3">
							<CardContainer className="flex flex-col gap-1 justify-start items-end p-3">
								<div className="w-full flex flex-col justify-center items-start gap-2 py-2 px-3">
									<label
										htmlFor='code'
										className='flex flex-row justify-start items-center text-[12px]'
									>
										<span>
											Code <span className='text-red-500'>*</span>{" "}
										</span>{" "}
										<TbInfoCircle className="ml-4 w-4 h-4 cursor-pointer" />
									</label>
									<input
										type="text"
										id='code'
										name='code'
										className='focus:border-inputbg/[0.47] px-3 py-2 text-center text-[22px] font-black uppercase'
										onChange={(e) => setCode(e.target.value)}
										value={code}
										length={6}
										placeholder="AF4B3T"
										maxLength="6"
									/>
									<small className="text-[9px] text-red-400">{codeError}</small>
								</div>
								<button
									disabled={code.length !== 6}
									onClick={() => dispatch(checkClassroomExistAction(code))}
									type="submit"
									className="text-sm float-right bg-iwingreen px-5 py-3 mb-1 text-white rounded-md font-medium"
								>
									Se connecter
								</button>
							</CardContainer>
							<JouerContainer className="w-full flex flex-row justify-between items-center bg-white">
								<BtnContainer onClick={() => setShowModal(true)} className="">
									Jouer
								</BtnContainer>
								<img src={LogoJeux} className="h-[105px]" alt="" />
							</JouerContainer>
							<CardContainer className="flex flex-row gap-2 justify-between items-center px-4 py-[4px]">
								<img src={GarImg} alt="" className="h-[70px] mt-1" />
								<div className='w-full flex flex-col justify-end items-end px-5 pt-4'>
									<h6 className="text-[12px] font-semibold text-iwinblue">
										Gestionnaire d’accés aux ressources
									</h6>
									<p className='text-[10px] font-bold text-gray-700'>A venir...</p>
								</div>
							</CardContainer>
						</div>
					</div>
					<Link to="/register" className="text-[12px] font-[600] text-white">
						<u>Je n'ai pas encore de compte, s'inscrire </u>
					</Link>
				</EnpdoitContainer>
				{JouerModal()}
			</div>
		</CSSTransition>
	);
};

export default Index;

const EnpdoitContainer = styled.div`
    padding: 20px;
    vertical-align: top;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

const FormContainer = styled.form`
    padding: 0.5em 1.5em;
     background: #fff no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 10px;
    vertical-align: top;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;

const CardContainer = styled.div`
    width: 100%;
     background: #fff no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 10px;
    vertical-align: top;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;

const JouerContainer = styled.div`
    padding: 0.1em 1.2em;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 10px;
    vertical-align: top;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;

const BtnContainer = styled.button`
    padding: 1.1rem 2.5rem;
    background: #FDC928;
    z-index:3;
    color: #000000;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 500;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;
