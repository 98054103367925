import React from "react";
import { InputSwitch } from "primereact/inputswitch";
import { FileUpload } from "primereact/fileupload";
import { BiImage } from "react-icons/bi";

const Index = ({
	response,
	handleChangeResponseText,
	questionIndex,
	responseIndex,
	handleChangeResponseSwitch,
}) => {
	return (
		<div
			key={responseIndex}
			className={
				"w-full border-l-[10px] shadow-lg bg-white rounded-lg px-3 py-2 mt-2 flex justify-between items-center gap-1"
			}
			style={{ borderColor: response.color }}
		>
			<input
				type="text"
				name="response"
				placeholder='Saisir la réponse...'
				className='px-2 py-1 text-xs drop-shadow-none'
				onChange={(e) => handleChangeResponseText(e, questionIndex, responseIndex)}
				value={response.response}
				style={{ border: "0px solid white", filter: "none", boxShadow: "none" }}
			/>
			<div className='flex flex-col justify-end items-end gap-2'>
				<InputSwitch
					checked={response.isCorrect}
					onChange={(e) => handleChangeResponseSwitch(e, questionIndex, responseIndex)}
				/>

				<div className='flex justify-center items-center rounded bg-gray-100 p-[1px]'>
					<label htmlFor='photo'>
						<BiImage className='w-5 h-5 cursor-pointer text-gray-400 ' />
						<input
							accept='image/*'
							onChange={(e) => {}}
							id="photo"
							name="imagePath"
							required
							type="file"
							className="hidden"
						/>
					</label>
				</div>
			</div>
		</div>
	);
};

export default Index;
