import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFamily } from "../../helpers/backend_helper";

export const getFamilyAction = createAsyncThunk(
	"family/getFamilyAction",
	async (id = null, { rejectWithValue }) => {
		try {
			const response = await getFamily();
			return response;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);
