import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./themes/index.scss";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import NotSideBar from "./components/layouts/NotSideBar";
import ParentLayout from "./components/layouts/parent";
import StudentLayout from "./components/layouts/student";
import TeacherLayout from "./components/layouts/teacher";

import { getUser, isUserAuthenticated } from "./helpers/common";

import { useEffect } from "react";
import {
	getCountries,
	getLevels,
	getMaterials,
	getModes,
	getTeacherClassRooms,
	getThemes,
} from "./helpers/backend_helper";
import { useActivities, useFolders } from "./hooks/useActivities";
import NotFoundPage from "./pages/404";
import { parentRoutes, publicRoutes, studentRoutes, teacherRoutes } from "./routes";
import { useAppStore } from "./store/store";

export default function App() {
	const { refresh: findActivities } = useActivities();
	const {refresh: findFolders} = useFolders()
	const setCountries = useAppStore((state) => state.setCountries);
	const setLevels = useAppStore((state) => state.setLevels);
	const setMaterials = useAppStore((state) => state.setMaterials);
	const setClassRooms = useAppStore((state) => state.setClassRooms);
	const setModes = useAppStore((state) => state.setModes);
	const account = useAppStore((state) => state.account);
	const setAccount = useAppStore((state) => state.setAccount);
	const setThemes = useAppStore((state) => state.setThemes);

	const syncTeacherInitalDatas = async () => {
		const modes = await getModes();
		const classRooms = await getTeacherClassRooms(getUser().id);
		if (modes.success) setModes(modes.data);
		if (classRooms.success) setClassRooms(classRooms.data);
		await findActivities();
		await findFolders()
	};

	const syncInitialsData = async () => {
		const levels = await getLevels();
		const materials = await getMaterials();
		const countries = await getCountries();
		const themes = await getThemes();

		if (levels.success) setLevels(levels.data);
		if (materials.success) setMaterials(materials.data);
		if (countries.data) setCountries(countries.data);
		if (themes.success) setThemes(themes.data);
	};

	useEffect(() => {
		syncInitialsData().catch();
	}, []);

	useEffect(() => {
		if (account) {
			syncTeacherInitalDatas().catch();
		}
	}, [account]);

	useEffect(() => {
		const user = getUser();
		if (user) {
			setAccount(user);
		}
	}, []);

	return (
		<>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				toastClassName={"toast-style"}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<BrowserRouter>
				<Routes>
					{publicRoutes.map((route) => (
						<Route
							path={route.path}
							key={route.path}
							element={
								<IsLogin path={route.path}>
									<NotSideBar>
										<route.component />
									</NotSideBar>
								</IsLogin>
							}
						/>
					))}
					{teacherRoutes.map((route) => (
						<Route
							path={route.path}
							key={route.path}
							element={
								<ProtectedRoute path={route.path}>
									<TeacherLayout>
										<route.component />
									</TeacherLayout>
								</ProtectedRoute>
							}
						/>
					))}
					{parentRoutes.map((route) => (
						<Route
							path={route.path}
							key={route.path}
							element={
								<ProtectedRoute path={route.path}>
									<ParentLayout>
										<route.component />
									</ParentLayout>
								</ProtectedRoute>
							}
						/>
					))}

					{studentRoutes.map((route) => (
						<Route
							path={route.path}
							key={route.path}
							element={
								<ProtectedRoute path={route.path}>
									<StudentLayout>
										<route.component />
									</StudentLayout>
								</ProtectedRoute>
							}
						/>
					))}

					<Route path="*" element={<NotFoundPage />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}

const ProtectedRoute = ({ children }) => {
	if (!isUserAuthenticated()) {
		return <Navigate to="/login" replace />;
	}
	return children;
};

const IsLogin = ({ children, path }) => {
	if (isUserAuthenticated()) {
		const role = getUser().account.role;
		if (path === "/login" || path === "/register") {
			if (role === "ADMIN") {
				return <Navigate to="/admin" replace />;
			} else if (role === "PARENT") {
				return <Navigate to="/parent" replace />;
			} else if (role === "TEACHER") {
				return <Navigate to="/" replace />;
			} else if (role === "STUDENT") {
				return <Navigate to="/student" replace />;
			}
		}
	}
	return children;
};
