import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { BiFilter } from "react-icons/bi";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { GoSearch } from "react-icons/go";
import { MdPlayArrow } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import * as Yup from "yup";

// import helpers
import { getUser } from "../../../../helpers/common";

// import components
import Profile from "../../../../components/common/Profile";

import { changePasswordAction } from "../../../../store/auth/login/actions";
// import store
import { getCountriesAction } from "../../../../store/country/actions";
import { getFamilyAction } from "../../../../store/family/actions";

import ArrowFatLineUpImg from "../../../../assets/images/ArrowFatLineUp.png";
import BankIcon from "../../../../assets/images/Bank.png";
import BookBookmarkIcon from "../../../../assets/images/BookBookmark.png";
import EnvelopeIcon from "../../../../assets/images/EnvelopeSimple.png";
import FlagIcon from "../../../../assets/images/Flag.png";
import LockIcon from "../../../../assets/images/Lock.png";
import MapIcon from "../../../../assets/images/MapPinLine.png";
import Pencil from "../../../../assets/images/Pencil.png";
import PhoneIcon from "../../../../assets/images/Phone.png";
//Assets images
import ShoppingIcon from "../../../../assets/images/ShoppingBag.png";
import TrashSimpleIcon from "../../../../assets/images/TrashSimple.png";
import UserIcon from "../../../../assets/images/User.png";
import CameraIcon from "../../../../assets/images/iconCamera.png";
import PercentImg from "../../../../assets/images/icons/Percent.png";
import UsersImg from "../../../../assets/images/icons/Users-white.png";
import UserProfile from "../../../../assets/images/user-p2.png";

const CardConatiner = styled.div`
    background: white no-repeat center center fixed; 
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
    height: 100%;
`;

const Index = () => {
	const { family, loginStatus, loginType, countries, dashboard } = useSelector((state) => ({
		family: state.familyState.family,
		loginStatus: state.authState.loginStatus,
		loginType: state.authState.loginType,
		countries: state.countryState.countries,
		dashboard: state.dashboardState.dashboard,
	}));
	const dispatch = useDispatch();
	const [user, setUser] = useState(getUser());
	const [showEditUserModal, setShowEditUserModal] = useState(false);
	const [showOffreModal, setShowOffreModal] = useState(false);
	const [showEditPasswordModal, setShowEditPasswordModal] = useState(false);
	const [showOldPassword, setShowOldPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const customStyles = {
		control: (base) => ({
			...base,
			border: "1px solid rgba(50, 202,188, 0.47)",
			background: "rgba(50, 202,188, 0.02)",
			color: "#303972",
			paddingBottom: "2px",
			paddingTop: "2px",
			boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
			filter: "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
			borderRadius: "0.375rem",
			"&:hover": {
				border: "1px solid rgba(50, 202,188, 0.47)",
			},
		}),
		option: (provided, state) => ({
			...provided,
			fontSize: "16px",
		}),
		placeholder: (defaultStyles) => {
			return {
				...defaultStyles,
				color: "#303972",
				fontSize: "16px",
			};
		},
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = "opacity 300ms";
			const fontSize = "16px";
			const color = "#303972";
			return { ...provided, color, fontSize, opacity, transition };
		},
		input: (styles, { data }) => ({
			...styles,
			outline: "none",
			border: "none",
			focus: "none",
			color: "#303972",
			fontSize: "16px",
		}),
		indicatorSeparator: (base) => ({
			...base,
			display: "none",
		}),
		dropdownIndicator: (base) => ({
			...base,
			color: "#303972",
			"&:hover": {
				color: "#303972",
			},
		}),
		menuList: (base) => ({
			...base,
			"::-webkit-scrollbar": {
				width: "4px",
				height: "0px",
				color: "#303972",
			},
			"::-webkit-scrollbar-track": {
				background: "#f1f1f1",
			},
			"::-webkit-scrollbar-thumb": {
				background: "#888",
			},
			"::-webkit-scrollbar-thumb:hover": {
				background: "#555",
			},
		}),
	};

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			firstName: user?.firstName || "",
			lastName: user.lastName || "",
			imagePath: "",
			country: { value: user.country.id, label: user.country.name },
			city: user?.city || "",
			phone: "",
		},
		validationSchema: Yup.object({
			firstName: Yup.string().required("Veuillez saisir le prénom !"),
			lastName: Yup.string().required("Veuillez saisir le nom  !"),
			phone: Yup.string().required("Veuillez saisir le téléphone !"),
			city: Yup.string().required("Veuillez saisir la ville !"),
			country: Yup.object().required("Veuillez sélectionner le pays !"),
			imagePath: Yup.mixed(),
		}),
		onSubmit: (values) => {
			setShowEditUserModal(false);
		},
	});

	const editPasswordForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			oldPassword: "",
			newPassword: "",
			confirmPassword: "",
		},
		validationSchema: Yup.object({
			oldPassword: Yup.string().required("Veuillez saisir l'ancien mot de passe !"),
			newPassword: Yup.string().required("Veuillez saisir le nouveau mot de passe !"),
			confirmPassword: Yup.string()
				.required("Veuillez confirmer le mot de passe !")
				.when("newPassword", {
					is: (val) => (val && val.length > 0 ? true : false),
					then: Yup.string().oneOf(
						[Yup.ref("newPassword")],
						"Les mots de passe ne correspondent pas !",
					),
				}),
		}),
		onSubmit: (values) => {
			dispatch(changePasswordAction(values));
		},
	});

	const editUserModal = () => {
		return showEditUserModal ? (
			<CSSTransition in={true} appear={true} timeout={300} classNames="fade">
				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div
						className="fixed inset-0 w-full h-full bg-black opacity-70"
						onClick={() => setShowEditUserModal(false)}
					/>
					<div className="flex items-center min-h-screen px-4 py-8">
						<div className="relative w-full max-w-3xl px-4 py-10 mx-auto bg-white rounded-md shadow-lg">
							<div className="w-full flex flex-col justify-center items-center px-10">
								<h2 className='w-full flex justify-center items-center text-lg font-bold text-iwingreen gap-3'>
									<img src={Pencil} className='w-5 h-5' alt="" />
									Modifier votre profil
								</h2>

								<form
									onSubmit={(e) => {
										e.preventDefault();
										validation.handleSubmit();
										return false;
									}}
									className='w-full mt-3'
								>
									<Row className='w-full'>
										<Col
											xs={12}
											sm={12}
											md={6}
											className='flex flex-col justify-start items-start gap-3 mb-4'
										>
											<label htmlFor="firstName">
												Prénom <span className='text-red-500'>*</span>
											</label>
											<input
												type="text"
												className="px-3 py-2"
												id="firstName"
												name="firstName"
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												value={validation.values.firstName}
												invalid={validation.touched.firstName && validation.errors.firstName}
											/>
											{validation.touched.firstName && validation.errors.firstName ? (
												<small className="text-[12px] text-red-400">
													{validation.errors.firstName}
												</small>
											) : null}
										</Col>
										<Col
											xs={12}
											sm={12}
											md={6}
											className='flex flex-col justify-start items-start gap-3 mb-4'
										>
											<label htmlFor="lastName">
												Nom <span className='text-red-500'>*</span>
											</label>
											<input
												type="text"
												className="px-3 py-2"
												id="lastName"
												name="lastName"
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												value={validation.values.lastName}
												invalid={validation.touched.lastName && validation.errors.lastName}
											/>
											{validation.touched.lastName && validation.errors.lastName ? (
												<small className="text-[12px] text-red-400">
													{validation.errors.lastName}
												</small>
											) : null}
										</Col>
										<Col
											xs={12}
											sm={12}
											md={6}
											className='flex flex-col justify-start items-start gap-3 mb-4'
										>
											<label>
												Photo <span className='text-red-500'>*</span>
											</label>
											<div className='flex justify-center items-center w-full  relative'>
												{validation.values.imagePath === "" ? (
													<img src={UserProfile} alt="" className='w-64 h-64' />
												) : (
													<img
														src={URL.createObjectURL(validation.values.imagePath)}
														alt=""
														className='rounded-md'
													/>
												)}
												<div className='w-full h-full rounded-md absolute top-0 bg-black/[.3] flex flex-col justify-start items-end'>
													<div className='mt-2 mr-2 py-1 px-2 border-dashed border-gray-700 border-2 rounded-md bg-gray-100/[.5]'>
														<label htmlFor='photo'>
															<img src={CameraIcon} alt="" className='w-9 h-9 cursor-pointer' />
															<input
																accept='image/*'
																onChange={(e) =>
																	validation.setFieldValue("imagePath", e.currentTarget.files[0])
																}
																onBlur={validation.handleBlur}
																id="photo"
																name="imagePath"
																type="file"
																className="hidden"
															/>
														</label>
													</div>
												</div>
											</div>
											{validation.touched.imagePath && validation.errors.imagePath ? (
												<small className="text-[12px] text-red-400">
													{validation.errors.imagePath}
												</small>
											) : null}
										</Col>
										<Col
											xs={12}
											sm={12}
											md={6}
											className='flex flex-col justify-start items-start gap-3 mb-4'
										>
											<Row>
												<Col
													xs={12}
													sm={12}
													md={12}
													className='flex flex-col justify-start items-start gap-3 mb-4'
												>
													<label htmlFor="country">
														Pays <span className='text-red-500'>*</span>
													</label>
													<Select
														id="country"
														name="country"
														styles={customStyles}
														options={countries}
														placeholder="Choisir Pays..."
														className="w-full"
														classNamePrefix="select"
														onChange={(e) => {
															validation.setFieldValue("country", e);
														}}
														onBlur={validation.handleBlur}
														value={validation.values.country}
														invalid={validation.touched.country && validation.errors.country}
													/>
													{validation.touched.country && validation.errors.country ? (
														<small className="text-[9px] text-red-400">
															{validation.errors.country}
														</small>
													) : null}
												</Col>
												<Col
													xs={12}
													sm={12}
													md={12}
													className='flex flex-col justify-start items-start gap-3 mb-4'
												>
													<label htmlFor="city">
														Ville <span className='text-red-500'>*</span>
													</label>
													<input
														type="text"
														className="px-3 py-2"
														id="city"
														name="city"
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
														value={validation.values.city}
														invalid={validation.touched.city && validation.errors.city}
													/>
													{validation.touched.city && validation.errors.city ? (
														<small className="text-[12px] text-red-400">
															{validation.errors.city}
														</small>
													) : null}
												</Col>
												<Col
													xs={12}
													sm={12}
													md={12}
													className='flex flex-col justify-start items-start gap-3 mb-4'
												>
													<label htmlFor="phone">
														Téléphone <span className='text-red-500'>*</span>
													</label>
													<input
														type="text"
														className="px-3 py-2"
														id="phone"
														name="phone"
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
														value={validation.values.phone}
														invalid={validation.touched.phone && validation.errors.phone}
													/>
													{validation.touched.phone && validation.errors.phone ? (
														<small className="text-[12px] text-red-400">
															{validation.errors.phone}
														</small>
													) : null}
												</Col>
											</Row>
										</Col>
										<Col className="w-full flex flex-row justify-end items-center -mt-5" md={12}>
											<button
												type="submit"
												className="px-5 py-2 text-sm rounded-md bg-iwincolor3 text-white"
											>
												Enregister
											</button>
										</Col>
									</Row>
								</form>
							</div>
						</div>
					</div>
				</div>
			</CSSTransition>
		) : null;
	};

	const offreModal = () => {
		return showOffreModal ? (
			<CSSTransition in={true} appear={true} timeout={300} classNames="fade">
				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div
						className="fixed inset-0 w-full h-full bg-black opacity-70"
						onClick={() => setShowOffreModal(false)}
					/>
					<div className="flex items-center min-h-screen px-4 py-8">
						<div className="relative w-full max-w-lg px-4 py-7 mx-auto bg-white rounded-md shadow-lg">
							<div className="w-full flex flex-col justify-center items-center">
								<div className="w-full flex flex-row justify-center items-center mt-7 gap-4">
									Lorem Ipsum is simply dummy text of the printing and typesetting industry.
								</div>
							</div>
						</div>
					</div>
				</div>
			</CSSTransition>
		) : null;
	};

	const editPasswordModal = () => {
		return showEditPasswordModal ? (
			<CSSTransition in={true} appear={true} timeout={300} classNames="fade">
				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div
						className="fixed inset-0 w-full h-full bg-black opacity-70"
						onClick={() => setShowEditPasswordModal(false)}
					/>
					<div className="flex items-center min-h-screen px-4 py-8">
						<div className="relative w-full max-w-lg px-4 py-7 mx-auto bg-white rounded-md shadow-lg">
							<div className="w-full flex flex-col justify-center items-center px-10">
								<h2 className='w-full flex justify-center items-center text-lg font-bold text-iwingreen gap-3'>
									<img src={LockIcon} className='w-5 h-5' alt="" />
									Changement de mot de passe
								</h2>
								<form
									onSubmit={(e) => {
										e.preventDefault();
										editPasswordForm.handleSubmit();
										return false;
									}}
									className='w-full mt-5'
								>
									<Row className='w-full'>
										<Col
											xs={12}
											sm={12}
											md={12}
											className="flex flex-col justify-start items-start gap-1 mb-4 relative"
										>
											<label
												htmlFor='oldPassword'
												className='text-[14px] text-iwinblue font-medium'
											>
												Mot de passe actuel <span className='text-red-500'>*</span>
											</label>
											<input
												type={showOldPassword ? "text" : "password"}
												id='oldPassword'
												name='oldPassword'
												className='w-full pr-7 text-[14px] drop-shadow-md outline-none border bg-inputbg/[0.02] border-inputbg/[0.47]  text-iwinblue rounded-md focus:border-inputbg/[0.47] px-3 py-2'
												onChange={editPasswordForm.handleChange}
												onBlur={editPasswordForm.handleBlur}
												value={editPasswordForm.values.oldPassword}
												invalid={
													editPasswordForm.touched.oldPassword &&
													editPasswordForm.errors.oldPassword
												}
											/>
											{showOldPassword ? (
												<FiEyeOff
													className="absolute right-4 top-[2.35rem]  text-iwinblue cursor-pointer"
													onClick={() => setShowOldPassword(!showOldPassword)}
												/>
											) : (
												<FiEye
													className="absolute right-4 top-[2.35rem]  text-iwinblue cursor-pointer"
													onClick={() => setShowOldPassword(!showOldPassword)}
												/>
											)}
											{editPasswordForm.touched.oldPassword &&
												editPasswordForm.errors.oldPassword ? (
												<small className="text-[9px] text-red-400">
													{editPasswordForm.errors.oldPassword}
												</small>
											) : null}
										</Col>
										<Col
											xs={12}
											sm={12}
											md={12}
											className="flex flex-col justify-start items-start gap-1 mb-4 relative"
										>
											<label
												htmlFor='newPassword'
												className='text-[14px] text-iwinblue font-medium'
											>
												Mot de passe <span className='text-red-500'>*</span>
											</label>
											<input
												type={showNewPassword ? "text" : "password"}
												id='newPassword'
												name='newPassword'
												className='w-full pr-7 text-[14px] drop-shadow-md outline-none border bg-inputbg/[0.02] border-inputbg/[0.47]  text-iwinblue rounded-md focus:border-inputbg/[0.47] px-3 py-2'
												onChange={editPasswordForm.handleChange}
												onBlur={editPasswordForm.handleBlur}
												value={validation.values.newPassword}
												invalid={
													editPasswordForm.touched.newPassword &&
													editPasswordForm.errors.newPassword
												}
											/>
											{showNewPassword ? (
												<FiEyeOff
													className="absolute right-4 top-[2.35rem]  text-iwinblue cursor-pointer"
													onClick={() => setShowNewPassword(!showNewPassword)}
												/>
											) : (
												<FiEye
													className="absolute right-4 top-[2.35rem]  text-iwinblue cursor-pointer"
													onClick={() => setShowNewPassword(!showNewPassword)}
												/>
											)}
											{editPasswordForm.touched.newPassword &&
												editPasswordForm.errors.newPassword ? (
												<small className="text-[9px] text-red-400">
													{editPasswordForm.errors.newPassword}
												</small>
											) : null}
										</Col>
										<Col
											xs={12}
											sm={12}
											md={12}
											className="flex flex-col justify-start items-start gap-1 mb-4 relative"
										>
											<label
												htmlFor='confirmPassword'
												className='text-[14px] text-iwinblue font-medium'
											>
												Ressaisir le nouveau mot de passe <span className='text-red-500'>*</span>
											</label>
											<input
												type={showConfirmPassword ? "text" : "password"}
												id='confirmPassword'
												name='confirmPassword'
												className='w-full pr-7 text-[14px] drop-shadow-md outline-none border bg-inputbg/[0.02] border-inputbg/[0.47]  text-iwinblue rounded-md focus:border-inputbg/[0.47] px-3 py-2'
												onChange={editPasswordForm.handleChange}
												onBlur={editPasswordForm.handleBlur}
												value={editPasswordForm.values.confirmPassword}
												invalid={
													editPasswordForm.touched.confirmPassword &&
													editPasswordForm.errors.confirmPassword
												}
											/>
											{showConfirmPassword ? (
												<FiEyeOff
													className="absolute right-4 top-[2.35rem]  text-iwinblue cursor-pointer"
													onClick={() => setShowConfirmPassword(!showConfirmPassword)}
												/>
											) : (
												<FiEye
													className="absolute right-4 top-[2.35rem]  text-iwinblue cursor-pointer"
													onClick={() => setShowConfirmPassword(!showConfirmPassword)}
												/>
											)}
											{editPasswordForm.touched.confirmPassword &&
												editPasswordForm.errors.confirmPassword ? (
												<small className="text-[9px] text-red-400">
													{editPasswordForm.errors.confirmPassword}
												</small>
											) : null}
										</Col>
										<Col className="w-full flex flex-row justify-end items-center" md={12}>
											<button
												type="submit"
												className="px-5 py-2 text-sm rounded-md bg-iwincolor3 text-white"
											>
												Changer
											</button>
										</Col>
									</Row>
								</form>
							</div>
						</div>
					</div>
				</div>
			</CSSTransition>
		) : null;
	};

	useEffect(() => {
		if (countries && countries.length === 0) {
			dispatch(getCountriesAction());
		}
		if (loginType === "changePassword" && loginStatus === "success") {
			setShowEditPasswordModal(false);
		}
		dispatch(getFamilyAction());
	}, [dispatch, loginType]);

	return (
		<div className="w-full flex flex-row justify-between items-start">
			<div className="w-full flex flex-col justify-start items-start mt-5 px-5">
				<div className="w-full flex justify-between items-center">
					<h1 className='text-iwinblue font-bold text-left text-[26px]'>Mon Profil</h1>
					<div className="relative w-[300px]">
						<input
							type="text"
							placeholder='Trouver une ressource ...'
							className="bg-white rounded-[46px] border-white bottom-0 px-[50px] py-2"
							style={{ border: "none", borderRadius: "40px" }}
						/>
						<GoSearch className="w-5 h-5 absolute top-[11px] left-[20px] text-asprimary" />
						<BiFilter className="w-7 h-7 absolute top-[8px] right-[15px] text-gray-700" />
					</div>
				</div>
				<div className='w-full bg-white rounded-md mt-4 shadow-md flex flex-col justify-start items-start px-3 pt-1 pb-5'>
					<p className='w-full text-left text-[10px] font-medium'>Code de famille</p>
					<div className='w-full flex justify-between items-center'>
						<div className='flex justify-start items-center gap-2 px-3 pt-2'>
							<h2 className='text-md font-bold text-iwingreen/[.6]'>
								{`${family?.name?.split("-")[0]} - ${family?.code}`}
							</h2>
						</div>
						<button
							className='px-4 py-3 text-xs font-bold text-gray-700 bg-iwinyellow rounded-md flex justify-start items-center gap-2'
							onClick={() => setShowOffreModal(true)}
						>
							Mon offre <img src={ShoppingIcon} className='w-5 h-5' />{" "}
						</button>
					</div>
					<hr className='w-full border-t-2 bg-gray-400 mt-3' />
					<div className='w-full flex justify-between items-start gap-4'>
						<div className='flex-1 flex flex-col items-start justify-center iwin-shadow bg-white px-4 py-3 rounded-md mt-4'>
							<div className='w-full flex justify-between items-center'>
								<h2 className='text-iwinblue text-sm font-bold'>Informations personnelles</h2>
								<button
									onClick={() => setShowEditUserModal(true)}
									className='px-3 py-2 rounded-md border-2 border-iwingreen bg-white flex justify-beteween items-center gap-2'
								>
									<h3 className='text-[10px] font-semibold text-iwingreen'>Modifier</h3>
									<img src={Pencil} className='w-4 h-4' alt="" />
								</button>
							</div>
							<div className='w-full flex justify-start items-start mt-3 gap-7'>
								<div className='rounded-full '>
									<img
										src={UserProfile}
										className="w-24 h-24 rounded-full border border-iwingreen"
										alt=""
									/>
								</div>
								<div className='flex flex-col justify-start items-start gap-3 pt-2'>
									<p className='text-iwinblue flex justify-start items-center gap-2 text-xs'>
										<img src={UserIcon} alt="" className='w-4 h-4' />
										<span className='font-bold'>
											<span className='capitalize'>{user.firstName}</span>{" "}
											{user.lastName.toUpperCase()}
										</span>
									</p>
									<p className='text-iwinblue flex justify-start items-center gap-2 text-xs'>
										<img src={BankIcon} alt="" className='w-4 h-4' />
										<span className='font-normal'>
											<span className='capitalize'>{user.firstName}</span>{" "}
											{user.lastName.toUpperCase()}
										</span>
									</p>
									<p className='text-iwinblue flex justify-start items-center gap-2 text-xs'>
										<img src={BookBookmarkIcon} alt="" className='w-4 h-4' />
										<span className='font-normal'>
											<span className='capitalize'>{user.firstName}</span>{" "}
											{user.lastName.toUpperCase()}
										</span>
									</p>
								</div>
							</div>
							<hr className='w-full border-t bg-gray-400 mt-5 mb-2' />
							<div className='w-full flex flex-col justify-start items-start'>
								<table className='w-full mb-3'>
									<tr key="1" className='w-full'>
										<td>
											<div className='flex justify-start items-center gap-2  py-2'>
												<img src={EnvelopeIcon} alt="" className='w-5 h-5' />
												<span className='text-[12px] font-normal text-iwinblue'>
													{user.account.email}
												</span>
											</div>
										</td>
										<td>
											<div className='flex justify-start items-center gap-2  py-2'>
												<img src={PhoneIcon} alt="" className='w-5 h-5' />
												<span className='text-[12px] font-normal text-iwinblue'>
													+33 66 77 65 76
												</span>
											</div>
										</td>
									</tr>
									<tr key="2" className='w-full'>
										<td>
											<div className='flex justify-start items-center gap-2  py-2'>
												<img src={MapIcon} alt="" className='w-5 h-5' />
												<span className='text-[12px] font-normal text-iwinblue'>Paris</span>
											</div>
										</td>
										<td>
											<div className='flex justify-start items-center gap-2  py-2'>
												<img src={FlagIcon} alt="" className='w-5 h-5' />
												<span className='text-[12px] font-normal text-iwinblue'>France</span>
											</div>
										</td>
									</tr>
								</table>
							</div>
						</div>
						<div className='flex-1 flex flex-col items-start justify-start iwin-shadow bg-white px-4 py-3 rounded-md mt-4'>
							<div className='w-full flex justify-start items-center py-2'>
								<h2 className='text-iwinblue text-sm font-bold'>Enfants</h2>
							</div>
							<div className='w-full flex flex-col gap-3 justify-start items-start mt-4 pb-24'>
								<div className='w-full flex justify-between items-center'>
									<div className='flex justify-start items-center gap-4'>
										<div className='bg-iwingreen rounded-full w-10 max-w-10 h-10 flex justify-center items-center p-2'>
											<img src={UsersImg} className="w-5 h-5" alt="" />
										</div>
										<span className='text-md font-black'>Enfants</span>
									</div>
									<span className='text-[14px] font-bold text-iwingreen'>
										{family?.child || "00"}
									</span>
								</div>
								<hr className='w-full border-t bg-gray-400 -mt-1' />
								<div className='w-full flex justify-between items-center'>
									<div className='flex justify-start items-center gap-4'>
										<div className='bg-iwingreen rounded-full w-10 max-w-10 h-10 flex justify-center items-center p-2'>
											<img src={PercentImg} className="w-5 h-5" alt="" />
										</div>
										<span className='text-md font-black'>Moyenne générale</span>
									</div>
									<span className='text-[14px] font-bold text-iwingreen'>
										{family?.child || "00"}
										<span className='text-[10px] '>/20</span>
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className='w-full flex flex-col items-start justify-start iwin-shadow bg-white px-4 py-3 rounded-md mt-3'>
						<div className='w-full flex justify-start items-center py-2'>
							<h2 className='text-iwinblue text-sm font-bold'>Paramètres avancés</h2>
						</div>
						<div className='w-full flex justify-start items-center gap-4 my-5'>
							<button
								onClick={() => setShowEditPasswordModal(true)}
								className='px-4 py-3 rounded-md border-2 border-iwingreen flex justify-start items-center text-[10px] font-bold text-iwingreen'
							>
								<span className='mr-2'>Changer de mot de passe</span>
								<img src={LockIcon} alt="" className='w-4 h-4' />
							</button>
							<button
								onClick={() => setShowEditPasswordModal(true)}
								className='px-4 py-2 rounded-md border-2 border-iwingreen flex justify-start items-center text-[10px] font-bold text-iwingreen'
							>
								<span className='mr-2'>Migrer mon compte</span>
								<img src={ArrowFatLineUpImg} alt="" className='w-6 h-6' />
							</button>
							<button className='px-4 py-3 rounded-md border-2 border-red-600 bg-red-300 flex justify-start items-center text-[10px] font-bold text-red-600'>
								<span className='mr-2'>Supprimer mon compte</span>
								<img src={TrashSimpleIcon} alt="" className='w-4 h-4' />
							</button>
						</div>
					</div>
				</div>
				{offreModal()}
				{editPasswordModal()}
				{editUserModal()}
			</div>
			<div className="w-[340px] bg-white px-7 pt-5 h-screen">
				<Profile />
				<div className='border-b border-gray-200 -mt-1' />

				<div className='w-full flex justify-end items-center'>
					<button className='text-[10px] font-medium text-iwingreen px-2 py-1 border border-iwingreen rounded-lg mt-4 flex justify-start items-center'>
						Tous les tutoriels <MdPlayArrow className='w-4 h-4 ml-1' />{" "}
					</button>
				</div>
			</div>
		</div>
	);
};

export default Index;
