import React from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { RiFileCopyLine } from "react-icons/ri";

import MediaIcon from "../../../assets/images/icons/MediaIcon.png";
import MediaIcon1 from "../../../assets/images/icons/MediaIcon1.png";
import TIcon from "../../../assets/images/icons/TextT.png";
import TIcon1 from "../../../assets/images/icons/TextT1.png";

import AudioIcon from "../../../assets/images/upload/AudioIcon.png";
import ImgIcon from "../../../assets/images/upload/ImgIcon.png";
import VideoIcon from "../../../assets/images/upload/VideoIcon.png";

const Index = ({
	deleteCarte,
	copyCarte,
	setActiveMot,
	activeMot,
	handlePhoto,
	index,
	mot,
	handleChangeTextMot,
	handleChangeInputTypeMot,
}) => {
	return (
		<div
			key={index}
			className={`w-full relative rounded-lg flex flex-col justify-center items-center border border-gray-100 px-10 py-2 mb-3 ${
				activeMot === index ? "bg-amber-50/[0.5] border-slate-500/[0.3]" : ""
			}`}
		>
			<span className='flex flex-col justify-end items-center absolute top-2 right-3 gap-3'>
				<FaRegTrashAlt onClick={() => deleteCarte(index)} className="w-4 h-4 cursor-pointer" />
				<RiFileCopyLine onClick={() => copyCarte(index)} className="w-5 h-5 cursor-pointer" />
			</span>
			<div
				className='w-full flex flex-col justify-start items-start gap-4'
				onClick={() => setActiveMot(index)}
			>
				<div className='w-full border border-iwingreen/[0.6] flex justify-start items-start h-[18vh] border rounded-md bg-white'>
					<div className='w-11 min-w-11 max-w-11 flex flex-col justify-start items-center gap-2 px-2 py-3'>
						<img
							onClick={() => handleChangeInputTypeMot("text", index)}
							src={mot.type === "text" ? TIcon : TIcon1}
							className='w-full max-w-full min-w-full cursor-pointer'
							alt=""
						/>
						<label htmlFor={`img-${index}`} onClick={() => handleChangeInputTypeMot("img", index)}>
							<img
								onClick={() => handleChangeInputTypeMot("img", index)}
								src={mot.type === "img" ? MediaIcon : MediaIcon1}
								className='cursor-pointer w-full max-w-full min-w-full'
								alt=""
							/>
							<input
								onClick={() => handleChangeInputTypeMot("img", index)}
								accept='image/*'
								onChange={(e) => handlePhoto(e, index)}
								id={`img-${index}`}
								type="file"
								className="hidden"
							/>
						</label>
					</div>
					{mot.type === "text" && (
						<textarea
							id="mot"
							required
							name="mot"
							className='w-full border  rounded-r-md border-white bg-white p-2 focus:outline-none text-sm max-h-full h-full min-h-full'
							value={mot.text}
							placeholder="Saisir la question..."
							onChange={(e) => handleChangeTextMot(e, index)}
						/>
					)}
					{mot.type === "img" && (
						<div className='w-full max-h-full h-full min-h-full'>
							{mot.img === "" && (
								<div className='w-full h-full flex flex-col justify-center items-center -gap-2'>
									<div className='flex justify-center items-center mb-4'>
										<img src={ImgIcon} className="w-7 -mb-2 -mr-2" alt="" />
										<img src={VideoIcon} className="w-5 -mt-5" alt="" />
										<img src={AudioIcon} className="w-7 -mb-2 -ml-2" alt="" />
									</div>
									<p className='text-xs text-gray-600'>Sélectionner ou déposer un fichier</p>
								</div>
							)}
							{mot.img !== "" && (
								<div className='h-full w-full rounded-md'>
									<label
										htmlFor={`photo-2-${index}`}
										className='w-full min-w-full h-full min-h-full flex justify-center items-center cursor-pointer rounded-md'
									>
										<img src={URL.createObjectURL(mot.img)} className="h-full rounded-md" alt="" />
										<input
											accept='image/*'
											id={`photo-2-${index}`}
											onChange={(e) => handlePhoto(e, index)}
											name={`photo-2-${index}`}
											type="file"
											className="hidden"
										/>
									</label>
								</div>
							)}
						</div>
					)}
				</div>
				<div className='w-full py-1 bg-white rounded-lg border-l-8 border-iwingreen'>
					<input
						type="text"
						placeholder='Ecrire...'
						className='w-full  bg-white p-2 text-sm max-h-full h-full min-h-full'
						style={{ border: "0px solid white", filter: "none", boxShadow: "none" }}
					/>
				</div>
			</div>
		</div>
	);
};

export default Index;
