import { useFormik } from "formik";
import React, { useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { BiEditAlt, BiFilter, BiTrashAlt } from "react-icons/bi";
import { GoSearch } from "react-icons/go";
import { MdOutlineEdit, MdOutlineSaveAlt } from "react-icons/md";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";

import { ScrollContainer } from "../../components/common";
// import components
import Profile from "../../components/common/Profile";

import { getTwoFirstLetter } from "../../helpers/common";

import CameraIcon from "../../assets/images/iconCamera.png";
// imports images
import UsersImg from "../../assets/images/icons/Users-blue.png";
import UserProfile from "../../assets/images/user-p2.png";

const Index = () => {
	const [showEditChildrenModal, setShowEditChildrenModal] = useState(false);
	const editStudentForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			id: "",
			firstName: "",
			lastName: "",
			imagePath: "",
		},
		validationSchema: Yup.object({
			id: Yup.string().required("Veuillez saisir le prénom !"),
			firstName: Yup.string().required("Veuillez saisir le prénom !"),
			lastName: Yup.string(),
			imagePath: Yup.mixed(),
		}),
		onSubmit: (values) => {},
	});
	const children = [
		{
			id: 1,
			firstName: "John",
			lastName: "Doe",
			url: "http://iwinsciences.com/fte578y8",
			valided: 1,
			image: "",
		},
		{
			id: 2,
			firstName: "Rachel",
			lastName: "Makingabie",
			url: "http://iwinsciences.com/ftye6678y8",
			valided: 1,
			image: "",
		},
		{
			id: 3,
			firstName: "Ousmane",
			lastName: "",
			url: "http://iwinsciences.com/fte578y8",
			valided: 0,
			image: "",
		},
		{
			id: 4,
			firstName: "Angel",
			lastName: "",
			url: "http://iwinsciences.com/fte7578y8",
			valided: 1,
			image: "",
		},
		{
			id: 5,
			firstName: "Malick",
			lastName: "Demb",
			url: "http://iwinsciences.com/fdte578y8",
			valided: 0,
			image: "",
		},
		{
			id: 6,
			firstName: "Yerim",
			lastName: "Faty",
			url: "http://iwinsciences.com/fte09578y8",
			valided: 1,
			image: "",
		},
	];
	const HeaderModalEditChildren = (
		<div className='w-full flex justify-center items-center'>
			<h2 className=' text-iwingreen font-bold flex justify-between items-center gap-5'>
				<MdOutlineEdit className="w-6 h-6" /> Modifier l'enfant
			</h2>
		</div>
	);

	const handleEditChildren = (id) => {
		let st = children.filter((std) => std.id === id)[0];

		editStudentForm.setFieldValue("id", st.id);
		editStudentForm.setFieldValue("firstName", st.firstName);
		editStudentForm.setFieldValue("lastName", st.lastName);
		editStudentForm.setFieldValue("imagePath", "");
		setShowEditChildrenModal(true);
	};
	const handleCancelEditChildren = () => {
		setShowEditChildrenModal(!showEditChildrenModal);
		editStudentForm.setFieldValue("id", "");
		editStudentForm.setFieldValue("firstName", "");
		editStudentForm.setFieldValue("lastName", "");
		editStudentForm.setFieldValue("imagePath", "");
	};
	const getEditImageProfile = () => {
		return <img src={UserProfile} alt="" className='w-64 h-64' />;
	};

	const imageBodyTemplate = (rowData) => {
		return (
			<div className='h-10 w-10 ml-3 rounded-full border-2 border-iwingreen flex justify-center items-center font-black text-md'>
				{getTwoFirstLetter(rowData.firstName)}
			</div>
		);
	};

	const ActionsBodyTemplate = (rowData) => {
		return (
			<div className='flex justify-start items-center gap-3'>
				<Link to={"/parent/children/details"}>
					<MdOutlineSaveAlt className='w-5 h-5 cursor-pointer' />
				</Link>
				<BiEditAlt
					className='w-5 h-5 cursor-pointer'
					onClick={() => handleEditChildren(rowData.id)}
				/>

				<BiTrashAlt className='w-5 h-5 cursor-pointer' />
			</div>
		);
	};

	return (
		<div className='w-full flex flex-col justify-start items-center px-5'>
			<div className="w-full flex justify-between items-center">
				<h1 className='text-iwinblue font-bold text-left mt-3 text-[24px]'>Mes enfants</h1>
				<div className="flex justify-end items-center">
					<div className="relative w-[300px] mt-3 mr-2">
						<input
							type="text"
							placeholder='Trouver une ressource ...'
							className="bg-white rounded-[46px] border-white bottom-0 px-[50px] py-[10px]"
							style={{ border: "none", borderRadius: "40px" }}
						/>
						<GoSearch className="w-5 h-5 absolute top-[15px] left-[20px] text-asprimary" />
						<BiFilter className="w-7 h-7 absolute top-[10px] right-[15px] text-gray-700" />
					</div>
					<div className="w-[267px] bg-white px-7 pt-5 rounded-b-md">
						<Profile />
						<div className='border-b border-gray-200 -mt-1' />
					</div>
				</div>
			</div>
			<ScrollContainer className=''>
				<div className='w-full mt-3 flx flex-col justify-start items-start gap-3 bg-white shadow-lg rounded-lg p-3'>
					<h2 className='flex justify-between items-center gap-3 text-iwinblue font-black text-xl py-2'>
						<div className='flex justify-start items-center gap-3'>
							<img src={UsersImg} className="w-7 h-7" alt="" /> Mes Enfants
						</div>
						<button className='border-2 border-iwinblue text-iwinblue rounded-lg px-3 py-2 text-sm'>
							Ajouter enfant
						</button>
					</h2>
					<DataTable emptyMessage="Aucune donnée" value={children}>
						<Column header="Photo" body={imageBodyTemplate} />
						<Column field="firstName" header="Prénom" />
						<Column field="url" header="Code de connexion" />
						<Column header="Actions" body={ActionsBodyTemplate} />
					</DataTable>
				</div>
			</ScrollContainer>
			<Dialog
				dismissableMask={true}
				resizable={false}
				style={{ width: "50vw" }}
				closable={false}
				onHide={() => handleCancelEditChildren()}
				header={HeaderModalEditChildren}
				icons={""}
				visible={showEditChildrenModal}
			>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						editStudentForm.handleSubmit();
						return false;
					}}
					className='w-full mt-3'
				>
					<Row className='w-full'>
						<Col xs={12} sm={12} md={6} className='mb-4'>
							<Row>
								<Col
									xs={12}
									sm={12}
									md={7}
									className='flex flex-col justify-start items-start gap-3 mb-4'
								>
									<label htmlFor="firstName">
										Prénom <span className='text-red-500'>*</span>
									</label>
									<input
										type="text"
										className="px-3 py-2"
										id="firstName"
										name="firstName"
										disabled={true}
										readOnly={true}
										onChange={editStudentForm.handleChange}
										onBlur={editStudentForm.handleBlur}
										value={editStudentForm.values.firstName}
										invalid={editStudentForm.touched.firstName && editStudentForm.errors.firstName}
									/>
									{editStudentForm.touched.firstName && editStudentForm.errors.firstName ? (
										<small className="text-[12px] text-red-400">
											{editStudentForm.errors.firstName}
										</small>
									) : null}
								</Col>
								<Col
									xs={12}
									sm={12}
									md={5}
									className='flex flex-col justify-start items-start gap-3 mb-4'
								>
									<label htmlFor="lastName">
										Nom <span className='text-red-500'>*</span>
									</label>
									<input
										type="text"
										className="px-3 py-2"
										id="lastName"
										name="lastName"
										disabled={true}
										readOnly={true}
										onChange={editStudentForm.handleChange}
										onBlur={editStudentForm.handleBlur}
										value={editStudentForm.values.lastName}
									/>
								</Col>
								<Col xs={12} sm={12} md={12} className="">
									<label className="">
										Photo <span className='text-red-500'>*</span>
									</label>
									<div className='flex justify-center items-center w-full relative mt-3'>
										{getEditImageProfile()}
										<div className='w-full h-full rounded-md absolute top-0 bg-black/[.3] flex flex-col justify-start items-end'>
											<div className='mt-2 mr-2 py-1 px-2 border-dashed border-gray-700 border-2 rounded-md bg-gray-100/[.5]'>
												<label htmlFor='photo'>
													<img src={CameraIcon} alt="" className='w-9 h-9 cursor-pointer' />
													<input
														accept='image/*'
														onChange={(e) =>
															editStudentForm.setFieldValue("imagePath", e.currentTarget.files[0])
														}
														onBlur={editStudentForm.handleBlur}
														id="photo"
														name="imagePath"
														type="file"
														className="hidden"
													/>
												</label>
											</div>
										</div>
									</div>
								</Col>
							</Row>
						</Col>
						<Col xs={12} sm={12} md={6}>
							<Row>
								<Col
									xs={12}
									sm={12}
									md={12}
									className='flex flex-col justify-start items-start gap-3 mb-4'
								>
									<label htmlFor="firstName">
										Adresse email <span className='text-red-500'>*</span>
									</label>
									<input
										type="text"
										className="px-3 py-2"
										id="firstName"
										name="firstName"
										disabled={true}
										readOnly={true}
										onChange={editStudentForm.handleChange}
										onBlur={editStudentForm.handleBlur}
										value={editStudentForm.values.firstName}
										invalid={editStudentForm.touched.firstName && editStudentForm.errors.firstName}
									/>
								</Col>
								<Col
									xs={12}
									sm={12}
									md={12}
									className='flex flex-col justify-start items-start gap-3 mb-5'
								>
									<label htmlFor="lastName">
										Niveau <span className='text-red-500'>*</span>
									</label>
									<input
										type="text"
										className="px-3 py-2"
										id="lastName"
										name="lastName"
										onChange={editStudentForm.handleChange}
										onBlur={editStudentForm.handleBlur}
										value={editStudentForm.values.lastName}
									/>
								</Col>
								<Col
									xs={12}
									sm={12}
									md={12}
									className='flex flex-col justify-start items-start gap-3 mb-5'
								>
									<label htmlFor="classroom">
										Sexe <span className='text-red-500'>*</span>
									</label>
									<input
										type="text"
										className="px-3 py-2"
										id="classroom"
										name="classroom"
										value={""}
										readOnly={true}
									/>
								</Col>

								<Col md={12} className="mb-5">
									<Row>
										<Col md={12} className="mb-3">
											<label htmlFor="password">
												Date de naissance <span className='text-red-500'>*</span>
											</label>
										</Col>
										<Col md={4} className="">
											<input
												type="password"
												className="px-3 py-2"
												id="password"
												name="password"
												onChange={editStudentForm.handleChange}
												onBlur={editStudentForm.handleBlur}
												value={""}
											/>
										</Col>

										<Col md={4} className="">
											<input
												type="password"
												className="px-3 py-2"
												id="password"
												name="password"
												onChange={editStudentForm.handleChange}
												onBlur={editStudentForm.handleBlur}
												value={""}
											/>
										</Col>

										<Col md={4} className="">
											<input
												type="password"
												className="px-3 py-2"
												id="password"
												name="password"
												onChange={editStudentForm.handleChange}
												onBlur={editStudentForm.handleBlur}
												value={""}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>

						<Col className="w-full flex flex-row justify-end items-center mt-2 gap-3" md={12}>
							<button
								type="button"
								onClick={() => handleCancelEditChildren()}
								className="px-5 py-2 text-sm rounded-md bg-black text-white"
							>
								Regénérer l'enfant
							</button>
							<button
								type="submit"
								className="px-5 py-2 text-sm rounded-md bg-iwincolor3 text-white"
							>
								Enregister
							</button>
						</Col>
					</Row>
				</form>
			</Dialog>
		</div>
	);
};

export default Index;
