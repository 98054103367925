import { Menu, MenuButton, MenuItem, MenuItemOption, MenuList } from "@chakra-ui/menu";
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from "@chakra-ui/react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { changeFolder, changeStatus, deleteActivity, duplicateActivity } from "../../helpers/backend_helper";
import { useActivities, useFolders } from "../../hooks/useActivities";
import { useAppStore } from "../../store/store";

export default function ActivityMenu({ id, className, activity, isFolder }) {
	const setActivity = useAppStore(state => state.setActivity)
	const setClassesOfActivity = useAppStore(state => state.setClassesOfActivity)
	const { refresh: refreshFolders, folders } = useFolders()
	const { refresh: refreshActivities } = useActivities()
	const { onToggle, isOpen } = useDisclosure();

	const handler = async (callback) => {
		await callback();
		await refreshActivities();
		await refreshFolders();
		onToggle();
	};


	const menu = [
		{
			name: "Attribuer",
			show: activity.status !== 'archived',
			handler: () => setClassesOfActivity(activity.id)
		},
		{
			name: "Modifier",
			show: activity.status !== 'archived',
			handler: () => setActivity(activity)
		},
		{
			name: "Dupliquer",
			show: true,
			handler: () => handler(() => duplicateActivity({ id, isFolder }))
		},
		{
			name: "Archiver",
			show: activity.status !== 'archived',
			handler: () => handler(() => changeStatus({ id, status: "archived", isFolder }))
		},
		{
			name: "Désarchiver",
			show: activity.status === 'archived',
			handler: () => handler(() => changeStatus({ id, status: "imported", isFolder }))
		},
		{
			name: "Dégrouper",
			show: activity.folderId,
			handler: () => handler(() => changeFolder({ id, folderId: null }))
		}
	]

	return (
		<Menu closeOnSelect={false} isOpen={isOpen} onClose={onToggle} onOpen={onToggle}>
			<MenuButton className={className} onClick={onToggle}>
				<BiDotsHorizontalRounded />
			</MenuButton>
			<MenuList>
				{
					menu.map(item => item.show && <MenuItem key={item.name}
						onClick={item.handler}
					>
						{item.name}
					</MenuItem>)
				}

				{!isFolder && activity.status !== "archived" && (
					<Accordion allowToggle>
						<AccordionItem>
							<h2>
								<AccordionButton>
									<Box as="span" flex='1' textAlign='left'>
										Déplacer
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
								{folders.map((el) => (
									<MenuItemOption key={el.id} value={el.id} onClick={() => handler(() => changeFolder({ id: activity.id, folderId: el.id }))}>
										{el.name}
									</MenuItemOption>
								))}
							</AccordionPanel>
						</AccordionItem>
					</Accordion>
				)}

				<DeleteActivity
					activity={activity}
					isFolder={isFolder}
					handleOk={async () => await handler(async () => await deleteActivity(id, isFolder))}
				/>
			</MenuList>
		</Menu>
	);
}

function DeleteActivity({ handleOk, activity, isFolder }) {
	const { isOpen, onClose, onOpen } = useDisclosure();

	return (
		<>
			<MenuItem onClick={onOpen} color='red.400'>
				Supprimer
			</MenuItem>

			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Alerte</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						Voulez-vous Supprimer {isFolder ? "ce dossier" : "cette activité"} ?
					</ModalBody>

					<ModalFooter>
						<Button colorScheme='green' mr={3} onClick={handleOk}>
							Oui
						</Button>
						<Button colorScheme='red' onClick={onClose}>
							Non
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
