import React, { useState } from "react";

import { BiFilter } from "react-icons/bi";
import { CgHashtag } from "react-icons/cg";
import { GoSearch } from "react-icons/go";
import { MdKeyboardArrowRight, MdPlayArrow } from "react-icons/md";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { ScrollContainer } from "../../components/common";
// import components
import Profile from "../../components/common/Profile";

import PuzzlePieceImg from "../../assets/images/icons/PuzzlePiece-white.png";
import TimerImg from "../../assets/images/icons/Timer.png";
import RessourceImg from "../../assets/images/icons/iconResources.png";

const Index = () => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [activeSubIndex, setActiveSubIndex] = useState(0);

	const HashBodyTemplate = (rowData) => {
		return (
			<div className='flex justify-center items-center text-gray-400 rounded-md bg-gray-200'>
				<CgHashtag className='w-5 h-5 ' />
			</div>
		);
	};

	return (
		<div className="w-full flex flex-row justify-between items-start">
			<div className="w-full flex flex-col justify-start items-start mt-5 px-5">
				<div className="w-full flex justify-between items-center">
					<h1 className='text-iwinblue font-bold text-left text-[26px]'>
						Bienvenue, <i className='text-iwingreen'>Abdoulaye</i> !
					</h1>
					<div className="relative w-[300px]">
						<input
							type="text"
							placeholder='Trouver une ressource ...'
							className="w-full bg-white rounded-[46px] border-white bottom-0 px-[50px] py-3"
							style={{ border: "none", borderRadius: "40px" }}
						/>
						<GoSearch className="w-6 h-6 absolute top-[15px] left-[20px] text-asprimary" />
						<BiFilter className="w-8 h-8 absolute top-[10px] right-[15px] text-gray-700" />
					</div>
				</div>
				<ScrollContainer className='w-full mt-4 px-2'>
					<div className='w-full flex flex-col justify-start items-start'>
						<div className='w-full flex justify-start items-center gap-1 text-iwinblue font-black py-2 mb-4'>
							<span>Mes Enfants</span>
							<MdKeyboardArrowRight className='w-4 h-4' />
							<span>suivi</span>
							<MdKeyboardArrowRight className='w-4 h-4' />
						</div>
						<div className="pt-2 pb-4 flex justify-start items-center gap-5">
							<button
								onClick={() => setActiveIndex(0)}
								className={`text-lg ${
									activeIndex === 0
										? "text-iwingreen border-b-4 border-iwingreen"
										: "border-b-0 text-iwinblue"
								}`}
							>
								Statistiques
							</button>
							<button
								onClick={() => setActiveIndex(1)}
								className={`text-lg ${
									activeIndex === 1
										? "text-iwingreen border-b-4 border-iwingreen"
										: "border-b-0 text-iwinblue"
								}`}
							>
								Compétences
							</button>
						</div>
						<div className='w-full'>
							{activeIndex === 0 ? (
								<div className='w-full flex flex-col justify-start items-start gap-3'>
									<div className='w-full flex flex-row justify-between items-center gap-4'>
										<div className='w-full flex justify-between items-center px-5 py-2 shadow-lg bg-white rounded-lg'>
											<div className='bg-iwincolor2 rounded-full w-[50px] max-w-[50px] h-11 flex justify-center items-center p-1'>
												<img src={PuzzlePieceImg} className='w-5 h-5' alt="" />
											</div>
											<div className='w-full flex flex-col justify-center items-start gap-1 px-3 py-1'>
												<h2 className='font-bold text-sm'>Progression</h2>
												<b className='text-xl font-black text-iwinblue -my-1'>75%</b>
												<p className='text-md font-thin text-gray-600'>
													<span className='text-iwingreen font-semibold'>+5</span> cette semaine
												</p>
											</div>
										</div>
										<div className='w-full flex justify-between items-center px-5 py-2 shadow-lg bg-white rounded-lg'>
											<div className='bg-iwingreen rounded-full w-14 w-[50px] max-w-[50px] h-11 flex justify-center items-center p-2'>
												<img src={RessourceImg} className="w-5 h-5" alt="" />
											</div>
											<div className='w-full flex flex-col justify-center items-start gap-1 px-3 py-1'>
												<h2 className='font-bold text-sm'>Activités réalisées</h2>
												<b className='text-xl font-black text-iwinblue -my-1'>14</b>
												<p className='text-md font-thin text-gray-600'>
													<span className='text-iwingreen font-semibold'>+3</span> cette semaine
												</p>
											</div>
										</div>
										<div className='w-full flex justify-between items-center px-5 py-2 shadow-lg bg-white rounded-lg'>
											<div className='bg-iwinpurple rounded-full w-[50px] max-w-[50px] h-11 flex justify-center items-center p-2'>
												<img src={TimerImg} className="w-5 h-5" alt="" />
											</div>
											<div className='w-full flex flex-col justify-center items-start gap-1 px-3 py-1'>
												<h2 className='font-bold text-sm'>Temps de travail</h2>
												<b className='text-xl font-black text-iwinblue -my-1'>1h27min.</b>
												<p className='text-md font-thin text-gray-600'>
													<span className='text-iwingreen font-semibold'>10min</span> cette semaine
												</p>
											</div>
										</div>
									</div>
									<div className='w-full mt-3 flx flex-col justify-start items-start gap-3 bg-white shadow-lg rounded-lg p-3'>
										<h2 className='flex capitalize justify-start items-center gap-3 text-iwinblue font-bold text-xl py-2'>
											Niveaux de jeu
										</h2>
										<DataTable headerClassName="bg-white" emptyMessage="Aucune donnée" value={[]}>
											<Column field="image" header={HashBodyTemplate} />
											<Column field="fisrtName" header="Notion" />
											<Column field="code" header="Titre" />
											<Column field="code" header="Temps de jeu" />
											<Column field="action" header="Niveau par compétence" />
										</DataTable>
									</div>
								</div>
							) : (
								<div className='w-full flex flex-col justify-start items-start px-5 py-3 shadow-lg bg-white rounded-lg'>
									<div className='flex justify-start items-center gap-7'>
										<button
											onClick={() => setActiveSubIndex(0)}
											className={`text-lg text-iwinblue ${
												activeSubIndex === 0 ? "border-b-4 border-iwingreen" : "border-b-0"
											}`}
										>
											Connaissances
										</button>
										<button
											onClick={() => setActiveSubIndex(1)}
											className={`text-lg text-iwinblue ${
												activeSubIndex === 1 ? "border-b-4 border-iwingreen" : "border-b-0"
											}`}
										>
											Savoir
										</button>
										<button
											onClick={() => setActiveSubIndex(2)}
											className={`text-lg text-iwinblue ${
												activeSubIndex === 2 ? "border-b-4 border-iwingreen" : "border-b-0"
											}`}
										>
											Savoir-faire
										</button>
									</div>
									<div className='w-full mt-4'>
										<DataTable value={[]} emptyMessage="Aucune donnée">
											<Column field="image" header={HashBodyTemplate} />
											<Column field="fisrtName" header="Champ 1" />
											<Column field="code" header="Champ 2" />
											<Column field="code" header="Champ 3" />
											<Column field="action" header="Actions" />
										</DataTable>
									</div>
								</div>
							)}
						</div>
					</div>
				</ScrollContainer>
			</div>
			<div className="w-[340px] bg-white px-7 pt-5 h-screen">
				<Profile />
				<div className='border-b border-gray-200 -mt-1' />

				<div className='w-full flex justify-end items-center'>
					<button className='text-[10px] font-medium text-iwingreen px-2 py-1 border border-iwingreen rounded-lg mt-4 flex justify-start items-center'>
						Tous les tutoriels <MdPlayArrow className='w-4 h-4 ml-1' />{" "}
					</button>
				</div>
			</div>
		</div>
	);
};

export default Index;
