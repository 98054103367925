import "./PdfCard.scss";
import fileGrey from "./file-grey.png";
import fileWhite from "./file-white.png";

import { RiDeleteBinLine } from "react-icons/ri";

export default function PdfCard({ selected, fileName, onRemoveDocument, onDocumentAction, edit }) {
	return (
		// rome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
		<div className={`PdfCard ${selected ? "active" : ""}`.trim()} onClick={onDocumentAction}>
			{!edit && <RiDeleteBinLine className="PdfCard-delete cursor-pointer" onClick={onRemoveDocument} />}
			<div>
				<img src={selected ? fileWhite : fileGrey} alt="fileWhite" />
			</div>
			<div className="PdfCard-caption">{fileName}</div>
		</div>
	);
}
