import React, { useState } from "react";
import { BsEye } from "react-icons/bs";
import { MdOutlineModeEdit } from "react-icons/md";
import Select from "react-select";

import Icon1 from "../../../assets/images/activity/img1.png";

const Index = ({ themesList }) => {
	const [actionIndex, setActionIndex] = useState(0);
	const [showAddCompetence, setShowAddCompetence] = useState(false);
	const [competences, setCompetences] = useState([]);
	const [subThemesList, setSubThemesList] = useState([]);
	const [title, setTitle] = useState("");
	const [selectedTheme, setSelectedTheme] = useState({ value: "", label: "" });
	const [selectedSubTheme, setSelectedSubTheme] = useState({ value: "", label: "" });
	const [textA, setTextA] = useState("");
	const customStyles = {
		control: (base) => ({
			...base,
			border: "1px solid rgba(50, 202,188, 0.47)",
			background: "#fff",
			color: "#303972",
			paddingBottom: "1px",
			paddingTop: "1px",
			height: "40px",
			boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
			filter: "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
			borderRadius: "0.375rem",
			"&:hover": {
				border: "1px solid rgba(50, 202,188, 0.47)",
			},
		}),
		option: (provided, state) => ({
			...provided,
			fontSize: "12px",
		}),
		placeholder: (defaultStyles) => {
			return {
				...defaultStyles,
				color: "#303972",
				fontSize: "12px",
				padding: "1px",
			};
		},
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = "opacity 300ms";
			const fontSize = "12px";
			const color = "#303972";
			return { ...provided, color, fontSize, opacity, transition };
		},
		input: (styles, { data }) => ({
			...styles,
			outline: "none",
			border: "none",
			focus: "none",
			color: "#303972",
			fontSize: "12px",
		}),
		indicatorSeparator: (base) => ({
			...base,
			display: "none",
		}),
		dropdownIndicator: (base) => ({
			...base,
			color: "#303972",
			"&:hover": {
				color: "#303972",
			},
		}),
		menuList: (base) => ({
			...base,
			"::-webkit-scrollbar": {
				width: "4px",
				height: "0px",
				color: "#303972",
			},
			"::-webkit-scrollbar-track": {
				background: "#f1f1f1",
			},
			"::-webkit-scrollbar-thumb": {
				background: "#888",
			},
			"::-webkit-scrollbar-thumb:hover": {
				background: "#555",
			},
		}),
	};
	const handleChangeTheme = (e) => {
		setSelectedTheme(e);
		setSelectedSubTheme({ value: "", label: "" });
		// if (e.value !== selectedTheme.value) {
		// 	getSubThemesByTheme(e.value).then((data) => {
		// 		setSubThemesList(data.data);
		// 	});
		// }
	};

	const handleChangeSubTheme = (e) => {
		setSelectedSubTheme(e);
	};
	return (
		<div className='w-full flex flex-col justify-start items-center '>
			<div className='w-full flex justify-between items-center'>
				<div className='w-full flex justify-start items-center'>
					<div className='w-56 flex flex-col justify-start items-start gap-1'>
						<label className=''>Titre</label>
						<input
							onChange={(e) => setTitle(e.target.value)}
							value={title}
							type="text"
							placeholder="Saisir le titre de l'activité"
							className='text-[12px] w-20 px-2 py-2 border border-iwingreen/[.4] rounded-lg'
						/>
					</div>
					<div className='w-36 ml-2 flex flex-col justify-start items-start gap-1'>
						<label className=''>Thème</label>
						<Select
							id="mode"
							name="mode"
							styles={customStyles}
							options={themesList}
							placeholder="---"
							className="w-full"
							classNamePrefix="select"
							onChange={(e) => {
								handleChangeTheme(e);
							}}
							value={selectedTheme}
						/>
					</div>
					<div className='w-36 ml-2 flex flex-col justify-start items-start gap-1'>
						<label className=''>Sous-thème</label>
						<Select
							id="mode"
							name="mode"
							styles={customStyles}
							options={subThemesList}
							placeholder="---"
							className="w-full"
							classNamePrefix="select"
							onChange={(e) => {
								handleChangeSubTheme(e);
							}}
							value={selectedSubTheme}
						/>
					</div>
					<div className='w-32 ml-2 flex flex-col justify-start items-start gap-1'>
						<label className=''>Arrière-plan</label>
						<div className='w-[30px] px-5 py-[6px] shadow-in border border-gray-300 bg-gray-400 rounded-lg cursor-pointer'>
							<input type="color" className='w-full h-full invisible' />
						</div>
					</div>
				</div>
				<div className='flex justify-end items-center gap-10 pt-3'>
					<div className='p-1 rounded-3xl border border-gray-300 bg-gray-200 flex justify-between items-center gap-4'>
						<div
							className={`flex justify-center items-center p-1  rounded-full cursor-pointer ${
								actionIndex === 0 ? "bg-iwingreen text-white" : "text-gray-400"
							}`}
							onClick={() => setActionIndex(0)}
						>
							<MdOutlineModeEdit className='w-4 h-4' />
						</div>
						<div
							className={`flex justify-center items-center p-1 rounded-full cursor-pointer ${
								actionIndex === 1 ? "bg-iwingreen text-white" : "text-gray-400"
							}`}
							onClick={() => setActionIndex(1)}
						>
							<BsEye className='w-4 h-4' />
						</div>
					</div>
					<button
						type='submit'
						className='px-10 py-3 text-sm font-semibold bg-gray-500 rounded-lg text-white'
					>
						Créer
					</button>
				</div>
			</div>
			<div className='w-full min-h-[65vh] h-[65vh] flex flex-col justify-center items-center gap-2'>
				<img src={Icon1} className="w-24" alt="" />
				<p className='w-3/5 px-16 text-center text-gray-400 font-normal'>
					Choisis un modéle dans le panneau de gauche pour créer une activité
				</p>
			</div>
		</div>
	);
};

export default Index;
