import * as Yup from "yup";

export const initialValues = {
	name: "",
	levels: [],
	materials: [],
	imagePath: "",
	modeId: "",
	teacherId: "",
};

export const validationSchema = Yup.object({
	name: Yup.string().required("Veuillez saisir le nom de votre classe !"),
	levels: Yup.array().min(1, "Veuillez sélectionner au moins un niveau !"),
	materials: Yup.array().min(1, "Veuillez sélectionner au moins une matière !"),
	imagePath: Yup.mixed(),
	modeId: Yup.string().required("Veuillez sélectionner le mode !"),
	teacherId: Yup.string(),
});
