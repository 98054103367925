import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { AiFillCaretLeft } from "react-icons/ai";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { CSSTransition } from "react-transition-group";

import Logo from "../../../assets/images/logo-iwin.png";
import SquareTopRight from "../../../assets/images/square-top-right.png";
import { teacherRegister } from "../../../helpers/backend_helper";
import { getCountriesAction } from "../../../store/country/actions";
import { FormContainer } from "../../common/FormContainer";
import { customStyles } from "../../common/react-select-styles";
import { initialValues, validationSchema } from "./create-teacher-yup";

export default function CreateTeacher() {
	const navigate = useNavigate();
	const { countries } = useSelector((state) => ({
		countries: state.countryState.countries,
		registerStatus: state.registerTeacherState.registerStatus,
		registerError: state.registerTeacherState.registerError,
	}));

	const dispatch = useDispatch();

	const navigateToCompletedProfil = (state) => {
		navigate("/register/teacher-completed/skills", { state });
	};

	const validation = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			const response = await teacherRegister(values);
			if (response?.data) {
				navigateToCompletedProfil(response.data);
			}
		},
	});

	useEffect(() => {
		dispatch(getCountriesAction());
	}, [dispatch]);

	return (
		<CSSTransition in={true} appear={true} timeout={300} classNames="fade">
			<div className="flex flex-col justify-center items-center mt-10">
				<div className="absolute z-10 top-5">
					<img src={Logo} alt="LOGO" className="h-8" />
				</div>

				<div className="absolute z-10 top-5 flex justify-start items-center left-5">
					<Link
						to={"/register"}
						className="px-4 py-2 text-white text-sm rounded-md bg-iwingreen flex flex-row justify-start items-center"
					>
						<AiFillCaretLeft className="w-5 h-5 -ml-1" /> Retour
					</Link>
				</div>
				<TeacterGeneralInformations countries={countries} validation={validation} />
			</div>
		</CSSTransition>
	);
}

function TeacterGeneralInformations({ validation, countries }) {
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	return (
		<FormContainer
			onSubmit={(e) => {
				e.preventDefault();
				validation.handleSubmit();
			}}
			className={`flex flex-col justify-center items-center relative z-30 mb-5 mt-3 ${
				validation.errors.length > 0 ? "pb-10" : ""
			}`}
		>
			<img src={SquareTopRight} className="absolute top-0 right-0 rounded-tr-[16px]" alt="" />
			<Row className="w-full">
				<Col xs={12} sm={12} md={6} className="flex flex-col justify-start items-start gap-1 mb-4">
					<label htmlFor='lastName' className='text-[14px] text-iwinblue font-medium'>
						Nom <span className='text-red-500'>*</span>
					</label>
					<input
						type="text"
						id='lastName'
						name='lastName'
						className='w-full text-[14px] drop-shadow-md outline-none border bg-inputbg/[0.02]  border-inputbg/[0.47] text-iwinblue rounded-md focus:border-inputbg  px-3 py-2'
						onChange={validation.handleChange}
						onBlur={validation.handleBlur}
						value={validation.values.lastName}
						invalid={validation.touched.lastName && validation.errors.lastName}
					/>
					{validation.touched.lastName && validation.errors.lastName ? (
						<small className="text-[9px] text-red-400">{validation.errors.lastName}</small>
					) : null}
				</Col>
				<Col xs={12} sm={12} md={6} className="flex flex-col justify-start items-start gap-1 mb-4">
					<label htmlFor='firstName' className='text-[14px] text-iwinblue font-medium'>
						Prénom <span className='text-red-500'>*</span>
					</label>
					<input
						type="text"
						id='firstName'
						name='firstName'
						className='w-full text-[14px] drop-shadow-md outline-none border bg-inputbg/[0.02] border-inputbg/[0.47]  text-iwinblue rounded-md focus:border-inputbg/[0.47] px-3 py-2'
						onChange={validation.handleChange}
						onBlur={validation.handleBlur}
						value={validation.values.firstName}
						invalid={validation.touched.firstName && validation.errors.firstName}
					/>
					{validation.touched.firstName && validation.errors.firstName ? (
						<small className="text-[9px] text-red-400">{validation.errors.firstName}</small>
					) : null}
				</Col>
				<Col xs={12} sm={12} md={12} className="flex flex-col justify-start items-start gap-1 mb-4">
					<label htmlFor='email' className='text-[14px] text-iwinblue font-medium'>
						Adresse email <span className='text-red-500'>*</span>
					</label>
					<input
						type="email"
						id='email'
						name='email'
						className='w-full text-[14px] drop-shadow-md outline-none border bg-inputbg/[0.02] border-inputbg/[0.47]  text-iwinblue rounded-md focus:border-inputbg/[0.47] px-3 py-2'
						onChange={validation.handleChange}
						onBlur={validation.handleBlur}
						value={validation.values.email}
						invalid={validation.touched.email && validation.errors.email}
					/>
					{validation.touched.email && validation.errors.email ? (
						<small className="text-[9px] text-red-400">{validation.errors.email}</small>
					) : null}
				</Col>

				<Col
					xs={12}
					sm={12}
					md={6}
					className="flex flex-col justify-start items-start gap-1 mb-4 relative"
				>
					<label htmlFor='password' className='text-[14px] text-iwinblue font-medium'>
						Mot de passe <span className='text-red-500'>*</span>
					</label>
					<input
						type={showPassword ? "text" : "password"}
						id='password'
						name='password'
						className='w-full pr-7 text-[14px] drop-shadow-md outline-none border bg-inputbg/[0.02] border-inputbg/[0.47]  text-iwinblue rounded-md focus:border-inputbg/[0.47] px-3 py-2'
						onChange={validation.handleChange}
						onBlur={validation.handleBlur}
						value={validation.values.password}
						invalid={validation.touched.password && validation.errors.password}
					/>
					{showPassword ? (
						<FiEyeOff
							className="absolute right-4 top-[2.35rem]  text-iwinblue cursor-pointer"
							onClick={() => setShowPassword(!showPassword)}
						/>
					) : (
						<FiEye
							className="absolute right-4 top-[2.35rem]  text-iwinblue cursor-pointer"
							onClick={() => setShowPassword(!showPassword)}
						/>
					)}
					{validation.touched.password && validation.errors.password ? (
						<small className="text-[9px] text-red-400">{validation.errors.password}</small>
					) : null}
				</Col>
				<Col
					xs={12}
					sm={12}
					md={6}
					className="flex flex-col justify-start items-start gap-1 mb-4 relative"
				>
					<label htmlFor='confirmPassword' className='text-[14px] text-iwinblue font-medium'>
						Confirmer le mot de passe <span className='text-red-500'>*</span>
					</label>
					<input
						type={showConfirmPassword ? "text" : "password"}
						id='confirmPassword'
						name='confirmPassword'
						className='w-full pr-7 text-[14px] drop-shadow-md outline-none border bg-inputbg/[0.02] border-inputbg/[0.47]  text-iwinblue rounded-md focus:border-inputbg/[0.47] px-3 py-2'
						onChange={validation.handleChange}
						onBlur={validation.handleBlur}
						value={validation.values.confirmPassword}
						invalid={validation.touched.confirmPassword && validation.errors.confirmPassword}
					/>
					{showConfirmPassword ? (
						<FiEyeOff
							className="absolute right-4 top-[2.35rem]  text-iwinblue cursor-pointer"
							onClick={() => setShowConfirmPassword(!showConfirmPassword)}
						/>
					) : (
						<FiEye
							className="absolute right-4 top-[2.35rem]  text-iwinblue cursor-pointer"
							onClick={() => setShowConfirmPassword(!showConfirmPassword)}
						/>
					)}
					{validation.touched.confirmPassword && validation.errors.confirmPassword ? (
						<small className="text-[9px] text-red-400">{validation.errors.confirmPassword}</small>
					) : null}
				</Col>
				<Col xs={12} sm={12} md={6} className='flex flex-col justify-start items-start gap-3 mb-4'>
					<label htmlFor="city">
						Ville <span className='text-red-500'>*</span>
					</label>
					<input
						type="text"
						className="px-3 py-2"
						id="city"
						name="city"
						onChange={validation.handleChange}
						onBlur={validation.handleBlur}
						value={validation.values.city}
						invalid={validation.touched.city && validation.errors.city}
					/>
					{validation.touched.city && validation.errors.city ? (
						<small className="text-[12px] text-red-400">{validation.errors.city}</small>
					) : null}
				</Col>
				<Col xs={12} sm={12} md={6} className='flex flex-col justify-start items-start gap-3 mb-4'>
					<label htmlFor="country">
						Pays <span className='text-red-500'>*</span>
					</label>
					<Select
						id="country"
						name="country"
						styles={customStyles}
						options={countries}
						placeholder="Choisir Pays..."
						className="w-full"
						classNamePrefix="select"
						onChange={(e) => {
							validation.setFieldValue("country", e);
						}}
						onBlur={validation.handleBlur}
						value={validation.values.country}
						invalid={validation.touched.country && validation.errors.country}
					/>
					{validation.touched.country && validation.errors.country ? (
						<small className="text-[9px] text-red-400">{validation.errors.country}</small>
					) : null}
				</Col>
				<Col lg={12} className="flex flex-row justify-start items-center mb-2">
					<input
						type="checkbox"
						id="rememberMe"
						className='mr-2'
						name="rememberMe"
						value={validation.values.rememberMe}
						onChange={validation.handleChange}
						onBlur={validation.handleBlur}
						checked={validation.values.rememberMe}
					/>
					<label htmlFor="rememberMe" className="text-[11px] text-iwinblue">
						Se souvenir de moi
					</label>
				</Col>
				<Col xs={12} sm={12} md={12} className="flex flex-col justify-start items-start gap-1 mb-2">
					<div className="flex justify-start items-center pl-0">
						<input
							type="checkbox"
							id="accepteTerm"
							className='mr-2'
							name="accepteTerm"
							value={validation.values.accepteTerm}
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							checked={validation.values.accepteTerm}
							invalid={validation.touched.accepteTerm && validation.errors.accepteTerm}
						/>
						<label htmlFor="accepteTerm" className="text-[11px] text-iwinblue">
							Accepter nos
							<Link to="/register" className="text-cyan-500">
								<span> CGU </span>
							</Link>
							et
							<Link to="/register" className="text-cyan-500">
								<span> politique de confidentialité </span>
							</Link>
							<span className='text-red-500'>*</span>
						</label>
					</div>
					{validation.touched.accepteTerm && validation.errors.accepteTerm ? (
						<small className="text-[9px] text-red-400">{validation.errors.accepteTerm}</small>
					) : null}
				</Col>
				<Col xs={12} sm={12} md={12} className="flex flex-col justify-start items-start gap-1 mb-4">
					<div className="flex justify-start items-center pl-0">
						<input
							type="checkbox"
							id="subscribed"
							className='mr-2'
							name="subscribed"
							value={validation.values.subscribed}
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							checked={validation.values.subscribed}
						/>
						<label htmlFor="subscribed" className="text-[11px] text-iwinblue">
							Je souhaite recevoir par mail l'actualité et les nouveautés
						</label>
					</div>
				</Col>
				<Col lg={12} className='w-full flex justify-center items-center mt-3'>
					<button
						type="submit"
						className="text-sm bg-iwingreen px-5 py-2 text-white rounded-md font-medium"
					>
						S'inscrire
					</button>
				</Col>
			</Row>
		</FormContainer>
	);
}
