import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	checkClassroom,
	addClassroom,
	getClassrooms,
	getClassroom,
	addStudentToClass,
	editClassroom,
} from "../../helpers/backend_helper";

export const addClassroomAction = createAsyncThunk(
	"classrooms/addClassroomAction",
	async (data, { rejectWithValue }) => {
		try {
			const response = await addClassroom(data);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);

export const editClassroomAction = createAsyncThunk(
	"classrooms/editClassroomAction",
	async (data, { rejectWithValue }) => {
		try {
			const response = await editClassroom(data);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);

export const addStudentClassAction = createAsyncThunk(
	"classrooms/addStudentClassAction",
	async (data, { rejectWithValue }) => {
		try {
			const response = await addStudentToClass(data.id, { students: data.students });
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);

export const getClassroomsAction = createAsyncThunk(
	"classrooms/getClassroomsAction",
	async (id = null, { rejectWithValue }) => {
		try {
			const response = await getClassrooms();
			return response.classes;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);

export const getClassroomAction = createAsyncThunk(
	"classrooms/getClassroomAction",
	async (id, { rejectWithValue }) => {
		try {
			const response = await getClassroom(id);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);

export const checkClassroomExistAction = createAsyncThunk(
	"classrooms/checkClassroomExistAction",
	async (code, { rejectWithValue }) => {
		try {
			const response = await checkClassroom(code);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);

export const resetCheckClassroomExistAction = createAsyncThunk(
	"classrooms/resetCheckClassroomExistAction",
	async (id = null, { rejectWithValue }) => {
		return {};
	},
);
