import { useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import React, { useState } from "react";
import { BiShareAlt } from "react-icons/bi";
import { BsCheckLg, BsCircleFill, BsEye, BsLink45Deg, BsPlusLg } from "react-icons/bs";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { MdOutlineModeEdit } from "react-icons/md";
import { TbColorPicker, TbFileDownload } from "react-icons/tb";
import { TiPlus } from "react-icons/ti";
import * as Yup from "yup";
import EtapeComponent from "../components/etape";

import Select from "react-select";

import { getSubThemesByTheme } from "../../../helpers/backend_helper";

const Index = ({ themesList }) => {
	const [actionIndex, setActionIndex] = useState(0);
	const [dataError, setDataError] = useState(false);
	const [displayEtapeModal, setDisplayEtapeModal] = useState(false);
	const [subThemesList, setSubThemesList] = useState([]);
	const [activeEtape, setActiveEtape] = useState(0);
	const [newEtape, setNewEtape] = useState({
		titleEtape: "",
		description: "",
		typeEtape: "",
		typeRessource: "",
		linkRessource: "",
		fileRessource: "",
		dateEtape: "",
		startTime: "",
		endTime: "",
		address: "",
		dureeHeure: "",
		dureeMinutes: "",
		dureeConsultationHeure: "",
		dureeConsultationMinutes: "",
		dureeRealisationHeure: "",
		dureeRealisationMinutes: "",
		isDepot: false,
		isCritere: false,
		labelCritere: "",
		startNumber: { value: 0, label: "0" },
		isLockedByCode: false,
		code: "",
		indice: "",
		vignette: "",
		color: "",
		labels: [],
	});

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			title: "",
			theme: "",
			subtheme: "",
			etapes: [],
		},
		validationSchema: Yup.object({
			title: Yup.string().required("Veuillez saisir le titre !"),
			theme: Yup.object().required("Veuillez sélectionner un thème !"),
			subtheme: Yup.object().required("Veuillez sélectionner un sous thème !"),
			etapes: Yup.array().of(Yup.object()).required("Veuillez ajouter au moins une étape !"),
		}),
		onSubmit: (values) => {},
	});

	const customStyles = {
		control: (base) => ({
			...base,
			border: "1px solid rgba(50, 202,188, 0.47)",
			background: "#fff",
			color: "#303972",
			paddingBottom: "1px",
			paddingTop: "1px",
			height: "40px",
			boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
			filter: "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
			borderRadius: "0.375rem",
			"&:hover": {
				border: "1px solid rgba(50, 202,188, 0.47)",
			},
		}),
		option: (provided, state) => ({
			...provided,
			fontSize: "12px",
		}),
		placeholder: (defaultStyles) => {
			return {
				...defaultStyles,
				color: "#303972",
				fontSize: "12px",
				padding: "1px",
			};
		},
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = "opacity 300ms";
			const fontSize = "12px";
			const color = "#303972";
			return { ...provided, color, fontSize, opacity, transition };
		},
		input: (styles, { data }) => ({
			...styles,
			outline: "none",
			border: "none",
			focus: "none",
			color: "#303972",
			fontSize: "12px",
		}),
		indicatorSeparator: (base) => ({
			...base,
			display: "none",
		}),
		dropdownIndicator: (base) => ({
			...base,
			color: "#303972",
			"&:hover": {
				color: "#303972",
			},
		}),
		menuList: (base) => ({
			...base,
			"::-webkit-scrollbar": {
				width: "4px",
				height: "0px",
				color: "#303972",
			},
			"::-webkit-scrollbar-track": {
				background: "#f1f1f1",
			},
			"::-webkit-scrollbar-thumb": {
				background: "#888",
			},
			"::-webkit-scrollbar-thumb:hover": {
				background: "#555",
			},
		}),
	};

	const handleChangeTheme = (e) => {
		validation.setFieldValue("subtheme", { value: "", label: "" });
		validation.setFieldValue("theme", e);
		if (e.value !== validation.values.theme) {
			getSubThemesByTheme(e.value).then((data) => {
				setSubThemesList(data.data);
			});
		}
	};

	const handleChangeSubTheme = (e) => {
		validation.setFieldValue("subtheme", e);
	};

	const handleCancelAddEtape = () => {
		setDisplayEtapeModal(!displayEtapeModal);
	};

	const handleRemoveEtape = (index) => {
		if (validation.values.etapes.length > 1) {
			let data = validation.values.etapes.filter((q, i) => i !== index);
			validation.setFieldValue("etapes", data);
		}
	};

	const handleAddEtape = () => {
		setDataError(false);
		if (newEtape.titleEtape === "" || newEtape.description === "") {
			setDataError(true);
		} else {
			validation.setFieldValue("etapes", [...validation.values.etapes, newEtape]);
			setNewEtape({
				titleEtape: "",
				description: "",
				typeEtape: "",
				typeRessource: "",
				linkRessource: "",
				fileRessource: "",
				dateEtape: "",
				startTime: "",
				endTime: "",
				address: "",
				dureeHeure: "",
				dureeMinutes: "",
				dureeConsultationHeure: "",
				dureeConsultationMinutes: "",
				dureeRealisationHeure: "",
				dureeRealisationMinutes: "",
				isDepot: false,
				isCritere: false,
				labelCritere: "",
				startNumber: { value: 0, label: "0" },
				isLockedByCode: false,
				code: "",
				indice: "",
				vignette: "",
				color: "",
			});
		}
	};

	const handleAddLabel = () => {
		setNewEtape({ ...newEtape, labels: [...newEtape.labels, { value: "", label: "" }] });
	};

	const handleLabelText = (e, i) => {};
	const handlelabelValue = (e, i) => {};

	const HeaderEtapeModal = (
		<div className='w-full flex justify-center items-center'>
			<h2 className=' text-iwingreen text-lg font-bold flex justify-between items-center gap-5'>
				Créer une étape
			</h2>
		</div>
	);

	const FooterEtapeModal = (
		<div className='w-full flex justify-end items-center'>
			<button
				type='button'
				onClick={handleAddEtape}
				className='px-5 py-2 rounded-md text-sm font-medium text-white bg-iwingreen'
			>
				Terminer
			</button>
		</div>
	);

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				validation.handleSubmit();
				return false;
			}}
			className='w-full'
		>
			<div className='w-full flex justify-between items-center'>
				<div className='flex justify items-center'>
					<div className='w-56 flex flex-col justify-start items-start gap-1'>
						<label className=''>Titre</label>
						<input
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.title}
							invalid={validation.touched.title && validation.errors.title}
							type="text"
							id="title"
							name="title"
							placeholder="Saisir le titre de l'activité"
							className='text-[12px] w-20 px-2 py-2 border border-iwingreen/[.4] rounded-lg'
						/>
						{validation.touched.title && validation.errors.title ? (
							<small className="text-[9px] text-red-400">{validation.errors.title}</small>
						) : null}
					</div>
					<div className='w-36 ml-2 flex flex-col justify-start items-start gap-1'>
						<label className=''>Thème</label>
						<Select
							id="theme"
							name="theme"
							styles={customStyles}
							options={themesList}
							placeholder="---"
							className="w-full"
							classNamePrefix="select"
							onChange={(e) => {
								handleChangeTheme(e);
							}}
							value={validation.values.theme}
							invalid={validation.touched.theme && validation.errors.theme}
						/>
						{validation.touched.theme && validation.errors.theme ? (
							<small className="text-[9px] text-red-400">{validation.errors.theme}</small>
						) : null}
					</div>
					<div className='w-36 ml-2 flex flex-col justify-start items-start gap-1'>
						<label className=''>Sous-thème</label>
						<Select
							id="mode"
							name="mode"
							styles={customStyles}
							options={subThemesList}
							placeholder="---"
							className="w-full"
							classNamePrefix="select"
							onChange={(e) => {
								handleChangeSubTheme(e);
							}}
							value={validation.values.subtheme}
							invalid={validation.touched.subtheme && validation.errors.subtheme}
						/>
						{validation.touched.subtheme && validation.errors.subtheme ? (
							<small className="text-[9px] text-red-400">{validation.errors.subtheme}</small>
						) : null}
					</div>
					<div className='w-32 ml-2 flex flex-col justify-start items-start gap-1'>
						<label className=''>Arrière-plan</label>
						<div className='w-[30px] px-5 py-[6px] shadow-in border border-gray-300 bg-gray-400 rounded-lg cursor-pointer'>
							<input type="color" className='w-full h-full invisible' />
						</div>
					</div>
				</div>
				<div className='flex justify-end items-center gap-10 pt-3'>
					<div className='p-1 rounded-3xl border border-gray-300 bg-gray-200 flex justify-between items-center gap-4'>
						<div
							className={`flex justify-center items-center p-1  rounded-full cursor-pointer ${
								actionIndex === 0 ? "bg-iwingreen text-white" : "text-gray-400"
							}`}
							onClick={() => setActionIndex(0)}
						>
							<MdOutlineModeEdit className='w-4 h-4' />
						</div>
						<div
							className={`flex justify-center items-center p-1 rounded-full cursor-pointer ${
								actionIndex === 1 ? "bg-iwingreen text-white" : "text-gray-400"
							}`}
							onClick={() => setActionIndex(1)}
						>
							<BsEye className='w-4 h-4' />
						</div>
					</div>
					<button
						type='submit'
						className='px-10 py-3 text-sm font-semibold bg-gray-500 rounded-lg text-white'
					>
						Créer
					</button>
				</div>
			</div>
			<div className={"w-full bg-gray-100 rounded-lg px-3 py-2} mt-3"}>
				<div className="w-full scroller2 flex justify-start items-center gap-3">
					<div className='w-[20%] h-[62vh] border-r-2 border-gray-300 pr-4'>
						<button className='border border-gray-400 rounded-md bg-white text-center text-sm px-7 py-2 flex justify-center items-center gap-2'>
							<BiShareAlt className='w-4 h-4' />
							<span>Partager</span>
						</button>
					</div>
					<div className='w-full py-5 scroller2 flex flex-col justify-between items-center'>
						<div className='w-full flex flex-col justify-start items-center'>
							{validation.values.etapes.map((etape, index) => (
								<EtapeComponent
									index={index}
									etape={etape}
									activeEtape={activeEtape}
									setActiveEtape={setActiveEtape}
									removeEtape={handleRemoveEtape}
								/>
							))}
							{validation.values.etapes.length < 1 && (
								<div className='w-full px-3 py-2 flex justufy-center items-center'>
									<p className='text-md text-red-300 font-medium w-full text-center'>
										Veuillez ajouter au moins une étape !
									</p>
								</div>
							)}
						</div>
						<button
							type='button'
							onClick={() => setDisplayEtapeModal(true)}
							className='border border-gray-400 rounded-md bg-white text-center text-xs px-3 py-2 flex justify-between items-center gap-2'
						>
							<TiPlus className='w-4 h-4' />
							<span>Nouvelle étape</span>
						</button>
					</div>
				</div>
			</div>
			<Dialog
				dismissableMask={true}
				resizable={false}
				style={{ width: "45vw" }}
				closable={false}
				onHide={() => handleCancelAddEtape()}
				header={HeaderEtapeModal}
				footer={FooterEtapeModal}
				icons={""}
				visible={displayEtapeModal}
			>
				<div className='px-3 py-2 flex flex-col justify-between items-center rounded-md bg-white absolute top-0 right-0 -mr-[17rem] w-56 max-w-56'>
					<h2 className='text-xl font-black text-black py-1'>Légende</h2>
					<ul className='w-full mt-3'>
						<li className='flex justify-start items-center gap-3 mb-3 text-gray-700 font-semibold'>
							<BsCircleFill className='w-5 h-5 rounded-full iwin-gradient' />
							<span className=''>Type de séance</span>
						</li>
						<li className='flex justify-start items-center gap-3 mb-3 text-gray-700 font-semibold'>
							<BsCircleFill className='w-5 h-5 text-iwincolor2' />
							<span className=''>Document</span>
						</li>
						<li className='flex justify-start items-center gap-3 mb-3 text-gray-700 font-semibold'>
							<BsCircleFill className='w-5 h-5 text-iwinyellow' />
							<span className=''>Exercice</span>
						</li>
						<li className='flex justify-start items-center gap-3 mb-3 text-gray-700 font-semibold'>
							<BsCircleFill className='w-5 h-5 text-iwincolor4' />
							<span className=''>Activité</span>
						</li>
					</ul>
				</div>
				<div className='w-full flex flex-col justify-start items-start gap-7 pb-4'>
					<div className='w-full flex flex-col justify-center items-center gap-2 pb-3'>
						<div className="w-full flex flex-col justify-start items-start gap-1 mb-3">
							<label htmlFor='titleEtape' className=''>
								Titre de l'étape
							</label>
							<input
								onChange={(e) => setNewEtape({ ...newEtape, titleEtape: e.target.value })}
								value={newEtape.titleEtape}
								type="text"
								id="titleEtape"
								name="titleEtape"
								placeholder="Titre..."
								className={"text-[12px] w-20 px-2 py-2 border rounded-lg }"}
							/>
							{dataError && newEtape.titleEtape === "" && (
								<small className='text-red-400'>Ce champ est obligatoire !</small>
							)}
						</div>
						<div className='w-full flex flex-col justify-center items-start gap-1 editor-etape mb-3'>
							<label className='description'>Description</label>
							<Editor
								id="description"
								name="description"
								className=''
								value={newEtape.description}
								onTextChange={(e) => setNewEtape({ ...newEtape, description: e.htmlValue })}
							/>
						</div>
						<div className='w-full flex flex-col justify-center items-start gap-1 mb-3'>
							<label className='' htmlFor='typeEtape'>
								Type d'étape
							</label>
							<Select
								id="typeEtape"
								name="typeEtape"
								styles={customStyles}
								options={[]}
								placeholder=""
								className="w-full"
								classNamePrefix="select"
								value={newEtape.typeEtape}
								onChange={(e) => setNewEtape({ ...newEtape, typeEtape: e })}
							/>
						</div>
						<div className='w-full flex flex-col justify-center items-start gap-1 mb-3'>
							<label className='' htmlFor='typeRessource'>
								Type de ressource
							</label>
							<Select
								id="typeRessource"
								name="typeRessource"
								styles={customStyles}
								options={[]}
								placeholder=""
								className="w-full"
								classNamePrefix="select"
								value={newEtape.typeRessource}
								onChange={(e) => setNewEtape({ ...newEtape, typeRessource: e })}
							/>
						</div>
						<div className='w-full flex flex-col justify-center items-start gap-1 mb-3'>
							<label className='' htmlFor='linkRessource'>
								Ressource (lien)
							</label>
							<div className='px-2 py-[0.1rem] w-full flex justify-start items-center border border-iwingreen/[.4] rounded-lg'>
								<div className='p-1 rounded-lg border border-iwingreen/[.4] '>
									<BsLink45Deg className='w-7 h-7 text-iwingreen' />
								</div>
								<input
									id="linkRessource"
									name="linkRessource"
									type="text"
									placeholder='Ressource...'
									className='px-2 py-2'
									value={newEtape.linkRessource}
									onChange={(e) => setNewEtape({ ...newEtape, linkRessource: e.target.value })}
									style={{ border: "0px solid white", filter: "none", boxShadow: "none" }}
								/>
							</div>
						</div>
						<div className='w-full flex flex-col justify-center items-start relative gap-1 mb-3'>
							<label className='' htmlFor='fileRessource'>
								Ressource (Fichier)
							</label>
							<label
								htmlFor='fileRessource'
								className='flex justify-between items-center cursor-pointer w-full h-full min-h-full  px-2 py-[0.1rem] w-full flex justify-start items-center border border-iwingreen/[.4] rounded-lg'
							>
								<div className='flex justify-start items-center gap-2'>
									<div className='p-1 rounded-lg border border-iwingreen/[.4] '>
										<TbFileDownload className='w-7 h-7 text-iwingreen' />
									</div>
									{newEtape.fileRessource !== "" && (
										<span className=''>newEtape.fileRessource.name</span>
									)}
								</div>
								<input
									accept='image/*'
									onChange={(e) => {}}
									id="fileRessource"
									name="fileRessource"
									type="file"
									className="hidden"
								/>
							</label>
							<IoIosCloseCircleOutline className="w-6 h-6 cursor-pointer absolute top-[2.2rem] right-2 " />
						</div>
						<div className='w-full flex justify-between items-center gap-3 mb-3'>
							<div className='w-2/4 flex flex-col justify-start items-start gap-1'>
								<label htmlFor='dateEtape' className=' -mb-2'>
									Date
								</label>
								<Calendar
									id="dateEtape"
									name="dateEtape"
									className='outline-none hover:border--iwingreen/[.4] focus:border-iwingreen/[.4] py-2'
									placeholder='--/--/----'
									value={newEtape.dateEtape}
									onChange={(e) => setNewEtape({ ...newEtape, dateEtape: e.value })}
									style={{ fontSize: "12px" }}
								/>
							</div>
							<div className='w-1/4 flex flex-col justify-start items-start gap-1'>
								<label htmlFor='startTime' className='-mb-2'>
									Début
								</label>
								<Calendar
									id="startTime"
									name="startTime"
									timeOnly
									className='outline-none hover:border--iwingreen/[.4] focus:border-iwingreen/[.4] py-2'
									placeholder='--:--'
									value={newEtape.startTime}
									onChange={(e) => setNewEtape({ ...newEtape, startTime: e.value })}
									style={{ fontSize: "12px" }}
								/>
							</div>
							<div className='w-1/4 flex flex-col justify-start items-start gap-1'>
								<label htmlFor='endTime' className='-mb-2'>
									Fin
								</label>
								<Calendar
									id="endTime"
									name="endTime"
									timeOnly
									className='outline-none hover:border--iwingreen/[.4] focus:border-iwingreen/[.4] py-2'
									placeholder='--:--'
									value={newEtape.endTime}
									onChange={(e) => setNewEtape({ ...newEtape, endTime: e.value })}
									style={{ fontSize: "12px" }}
								/>
							</div>
						</div>
						<div className="w-full flex flex-col justify-start items-start gap-1 mb-3">
							<label htmlFor='address' className=''>
								Adresse ou lien de visioconférence
							</label>
							<input
								onChange={(e) => setNewEtape({ ...newEtape, address: e.target.value })}
								value={newEtape.address}
								type="text"
								id="address"
								name="address"
								placeholder="Addresse ou lien..."
								className='text-[12px] w-20 px-2 py-2 border border-iwingreen/[.4] rounded-lg'
							/>
						</div>
						<div className="w-full flex flex-col justify-start items-start gap-1 mb-3">
							<label htmlFor='dureeHeure' className=''>
								Durée
							</label>
							<div className='w-full flex justify-between items-center gap-3'>
								<div className='w-3/5 flex justify-start items-center'>
									<Select
										id="dureeHeure"
										name="dureeHeure"
										styles={customStyles}
										options={[]}
										placeholder="---"
										className="w-full"
										classNamePrefix="select"
										onChange={(e) => setNewEtape({ ...newEtape, dureeHeure: e })}
										value={newEtape.dureeHeure}
									/>
									<span className='font-bold ml-2'>h</span>
								</div>
								<div className='w-2/5'>
									<Select
										id="dureeMinutes"
										name="dureeMinutes"
										styles={customStyles}
										options={[]}
										placeholder="---"
										className="w-full"
										classNamePrefix="select"
										onChange={(e) => setNewEtape({ ...newEtape, dureeMinutes: e })}
										value={newEtape.dureeMinutes}
									/>
								</div>
							</div>
						</div>
						<div className="w-full flex flex-col justify-start items-start gap-1 mb-3">
							<label htmlFor='dureeConsultationHeure' className=''>
								Durée estimée de consultation
							</label>
							<div className='w-full flex justify-between items-center gap-3'>
								<div className='w-3/5 flex justify-start items-center'>
									<Select
										id="dureeConsultationHeure"
										name="dureeConsultationHeure"
										styles={customStyles}
										options={[]}
										placeholder="---"
										className="w-full"
										classNamePrefix="select"
										onChange={(e) => setNewEtape({ ...newEtape, dureeConsultationHeure: e })}
										value={newEtape.dureeConsultationHeure}
									/>
									<span className='font-bold ml-2'>h</span>
								</div>
								<div className='w-2/5'>
									<Select
										id="dureeConsultationMinutes"
										name="dureeConsultationMinutes"
										styles={customStyles}
										options={[]}
										placeholder="---"
										className="w-full"
										classNamePrefix="select"
										onChange={(e) => setNewEtape({ ...newEtape, dureeConsultationMinutes: e })}
										value={newEtape.dureeConsultationMinutes}
									/>
								</div>
							</div>
						</div>
						<div className="w-full flex flex-col justify-start items-start gap-1 mb-3">
							<label htmlFor='dureeRealisationHeure' className=''>
								Durée estimée de réalisation
							</label>
							<div className='w-full flex justify-between items-center gap-3'>
								<div className='w-3/5 flex justify-start items-center'>
									<Select
										id="dureeRealisationHeure"
										name="dureeRealisationHeure"
										styles={customStyles}
										options={[]}
										placeholder="---"
										className="w-full"
										classNamePrefix="select"
										onChange={(e) => setNewEtape({ ...newEtape, dureeRealisationHeure: e })}
										value={newEtape.dureeRealisationHeure}
									/>
									<span className='font-bold ml-2'>h</span>
								</div>
								<div className='w-2/5'>
									<Select
										id="dureeRealisationMinutes"
										name="dureeRealisationMinutes"
										styles={customStyles}
										options={[]}
										placeholder="---"
										className="w-full"
										classNamePrefix="select"
										onChange={(e) => setNewEtape({ ...newEtape, dureeRealisationMinutes: e })}
										value={newEtape.dureeRealisationMinutes}
									/>
								</div>
							</div>
						</div>
						<div className="w-full flex flex-col justify-start items-start gap-1 mb-3">
							<label htmlFor='isDepot' className='flex justify-start items-center gap-7'>
								<span>Dépot par les apprenants (lien ou fichier) : </span>
								<Checkbox
									inputId="isDepot"
									value={newEtape.isDepot}
									onChange={() => setNewEtape({ ...newEtape, isDepot: !newEtape.isDepot })}
									checked={newEtape.isDepot}
								/>
							</label>
						</div>
						<div className="w-full flex flex-col justify-start items-start gap-1 mb-3">
							<label htmlFor='isCritere' className='flex justify-start items-center gap-7'>
								<span>Critère d'évoluation : </span>
								<Checkbox
									inputId="isCritere"
									value={newEtape.isCritere}
									onChange={() => setNewEtape({ ...newEtape, isCritere: !newEtape.isCritere })}
									checked={newEtape.isCritere}
								/>
							</label>
						</div>
						<div className="w-full flex justify-betzeen items-center gap-5 mb-3">
							<div className='flex-1 flex flex-col justify-start items-start gap-2'>
								<label htmlFor='labelCritere' className=''>
									Libelé du critère
								</label>
								<input
									onChange={(e) => setNewEtape({ ...newEtape, labelCritere: e.target.value })}
									value={newEtape.labelCritere}
									type="text"
									id="labelCritere"
									name="labelCritere"
									placeholder="Titre..."
									className='text-[12px] w-20 px-2 py-2 border border-iwingreen/[.4] rounded-lg'
								/>
							</div>
							<div className='flex-1 flex flex-col justify-start items-start gap-2'>
								<label htmlFor="startNumber" className="">
									Nombre d'étoiles
								</label>
								<Select
									id="startNumber"
									name="startNumber"
									styles={customStyles}
									options={[
										{ value: 0, label: "0" },
										{ value: 1, label: "1" },
										{ value: 2, label: "2" },
										{ value: 3, label: "3" },
										{ value: 4, label: "4" },
										{ value: 5, label: "5" },
									]}
									placeholder="---"
									className="w-full"
									classNamePrefix="select"
									onChange={(e) => setNewEtape({ ...newEtape, startNumber: e })}
									value={newEtape.startNumber}
								/>
							</div>
						</div>
						{newEtape.labels.map((lb, i) => (
							<div key={i} className="w-full flex justify-betzeen items-center gap-5 mb-1">
								<div className='w-full flex justify-between items-center gap-5'>
									<div className='flex-1 flex flex-col justify-start items-start gap-2'>
										<label htmlFor='label' className=''>
											Libelé du critère
										</label>
										<input
											onChange={(e) => handleLabelText(e, i)}
											value={lb.label}
											type="text"
											id="label"
											name="label"
											placeholder="Titre..."
											className='text-[12px] w-20 px-2 py-2 border border-iwingreen/[.4] rounded-lg'
										/>
									</div>
									<div className='flex-1 flex flex-col justify-start items-start gap-2'>
										<label htmlFor='value' className=''>
											valeur du critère
										</label>
										<input
											onChange={(e) => handlelabelValue(e, i)}
											value={lb.value}
											type="text"
											id="value"
											name="value"
											placeholder="Titre..."
											className='text-[12px] w-20 px-2 py-2 border border-iwingreen/[.4] rounded-lg'
										/>
									</div>
								</div>
								<div
									onClick={() => {}}
									className='p-2 cursor-pointer mt-7 flex justify-center items-center bg-red-400 border-red-500 rounded-md'
								>
									<IoIosCloseCircleOutline className='w-6 h-6 text-white' />
								</div>
							</div>
						))}

						<div className='w-full flex justify-start items-center gap-3 mb-3'>
							<div
								onClick={() => handleAddLabel()}
								className='px-3 py-1 flex justify-start cursor-pointer items-center rounded-md border-2 border-iwingreen/[0.7] bg-iwingreen/[0.7]'
							>
								<BsPlusLg className='w-4 h-4 text-white' />
								<span className='ml-2 text-white font-medium'>Nouveau critère</span>
							</div>
						</div>
						<div className="w-full flex flex-col justify-start items-start gap-1 mb-3">
							<label htmlFor='isLockedByCode' className='flex justify-start items-center gap-7'>
								<span>Etape vérouillé par un code d'accès : </span>
								<Checkbox
									inputId="isLockedByCode"
									value={newEtape.isLockedByCode}
									onChange={() =>
										setNewEtape({ ...newEtape, isLockedByCode: !newEtape.isLockedByCode })
									}
									checked={newEtape.isLockedByCode}
								/>
							</label>
						</div>
						<div className="w-full flex flex-col justify-start items-start gap-1 mb-3">
							<label htmlFor='code' className=''>
								Code d'accès
							</label>
							<input
								onChange={(e) => setNewEtape({ ...newEtape, code: e.target.value })}
								value={newEtape.code}
								type="text"
								id="code"
								name="code"
								placeholder="Code..."
								className='text-[12px] w-20 px-2 py-2 border border-iwingreen/[.4] rounded-lg'
							/>
						</div>
						<div className="w-full flex flex-col justify-start items-start gap-1 mb-3">
							<label htmlFor='indice' className=''>
								Indice pour trouver le code
							</label>
							<input
								onChange={(e) => setNewEtape({ ...newEtape, indice: e.target.value })}
								value={newEtape.indice}
								type="text"
								id="indice"
								name="indice"
								placeholder="Indece..."
								className='text-[12px] w-20 px-2 py-2 border border-iwingreen/[.4] rounded-lg'
							/>
						</div>
						<div className='w-full flex flex-col justify-start items-start gap-1 mb-3'>
							<label className='' htmlFor='vignette'>
								Vignette
							</label>
							<Select
								id="vignette"
								name="vignette"
								styles={customStyles}
								options={[]}
								placeholder="---"
								className="w-full"
								classNamePrefix="select"
								onChange={(e) => setNewEtape({ ...newEtape, vignette: e })}
								value={newEtape.vignette}
							/>
						</div>
						<div className='w-full flex flex-col justify-start items-start gap-1 mb-3'>
							<label className=''>Couleur</label>
							<div className='w-full flex justify-start items-center gap-3'>
								<div className='flex justify-center items-center min-w-12 w-12 max-w-12 min-h-12 h-12 max-h-12 cursor-pointer rounded shadow-lg bg-iwingreen border border-gray-300'>
									<BsCheckLg className='w-5 h-5 text-black font-black' />
								</div>
								<div className='flex justify-center items-center min-w-12 w-12 max-w-12 min-h-12 h-12 max-h-12 cursor-pointer rounded shadow-lg bg-iwinyellow border border-gray-300'>
									{false && <BsCheckLg className='w-5 h-5 text-black font-black' />}
								</div>
								<div className='flex justify-center items-center min-w-12 w-12 max-w-12 min-h-12 h-12 max-h-12 cursor-pointer rounded shadow-lg bg-asprimary border border-gray-300'>
									{false && <BsCheckLg className='w-5 h-5 text-black font-black' />}
								</div>
								<div className='flex justify-center items-center min-w-12 w-12 max-w-12 min-h-12 h-12 max-h-12 cursor-pointer rounded shadow-lg bg-iwincolor2 border border-gray-300'>
									{false && <BsCheckLg className='w-5 h-5 text-black font-black' />}
								</div>
								<div className='flex justify-center items-center min-w-12 w-12 max-w-12 min-h-12 h-12 max-h-12 cursor-pointer rounded shadow-lg bg-red-500 border border-gray-300'>
									{false && <BsCheckLg className='w-5 h-5 text-black font-black' />}
								</div>
								<div className='flex justify-center items-center min-w-12 w-12 max-w-12 min-h-12 h-12 max-h-12 cursor-pointer rounded shadow-lg bg-gray-300 border border-gray-300'>
									<TbColorPicker className='w-5 h-5 text-black font-black' />
								</div>
								<div className='flex justify-center items-center min-w-12 w-12 max-w-12 min-h-12 h-12 max-h-12 cursor-pointer rounded shadow-lg bg-black border border-gray-300'>
									{false && <BsCheckLg className='w-5 h-5 text-black font-black' />}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Dialog>
		</form>
	);
};

export default Index;
