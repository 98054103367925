import { useFormik } from "formik";
import React, { useState } from "react";
import { BsCameraVideo, BsEye } from "react-icons/bs";
import { GoInfo } from "react-icons/go";
import { MdOutlineModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Checkbox } from "primereact/checkbox";

import Select from "react-select";

import { createActivityDescJeu, getSubThemesByTheme } from "../../../helpers/backend_helper";

import { map } from "lodash";
import CameraIcon from "../../../assets/images/iconCamera.png";

const Index = ({ themesList }) => {
	let navigate = useNavigate();
	const [actionIndex, setActionIndex] = useState(0);
	const [competenceProgram, setCompetenceProgram] = useState([]);
	const [url, setUrl] = useState("");
	const [filetype, setFiletype] = useState("");
	const [competenceJeu, setCompetenceJeu] = useState([]);
	const [subThemesList, setSubThemesList] = useState([]);

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			title: "",
			theme: "",
			subtheme: "",
			gameTitle: "",
			imgBg: "",
			description: "",
			videoOrGif: "",
			competenceJeu: [],
			competenceProgram: [],
		},
		validationSchema: Yup.object({
			title: Yup.string().required("Veuillez saisir le titre !"),
			gameTitle: Yup.string().required("Veuillez saisir le titre !"),
			description: Yup.string().required("Veuillez saisir la description !"),
			imgBg: Yup.mixed().required("Veuillez ajouter une image de font !"),
			videoOrGif: Yup.mixed().required("Veuillez ajouter une vidéo ou un GIF !"),
			theme: Yup.object().required("Veuillez sélectionner un thème !"),
			subtheme: Yup.object().required("Sélectionner un sous thème !"),
			competenceJeu: Yup.array().of(Yup.object()),
			competenceProgram: Yup.array().of(Yup.object()),
		}),
		onSubmit: (values) => {
			let comps = competenceProgram.filter((c) => c.checked);
			values = { ...values, competenceProgram: comps };
			createActivityDescJeu(values)
				.then(() => {
					// navigate('/activites', { replace: true });
				})
				.catch((err) => {
					console.error(err);
				});
		},
	});
	const customStyles = {
		control: (base) => ({
			...base,
			border: "1px solid rgba(50, 202,188, 0.47)",
			background: "rgba(50, 202,188, 0.02)",
			color: "#303972",
			paddingBottom: "1px",
			paddingTop: "1px",
			height: "40px",
			boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
			filter: "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
			borderRadius: "0.375rem",
			"&:hover": {
				border: "1px solid rgba(50, 202,188, 0.47)",
			},
		}),
		option: (provided, state) => ({
			...provided,
			fontSize: "12px",
		}),
		placeholder: (defaultStyles) => {
			return {
				...defaultStyles,
				color: "#303972",
				fontSize: "12px",
				padding: "1px",
			};
		},
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = "opacity 300ms";
			const fontSize = "12px";
			const color = "#303972";
			return { ...provided, color, fontSize, opacity, transition };
		},
		input: (styles, { data }) => ({
			...styles,
			outline: "none",
			border: "none",
			focus: "none",
			color: "#303972",
			fontSize: "12px",
		}),
		indicatorSeparator: (base) => ({
			...base,
			display: "none",
		}),
		dropdownIndicator: (base) => ({
			...base,
			color: "#303972",
			"&:hover": {
				color: "#303972",
			},
		}),
		menuList: (base) => ({
			...base,
			"::-webkit-scrollbar": {
				width: "4px",
				height: "0px",
				color: "#303972",
			},
			"::-webkit-scrollbar-track": {
				background: "#f1f1f1",
			},
			"::-webkit-scrollbar-thumb": {
				background: "#888",
			},
			"::-webkit-scrollbar-thumb:hover": {
				background: "#555",
			},
		}),
	};

	const customMultiStyles = {
		control: (base) => ({
			...base,
			border: "1px solid rgba(50, 202,188, 0.47)",
			background: "rgba(50, 202,188, 0.02)",
			color: "#303972",
			boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
			filter: "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
			borderRadius: "0.375rem",
			"&:hover": {
				border: "1px solid rgba(50, 202,188, 0.47)",
			},
		}),
		option: (provided, state) => ({
			...provided,
			fontSize: "12px",
		}),
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = "opacity 300ms";
			const fontSize = "12px";
			const color = "#303972";
			return { ...provided, color, fontSize, opacity, transition };
		},
		placeholder: (defaultStyles) => {
			return {
				...defaultStyles,
				color: "#303972",
				fontSize: "10px",
			};
		},
		multiValue: (styles, { data }) => {
			return {
				...styles,
				backgroundColor: "#009688",
				borderRadius: "50px",
				color: "#FFFFFF",
				padding: "2px 7px",
				textTransform: "uppercase",
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "#FFFFFF",
			fontWeight: "bold",
			fontSize: "10px",
		}),
		input: (styles, { data }) => ({
			...styles,
			outline: "none",
			border: "none",
			color: "#303972",
			focus: "none",
		}),
		menuList: (base) => ({
			...base,
			"::-webkit-scrollbar": {
				width: "4px",
				height: "0px",
			},
			"::-webkit-scrollbar-track": {
				background: "#f1f1f1",
			},
			"::-webkit-scrollbar-thumb": {
				background: "#888",
			},
			"::-webkit-scrollbar-thumb:hover": {
				background: "#555",
			},
		}),
	};

	const handleChangeTheme = (e) => {
		validation.setFieldValue("subtheme", { value: "", label: "" });
		validation.setFieldValue("theme", e);
		if (e.value !== validation.values.theme) {
			getSubThemesByTheme(e.value).then((data) => {
				setSubThemesList(data.data);
			});
		}
	};

	const handleChangeSubTheme = (e) => {
		validation.setFieldValue("subtheme", e);
	};

	// useEffect(() => {
	// 	getCompetencesProgram().then((data) => {
	// 		setCompetenceProgram(data.data);
	// 	});
	// 	getCompetencesJeu().then((data) => {
	// 		setCompetenceJeu(data.data);
	// 	});
	// }, []);

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		const u = URL.createObjectURL(file);
		validation.setFieldValue("videoOrGif", file);
		setUrl(u);
		setFiletype(file.type);
	};

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				validation.handleSubmit();
				return false;
			}}
			className='w-full'
		>
			<div className='w-full flex justify-between items-center'>
				<div className='flex justify items-center'>
					<div className='w-56 flex flex-col justify-start items-start gap-1'>
						<label className=''>Titre</label>
						<input
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.title}
							invalid={validation.touched.title && validation.errors.title}
							type="text"
							id="title"
							name="title"
							placeholder="Saisir le titre de l'activité"
							className='text-[12px] w-20 px-2 py-2 border border-iwingreen/[.4] rounded-lg'
						/>
						{validation.touched.title && validation.errors.title ? (
							<small className="text-[9px] text-red-400">{validation.errors.title}</small>
						) : null}
					</div>
					<div className='w-36 ml-2 flex flex-col justify-start items-start gap-1'>
						<label className=''>Thème</label>
						<Select
							id="theme"
							name="theme"
							styles={customStyles}
							options={themesList}
							placeholder="---"
							className="w-full"
							classNamePrefix="select"
							onChange={(e) => {
								handleChangeTheme(e);
							}}
							value={validation.values.theme}
							invalid={validation.touched.theme && validation.errors.theme}
						/>
						{validation.touched.theme && validation.errors.theme ? (
							<small className="text-[9px] text-red-400">{validation.errors.theme}</small>
						) : null}
					</div>
					<div className='w-36 ml-2 flex flex-col justify-start items-start gap-1'>
						<label className=''>Sous-thème</label>
						<Select
							id="mode"
							name="mode"
							styles={customStyles}
							options={subThemesList}
							placeholder="---"
							className="w-full"
							classNamePrefix="select"
							onChange={(e) => {
								handleChangeSubTheme(e);
							}}
							value={validation.values.subtheme}
							invalid={validation.touched.subtheme && validation.errors.subtheme}
						/>
						{validation.touched.subtheme && validation.errors.subtheme ? (
							<small className="text-[9px] text-red-400">{validation.errors.subtheme}</small>
						) : null}
					</div>
					<div className='w-32 ml-2 flex flex-col justify-start items-start gap-1'>
						<label className=''>Arrière-plan</label>
						<div className='w-[30px] px-5 py-[6px] shadow-in border border-gray-300 bg-gray-400 rounded-lg cursor-pointer'>
							<input type="color" className='w-full h-full invisible' />
						</div>
					</div>
				</div>
				<div className='flex justify-end items-center gap-10 pt-3'>
					<div className='p-1 rounded-3xl border border-gray-300 bg-gray-200 flex justify-between items-center gap-4'>
						<div
							className={`flex justify-center items-center p-1  rounded-full cursor-pointer ${actionIndex === 0 ? "bg-iwingreen text-white" : "text-gray-400"
								}`}
							onClick={() => setActionIndex(0)}
						>
							<MdOutlineModeEdit className='w-4 h-4' />
						</div>
						<div
							className={`flex justify-center items-center p-1 rounded-full cursor-pointer ${actionIndex === 1 ? "bg-iwingreen text-white" : "text-gray-400"
								}`}
							onClick={() => setActionIndex(1)}
						>
							<BsEye className='w-4 h-4' />
						</div>
					</div>
					<button
						type='submit'
						className='px-10 py-3 text-sm font-semibold bg-gray-500 rounded-lg text-white'
					>
						Créer
					</button>
				</div>
			</div>
			<div className={"w-full bg-gray-100 rounded-lg px-3 py-2} mt-3"}>
				<div className="w-full scroller2 flex justify-start items-center gap-3">
					<div className='w-[20%] h-[62vh] border-r-2 border-gray-300 pr-4' />
					<div className='w-full py-5 scroller2'>
						<div className='w-full flex flex-col justify-center items-start'>
							<div className='w-1/2 flex justify-between items-center'>
								<label className='font-medium' htmlFor='title'>
									Titre du jeu
								</label>
								<GoInfo className='w-4 h-4 text-yellow-500 cursor-pointer' />
							</div>
							<input
								type="text"
								id="gameTitle"
								name="gameTitle"
								onChange={validation.handleChange}
								onBlur={validation.handleBlur}
								value={validation.values.gameTitle}
								invalid={validation.touched.gameTitle && validation.errors.gameTitle}
								className='w-full border-iwingreen/[0.4] border px-2 py-2 text-[12px] rounded-md'
							/>
							{validation.touched.gameTitle && validation.errors.gameTitle ? (
								<small className="text-[9px] text-red-400">{validation.errors.gameTitle}</small>
							) : null}
						</div>
						<div className='w-full flex justify-between items-center gap-10 mt-5'>
							<div className='flex-1 flex flex-col justify-center items-start gap-1'>
								<label className='w-full flex justify-between items-center gap-3'>
									<span className='font-medium'>
										Image de couverture<span className='text-red-500'>*</span>
									</span>
									<GoInfo className='w-4 h-4 text-yellow-500 cursor-pointer' />
								</label>
								<div className='w-full h-[20vh] relative border border-iwingreen/[0.4] rounded-md bg-white cursor-pointer shadow'>
									{validation.values.imgBg !== "" && (
										<img
											src={URL.createObjectURL(validation.values.imgBg)}
											className="h-full w-full absolute rounded-md"
											alt=""
										/>
									)}
									<div className='flex justify-between items-center p-[4px] absolute right-1 top-1 border-dashed border-gray-500 border rounded-md bg-gray-100/[.5]'>
										<label htmlFor='imgBg'>
											<img src={CameraIcon} alt="" className='w-6 h-6 cursor-pointer' />
											<input
												accept='image/*'
												onChange={(e) =>
													validation.setFieldValue("imgBg", e.currentTarget.files[0])
												}
												id="imgBg"
												name="imgBg"
												type="file"
												className="hidden"
											/>
										</label>
									</div>
								</div>
								{validation.touched.imgBg && validation.errors.imgBg ? (
									<small className="text-[9px] text-red-400">{validation.errors.imgBg}</small>
								) : null}
							</div>
							<div className='flex-1 flex flex-col justify-center items-start gap-1'>
								<label className='w-full flex justify-between items-center gap-3'>
									<span className='font-medium'>
										Vidéo ou GIF du jeu<span className='text-red-500'>*</span>
									</span>
									<GoInfo className='w-4 h-4 text-yellow-500 cursor-pointer' />
								</label>
								<div className='w-full relative h-[20vh] border border-iwingreen/[0.4] rounded-md bg-white cursor-pointer shadow'>
									{url !== "" ? (
										<div className='w-full h-full absolute'>
											{filetype.includes("video") ? (
												<video className="w-full h-full rounded-md" controls src={url} />
											) : null}
											{filetype.includes("gif") ? (
												<img src={url} className="h-full w-full rounded-md" alt="" />
											) : null}
										</div>
									) : null}
									<div className='flex justify-between items-center p-[6px] absolute right-1 top-1 border-dashed border-gray-500 border rounded-md bg-gray-100/[.5]'>
										<label htmlFor='videoOrGif'>
											<BsCameraVideo className='w-5 h-5 cursor-pointer' />
											<input
												accept='video/*,.gif'
												onChange={handleFileChange}
												id="videoOrGif"
												name="videoOrGif"
												type="file"
												className="hidden"
											/>
										</label>
									</div>
								</div>
								{validation.touched.videoOrGif && validation.errors.videoOrGif ? (
									<small className="text-[9px] text-red-400">{validation.errors.videoOrGif}</small>
								) : null}
							</div>
						</div>
						<div className='w-full flex flex-col justify-center items-start gap-1 mt-5'>
							<label className='w-1/2 flex justify-between items-center gap-3'>
								<span className='font-medium'>
									Description du jeu<span className='text-red-500'>*</span>
								</span>
								<GoInfo className='w-4 h-4 text-yellow-500 cursor-pointer' />
							</label>
							<textarea
								id="description"
								name="description"
								onChange={validation.handleChange}
								onBlur={validation.handleBlur}
								value={validation.values.description}
								invalid={validation.touched.description && validation.errors.description}
								className='w-full border border-iwingreen/[0.4] rounded-md bg-white p-2 focus:outline-none text-sm'
								rows="3"
							/>
							{validation.touched.description && validation.errors.description ? (
								<small className="text-[9px] text-red-400">{validation.errors.description}</small>
							) : null}
						</div>
						<div className='flex-1 flex flex-col justify-center items-start gap-1 mt-5'>
							<label
								htmlFor='competenceJeu'
								className='w-full flex justify-between items-center gap-3'
							>
								<span className='font-medium'>Compétences du jeu</span>
							</label>
							<Select
								id="competenceJeu"
								name="competenceJeu"
								isMulti
								styles={customMultiStyles}
								options={competenceJeu}
								placeholder="---"
								className="w-full"
								classNamePrefix="select"
								onChange={(e) => {
									validation.setFieldValue("competenceJeu", e);
								}}
								value={validation.values.competenceJeu}
							/>
						</div>
						<div className='flex-1 flex flex-col justify-center items-start gap-1 mt-5'>
							<label className='w-full flex justify-between items-center gap-3'>
								<span className='font-medium'>Compétences et notions du programme</span>
							</label>
							<div className='w-full rounded-lg grid grid-cols-3 gap-2 items-center p-2 border border-iwingreen/[0.6]'>
								{map(competenceProgram, (cmp, index) => (
									<div
										className='px-2 py-1 bg-iwingreen/[0.2] text-sm font-medium rounded-lg border border-iwingreen/[0.6] flex justify-between items-center'
										key={`comp-${index}`}
									>
										<span>{cmp.label}</span>
										<Checkbox
											id="cmp"
											name="cmp"
											className=''
											onChange={() => {
												let cmps = competenceProgram.map((c) =>
													c.value === cmp.value ? { ...c, checked: !c.checked } : c,
												);
												setCompetenceProgram(cmps);
											}}
											checked={cmp.checked}
										/>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

export default Index;
