import { FormControl, FormLabel, Switch } from "@chakra-ui/react";
import AddDocument from "../AddDocument/AddDocument";
import PdfCard from "../PdfCard/PdfCard";
import "./DocumentGallery.scss";

export default function DocumentGallery({
	activities,
	onFileUploaded,
	onRemoveDocument,
	onDocumentAction,
	selectedActivity,
	toggleGroupe,
	isGroupe,
	setActivityName,
	activityName,
	edit
}) {
	return (
		<div className="DocumentGallery">
			<h3 className="text-[24px] font-bold text-center text-iwincolor1 mb-2">
				{!edit && "Importation de documents"}
				{edit && isGroupe && `Modification du dossier ${activityName}`}
				{edit && !isGroupe && `Modification de l'activité ${activityName} `}

			</h3>

			{
				!edit && <FormControl display='flex' alignItems='center' className="mb-2">
					<FormLabel mb='0'>
						Grouper en un dossier ?
						<Switch onChange={toggleGroupe} isChecked={isGroupe} />
					</FormLabel>
				</FormControl>
			}
			{isGroupe && (
				<label>
					Nom du dossier <span className="text-red-400">*</span> :
					<input
						type="text"
						className="Input mb-2"
						value={activityName ?? ""}
						onChange={(event) => setActivityName(event.target.value)}
					/>
				</label>
			)}

			<div className="flex flex-wrap gap-2 mb-2">
				{activities.map((activity) => (
					<PdfCard
						edit={edit}
						key={activity.id}
						selected={selectedActivity?.id === activity.id}
						fileName={activity.file.name}
						onDocumentAction={() => onDocumentAction(activity)}
						onRemoveDocument={() => onRemoveDocument(activity.id)}
					/>
				))}
				{
					!edit &&
					<AddDocument onFileUploaded={onFileUploaded} />
				}
			</div>
		</div>
	);
}
