import React from "react";
import { BiFilter } from "react-icons/bi";
import { GoSearch } from "react-icons/go";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Profile from "../../components/common/Profile";

import ActiviteIcon from "../../assets/images/activite-icon.png";
import ClassIcon from "../../assets/images/class-icon.png";
import EleveIcon from "../../assets/images/eleve-icon.png";

import BtnCreateActivite from "../../assets/images/btnCreateActivite.png";
import BtnCreateClass from "../../assets/images/btnCreateClass.png";

import Mask2 from "../../assets/images/logo-jeux-2.svg";

import BackgroundImg from "../../assets/images/jumbotronbg.png";
import { useAppStore } from "../../store/store";

const JumbotronConatiner = styled.div`
  background: url(${BackgroundImg}) no-repeat center center fixed;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  height: 100%;
`;

export default function TeackerDashboard() {
	const classRooms = useAppStore((state) => state.classRooms);
	const activities = useAppStore((state) => state.activities);
	const folders = useAppStore((state) => state.folders);


	return (
		<div className="w-full flex flex-row justify-between items-start">
			<div className="w-full flex flex-col justify-start items-start mt-5 px-5">
				<div className="w-full  flex justify-between items-center">
					<h1 className="text-iwinblue font-bold text-left text-[26px]">Bienvenue</h1>
					<div className="relative w-[300px]">
						<input
							type="text"
							placeholder="Trouver une ressource ..."
							className="w-full bg-white rounded-[46px] border-white bottom-0 px-[50px] py-3"
							style={{ border: "none", borderRadius: "40px" }}
						/>
						<GoSearch className="w-6 h-6 absolute top-[15px] left-[20px] text-asprimary" />
						<BiFilter className="w-8 h-8 absolute top-[10px] right-[15px] text-gray-700" />
					</div>
					<Profile />
				</div>
				<div className="w-full drop-shadow-lg rounded-xl bg-white px-7 py-5 flex flex-row justify-between items-center mt-7">
					<div className="flex flex-row justify-between items-center">
						<img src={ClassIcon} className="w-14 mr-4" alt="" />
						<Link to="/classes">
							<div className="flex flex-col justify-center items-center">
								<h6 className="text-gray-400 text-[15px]">Mes classes</h6>
								<strong className="text-notiftext text-[18px]">
									{classRooms.length < 10 ? `0${classRooms.length}` : classRooms.length}
								</strong>
							</div>
						</Link>
					</div>
					<div className="flex flex-row justify-between items-center">
						<img src={EleveIcon} className="w-14 mr-4" alt="" />
						<div className="flex flex-col justify-center items-center">
							<h6 className="text-gray-400 text-[15px]">Mes élèves</h6>
							<strong className="text-notiftext text-[18px]">00</strong>
						</div>
					</div>
					<div className="flex flex-row justify-between items-center">
						<img src={ActiviteIcon} className="w-14 mr-4" alt="" />
						<Link to="/activites">
							<div className="flex flex-col justify-center items-center">
								<h6 className="text-gray-400 text-[15px]">Activités</h6>
								<strong className="text-notiftext text-[18px]">{(activities.length + folders.length) < 10 ? `0${activities.length + folders.length}` : activities.length + folders.length}</strong>
							</div>
						</Link>
					</div>
				</div>
				<JumbotronConatiner className="w-full mt-5 rounded-lg px-9 pt-4 flex flex-row justify-start items-center">
					<div className="w-[80px] h-[65px]  bg-white rounded-full flex justify-center items-center mb-2">
						<img src={Mask2} className="h-[50px] object-cover" alt="" />
					</div>
					<div className="w-full flex flex-col justify-center items-center font-bold ml-5 mb-2">
						<h4 className="w-full text-[18px]">Découvrez notre jeu Scibot’s Sciences Labs</h4>
						<p className="w-full font-medium text-[12px]">
							Des expériences scientifiques et des mini-jeux pour découvrir ou réviser la
							Physique-Chimie !
						</p>
					</div>
					<Link to="/game" className="px-5 py-2 bg-iwincolor2 rounded-lg text-white mb-2 text-sm">
						Découvrir
					</Link>
				</JumbotronConatiner>

				<div className="w-full flex flex-row justify-center items-center mt-5 gap-5">
					<div className="bg-white px-14 py-6 rounded-lg flex flex-col justify-start items-center shadow-lg">
						<h5 className="text-gray-600 text-[12px] font-normal">Vous avez ({classRooms.length < 10 ? `0${classRooms.length}` : classRooms.length}) classe.</h5>
						<p className="text-gray-600 text-[12px] font-normal mb-3">
							Commencer par en créer une.
						</p>
						<Link to="/add-class" className="">
							<img src={BtnCreateClass} className="h-36 mb-3" alt="" />
						</Link>
					</div>

					<div className="bg-white px-14 py-6 rounded-lg flex flex-col justify-start items-center shadow-lg">
						<h6 className="text-gray-600 text-[12px] font-normal">Vous avez ({(activities.length + folders.length) < 10 ? `0${activities.length + folders.length}` : activities.length + folders.length}) activité.</h6>
						<p className="text-gray-600 text-[12px] font-normal mb-3">
							Commencer par en créer une.
						</p>
						<Link to="/activites" className="">
							<img src={BtnCreateActivite} className="h-36 mb-3" alt="" />
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
