import { getTeacherActivities, getTeacherFolders } from "../helpers/backend_helper";
import { useAppStore } from "../store/store";

export function useActivities() {
	const account = useAppStore((state) => state.account);
	const activities = useAppStore((state) => state.activities);
	const setActivities = useAppStore((state) => state.setActivities);

	const syncActivities = async () => {
		const activities = await getTeacherActivities(account.id);
		if (activities.success) setActivities(activities.data);
	};

	return {
		activities,
		refresh: syncActivities,
	};
}


export function useFolders() {
	const account = useAppStore((state) => state.account);
	const folders = useAppStore((state) => state.folders);
	const setFolders = useAppStore((state) => state.setFolders);

	const syncFolders = async () => {
		const res = await getTeacherFolders(account.id);
		if (res.success) setFolders(res.data ?? []);
	};

	return {
		folders,
		refresh: syncFolders,
	};
}
