import { createSlice } from "@reduxjs/toolkit";
import { registerStudentAction } from "./actions";

const initialState = {
	registerStatus: "",
	registerError: "",
};

const registerStudentSlice = createSlice({
	name: "teachers",
	initialState,
	reducers: {},
	extraReducers: {
		[registerStudentAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				registerStatus: "pending",
				registerError: "",
			};
		},
		[registerStudentAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				registerStatus: "success",
				registerError: "",
			};
		},
		[registerStudentAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				registerStatus: "rejected",
				registerError: payload,
			};
		},
	},
});

export default registerStudentSlice.reducer;
