import { useEffect, useState } from "react";
import { useAppStore } from "../../../store/store";
import filePlaceholder from "../PdfCard/file-grey.png";
import "./DocumentDetail.scss";

export default function DocumentDetail({ activity, commitChange }) {
	const [selectedTheme, setSelectedTheme] = useState();
	const themes = useAppStore((state) => state.themes);

	const handleThemeChange = (theme) => {
		const currentTheme = themes.find((item) => item.id === theme);
		const data = { ...activity, themeId: currentTheme?.id };
		commitChange(data);
		setSelectedTheme(currentTheme);
	};


	useEffect(() => {
		setSelectedTheme(themes.find((item) => item.id === activity.themeId));
	}, [themes, activity]);

	return (
		<div className="DocumentDetail pl-2 mt-4">
			<div className="flex items-center gap-1 text-[12px] font-semibold">
				<img className="h-3 w-3" src={filePlaceholder} alt="filePlaceholder" />
				<p>{activity.name}</p>
			</div>
			<div>
				<label>
					Titre <span className="text-red-400">*</span>
					<input
						type="text"
						className="Input"
						value={activity.name ?? ""}
						onChange={(event) => {
							const data = { ...activity, name: event.target.value };
							commitChange(data);
						}}
					/>
				</label>

				<label>
					Thème <span className="text-red-400">*</span>
					<select
						className="Input"
						value={selectedTheme?.id ?? ""}
						onChange={(event) => handleThemeChange(event.target.value)}
					>
						<option>Veuillez selectionner un thème</option>
						{themes.map((theme) => (
							<option key={theme.id} value={theme.id}>
								{theme.name}
							</option>
						))}
					</select>
				</label>

				<label>
					Sous-thème <span className="text-red-400">*</span>
					<select
						value={activity.subThemeId}
						className="Input"
						onChange={(event) => {
							const data = { ...activity, subThemeId: event.target.value };
							commitChange(data);
						}}
					>
						<option>Veuillez selectionner un sous-thème</option>
						{selectedTheme?.subThemes.map((subTheme) => (
							<option key={subTheme.id} value={subTheme.id}>
								{subTheme.name}
							</option>
						))}
					</select>
				</label>

				<label>
					Description
					<textarea
						className="Input"
						placeholder="Description courte ..."
						value={activity.description ?? ""}
						onChange={(event) => {
							const data = { ...activity, description: event.target.value };
							commitChange(data);
						}}
					/>
				</label>
			</div>
		</div>
	);
}
