import { getTeacherClassRooms } from "../helpers/backend_helper";
import { getUser } from "../helpers/common";
import { useAppStore } from "../store/store";

export function useClassRooms() {
	const setClassRooms = useAppStore((state) => state.setClassRooms);

	const refetch = async () => {
		const res = await getTeacherClassRooms(getUser().id);
		if (res.success && res.data) setClassRooms(res.data);
	};

	return { refetch };
}
