import {
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
	useDisclosure,
} from "@chakra-ui/react";
import Select from "react-select";

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import classicon from "../../assets/images/iconAddClass.png";
import { useAppStore } from "../../store/store";
import { reactSelectTheme } from "../common/react-select";

export default function ChangeClassRoom(props) {
	const [confirm, setConfirm] = useState();
	const [loading, setLoading] = useState(false);
	const [student, setStudent] = useState();
	const [classRoom, setClassRoom] = useState();

	const classRoomList = useAppStore((state) => state.classRooms);
	const { isOpen, onClose, onOpen } = useDisclosure();

	const students = () => {
		if (!Array.isArray(props.students)) throw new Error("Missing list of students");
		return props.students.map((item) => ({
			label: `${item.firstName} ${item.lastName}`,
			value: item.id,
		}));
	};

	const classRooms = () => {
		return classRoomList.map((item) => ({ label: item.name, value: item.id }));
	};

	const syncInitalDatas = () => {
		const student = props.students.find((item) => item.id === props.student.id);
		if (!student) throw new Error(`can't find student ${props.student.id}`);

		const classRoom = classRoomList.find(
			(item) => item.id === props.student.ClassroomStudent.ClassroomId,
		);
		if (!classRoom) throw new Error(`can't find classroom of student ${props.student.id}`);

		setStudent({ label: `${student.firstName} ${student.lastName}`, value: student.id });
		setClassRoom({ label: classRoom.name, value: classRoom.id });
	};

	const onSubmit = async () => {
		if (confirm !== "CONFIRMER") {
			return toast.error(`Saisissez "CONFIRMER" pour changer la classe de l’élève.`);
		}
		const payload = {
			studentId: student.value,
			replaceClassId: props.student.ClassroomStudent.ClassroomId,
			classRoomId: classRoom.value,
		};
		setLoading(true);
		await props.handleChangeStudentClassRoom(payload);
		setLoading(false);
		onClose();
	};

	useEffect(syncInitalDatas, [props.student]);

	return (
		<>
			<li>
				<button className='text-white hover:text-gray-300' onClick={onOpen} type='button'>
					Changer de classe
				</button>
			</li>

			<Modal size="3xl" isCentered isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<div className="flex gap-2 justify-center items-center">
							<img src={classicon} alt="classicon" className="w-[27px]" />
							<p className="text-iwingreen text-[24px] font-bold">Changement de classe</p>
						</div>
					</ModalHeader>
					<ModalBody>
						<div className="mb-3">
							<label htmlFor="student" className="text-[18px] font-semibold">
								Elève à transférer
							</label>
							<Select
								options={students()}
								{...reactSelectTheme}
								value={student}
								onChange={setStudent}
							/>
						</div>

						<div className="mb-3">
							<label htmlFor="student" className="text-[18px] font-semibold">
								Nouvelle classe
							</label>
							<Select
								options={classRooms()}
								{...reactSelectTheme}
								value={classRoom}
								onChange={setClassRoom}
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="student" className="text-[18px] font-semibold">
								Saisissez <strong>"CONFIRMER"</strong> pour changer la classe de l’élève.
							</label>
							<input
								type="text"
								className="px-3 py-2"
								onChange={(event) => setConfirm(event.target.value)}
							/>
						</div>
					</ModalBody>
					<ModalFooter>
						{loading ? (
							<Spinner />
						) : (
							<button
								type="button"
								className="bg-iwingreen text-white pt-3 pb-3 pl-6 pr-6 rounded-[10px]"
								onClick={onSubmit}
							>
								Changer
							</button>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
