import { createSlice } from "@reduxjs/toolkit";
import { getFamilyAction } from "./actions";

const initialState = {
	getFamilyStatus: "",
	getFamilyError: "",
	family: {},
};

const familySlice = createSlice({
	name: "family",
	initialState,
	reducers: {},
	extraReducers: {
		[getFamilyAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				getFamilyStatus: "pending",
				getFamilyError: "",
				family: {},
			};
		},
		[getFamilyAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				family: payload,
				getFamilyStatus: "success",
				getFamilyError: "",
			};
		},
		[getFamilyAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				getFamilyStatus: "rejected",
				getFamilyError: payload,
			};
		},
	},
});

export default familySlice.reducer;
