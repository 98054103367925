import moment from "moment";
import "moment/locale/fr";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { ImGoogle } from "react-icons/im";
import { RiAppleLine, RiFacebookCircleLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

import Logo from "../../assets/images//logo-iwin.png";
import ParentWhiteIcon from "../../assets/images/Parent-white.png";
import StudentWhiteIcon from "../../assets/images/Student-white.png";
import EnseignantWhiteIcon from "../../assets/images/Teacher-white.png";
import EnpointBgImg from "../../assets/images/bg-indpoint.png";

import EnseignantGreenIcon from "../../assets/images/Teacher2.png";
import ParentGreenIcon from "../../assets/images/Parent.png";
import StudentGreenIcon from "../../assets/images/Student.png";

import { map } from "lodash";
import "./style.css";

const days = [
	{ value: 1, label: "01" },
	{ value: 2, label: "02" },
	{ value: 3, label: "03" },
	{ value: 4, label: "04" },
	{ value: 5, label: "05" },
	{ value: 6, label: "06" },
	{ value: 7, label: "07" },
	{ value: 8, label: "08" },
	{ value: 9, label: "09" },
	{ value: 10, label: "10" },
	{ value: 1, label: "11" },
	{ value: 12, label: "12" },
	{ value: 13, label: "13" },
	{ value: 14, label: "14" },
	{ value: 15, label: "15" },
	{ value: 16, label: "16" },
	{ value: 17, label: "17" },
	{ value: 18, label: "18" },
	{ value: 19, label: "19" },
	{ value: 20, label: "20" },
	{ value: 21, label: "21" },
	{ value: 22, label: "22" },
	{ value: 23, label: "23" },
	{ value: 24, label: "24" },
	{ value: 25, label: "25" },
	{ value: 26, label: "26" },
	{ value: 27, label: "27" },
	{ value: 28, label: "28" },
	{ value: 29, label: "29" },
	{ value: 30, label: "30" },
	{ value: 31, label: "31" },
];

const EnpdoitContainer = styled.div`
    width: 750px;
    padding: 20px 15px;
     background: url(${EnpointBgImg}) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 16px
`;

const customStyles = {
	control: (base) => ({
		...base,
		height: "45px",
		border: "1px solid rgba(50, 202,188, 0.47)",
		background: "rgba(50, 202,188, 0.02)",
		color: "#303972",
		boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
		filter: "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
		borderRadius: "0.375rem",
		"&:hover": {
			border: "1px solid rgba(50, 202,188, 0.47)",
		},
	}),
	option: (provided, state) => ({
		...provided,
		fontSize: "14px",
		textAlign: "center",
		textTransform: "capitalize",
	}),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: "#303972",
			fontSize: "16px",
		};
	},
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";
		const fontSize = "16px";
		const color = "#303972";
		const textTransform = "capitalize";
		return { ...provided, color, fontSize, textTransform, opacity, transition };
	},
	input: (styles, { data }) => ({
		...styles,
		outline: "none",
		border: "none",
		focus: "none",
		color: "#303972",
	}),
	indicatorSeparator: (base) => ({
		...base,
		display: "none",
	}),
	dropdownIndicator: (base) => ({
		...base,
		color: "#303972",
		"&:hover": {
			color: "#303972",
		},
	}),
	menuList: (base) => ({
		...base,
		"::-webkit-scrollbar": {
			width: "4px",
			height: "0px",
			color: "#303972",
		},
		"::-webkit-scrollbar-track": {
			background: "#f1f1f1",
		},
		"::-webkit-scrollbar-thumb": {
			background: "#888",
		},
		"::-webkit-scrollbar-thumb:hover": {
			background: "#555",
		},
	}),
};

const Register = () => {
	const navigate = useNavigate();
	const [openTab, setOpenTab] = useState(1);
	const [day, setDay] = useState("");
	const [month, setMonth] = useState("");
	const [year, setYear] = useState("");
	const [age, setAge] = useState(0);
	const [birthDay, setBirthDay] = useState("");

	function getMonthNames() {
		moment.locale("fr");
		let months = [];
		map(moment.months(), (month, index) => {
			months.push({ value: index + 1, label: month });
		});

		return months;
	}

	function generateArrayOfYears() {
		var max = new Date().getFullYear();
		var min = max - 20;
		var years = [];

		for (var i = max; i >= min; i--) {
			years.push({ value: i, label: `${i}` });
		}
		return years;
	}

	const goToNext = () => {
		const state = { age, birthDay };
		navigate("/register/student", { replace: true, state });
	};

	useEffect(() => {
		if (day !== "" && month !== "" && year !== "") {
			let d = day.value;
			let m = month.value;
			let y = year.value;
			let dateString = `${y}-${m}-${d}`;
			let momentObj = moment(dateString).format("YYYY-MM-DD");
			let today = moment().format("YYYY-MM-DD");
			setBirthDay(momentObj);
			setAge(moment(today).diff(moment(momentObj), "years"));
		}
	}, [day, month, year]);

	return (
		<CSSTransition in={true} appear={true} timeout={300} classNames="fade">
			<div className="flex flex-col justify-center items-center">
				<div className="absolute z-10 top-5">
					<img src={Logo} alt="LOGO" className="h-8" />
				</div>
				<h4 className="mt-5 text-[18px] font-bold text-white mb-3">INSCRIPTION</h4>
				<EnpdoitContainer className="flex flex-col justify-center items-center">
					<div className='w-full flex justify-center items-center px-14'>
						<div className="w-full">
							<ul
								className="flex mb-0 list-none flex-wrap pt-3 rounded-lg flex-row  shadow-xl"
								role="tablist"
							>
								<li className="-mb-px flex-auto text-center">
									{/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
									<a
										className={`flex justify-start items-center text-md font-semibold uppercase px-5 py-4 shadow-lg rounded-l-lg block leading-normal ${
											openTab === 1
												? "text-white bg-iwingreen"
												: "text-iwingreen bg-iwingreen/[.07]"
										}`}
										onClick={(e) => {
											e.preventDefault();
											setOpenTab(1);
										}}
										data-toggle="tab"
										href="#link1"
										role="tablist"
									>
										<img
											alt=""
											src={openTab === 1 ? StudentWhiteIcon : StudentGreenIcon}
											className="w-8 h-8 text-base mr-3"
										/>{" "}
										Apprenant
									</a>
								</li>
								<li className="-mb-px flex-auto text-center">
									{/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
									<a
										className={`flex justify-start items-center text-md font-semibold uppercase px-5 py-4 shadow-lg block leading-normal ${
											openTab === 2
												? "text-white bg-iwingreen"
												: "text-iwingreen bg-iwingreen/[.07]"
										}`}
										onClick={(e) => {
											e.preventDefault();
											setOpenTab(2);
										}}
										data-toggle="tab"
										href="#link2"
										role="tablist"
									>
										<img
											alt=""
											src={openTab === 2 ? ParentWhiteIcon : ParentGreenIcon}
											className="w-8 h-8 text-base mr-3"
										/>{" "}
										Parent
									</a>
								</li>
								<li className="-mb-px flex-auto text-center">
									{/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
									<a
										className={`flex justify-start items-center text-md font-semibold uppercase px-5 py-4 shadow-lg rounded-r-lg  leading-normal ${
											openTab === 3
												? "text-white bg-iwingreen"
												: "text-iwingreen bg-iwingreen/[.07]"
										}`}
										onClick={(e) => {
											e.preventDefault();
											setOpenTab(3);
										}}
										data-toggle="tab"
										href="#link3"
										role="tablist"
									>
										<img
											alt=""
											src={openTab === 3 ? EnseignantWhiteIcon : EnseignantGreenIcon}
											className="w-8 h-8 text-base mr-3"
										/>{" "}
										Enseignant
									</a>
								</li>
							</ul>
							<div className="w-full">
								<div className="p-8 flex-auto">
									<div className="tab-content tab-space">
										<div className={openTab === 1 ? "block" : "hidden"} id="link1">
											<Row className='py-[0.1rem]'>
												<Col md={12} className='flex justify-start items-center mb-3'>
													<label>Quelle est ta date de naissance</label>
												</Col>
												<Col sm={4} className=''>
													<Select
														id="day"
														name="day"
														styles={customStyles}
														options={days}
														placeholder="Jour..."
														className="w-full"
														classNamePrefix="select"
														onChange={(e) => setDay(e)}
														value={day}
													/>
												</Col>
												<Col sm={4} className=''>
													<Select
														id="month"
														name="month"
														styles={customStyles}
														options={getMonthNames()}
														placeholder="Mois..."
														className="w-full"
														classNamePrefix="select"
														onChange={(e) => setMonth(e)}
														value={month}
													/>
												</Col>
												<Col sm={4} className={`${birthDay !== "" ? "" : "mb-[105px]"}`}>
													<Select
														id="year"
														name="year"
														styles={customStyles}
														options={generateArrayOfYears()}
														placeholder="Année..."
														className="w-full"
														classNamePrefix="select"
														onChange={(e) => setYear(e)}
														value={year}
													/>
												</Col>

												{birthDay !== "" && age >= 13 ? (
													<Col
														lg={12}
														className='w-full flex flex-col justify-center items-start mt-7 gap-3'
													>
														<label>S'inscrire avec :</label>
														<div className='w-full flex justify-start items-center'>
															<button
																onClick={() => goToNext()}
																type="button"
																className="text-sm bg-iwingreen border border-iwingreen px-5 py-3 text-white rounded-md font-semibold"
															>
																Mon adresse email
															</button>
															<div className='text-gray-300 mx-10 text-[20px] h-6 border-l bg-gray-600' />
															<div className='flex justify-start items-center gap-4'>
																<button
																	type="button"
																	className='w-9 h-9 rounded-full flex justify-center items-center bg-blue-600'
																>
																	<RiFacebookCircleLine className='w-6 h-6 text-white' />
																</button>
																<button
																	type="button"
																	className='w-9 h-9 rounded-full flex justify-center items-center bg-red-500'
																>
																	<ImGoogle className='w-4 h-4 text-white' />
																</button>
																<button
																	type="button"
																	className='w-9 h-9 rounded-full flex justify-center items-center bg-gray-300'
																>
																	<RiAppleLine className='w-5 h-5 text-white' />
																</button>
															</div>
														</div>
													</Col>
												) : null}
												{birthDay && age < 13 ? (
													<Col md={12} className='mt-[60px]'>
														<button
															onClick={() => goToNext()}
															type="button"
															className="text-sm bg-iwingreen/[.06] border border-iwingreen px-5 py-3 text-white rounded-md font-semibold"
														>
															Suivant
														</button>
													</Col>
												) : null}
												<Col md={12} className='flex justify-end mt-[26px]'>
													<Link className='text-iwingreen/[.7] text-sm' to='/login'>
														<u>J'ai déjà un compte</u>
													</Link>
												</Col>
											</Row>
										</div>
										<div className={openTab === 2 ? "block" : "hidden"} id="link2">
											<div className='w-full flex flex-col justify-center items-start gap-3'>
												<label>S'inscrire avec :</label>
												<div className='w-full flex justify-start items-center'>
													<Row>
														<Col md={12} className='flex justify-start items-center mb-[105px]'>
															<Link
																to='/register/parent'
																type="button"
																className="text-sm bg-iwingreen border border-iwingreen  px-5 py-3 text-white rounded-md font-semibold"
															>
																Mon adresse email
															</Link>
															<div className='text-gray-300 mx-10 text-[20px] h-6 border-l bg-gray-600' />
															<div className='flex justify-start items-center gap-4'>
																<button
																	type="button"
																	className='w-9 h-9 rounded-full flex justify-center items-center bg-blue-600'
																>
																	<RiFacebookCircleLine className='w-6 h-6 text-white' />
																</button>
																<button
																	type="button"
																	className='w-9 h-9 rounded-full flex justify-center items-center bg-red-500'
																>
																	<ImGoogle className='w-4 h-4 text-white' />
																</button>
																<button
																	type="button"
																	className='w-9 h-9 rounded-full flex justify-center items-center bg-gray-300'
																>
																	<RiAppleLine className='w-5 h-5 text-white' />
																</button>
															</div>
														</Col>
														<Col md={12} className='flex justify-end mt-7'>
															<Link className='text-iwingreen/[.7] text-sm' to='/login'>
																<u>J'ai déjà un compte</u>
															</Link>
														</Col>
													</Row>
												</div>
											</div>
										</div>
										<div className={openTab === 3 ? "block" : "hidden"} id="link3">
											<div className='w-full flex flex-col justify-center items-start gap-3'>
												<label>S'inscrire avec :</label>
												<div className='w-full flex justify-start items-center'>
													<Row>
														<Col md={12} className='flex justify-start items-center mb-[105px]'>
															<Link
																to='/register/teacher'
																type="button"
																className="text-sm bg-iwingreen border border-iwingreen  px-5 py-3 text-white rounded-md font-semibold"
															>
																Mon adresse email
															</Link>
															<div className='text-gray-300 mx-10 text-[20px] h-6 border-l bg-gray-600' />
															<div className='flex justify-start items-center gap-4'>
																<button
																	type="button"
																	className='w-9 h-9 rounded-full flex justify-center items-center bg-blue-600'
																>
																	<RiFacebookCircleLine className='w-6 h-6 text-white' />
																</button>
																<button
																	type="button"
																	className='w-9 h-9 rounded-full flex justify-center items-center bg-red-500'
																>
																	<ImGoogle className='w-4 h-4 text-white' />
																</button>
																<button
																	type="button"
																	className='w-9 h-9 rounded-full flex justify-center items-center bg-gray-300'
																>
																	<RiAppleLine className='w-5 h-5 text-white' />
																</button>
															</div>
														</Col>
														<Col md={12} className='flex justify-end mt-7'>
															<Link className='text-iwingreen/[.7] text-sm' to='/login'>
																<u>J'ai déjà un compte</u>
															</Link>
														</Col>
													</Row>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</EnpdoitContainer>
			</div>
		</CSSTransition>
	);
};

export default Register;
