import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from "@chakra-ui/react";
import randomColor from "randomcolor";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { BiFilter, BiTrash } from "react-icons/bi";
import { GoSearch } from "react-icons/go";
import { Link, Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ScrollContainer } from "../../components/common";
import Profile from "../../components/common/Profile";
import { addStudentToClass } from "../../helpers/backend_helper";
import { getFistLetter } from "../../helpers/common";
import { useClassRooms } from "../../hooks/useClassRooms";
import { useAppStore } from "../../store/store";
import CreateClassStudentForm from "./CreateClassStudentForm/CreateClassStudentForm";

export default function CreateClassStudent() {
	const classRooms = useAppStore((state) => state.classRooms);
	const { refetch } = useClassRooms();

	const location = useLocation();
	const state = location.state;
	const [classRoom, setClassRoom] = useState(state);

	const addStudent = async (student, toastId) => {
		const ids = new Set(classRoom.students.map((item) => item.id));
		if (ids.has(student.id)) {
			toast.done(toastId);
			return toast.info("Cet Elève existe déja !");
		}

		ids.add(student.id);
		const res = await addStudentToClass({ classRoomId: state.id, studentIds: [...ids] });
		if (res.success && res.data) {
			await refetch();
			toast.done(toastId);
			return toast.info("Elève ajouté");
		}
	};

	const removeStudent = async (id) => {
		const ids = new Set(classRoom.students.map((item) => item.id));
		ids.delete(id);
		const res = await addStudentToClass({ classRoomId: state.id, studentIds: [...ids] });
		if (res.success && res.data) {
			await refetch();
			toast.info("Elève supprimé");
		}
	};

	useEffect(() => {
		if (state) {
			const currentClass = [...classRooms].find((item) => item.id === state.id);
			if (currentClass) setClassRoom(currentClass);
		}
	}, [state, classRooms]);

	if (!state) return <Navigate to="/classes" />;

	return (
		<div className="w-full flex flex-row justify-between items-start">
			<div className="w-full flex flex-col justify-start items-start mt-4 px-5">
				<div className="w-full flex justify-between items-center">
					<div className="flex flex-row justify-start items-center">
						<Link to="/classes" className="text-iwinblue font-bold text-left mr-1">
							{"Mes Classes > "}{" "}
						</Link>
						<Link to="/classes" className="text-iwinblue font-bold text-left mr-1">
							{"Créer une classe > "}{" "}
						</Link>
						<span className="text-iwinblue font-bold text-left text-[24px]">Elèves</span>
					</div>
					<div className="relative w-[300px] mt-3">
						<input
							type="text"
							placeholder="Trouver une ressource ..."
							className="bg-white rounded-[46px] border-white bottom-0 px-[50px] py-[10px]"
							style={{ border: "none", borderRadius: "40px" }}
						/>
						<GoSearch className="w-5 h-5 absolute top-[15px] left-[20px] text-asprimary" />
						<BiFilter className="w-7 h-7 absolute top-[10px] right-[15px] text-gray-700" />
					</div>
					<Profile />
				</div>
				<ScrollContainer>
					<div className="w-full flex flex-col justify-start items-center p-5 shadow-lg bg-white rounded-lg mt-5">
						<Row className="w-full">
							<CreateClassStudentForm handleSubmit={addStudent} />

							<Col md={12} className="w-full mt-4">
								<ul className="w-full">
									{classRoom.students.map((student, index) => (
										<li
											key={`${student.firstName}-${student.lastName}`}
											className="w-full shadow-md rounded-md px-4 py-1 flex flex-row justify-between items-center mb-3 bg-gray-100"
										>
											<div>
												<div
													className="inline-flex overflow-hidden relative justify-center items-center w-9 h-9 rounded-full "
													style={{ backgroundColor: randomColor() }}
												>
													<span className="text-white  uppercase font-black">
														{getFistLetter(student.firstName)}
														{getFistLetter(student.lastName)}
													</span>
												</div>
												<span className="ml-3 text-sm">
													{student.firstName} {student.lastName}
												</span>
											</div>
											<DeleteStudentModal
												onDelete={() => removeStudent(student.id)}
												name={student.firstName}
											/>
										</li>
									))}
								</ul>
							</Col>
							<Col className="w-full flex flex-row justify-end items-center mt-5" md={12}>
								<Link
									to={`/classes/${classRoom.id}`}
									className="px-5 py-2 text-sm rounded-md bg-iwinyellow text-white mx-4"
								>
									Voir la classe
								</Link>

								<Link
									to="/classes"
									className="px-5 py-2 text-sm rounded-md bg-iwincolor3 text-white mx-4"
								>
									Terminer
								</Link>
							</Col>
						</Row>
					</div>
				</ScrollContainer>
			</div>
		</div>
	);
}

function DeleteStudentModal({ onDelete, name }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<>
			<BiTrash onClick={onOpen} className="text-red-500 w-5 h-5 cursor-pointer" />

			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader fontSize="md">Gestion des élèves</ModalHeader>
					<ModalCloseButton />
					<ModalBody>Voulez vous supprimer {name} ?</ModalBody>

					<ModalFooter>
						<Button variant="solid" bg="#FDC928" mr={3} onClick={onClose}>
							Non
						</Button>
						<Button variant='solid' bg="#009688" onClick={onDelete}>
							Oui
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
