import React from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";

import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import {
	Button,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from "@chakra-ui/react";
import Icon2 from "../../assets/images/icon-2.png";
import Icon3 from "../../assets/images/icon-3.png";
import { deleteClassRoom } from "../../helpers/backend_helper";
import { useClassRooms } from "../../hooks/useClassRooms";

export default function ClassCard({ classroom }) {
	const navigate = useNavigate();
	const { refetch } = useClassRooms();

	const handleDelete = async () => {
		const res = await deleteClassRoom(classroom.id);
		if (res.success) {
			await refetch();
		}
	};

	const toEdit = () => navigate(`/classes/${classroom.id}`);

	return (
		<div className='w-full flex flex-col justify-center items-center rounded-xl bg-white shadow-md mb-4'>
			<div className='w-full h-36 rounded-xl bg-image-card relative'>
				{classroom.imagePath ? (
					<Image
						src={classroom.imagePath}
						alt="photo"
						className='w-full h-full rounded-t-xl object-cover'
					/>
				) : (
					<div className="w-full h-full rounded-t-xl" />
				)}

				<div className='w-full h-full rounded-xl absolute top-0 bg-black/[.3] flex flex-col justify-center items-center'>
					<div className="absolute top-2 right-2 w-6 h-6 cursor-pointer z-20">
						<Menu>
							<MenuButton>
								<BiDotsHorizontalRounded className="text-white" />
							</MenuButton>
							<MenuList>
								<MenuItem as={Link} to={`/edit-class/${classroom.id}`}>
									Editer
								</MenuItem>
								<DeleteClassModal onDelete={handleDelete} name={classroom.name} />
							</MenuList>
						</Menu>
					</div>
				</div>
			</div>
			{/* rome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
			<div
				onClick={toEdit}
				className='w-full flex flex-col justify-center items-start cursor-pointer'
			>
				<p className=" w-full font-bold text-center text-notiftext normal-case mt-2">
					{classroom.name}
				</p>

				<div className='w-full flex justify-between items-center px-7 mt-3 pb-2'>
					<div className='flex flex-col items-center justify-center'>
						<img src={Icon3} alt="" className='w-4 h-4 mb-1' />
						<p className='w-full text-center text-[9px] text-gray-400 font-medium uppercase'>
							Elèves
						</p>
						<p className='text-[12px] font-bold text-black/[.8]'>
							{classroom.students.length < 10
								? `0${classroom.students.length}`
								: classroom.students.length}
						</p>
					</div>

					<div className='flex flex-col items-center justify-center'>
						<img src={Icon2} alt="" className='w-3 h-3 mb-1' />
						<p className='w-full text-center text-[9px] text-gray-400 font-medium uppercase'>
							Activités
						</p>
						<p className='text-[12px] font-bold text-black/[.8]'>00</p>
					</div>
				</div>
			</div>
		</div>
	);
}

function DeleteClassModal({ onDelete, name }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<>
			<MenuItem onClick={onOpen}>Supprimer</MenuItem>

			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader fontSize="md">Gestion des classes</ModalHeader>
					<ModalCloseButton />
					<ModalBody>Voulez vous supprimer la classe {name} ?</ModalBody>

					<ModalFooter>
						<Button variant='solid' bg="#009688" mr={3} onClick={onDelete}>
							Oui
						</Button>
						<Button variant="solid" bg="#FDC928" onClick={onClose}>
							Non
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
