import { configureStore } from "@reduxjs/toolkit";

import subjectSlice from "./subject/subject.slice";
import levelSlice from "./level/level.slice";
import familySlice from "./family/family.slice";
import groupSlice from "./groups/group.slice";
import classroomSlice from "./classroom/classroom.slice";
import registerTeacherSlice from "./auth/register/teacher.slice";
import registerParentSlice from "./auth/register/parent.slice";
import registerStudentSlice from "./auth/register/student.slice";
import verifyEmailSlice from "./auth/register/verify-email.slice";
import authSlice from "./auth/login/login.slice";
import moduleSlice from "./modules/modules.slice";
import countrySlice from "./country/country.slice";
import dashboardSlice from "./dashboard/dashboard.slice";

export const store = configureStore({
	reducer: {
		subjectsState: subjectSlice,
		levelsState: levelSlice,
		registerParentState: registerParentSlice,
		registerStudentState: registerStudentSlice,
		registerTeacherState: registerTeacherSlice,
		verifyEmailState: verifyEmailSlice,
		classroomState: classroomSlice,
		authState: authSlice,
		moduleState: moduleSlice,
		dashboardState: dashboardSlice,
		countryState: countrySlice,
		familyState: familySlice,
		groupState: groupSlice,
	},
});
