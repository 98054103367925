import React, { useState, useEffect } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { TiPlus } from "react-icons/ti";
import { FaRegTrashAlt } from "react-icons/fa";
import { RiFileCopyLine } from "react-icons/ri";
import randomColor from "randomcolor";
import { BiImageAdd } from "react-icons/bi";
import { FiVolume1 } from "react-icons/fi";

import TIcon1 from "../../../assets/images/icons/TextT1.png";
import TIcon from "../../../assets/images/icons/TextT.png";
import MediaIcon1 from "../../../assets/images/icons/MediaIcon1.png";
import MediaIcon from "../../../assets/images/icons/MediaIcon.png";

import ImgIcon from "../../../assets/images/upload/ImgIcon.png";
import VideoIcon from "../../../assets/images/upload/VideoIcon.png";
import AudioIcon from "../../../assets/images/upload/AudioIcon.png";

const Index = ({
	paire,
	index,
	handleChangePaireOneText,
	handleChangePaireTwoText,
	deletePaire,
	actionEtape,
	setActionEtape,
	handleCopyPaire,
	activePaire,
	setActivePaire,
	handleChangeInputTypePaireOne,
	handleChangeInputTypePaireTwo,
	handlePaireOnePhoto,
	handlePaireTwoPhoto,
}) => {
	useEffect(() => {}, []);

	return (
		<div
			className={`w-full relative rounded-lg flex flex-col justify-center items-center px-16 py-2 mb-3 ${
				activePaire === index ? "bg-amber-50/[0.5] border border-slate-500/[0.3]" : ""
			}`}
		>
			<span className='flex flex-col justify-end items-center absolute top-2 right-3 gap-3'>
				<FaRegTrashAlt onClick={() => deletePaire(index)} className="w-4 h-4 cursor-pointer" />
				<RiFileCopyLine onClick={() => handleCopyPaire(index)} className="w-5 h-5 cursor-pointer" />
			</span>
			<div
				onClick={() => setActivePaire(index)}
				className='w-full flex justify-between items-center gap-4'
			>
				<div className='w-full flex justify-start items-start h-[18vh] border rounded-md bg-white border border-iwingreen/[.7]'>
					<div className='w-11 flex flex-col justify-start items-center gap-2 px-2 py-3'>
						<img
							onClick={() => handleChangeInputTypePaireOne("text", index)}
							src={paire.paireOne.type === "text" ? TIcon : TIcon1}
							className='cursor-pointer'
							alt=""
						/>
						<label
							htmlFor={`img-one-${index}`}
							onClick={() => handleChangeInputTypePaireOne("img", index)}
						>
							<img
								src={paire.paireOne.type === "img" ? MediaIcon : MediaIcon1}
								className='cursor-pointer'
								alt=""
							/>
							<input
								accept='image/*'
								onChange={(e) => handlePaireOnePhoto(e, index)}
								id={`img-one-${index}`}
								type="file"
								className="hidden"
							/>
						</label>
					</div>
					<div className='w-full h-full min-h-full max-h-full '>
						{paire.paireOne.type === "text" && (
							<textarea
								id={`paire-one-${index}`}
								required
								name={`paire-one-${index}`}
								className='w-full border  rounded-r-md border-white bg-white p-2 focus:outline-none text-sm max-h-full h-full min-h-full'
								value={paire.paireOne.text}
								placeholder="Saisir le texte..."
								onChange={(e) => handleChangePaireOneText(e, index)}
							/>
						)}

						{paire.paireOne.type === "img" && (
							<div className='w-full max-h-full h-full min-h-full'>
								{paire.paireOne.img === "" && (
									<div className='w-full h-full flex flex-col justify-center items-center -gap-2'>
										<div className='flex justify-center items-center mb-4'>
											<img src={ImgIcon} className="w-6 -mb-2 -mr-2" alt="" />
											<img src={VideoIcon} className="w-5 -mt-5" alt="" />
											<img src={AudioIcon} className="w-7 -mb-2 -ml-2" alt="" />
										</div>
										<p className='text-xs text-gray-600'>Sélectionner ou déposer un fichier</p>
									</div>
								)}

								{paire.paireOne.img !== "" && (
									<div className='h-full w-full rounded-md'>
										<label
											htmlFor={`photo-1-${index}`}
											className='w-full min-w-full h-full flex justify-center items-center min-h-full cursor-pointer rounded-md'
										>
											<img
												src={URL.createObjectURL(paire.paireOne.img)}
												className="h-full rounded-md"
												alt=""
											/>
											<input
												accept='image/*'
												id={`photo-1-${index}`}
												onChange={(e) => handlePaireOnePhoto(e, index)}
												name={`photo-1-${index}`}
												type="file"
												className="hidden"
											/>
										</label>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
				<div className='w-full flex justify-start items-start h-[18vh] border rounded-md bg-white border border-iwingreen/[.7]'>
					<div className='w-11 flex flex-col justify-start items-center gap-2 px-2 py-3'>
						<img
							onClick={() => handleChangeInputTypePaireTwo("text", index)}
							src={paire.paireTwo.type === "text" ? TIcon : TIcon1}
							className='cursor-pointer'
							alt=""
						/>
						<label
							htmlFor={`img-two-${index}`}
							onClick={() => handleChangeInputTypePaireTwo("img", index)}
						>
							<img
								src={paire.paireTwo.type === "img" ? MediaIcon : MediaIcon1}
								className='cursor-pointer'
								alt=""
							/>
							<input
								accept='image/*'
								onChange={(e) => handlePaireTwoPhoto(e, index)}
								id={`img-two-${index}`}
								type="file"
								className="hidden"
							/>
						</label>
					</div>
					<div className='w-full h-full min-h-full max-h-full '>
						{paire.paireTwo.type === "text" && (
							<textarea
								id={`paire-two-${index}`}
								required
								name={`paire-two-${index}`}
								className='w-full border  rounded-r-md border-white bg-white p-2 focus:outline-none text-sm max-h-full h-full min-h-full'
								value={paire.paireTwo.text}
								placeholder="Saisir le texte..."
								onChange={(e) => handleChangePaireTwoText(e, index)}
							/>
						)}
						{paire.paireTwo.type === "img" && (
							<div className='w-full max-h-full h-full min-h-full'>
								{paire.paireTwo.img === "" && (
									<div className='w-full h-full flex flex-col justify-center items-center -gap-2'>
										<div className='flex justify-center items-center mb-4'>
											<img src={ImgIcon} className="w-6 -mb-2 -mr-2" alt="" />
											<img src={VideoIcon} className="w-5 -mt-5" alt="" />
											<img src={AudioIcon} className="w-7 -mb-2 -ml-2" alt="" />
										</div>
										<p className='text-xs text-gray-600'>Sélectionner ou déposer un fichier</p>
									</div>
								)}
								{paire.paireTwo.img !== "" && (
									<div className='h-full w-full rounded-md'>
										<label
											htmlFor={`photo-2-${index}`}
											className='w-full min-w-full h-full min-h-full flex justify-center items-center cursor-pointer rounded-md'
										>
											<img
												src={URL.createObjectURL(paire.paireTwo.img)}
												className="h-full rounded-md"
												alt=""
											/>
											<input
												accept='image/*'
												id={`photo-2-${index}`}
												onChange={(e) => handlePaireTwoPhoto(e, index)}
												name={`photo-2-${index}`}
												type="file"
												className="hidden"
											/>
										</label>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Index;
