import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLevels } from "../../helpers/backend_helper";

export const getLevelsAction = createAsyncThunk(
	"levels/getLevelsAction",
	async (id = null, { rejectWithValue }) => {
		try {
			const response = await getLevels();
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);
