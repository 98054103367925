import React from "react";

import SideBarLeft from "../sideBarLeft/student";
const Index = ({ children }) => {
	return (
		<div className="w-full flex flex-row justify-start items-start">
			<SideBarLeft />
			<main className='w-full'>{children}</main>
		</div>
	);
};

export default Index;
