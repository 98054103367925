import { createSlice } from "@reduxjs/toolkit";
import { getModulesAction } from "./actions";

const initialState = {
	getModulesStatus: "",
	getModulesError: "",
	modules: [],
};

const moduleSlice = createSlice({
	name: "modules",
	initialState,
	reducers: {},
	extraReducers: {
		[getModulesAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				getModulesStatus: "pending",
				getModulesError: "",
			};
		},
		[getModulesAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				modules: payload,
				getModulesStatus: "success",
				getModulesError: "",
			};
		},
		[getModulesAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				getModulesStatus: "rejected",
				getModulesError: payload,
			};
		},
	},
});

export default moduleSlice.reducer;
