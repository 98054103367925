function getEnvVar(varname) {
	const variable = process.env[varname];
	if (!variable) throw new Error(`Missing local variable ${varname}`);
	return variable;
}

export const API_URL = getEnvVar("REACT_APP_API_URL");

export function generateUUID() {
	let dt = new Date().getTime();
	const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		const r = ((dt + Math.random() * 16) % 16) | 0;
		dt = Math.floor(dt / 16);
		return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
	});
	return uuid;
}
