import { useFormik } from "formik";
import { Divider } from "primereact/divider";
import randomColor from "randomcolor";
import React, { useEffect, useState } from "react";
import { BsEye } from "react-icons/bs";
import { MdOutlineModeEdit } from "react-icons/md";
import { TiPlus } from "react-icons/ti";
import * as Yup from "yup";

import Select from "react-select";

import { getSubThemesByTheme } from "../../../helpers/backend_helper";

import { InputTextarea } from "primereact/inputtextarea";

import QuestionComponent from "../components/question";

const Index = ({ themesList }) => {
	const [actionIndex, setActionIndex] = useState(0);
	const [typeReponses, setTypeReponses] = useState([]);
	const [tempsReponses, setTempsReponses] = useState([]);
	const [points, setPoints] = useState([]);
	const [subThemesList, setSubThemesList] = useState([]);
	const [activeQuestion, setActiveQuestion] = useState(0);
	const [options, setOptions] = useState({
		responseTime: { value: "", label: "" },
		responseType: { value: "Multiple", label: "Multiple" },
		point: { value: "", label: "" },
	});

	const [response, setResponse] = useState("");

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			title: "",
			theme: "",
			subtheme: "",
			feedback: "",
			questions: [],
		},
		validationSchema: Yup.object({
			title: Yup.string().required("Veuillez saisir le titre !"),
			theme: Yup.object().required("Veuillez sélectionner un thème !"),
			subtheme: Yup.object().required("Veuillez sélectionner un sous thème !"),
			questions: Yup.array().of(Yup.object()).required("Veuillez ajouter au moins une question !"),
		}),
		onSubmit: (values) => {},
	});

	const customStyles = {
		control: (base) => ({
			...base,
			border: "1px solid rgba(50, 202,188, 0.47)",
			background: "rgba(50, 202,188, 0.02)",
			color: "#303972",
			paddingBottom: "1px",
			paddingTop: "1px",
			height: "40px",
			boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
			filter: "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
			borderRadius: "0.375rem",
			"&:hover": {
				border: "1px solid rgba(50, 202,188, 0.47)",
			},
		}),
		option: (provided, state) => ({
			...provided,
			fontSize: "12px",
		}),
		placeholder: (defaultStyles) => {
			return {
				...defaultStyles,
				color: "#303972",
				fontSize: "12px",
				padding: "1px",
			};
		},
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = "opacity 300ms";
			const fontSize = "12px";
			const color = "#303972";
			return { ...provided, color, fontSize, opacity, transition };
		},
		input: (styles, { data }) => ({
			...styles,
			outline: "none",
			border: "none",
			focus: "none",
			color: "#303972",
			fontSize: "12px",
		}),
		indicatorSeparator: (base) => ({
			...base,
			display: "none",
		}),
		dropdownIndicator: (base) => ({
			...base,
			color: "#303972",
			"&:hover": {
				color: "#303972",
			},
		}),
		menuList: (base) => ({
			...base,
			"::-webkit-scrollbar": {
				width: "4px",
				height: "0px",
				color: "#303972",
			},
			"::-webkit-scrollbar-track": {
				background: "#f1f1f1",
			},
			"::-webkit-scrollbar-thumb": {
				background: "#888",
			},
			"::-webkit-scrollbar-thumb:hover": {
				background: "#555",
			},
		}),
	};

	const handleChangeTheme = (e) => {
		validation.setFieldValue("subtheme", { value: "", label: "" });
		validation.setFieldValue("theme", e);
		if (e.value !== validation.values.theme) {
			getSubThemesByTheme(e.value).then((data) => {
				setSubThemesList(data.data);
			});
		}
	};

	const handleChangeSubTheme = (e) => {
		validation.setFieldValue("subtheme", e);
	};

	const handleQuestion = () => {
		let color = randomColor();
		let qs = {
			img: "",
			question: "",
			responses: [{ response: "", isCorrect: false, color }],
			options: {
				responseTime: { value: "", label: "" },
				responseType: { value: "", label: "" },
				point: { value: "", label: "" },
			},
		};
		let qts = validation.values.questions;
		validation.setFieldValue("questions", [...qts, qs]);
		setActiveQuestion(validation.values.questions.length);
	};

	const handlePhoto = (event, index) => {
		setActiveQuestion(index);
		const file = event.target.files[0];
		let data = [...validation.values.questions];
		data[activeQuestion].img = file;
		validation.setFieldValue("questions", data);
	};

	const handleAddResponse = (index) => {
		let data = [...validation.values.questions];
		let color = randomColor();
		data[index].responses = [...data[index].responses, { response: "", isCorrect: false, color }];
		validation.setFieldValue("questions", data);
	};
	const handleRemoveQuestion = (index) => {
		if (validation.values.questions.length > 1) {
			let data = validation.values.questions.filter((q, i) => i !== index);
			validation.setFieldValue("questions", data);
		}
	};

	const handleQuestionText = (event, index) => {
		let data = [...validation.values.questions];
		data[index].question = event.target.value;
		validation.setFieldValue("questions", data);
	};

	const handleChangeResponseText = (event, questionIndex, responseIndex) => {
		let data = validation.values.questions.map((ques, i) => {
			if (i === questionIndex) {
				let ress = ques.responses.map((res, index) => {
					if (index === responseIndex) {
						res = { ...res, response: event.target.value };
					}
					return res;
				});
				ques = { ...ques, responses: ress };
			}
			return ques;
		});
		validation.setFieldValue("questions", data);
	};

	const handleChangeResponseSwitch = (event, questionIndex, responseIndex) => {
		let data = validation.values.questions.map((ques, i) => {
			if (i === questionIndex) {
				let ress = ques.responses.map((res, index) => {
					if (index === responseIndex) {
						res = { ...res, isCorrect: !res.isCorrect };
					}
					return res;
				});
				ques = { ...ques, responses: ress };
			}
			return ques;
		});
		validation.setFieldValue("questions", data);
	};

	const handleResponseTime = (e) => {
		setOptions({ ...options, responseTime: e });
		let data = [...validation.values.questions];
		let opts = data[activeQuestion].options;
		opts = { ...opts, responseTime: e };
		data[activeQuestion].options = opts;
		validation.setFieldValue("questions", data);
	};
	const handleResponseType = (e) => {
		setOptions({ ...options, responseType: e });
		let data = [...validation.values.questions];
		let opts = data[activeQuestion].options;
		opts = { ...opts, responseType: e };
		data[activeQuestion].options = opts;
		validation.setFieldValue("questions", data);
	};
	const handlePoints = (e) => {
		setOptions({ ...options, point: e });
		let data = [...validation.values.questions];
		let opts = data[activeQuestion].options;
		opts = { ...opts, point: e };
		data[activeQuestion].options = opts;
		validation.setFieldValue("questions", data);
	};

	// useEffect(() => {
	// 	getPointReponses().then((data) => setPoints(data.data));

	// 	getTimeReponses().then((data) => setTempsReponses(data.data));

	// 	getTypeReponses().then((data) => setTypeReponses(data.data));

	// 	let color = randomColor();

	// 	validation.setFieldValue("questions", [
	// 		{
	// 			img: "",
	// 			question: "",
	// 			responses: [{ response: "", isCorrect: false, color }],
	// 			options: {
	// 				responseTime: { value: "", label: "" },
	// 				responseType: { value: "", label: "" },
	// 				point: { value: "", label: "" },
	// 			},
	// 		},
	// 	]);

	// 	setActiveQuestion(0);
	// }, []);

	useEffect(() => {
		let ops = validation.values.questions[activeQuestion]?.options || {
			responseTime: { value: "", label: "" },
			responseType: { value: "", label: "" },
			point: { value: "", label: "" },
		};
		setOptions(ops);
	}, [activeQuestion]);

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				validation.handleSubmit();
				return false;
			}}
			className='w-full'
		>
			<div className='w-full flex flex-col justify-start items-center'>
				<div className='w-full flex justify-between items-center'>
					<div className='flex justify items-center'>
						<div className='w-56 flex flex-col justify-start items-start gap-1'>
							<label className=''>Titre</label>
							<input
								onChange={validation.handleChange}
								onBlur={validation.handleBlur}
								value={validation.values.title}
								invalid={validation.touched.title && validation.errors.title}
								type="text"
								id="title"
								name="title"
								placeholder="Saisir le titre de l'activité"
								className='text-[12px] w-20 px-2 py-2 border border-iwingreen/[.4] rounded-lg'
							/>
							{validation.touched.title && validation.errors.title ? (
								<small className="text-[9px] text-red-400">{validation.errors.title}</small>
							) : null}
						</div>
						<div className='w-36 ml-2 flex flex-col justify-start items-start gap-1'>
							<label className=''>Thème</label>
							<Select
								id="theme"
								name="theme"
								styles={customStyles}
								options={themesList}
								placeholder="---"
								className="w-full"
								classNamePrefix="select"
								onChange={(e) => {
									handleChangeTheme(e);
								}}
								value={validation.values.theme}
								invalid={validation.touched.theme && validation.errors.theme}
							/>
							{validation.touched.theme && validation.errors.theme ? (
								<small className="text-[9px] text-red-400">{validation.errors.theme}</small>
							) : null}
						</div>
						<div className='w-36 ml-2 flex flex-col justify-start items-start gap-1'>
							<label className=''>Sous-thème</label>
							<Select
								id="mode"
								name="mode"
								styles={customStyles}
								options={subThemesList}
								placeholder="---"
								className="w-full"
								classNamePrefix="select"
								onChange={(e) => {
									handleChangeSubTheme(e);
								}}
								value={validation.values.subtheme}
								invalid={validation.touched.subtheme && validation.errors.subtheme}
							/>
							{validation.touched.subtheme && validation.errors.subtheme ? (
								<small className="text-[9px] text-red-400">{validation.errors.subtheme}</small>
							) : null}
						</div>
						<div className='w-32 ml-2 flex flex-col justify-start items-start gap-1'>
							<label className=''>Arrière-plan</label>
							<div className='w-[30px] px-5 py-[6px] shadow-in border border-gray-300 bg-gray-400 rounded-lg cursor-pointer'>
								<input type="color" className='w-full h-full invisible' />
							</div>
						</div>
					</div>
					<div className='flex justify-end items-center gap-10 pt-3'>
						<div className='p-1 rounded-3xl border border-gray-300 bg-gray-200 flex justify-between items-center gap-4'>
							<div
								className={`flex justify-center items-center p-1  rounded-full cursor-pointer ${
									actionIndex === 0 ? "bg-iwingreen text-white" : "text-gray-400"
								}`}
								onClick={() => setActionIndex(0)}
							>
								<MdOutlineModeEdit className='w-4 h-4' />
							</div>
							<div
								className={`flex justify-center items-center p-1 rounded-full cursor-pointer ${
									actionIndex === 1 ? "bg-iwingreen text-white" : "text-gray-400"
								}`}
								onClick={() => setActionIndex(1)}
							>
								<BsEye className='w-4 h-4' />
							</div>
						</div>
						<button
							type='submit'
							className='px-10 py-3 text-sm font-semibold bg-gray-500 rounded-lg text-white'
						>
							Créer
						</button>
					</div>
				</div>
				<div className={"w-full bg-gray-100 rounded-lg px-3 py-2} mt-3"}>
					<div className="w-full flex justify-start items-center gap-3">
						<div className='w-[22%] h-[62vh] flex flex-col justify-between items-center gap-3 px-0 py-1 border-r-2 border-gray-300 pr-4 '>
							<div className='w-full flex flex-col justify-between items-start gap-2'>
								<div className='w-full flex flex-col justify-between items-start gap-1'>
									<label className='text-xs font-medium'>Temps de réponse</label>
									<Select
										id="responseTime"
										name="responseTime"
										styles={customStyles}
										options={tempsReponses}
										className="w-full"
										classNamePrefix="select"
										onChange={(e) => handleResponseTime(e)}
										value={options.responseTime}
									/>
								</div>
								<div className='w-full flex flex-col justify-between items-start gap-1'>
									<label className='text-xs font-medium'>Points</label>
									<Select
										id="point"
										name="point"
										styles={customStyles}
										options={points}
										className="w-full"
										classNamePrefix="select"
										onChange={(e) => handlePoints(e)}
										value={options.point}
									/>
								</div>
								<div className='w-full flex flex-col justify-between items-start gap-1'>
									<label className='text-xs font-medium'>Type de réponse </label>
									<Select
										id="responseType"
										name="responseType"
										styles={customStyles}
										options={typeReponses}
										className="w-full"
										classNamePrefix="select"
										onChange={(e) => handleResponseType(e)}
										value={options.responseType}
									/>
								</div>
							</div>
							<div className='w-full flex flex-col justify-between items-start gap-1'>
								<label className='text-xs font-medium'>Feedback final</label>
								<InputTextarea
									id="feedback"
									name="feedback"
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.feedback}
									className='w-full border border-iwingreen/[0.4] rounded-md bg-white p-2 focus:outline-none text-sm'
									rows={4}
									autoResize
								/>
							</div>
						</div>
						<div className='scroller2 w-full py-3 pl-2'>
							<div className='w-full flex flex-col justify-start items-center'>
								{validation.values.questions.map((input, index) => {
									return (
										<div className='w-full flex flex-col justify-center items-center'>
											<QuestionComponent
												index={index}
												activeQuestion={activeQuestion}
												setActiveQuestion={setActiveQuestion}
												question={input}
												handleChangeResponseText={handleChangeResponseText}
												handleChangeResponseSwitch={handleChangeResponseSwitch}
												removeQuestion={handleRemoveQuestion}
												handleQuestion={handleQuestionText}
												handleAddResponse={handleAddResponse}
												setOptions={setOptions}
												handlePhoto={handlePhoto}
											/>
											{index !== validation.values.questions.length - 1 && <Divider />}
										</div>
									);
								})}
							</div>
							<button
								type='button'
								onClick={handleQuestion}
								className='mt-3 mx-auto border border-gray-400 rounded-md bg-white text-center text-xs px-3 py-2 flex justify-between items-center gap-2'
							>
								<TiPlus className='w-4 h-4' />
								<span>Nouvelle question</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

export default Index;
