import { createSlice } from "@reduxjs/toolkit";
import { registerTeacherAction, registerCompletTeacherAction, editTeacherAction } from "./actions";

const initialState = {
	registerStatus: "",
	registerType: "",
	registerError: "",
};

const registerTeacherSlice = createSlice({
	name: "teachers",
	initialState,
	reducers: {},
	extraReducers: {
		[registerTeacherAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				registerStatus: "pending",
				registerType: "",
				registerError: "",
			};
		},
		[registerTeacherAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				registerStatus: "success",
				registerType: "register",
				registerError: "",
			};
		},
		[registerTeacherAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				registerStatus: "rejected",
				registerType: "register",
				registerError: payload,
			};
		},

		[editTeacherAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				registerStatus: "pending",
				registerError: "",
			};
		},
		[editTeacherAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				registerStatus: "success",
				registerType: "edit",
				registerError: "",
			};
		},
		[editTeacherAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				registerStatus: "rejected",
				registerType: "edit",
				registerError: payload,
			};
		},

		[registerCompletTeacherAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				registerStatus: "pending",
				registerType: "",
				registerError: "",
			};
		},
		[registerCompletTeacherAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				registerStatus: "success",
				registerType: "complet",
				registerError: "",
			};
		},
		[registerCompletTeacherAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				registerStatus: "rejected",
				registerType: "complet",
				registerError: payload,
			};
		},
	},
});

export default registerTeacherSlice.reducer;
