export const getUserRoles = () => {
	const data = localStorage.getItem("_iws_user");
	if (data) return JSON.parse(data).user.account.role;
	else return null;
};

export const getUser = () => {
	let data = localStorage.getItem("_iws_user");
	if (!data) return;
	data = JSON.parse(data);
	if (!data.user) throw new Error("Missing user data");
	return data.user;
};

export const getToken = () => {
	const data = localStorage.getItem("_iws_user");
	if (data) return `Bearer ${JSON.parse(data).accessToken}`;
	else return null;
};

export const getRefreshToken = () => {
	const data = localStorage.getItem("_iws_user");
	if (data) return JSON.parse(data).refreshToken;
	else return null;
};

export const removeUserSession = () => {
	localStorage.removeItem("_iws_user");
};

export const setUserSession = (data) => {
	localStorage.setItem("_iws_user", JSON.stringify(data));
};

export const updateUserSession = (data) => {
	const user = getUser();
	const updatedUser = {
		...user,
		firstName: data.firstName,
		lastName: data.lastName,
		phone: data.phone,
		city: data.city,
		country: data.country,
		account: data.account,
	};
	let userSession = JSON.parse(localStorage.getItem("_iws_user"));
	userSession = { ...userSession, user: updatedUser };
	setUserSession(userSession);
};

export const updateAccessToken = (token) => {
	let userSession = JSON.parse(localStorage.getItem("_iws_user"));
	userSession = { ...userSession, accessToken: token };
	setUserSession(userSession);
};

const getLoggedInUser = () => {
	const user = localStorage.getItem("_iws_user");
	if (user) return JSON.parse(user);
	return null;
};

//is user is logged in
export const isUserAuthenticated = () => {
	return getLoggedInUser() !== null;
};

export const getTwoFirstLetter = (str) => str.substring(0, 2).toUpperCase();
export const getFistLetter = (str) => str.substring(0, 1).toUpperCase();
