import { createSlice } from "@reduxjs/toolkit";
import { getGroupsByClassAction, addGroupAction, getGroupAction } from "./actions";

const initialState = {
	groupStatus: "",
	groupType: "",
	groupError: "",
	groups: [],
	group: {},
};

const groupSlice = createSlice({
	name: "group",
	initialState,
	reducers: {},
	extraReducers: {
		[getGroupsByClassAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				groupStatus: "pending",
				groupType: "",
				groupError: "",
				groups: [],
				group: {},
			};
		},
		[getGroupsByClassAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				groups: payload,
				groupStatus: "success",
				groupType: "get",
				groupError: "",
				group: {},
			};
		},
		[getGroupsByClassAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				groupStatus: "rejected",
				groupType: "get",
				groupError: payload,
				groups: [],
				group: {},
			};
		},

		[getGroupAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				groupStatus: "pending",
				groupType: "",
				groupError: "",
				group: {},
			};
		},
		[getGroupAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				groupStatus: "success",
				groupType: "getOne",
				groupError: "",
				group: payload,
			};
		},
		[getGroupAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				groupStatus: "rejected",
				groupType: "getOne",
				groupError: payload,
				group: {},
			};
		},

		[addGroupAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				groupStatus: "pending",
				groupType: "",
				groupError: "",
				group: {},
			};
		},
		[addGroupAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				groupStatus: "success",
				groupType: "add",
				groupError: "",
				group: {},
			};
		},
		[addGroupAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				groupStatus: "rejected",
				groupType: "add",
				groupError: payload,
				group: {},
			};
		},
	},
});

export default groupSlice.reducer;
