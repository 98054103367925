import React from "react";
import { BiFilter } from "react-icons/bi";
import { GoSearch } from "react-icons/go";

// import components
import { ScrollContainer } from "../../components/common";
import Profile from "../../components/common/Profile";

const Index = () => {
	return (
		<div className='w-full flex flex-col justify-start items-center'>
			<div className="w-full flex justify-between items-center">
				<h1 className='text-iwinblue font-bold text-left mt-3 text-[24px] mx-5'>Contact</h1>
				<div className="flex justify-end items-center">
					<div className="relative w-[300px] mt-3 mr-2">
						<input
							type="text"
							placeholder='Trouver une ressource ...'
							className="bg-white rounded-[46px] border-white bottom-0 px-[50px] py-[10px]"
							style={{ border: "none", borderRadius: "40px" }}
						/>
						<GoSearch className="w-5 h-5 absolute top-[15px] left-[20px] text-asprimary" />
						<BiFilter className="w-7 h-7 absolute top-[10px] right-[15px] text-gray-700" />
					</div>
					<div className="w-[267px] bg-white px-7 pt-5 rounded-b-md">
						<Profile />
						<div className='border-b border-gray-200 -mt-1' />
					</div>
				</div>
			</div>
			<ScrollContainer className=''>
				<div className='w-full grid grid-cols-3 gap-2' />
			</ScrollContainer>
		</div>
	);
};

export default Index;
