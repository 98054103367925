import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCountries } from "../../helpers/backend_helper";

export const getCountriesAction = createAsyncThunk(
	"countries/getCountriesAction",
	async (id = null, { rejectWithValue }) => {
		try {
			const response = await getCountries();
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);
