import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { Link } from "react-router-dom";
export default function NotFoundPage() {
	return (
		<div className="w-full h-screen flex items-center justify-center flex-col gap-3">
			<h1 className="text-9xl">404</h1>
			<p>Page introuvable</p>
			<Link to="/" className="Button primary flex gap-2 items-center">
				<MdOutlineKeyboardBackspace />
				Retour à la page d'accueil
			</Link>
		</div>
	);
}
