import React, { useState, useEffect } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { TiPlus } from "react-icons/ti";
import { FaRegTrashAlt } from "react-icons/fa";
import { RiFileCopyLine } from "react-icons/ri";
import randomColor from "randomcolor";
import { BiImageAdd } from "react-icons/bi";
import { FiVolume1 } from "react-icons/fi";

import TIcon1 from "../../../assets/images/icons/TextT1.png";
import TIcon from "../../../assets/images/icons/TextT.png";
import MediaIcon1 from "../../../assets/images/icons/MediaIcon1.png";
import MediaIcon from "../../../assets/images/icons/MediaIcon.png";

const Index = ({ deleteCarte, setActiveMot, activeMot, index, mot, handleChangeMotText }) => {
	return (
		<div
			key={index}
			className={`w-full relative rounded-lg flex flex-col justify-center items-center border border-gray-100 px-10 py-2 mb-1 ${
				activeMot === index ? "bg-amber-50/[0.5] border-slate-500/[0.3]" : ""
			}`}
		>
			<span className='flex flex-col justify-end items-center absolute top-2 right-3 gap-3'>
				<FaRegTrashAlt onClick={() => deleteCarte(index)} className="w-4 h-4 cursor-pointer" />
			</span>
			<div
				onClick={() => setActiveMot(index)}
				className='w-full border border-iwingreen/[0.6] px-2 flex justify-start items-center border rounded-md bg-white'
			>
				<img src={TIcon} className='w-6 cursor-pointer' alt="" />
				<input
					type="text"
					className='w-full p-2'
					name="mot"
					id={`mot-${index}`}
					value={mot.mot}
					onChange={(e) => handleChangeMotText(e, index)}
					style={{ border: "0px solid white", filter: "none", boxShadow: "none" }}
				/>
			</div>
		</div>
	);
};

export default Index;
