import {
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { Tooltip } from "primereact/tooltip";
import { useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { TiPencil } from "react-icons/ti";
import CameraIcon from "../../assets/images/iconCamera.png";
import profilPlaceHolder from "../../assets/images/user-p2.png";
import { getFistLetter } from "../../helpers/common";
import ChangeClassRoom from "../ChangeClassRoom/ChangeClassRoom";
import DeleteStudent from "../DeleteStudent/DeleteStudent";
import { validationSchema } from "./yup-data";

export default function EditStudentProfil({
	student,
	classRoom,
	index,
	handleRemoveStudent,
	handleChangeStudentClassRoom,
}) {
	const { password, ...initialValues } = student;
	const [edit, setEdit] = useState(true);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const validation = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		onSubmit: (values) => {},
	});

	const toggleEdition = () => setEdit((v) => !v);

	const getEditImageProfile = () => {
		let src = profilPlaceHolder;
		if (validation.values.imagePath) {
			src =
				typeof validation.values.imagePath === "string"
					? validation.values.imagePath
					: URL.createObjectURL(validation.values.imagePath);
		}
		return <img src={src} alt="profil" className='w-64 h-64' />;
	};

	return (
		<>
			<td className='flex justify-start items-center gap-2 text-sm text-iwinblue font-bold py-3'>
				<div className="inline-flex overflow-hidden relative justify-center items-center w-10 h-10 border-2 border-iwingreen bg-gray-100 rounded-full dark:bg-gray-600">
					<span className="font-black text-gray-600 dark:text-gray-300">
						{getFistLetter(student.firstName)} {getFistLetter(student.lastName)}
					</span>
				</div>
				<button className='capitalize cursor-pointer' onClick={onOpen}>
					{student.firstName} {student.lastName}
				</button>
			</td>

			<td className='py-3'>
				<div className='text-iwinblue font-bold text-sm flex justify-start items-center gap-2'>
					<span>00/100</span>
					<div className='w-[20px] px-4 py-2 bg-iwingreen rounded-xl' />
				</div>
			</td>
			<td className='py-3 flex justify-end items-center relative'>
				<BiDotsVerticalRounded
					className={`w-6 h-6 text-gray-400 cursor-pointer action-${index}`}
					data-pr-position="left"
				/>
				<Tooltip tooltip="Teal" target={`.action-${index}`} autoHide={false}>
					<ul className=''>
						<li>
							<button className='text-white hover:text-gray-300' onClick={onOpen} type='button'>
								Modifier
							</button>
						</li>
						<ChangeClassRoom
							students={classRoom.students}
							student={student}
							handleChangeStudentClassRoom={handleChangeStudentClassRoom}
						/>
						<DeleteStudent student={student} handleRemoveStudent={handleRemoveStudent} />
					</ul>
				</Tooltip>
			</td>

			<Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<div className="w-full flex justify-between">
							<p className="text-iwingreen text-[24px]">Profil</p>
							<button
								onClick={toggleEdition}
								type="button"
								className="px-3 py-1 flex justify-between items-center gap-2 border-2 border-iwingreen text-iwingreen rounded-lg font-semibold"
							>
								Modifier
								<TiPencil className='w-5 h-5' />
							</button>
						</div>
					</ModalHeader>
					<ModalBody>
						<Row className='w-full p-3'>
							<Col
								xs={12}
								sm={12}
								md={6}
								className='flex flex-col justify-start items-start gap-3 mb-4'
							>
								<label>
									Photo <span className='text-red-500'>*</span>
								</label>
								<div className='flex justify-center items-center w-full  relative'>
									{getEditImageProfile()}
									<div className='w-full h-full rounded-md absolute top-0 bg-black/[.3] flex flex-col justify-start items-end'>
										<div className='mt-2 mr-2 py-1 px-2 border-dashed border-gray-700 border-2 rounded-md bg-gray-100/[.5]'>
											<label htmlFor='photo'>
												<img src={CameraIcon} alt="" className='w-9 h-9 cursor-pointer' />
												<input
													accept='image/*'
													readOnly={edit}
													disabled={edit}
													onChange={(e) =>
														validation.setFieldValue("imagePath", e.currentTarget.files[0])
													}
													onBlur={validation.handleBlur}
													id="photo"
													name="imagePath"
													type="file"
													className="hidden"
												/>
											</label>
										</div>
									</div>
								</div>
							</Col>
							<Col xs={12} sm={12} md={6}>
								<Row>
									<Col
										xs={12}
										sm={12}
										md={12}
										className='flex flex-col justify-start items-start gap-3 mb-4'
									>
										<label htmlFor="firstName">
											Prénom <span className='text-red-500'>*</span>
										</label>
										<input
											type="text"
											className="px-3 py-2"
											id="firstName"
											name="firstName"
											readOnly={edit}
											disabled={edit}
											onChange={validation.handleChange}
											onBlur={validation.handleBlur}
											value={validation.values.firstName}
											invalid={validation.touched.firstName && validation.errors.firstName}
										/>
										{validation.touched.firstName && validation.errors.firstName ? (
											<small className="text-[12px] text-red-400">
												{validation.errors.firstName}
											</small>
										) : null}
									</Col>
									<Col
										xs={12}
										sm={12}
										md={12}
										className='flex flex-col justify-start items-start gap-3 mb-4'
									>
										<label htmlFor="lastName">
											Nom <span className='text-red-500'>*</span>
										</label>
										<input
											type="text"
											className="px-3 py-2"
											id="lastName"
											name="lastName"
											readOnly={edit}
											disabled={edit}
											onChange={validation.handleChange}
											onBlur={validation.handleBlur}
											value={validation.values.lastName}
										/>
									</Col>
									<Col
										xs={12}
										sm={12}
										md={12}
										className='flex flex-col justify-start items-start gap-3 mb-4'
									>
										<label htmlFor="classroom">
											Classe <span className='text-red-500'>*</span>
										</label>
										<input
											type="text"
											className="px-3 py-2"
											id="classroom"
											name="classroom"
											value={classRoom?.name}
											readOnly={true}
											disabled={edit}
										/>
									</Col>
								</Row>
							</Col>
							<Col md={12}>
								<label htmlFor="password">
									Mot de passe <span className='text-red-500'>*</span>
								</label>
							</Col>
							<Col md={6} className="mb-5">
								<input
									type="password"
									className="px-3 py-2"
									id="password"
									name="password"
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.password}
								/>
							</Col>

							<Col md={6} className="">
								<button className='w-full border-2 border-iwingreen px-2 py-2 rounded-lg text-iwingreen'>
									Générer un nouveau mdp
								</button>
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter>
						<button
							type="submit"
							className="px-5 py-2 mr-3 text-sm rounded-md bg-iwincolor3 text-white"
						>
							Enregister
						</button>
						<button
							type="button"
							onClick={onClose}
							className="px-5 py-2 text-sm rounded-md bg-red-500 text-white"
						>
							Annuler
						</button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
