import { useFormik } from "formik";
import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { AiFillCaretLeft } from "react-icons/ai";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import SquareTopRight from "../../../assets/images/square-top-right.png";
import { setTeacherSchool } from "../../../helpers/backend_helper";
import { FormContainer } from "../../common/FormContainer";

const initialValues = {
	postalCode: "",
	scoolName: "",
};

const validationSchema = Yup.object({
	postalCode: Yup.string().required("Veuillez remplir le code postal !"),
	scoolName: Yup.string().required("Veuillez remplir le nom de l'établissement !"),
});

export default function EtablissementForm() {
	const navigate = useNavigate();
	const { state } = useLocation();

	const validation = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		onSubmit: async (values) => {
			try {
				await setTeacherSchool({ ...values, teacherId: state.id });
				navigate("/account-created", { state: { type: "Teacher" } });
			} catch (error) {
				console.error(error);
				toast.error(
					"Nous rencontrons un problème pour enregistrer les informations de l'établissement ",
				);
			}
		},
	});

	if (!state) return <Navigate to="/register/teacher" />;

	return (
		<>
			<div className="absolute z-10 top-5 flex justify-start items-center left-5">
				<Link
					to="/register/teacher-completed/skills"
					state={state}
					className="px-4 py-2 text-white text-sm rounded-md bg-iwingreen flex flex-row justify-start items-center"
				>
					<AiFillCaretLeft className="w-5 h-5 -ml-1" /> Retour
				</Link>
			</div>
			<FormContainer
				onSubmit={(e) => {
					e.preventDefault();
					validation.handleSubmit();
				}}
				className={`flex flex-col justify-center items-center relative z-30 mb-5 mt-3 ${
					validation.errors.length > 0 ? "pb-10" : ""
				}`}
			>
				<img src={SquareTopRight} className="absolute top-0 right-0 rounded-tr-[16px]" alt="" />
				<Row className="w-full">
					<Col
						xs={12}
						sm={12}
						md={12}
						className="flex flex-col justify-start items-start gap-1 mb-4"
					>
						<label htmlFor='lastName' className='text-[14px] text-iwinblue font-medium'>
							Code postal <span className='text-red-500'>*</span>
						</label>
						<input
							type="text"
							id='postalCode'
							name='postalCode'
							className='w-full text-[14px] drop-shadow-md outline-none border bg-inputbg/[0.02]  border-inputbg/[0.47] text-iwinblue rounded-md focus:border-inputbg  px-3 py-2'
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.postalCode}
							invalid={validation.touched.postalCode && validation.errors.postalCode}
						/>
						{validation.touched.postalCode && validation.errors.postalCode ? (
							<small className="text-[9px] text-red-400">{validation.errors.postalCode}</small>
						) : null}
					</Col>
					<Col
						xs={12}
						sm={12}
						md={12}
						className="flex flex-col justify-start items-start gap-1 mb-4"
					>
						<label htmlFor='firstName' className='text-[14px] text-iwinblue font-medium'>
							Nom de l'établissement <span className='text-red-500'>*</span>
						</label>
						<input
							type="text"
							id='scoolName'
							name='scoolName'
							className='w-full text-[14px] drop-shadow-md outline-none border bg-inputbg/[0.02] border-inputbg/[0.47]  text-iwinblue rounded-md focus:border-inputbg/[0.47] px-3 py-2'
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.scoolName}
							invalid={validation.touched.scoolName && validation.errors.scoolName}
						/>
						{validation.touched.scoolName && validation.errors.scoolName ? (
							<small className="text-[9px] text-red-400">{validation.errors.scoolName}</small>
						) : null}
					</Col>
					<Col lg={12} className='w-full flex justify-center items-center mt-3'>
						<button
							type="submit"
							className="text-sm bg-iwingreen px-5 py-2 text-white rounded-md font-medium"
						>
							Enregistrer
						</button>
					</Col>
				</Row>
			</FormContainer>
		</>
	);
}
