import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { toast } from "react-toastify";
import { generateUUID } from "../../context";
import { createActivity, createFolder, uploadActivityPdf } from "../../helpers/backend_helper";
import { getUser } from "../../helpers/common";
import { useActivities, useFolders } from "../../hooks/useActivities";
import Spinner from "../Spinner/Spinner";
import DocumentDetail from "./DocumentDetail/DocumentDetail";
import DocumentGallery from "./DocumentGallery/DocumentGallery";

export function ImportActivity({ isOpen, onClose }) {
	const { isOpen: isLoading, onOpen: startLoading, onClose: stopLoading } = useDisclosure();
	const { refresh: refreshActivities } = useActivities();
	const { refresh: refreshFolder } = useFolders();
	const [payload, setPayload] = useState({
		activityName: undefined,
		isGroupe: false,
		files: [],
		teacherId: undefined,
	});

	const [selectedActivity, setSelectedActivity] = useState();

	const onFileUploaded = (fileList) => {
		const activities = [];
		for (const file of fileList) {
			activities.push({
				id: generateUUID(),
				file,
				name: file.name,
				description: undefined,
				subThemeId: undefined,
				themeId: undefined,
			});
		}
		const files = [payload.files, activities].flat();
		setPayload({ ...payload, files });
		setSelectedActivity(files[files.length - 1]);
	};

	const onDocumentAction = (activity) => {
		if (activity.id === selectedActivity?.id) return setSelectedActivity(undefined);
		setSelectedActivity(activity);
	};

	const onRemoveDocument = (id) => {
		setSelectedActivity(undefined);
		const files = payload.files.filter((item) => item.id !== id);
		setPayload({ ...payload, files });
	};

	const commitActivityChange = (activity) => {
		const activities = [...payload.files];

		const index = activities.findIndex((item) => item.id === activity.id);

		if (index === -1) throw new Error(`can't find activity '${activity.id}'`);
		if (!selectedActivity) throw new Error("Missing selectedActivity");
		if (activities[index].id !== selectedActivity.id) throw new Error("Bad activity");
		activities[index] = activity;
		setSelectedActivity(activity);
		setPayload({ ...payload, files: activities });
	};

	const toggleGroupe = () => {
		if (!payload.isGroupe === false)
			return setPayload({ ...payload, activityName: undefined, isGroupe: !payload.isGroupe });

		setPayload({ ...payload, isGroupe: !payload.isGroupe });
	};

	const handleSubmit = async () => {
		const requestBody = { ...payload };
		requestBody.teacherId = getUser()?.id;

		for (const activity of requestBody.files) {
			if (!activity.name) {
				return toast.error(`Veuillez remplir le champs titre du document ${activity.file.name}`);
			}

			if (!activity.subThemeId) {
				return toast.error(
					`Veuillez remplir le champs thème et sous-theme du document ${activity.file.name}`,
				);
			}
		}

		if (payload.isGroupe && !payload.activityName) {
			return toast.error("Veuillez remplir le champs nom du dossier");
		}
		let folder = undefined
		if (requestBody.isGroupe) {
			const res = await createFolder({ name: requestBody.activityName, teacherId: requestBody.teacherId })
			if (res.success && res.data) folder = res.data
		}
		startLoading();
		for (let { file, ...item } of requestBody.files) {
			file = await uploadActivityPdf(file)
			item.fileId = file.id
			item.folderId = folder?.id
			item.status = 'imported'
			item.teacherId = requestBody.teacherId
			await createActivity(item)
		}
		await refreshActivities()
		await refreshFolder()
		stopLoading();
		setPayload({
			activityName: undefined,
			isGroupe: false,
			files: [],
			teacherId: undefined,
		});
		setSelectedActivity(undefined);
		onClose();
	};

	const setActivityName = (activityName) => {
		setPayload({ ...payload, activityName });
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered size="4xl">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<div className="flex p-2">
						<DocumentGallery
							selectedActivity={selectedActivity}
							activities={payload.files}
							onFileUploaded={onFileUploaded}
							onDocumentAction={onDocumentAction}
							onRemoveDocument={onRemoveDocument}
							toggleGroupe={toggleGroupe}
							isGroupe={payload.isGroupe}
							setActivityName={setActivityName}
							activityName={payload.activityName}
						/>
						{selectedActivity && (
							<DocumentDetail
								activity={selectedActivity}
								setActivity={setSelectedActivity}
								commitChange={commitActivityChange}
							/>
						)}
					</div>

					{isLoading ? (
						<Spinner />
					) : (
						<button className="Button primary" onClick={handleSubmit}>
							Enregistrer
						</button>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}



