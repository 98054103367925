import { createAsyncThunk } from "@reduxjs/toolkit";
import { getModules } from "../../helpers/backend_helper";

export const getModulesAction = createAsyncThunk(
	"modules/getModulesAction",
	async (id = null, { rejectWithValue }) => {
		try {
			const response = await getModules();
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);
