
import AddDocument from "../../ImportActivity/AddDocument/AddDocument";
import UpdatePdfCard from "../UpdatePdfCard/UpdatePdfCard";
import "./UpdateDocumentGallery.scss";

export default function UpdateDocumentGallery({
	activities,
	onFileUploaded,
	onRemoveDocument,
	onDocumentAction,
	selectedActivity,
	isGroupe,
	setActivityName,
	activityName }) {
	return (
		<div className="DocumentGallery">
			<h3 className="text-[24px] font-bold text-center text-iwincolor1 mb-2">
				{isGroupe ? `Modification du dossier ${activityName}` : `Modification de l'activité ${activityName} `}

			</h3>

			{isGroupe && (
				<label>
					Nom du dossier <span className="text-red-400">*</span> :
					<input
						type="text"
						className="Input mb-2"
						value={activityName ?? ""}
						onChange={(event) => setActivityName(event.target.value)}
					/>
				</label>
			)}

			<div className="flex flex-wrap gap-2 mb-2">
				{activities.map((activity) => (
					<UpdatePdfCard
						key={activity.id}
						selected={selectedActivity?.id === activity.id}
						file={activity}
						onDocumentAction={() => onDocumentAction(activity)}
						onRemoveDocument={() => onRemoveDocument(activity.id)}
						isGroupe={isGroupe}
						onFileUploaded={onFileUploaded}
					/>
				))}
				{isGroupe && <AddDocument onFileUploaded={onFileUploaded} />}

			</div>
		</div>
	);
}
