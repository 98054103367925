import { createAsyncThunk } from "@reduxjs/toolkit";
import { removeUserSession, setUserSession } from "../../../helpers//common";
import { changePassword, login } from "../../../helpers/backend_helper";

export const loginAction = createAsyncThunk(
	"auth/loginAction",
	async (user, { rejectWithValue }) => {
		try {
			const response = await login(user);
			console.log('response', response)
			if (response?.data) {
				setUserSession(response.data);
				return response.data;
			}
		} catch (e) {
			console.error("errore", e);
			return rejectWithValue(e?.response?.data);
		}
	},
);

export const changePasswordAction = createAsyncThunk(
	"auth/loginAction",
	async (data, { rejectWithValue }) => {
		try {
			const response = await changePassword(data);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	},
);

export const logoutAction = createAsyncThunk("auth/logoutAction", removeUserSession);
