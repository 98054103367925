import { createSlice } from "@reduxjs/toolkit";
import { changePasswordAction, loginAction, logoutAction } from "./actions";

const initialState = {
	loginStatus: "",
	logoutStatus: "",
	loginType: "",
	loginError: "",
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {},
	extraReducers: {
		[loginAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				loginStatus: "pending",
				logoutStatus: "",
				loginType: "",
				loginError: "",
			};
		},
		[loginAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				loginStatus: "success",
				logoutStatus: "",
				loginType: "login",
				loginError: "",
			};
		},
		[loginAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				loginStatus: "rejected",
				logoutStatus: "",
				loginType: "login",
				loginError: payload.errors,
			};
		},

		[changePasswordAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				loginStatus: "pending",
				logoutStatus: "",
				loginType: "",
				loginError: "",
			};
		},
		[changePasswordAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				loginStatus: "success",
				logoutStatus: "",
				loginType: "changePassword",
				loginError: "",
			};
		},
		[changePasswordAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				loginStatus: "rejected",
				logoutStatus: "",
				loginType: "changePassword",
				loginError: payload?.errors,
			};
		},

		[logoutAction.pending]: (state, { type, payload }) => {
			return {
				...state,
				loginStatus: "",
				logoutStatus: "pending",
				loginType: "",
				loginError: "",
			};
		},
		[logoutAction.fulfilled]: (state, { type, payload }) => {
			return {
				...state,
				loginStatus: "",
				logoutStatus: "success",
				loginType: "logout",
				loginError: "",
			};
		},
		[logoutAction.rejected]: (state, { type, payload }) => {
			return {
				...state,
				loginStatus: "",
				logoutStatus: "rejected",
				loginType: "logout",
				loginError: payload.errors,
			};
		},
	},
});

export default authSlice.reducer;
