import {
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
	useDisclosure,
} from "@chakra-ui/react";
import { map } from "lodash";
import randomColor from "randomcolor";
import { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { BiTrash } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import { MdOutlinePeopleAlt } from "react-icons/md";
import Select from "react-select";
import { toast } from "react-toastify";
import groupImg from "../../../assets/images/Users.png";
import { getTwoFirstLetter } from "../../../helpers/common";
import { reactSelectTheme } from "../../common/react-select";

export default function CreateGroupe(props) {
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState();
	const [student, setStudent] = useState(null);
	const [selectedStudents, setSelectedStudents] = useState([]);
	const [students, setStudents] = useState([]);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const formatDatas = () => {
		const { students } = props;
		if (!Array.isArray(students)) throw new Error("Missing student list");

		setStudents(
			students.map((item) => ({ value: item.id, label: `${item.firstName} ${item.lastName}` })),
		);
	};

	const addStudent = () => {
		if (!student) return;
		const ids = new Set(selectedStudents.map((item) => item.value));
		if (ids.has(student.value)) return setStudent(null);
		student.bg = randomColor();
		setSelectedStudents([student, ...selectedStudents]);
		setStudent(null);
	};

	const removeStudent = (id) => {
		setSelectedStudents((values) => values.filter((item) => item.value !== id));
	};

	const handleSubmit = async () => {
		if (!name) return toast.error("Le nom du groupe est requis !");
		const ids = selectedStudents.map((item) => item.value);
		if (ids.length === 0) return toast.error("Veuillez selectionner au moins un élève!");
		const payload = {
			name,
			students: ids,
		};
		setLoading(true);
		await props.handleAddGroupe(payload);
		setLoading(false);
		onClose();
	};

	useEffect(formatDatas, []);

	return (
		<>
			<button
				onClick={onOpen}
				className='flex justify-start items-center px-3 py-2 rounded-md border-[.14rem] border-iwingreen text-sm text-iwingreen font-medium gap-2'
			>
				<span>Créer un groupe</span>
				<MdOutlinePeopleAlt />
			</button>

			<Modal isOpen={isOpen} onClose={onClose} closeOnEsc isCentered size="2xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className="w-full">
						<p className="flex justify-center items-center gap-5">
							<img src={groupImg} alt="groupe" /> Créer un groupe
						</p>
					</ModalHeader>
					<ModalBody>
						<Row className='px-5'>
							<Col
								xs={12}
								sm={12}
								md={12}
								className="flex flex-col justify-start items-start gap-1 mb-4 relative"
							>
								<label htmlFor='name' className='text-[14px] text-iwinblue font-medium'>
									Nom du groupe <span className='text-red-500'>*</span>
								</label>
								<input
									type="text"
									name='name'
									onChange={(event) => {
										setName(event.target.value);
									}}
									className='w-full text-[14px] drop-shadow-md outline-none border bg-inputbg/[0.02] border-inputbg/[0.47]  text-iwinblue rounded-md focus:border-inputbg/[0.47] px-3 py-2'
								/>
							</Col>
							<Col md={12} className='mb-1'>
								<label htmlFor='name' className='text-[14px] text-iwinblue font-medium'>
									Elèves du groupe <span className='text-red-500'>*</span>
								</label>
							</Col>
							<Col
								sx={12}
								md={9}
								className='flex flex-col justify-start items-start gap-1 mb-4 relative'
							>
								<Select
									name="student"
									options={students}
									placeholder="Choisir élève..."
									className="w-full"
									onChange={(value) => {
										setStudent(value);
									}}
									value={student}
									{...reactSelectTheme}
								/>
							</Col>
							<Col md={3} className="w-full flex flex-row justify-end items-end mb-4 ">
								<button
									type='button'
									onClick={addStudent}
									className='flex flex-row justify-center items-center border-iwingreen border rounded-md px-4 py-[10px] shadow-md text-iwingreen text-[14px]'
								>
									<span>Ajouter</span>
									<FaPlus className='ml-3' />
								</button>
							</Col>

							<Col md={12} className="w-full mt-4">
								<ul className="w-full">
									{map(selectedStudents, (student) => (
										<li
											key={student.value}
											className="w-full shadow-md rounded-md px-4 py-1 flex flex-row justify-between items-center mb-3 bg-gray-100"
										>
											<div>
												<div
													className="inline-flex overflow-hidden relative justify-center items-center w-9 h-9 rounded-full "
													style={{ backgroundColor: student.color }}
												>
													<span
														className="text-black  uppercase font-black border p-8 rounded-full"
														style={{ backgroundColor: student.bg }}
													>
														{getTwoFirstLetter(student.label)}
													</span>
												</div>
												<span className='ml-3 text-sm capitalize'>{student.label}</span>
											</div>
											<BiTrash
												className="text-red-500 w-5 h-5 cursor-pointer"
												onClick={() => removeStudent(student.value)}
											/>
										</li>
									))}
								</ul>
							</Col>
							<Col md={12} className='my-6'>
								<div className='border-b border-gray-300 -mt-1' />
							</Col>
						</Row>
					</ModalBody>

					<ModalFooter>
						{loading ? (
							<Spinner />
						) : (
							<button
								type="button"
								className="px-5 py-2 text-md rounded bg-iwincolor3 text-white"
								onClick={handleSubmit}
							>
								Terminer
							</button>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
