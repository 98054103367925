import React, { useEffect, useState } from "react";

import { BiDotsVerticalRounded, BiFilter, BiLinkAlt } from "react-icons/bi";
import { GoSearch } from "react-icons/go";
import { ImQrcode } from "react-icons/im";
import { RiFileCopyLine } from "react-icons/ri";
import { TiPlus } from "react-icons/ti";

import { useFormik } from "formik";
import { map } from "lodash";
import { Col, Row } from "react-flexbox-grid";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Tooltip } from "primereact/tooltip";

import Pencil from "../../assets/images/Pencil-black.png";
import { ScrollContainer } from "../../components/common";
import Profile from "../../components/common/Profile";
import {
	changeClassroom,
	createGroup,
	deleteStudent,
	getClassroom,
	getGroupsByClass,
} from "../../helpers/backend_helper";
import { useClassRooms } from "../../hooks/useClassRooms";
import EditStudentProfil from "../EditStudentProfil/EditStudentProfil";
import "./ClassDetails.scss";
import ClassGroups from "./ClassGroups/ClassGroups";
import CreateGroupe from "./CreateGroupe/CreateGroupe";

export default function ClassDetails() {
	const { refetch } = useClassRooms();
	const navigate = useNavigate();
	const [classRoom, setClassRoom] = useState();
	const [groupes, setGroupes] = useState([]);
	const [toEdit, setToEdit] = useState(false);

	const [showUpdateStudent, setShowUpdateStudent] = useState(false);
	const [showChangerClasse, setShowChangerClasse] = useState(false);
	const [showSupprimerStudent, setShowSupprimerStudent] = useState(false);
	const [showEditStudentModal, setShowEditStudentModal] = useState(false);
	const { id } = useParams();

	const editStudentForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			id: "",
			firstName: "",
			lastName: "",
			imagePath: "",
			classroom: { value: "", label: "" },
		},
		validationSchema: Yup.object({
			id: Yup.string().required("Veuillez saisir le prénom !"),
			firstName: Yup.string().required("Veuillez saisir le prénom !"),
			lastName: Yup.string(),
			imagePath: Yup.mixed(),
		}),
		onSubmit: (values) => {},
	});

	const addStudents = () => {
		navigate("/add-student-to-class", { replace: true, state: classRoom });
	};

	const HeaderModalUpdateStudent = (
		<div className='w-full flex justify-center items-center'>
			<h2 className='text-iwingreen font-bold flex justify-between items-center gap-3'>
				Editer l'élève
			</h2>
		</div>
	);

	const handleAddGroupe = async (payload) => {
		if (!classRoom) throw new Error("Missing classRoomId");
		payload.classRoomId = classRoom.id;
		await createGroup(payload);
		await syncGroupes();
	};

	const syncGroupes = async () => {
		const groupes = await getGroupsByClass(id);
		if (groupes.success) setGroupes(groupes.data);
	};

	const syncClass = async () => {
		const res = await getClassroom(id);
		if (res.success) setClassRoom(res.data);
	};

	const refreshDatas = async () => {
		await syncClass();
		await syncGroupes();
		await refetch();
	};

	const handleRemoveStudent = async (id) => {
		const res = await deleteStudent(id);
		if (res.success) await refreshDatas();
	};

	const handleChangeStudentClassRoom = async (payload) => {
		const res = await changeClassroom(payload);
		if (res.success) await refreshDatas();
	};

	useEffect(() => {
		syncClass().catch(console.error);
		syncGroupes().catch(console.error);
	}, [id]);

	if (!classRoom) {
		return (
			<div className="flex justify-center items-center">
				<p>Chargement ...</p>
			</div>
		);
	}

	return (
		<div className='w-full flex flex-col justify-start items-center'>
			<div className="w-full flex justify-between items-center">
				<h1 className='text-iwinblue font-bold text-left mt-3 text-[24px] mx-5'>Mes classes</h1>
				<div className="flex justify-end items-center">
					<div className="relative w-[300px] mt-3 mr-2">
						<input
							type="text"
							placeholder='Trouver une ressource ...'
							className="bg-white rounded-[46px] border-white bottom-0 px-[50px] py-[10px]"
							style={{ border: "none", borderRadius: "40px" }}
						/>
						<GoSearch className="w-5 h-5 absolute top-[15px] left-[20px] text-asprimary" />
						<BiFilter className="w-7 h-7 absolute top-[10px] right-[15px] text-gray-700" />
					</div>
				</div>
				<div className="m-2">
					<Profile />
				</div>
			</div>
			<ScrollContainer className='w-full mt-4 px-5'>
				<div className='w-full bg-white shadow-md rounded-xl flex flex-col justify-start items-center p-6 overscroll-auto gap-3 m-3'>
					<div className='w-full flex justify-between items-center'>
						<div className='flex justify-start items-center gap-3'>
							<div className='px-4 py-2 bg-iwinyellow text-black rounded-lg flex justify-start items-center gap-2'>
								<span>Code classe :</span>
								<span className='font-black text-3lg'>{classRoom?.code}</span>
								<RiFileCopyLine className='w-5 h-5 cursor-pointer' />
							</div>
							<button
								type='button'
								className='px-3 py-2 bg-iwinyellow text-black rounded-lg flex justify-start items-center'
							>
								<BiLinkAlt className='w-6 h-6' />
							</button>
							<button
								type='button'
								className='px-3 py-2 bg-iwinyellow text-black rounded-lg flex justify-start items-center'
							>
								<ImQrcode className='w-6 h-6' />
							</button>
						</div>
						<div className='flex justify-end items-center gap-3'>
							<button
								onClick={() => addStudents()}
								className='flex justify-start items-center px-3 py-2 rounded-md border-[.14rem] border-iwingreen text-sm text-iwingreen font-medium gap-2'
							>
								<span>Ajouter des élèves</span>
								<TiPlus className='' />
							</button>
							<CreateGroupe students={classRoom.students} handleAddGroupe={handleAddGroupe} />
						</div>
					</div>
					<div className='w-full border-b border-gray-200' />
					<div className='w-full bg-white iwin-shadow-2 rounded-xl flex flex-col justify-start items-center p-6 overscroll-auto'>
						<div className='w-full flex justify-between items-center gap-2'>
							<div className='flex justify-start items-center gap-3'>
								<span className='text-[18px] text-iwinblue font-bold capitalize'>
									{classRoom?.name}
								</span>
								<Link to={`/edit-class/${classRoom?.id}`} className=''>
									<img src={Pencil} className='w-6 h-6 -mt-1' alt="" />
								</Link>
							</div>
							<div className='flex justify-end items-center gap-2'>
								<button className='flex justify-start items-center px-3 py-2 rounded-md border-[.14rem] border-iwingreen text-sm text-iwingreen font-medium'>
									Assigner une activité
								</button>
								<button className='flex justify-start items-center p-2 rounded-md border-[.14rem] border-iwingreen text-sm text-iwingreen font-medium'>
									<BiDotsVerticalRounded className='w-5 h-5 action-expoter' />
									<Tooltip
										tooltip="Teal"
										target={".action-expoter"}
										position="left"
										autoHide={false}
									>
										<ul className=''>
											<li>
												<button
													className='text-white hover:text-gray-300'
													onClick={() => {}}
													type='button'
												>
													Exporter le resultat en csv
												</button>
											</li>
											<li>
												<button
													className='text-white hover:text-gray-300'
													onClick={() => {}}
													type='button'
												>
													Exporter le resultat en PDF
												</button>
											</li>
										</ul>
									</Tooltip>
								</button>
							</div>
						</div>
						<div className='w-full flex justify-between items-start gap-3 mt-2'>
							<div className='w-[500px] py-4 '>
								<table className='w-full'>
									<thead>
										<tr key="th-1" className='py-5'>
											<td className='flex justify-start items-center gap-4 pb-4'>
												<span className='text-sm font-medium'>Elève</span>
												<BiFilter className='w-5 h-5' />
											</td>
											<td className='text-sm font-medium pb-4'>Moyenne</td>
											<td className='pb-4' />
										</tr>
									</thead>
									<tbody className='mt-5'>
										{map(classRoom.students ?? [], (student, index) => (
											<tr key={`tb-${index}`} className='border-b border-gray-300'>
												<EditStudentProfil
													student={student}
													classRoom={classRoom}
													index={index}
													handleRemoveStudent={handleRemoveStudent}
													handleChangeStudentClassRoom={handleChangeStudentClassRoom}
												/>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							<div className='w-full px-2 py-4 iwin-shadow-2' />
						</div>
					</div>
					<ClassGroups groups={groupes} />

					<Dialog
						visible={showUpdateStudent}
						onHide={() => setShowUpdateStudent(false)}
						dismissableMask={true}
						resizable={false}
						header={HeaderModalUpdateStudent}
						style={{ width: "40vw" }}
					>
						<form>
							<Row>
								<Col md={6} className="mb-5">
									<label htmlFor="firstName">
										Prénom <span className='text-red-500'>*</span>
									</label>
									<input
										type="text"
										className="px-3 py-2"
										id="firstName"
										name="firstName"
										onChange={editStudentForm.handleChange}
										onBlur={editStudentForm.handleBlur}
										value={editStudentForm.values.firstName}
										invalid={editStudentForm.touched.firstName && editStudentForm.errors.firstName}
									/>
									{editStudentForm.touched.firstName && editStudentForm.errors.firstName ? (
										<small className="text-[12px] text-red-400">
											{editStudentForm.errors.firstName}
										</small>
									) : null}
								</Col>
								<Col md={6} className="mb-5">
									<label htmlFor="lastName">
										Nom <span className='text-red-500'>*</span>
									</label>
									<input
										type="text"
										className="px-3 py-2"
										id="lastName"
										name="lastName"
										onChange={editStudentForm.handleChange}
										onBlur={editStudentForm.handleBlur}
										value={editStudentForm.values.lastName}
									/>
								</Col>
								<Col md={12}>
									<label htmlFor="password">
										Mot de passe <span className='text-red-500'>*</span>
									</label>
								</Col>
								<Col md={6} className="mb-5">
									<input
										type="password"
										className="px-3 py-2"
										id="password"
										name="password"
										onChange={editStudentForm.handleChange}
										onBlur={editStudentForm.handleBlur}
										value={""}
									/>
								</Col>
								<Col md={6} className="">
									<button className='w-full border-2 border-iwingreen px-2 py-2 rounded-lg text-iwingreen'>
										Générer un nouveau mdp
									</button>
								</Col>
								<Col md={12}>
									<Divider />
								</Col>
								<Col className="w-full flex flex-row justify-end items-center mt-2 gap-3" md={12}>
									<button
										type="submit"
										className="px-5 py-2 text-md rounded-md bg-iwincolor3 text-white"
									>
										Enregister
									</button>
								</Col>
							</Row>
						</form>
					</Dialog>

					<Dialog visible={showChangerClasse} onHide={() => setShowChangerClasse(false)} />

					<Dialog visible={showSupprimerStudent} onHide={() => setShowSupprimerStudent(false)}>
						<div className='w-[500px] flex flex-col justify-between items-start'>
							<h2 className='w-full flex justify-center items-center gap-2 text-iwingreen font-bold'>
								Supprimer élève
							</h2>
						</div>
					</Dialog>
				</div>
			</ScrollContainer>
		</div>
	);
}
