import styled from "styled-components";

export const AlertContainer = styled.div`
max-width: 400px;
padding: 10px 15px;
background: #F4DC62 no-repeat center center fixed; 
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
border-radius: 10px;
margin-bottom: 20px;
`;
