import "./AddDocument.scss";
export default function AddDocument({ onFileUploaded }) {
	return (
		<label className="AddDocument cursor-pointer">
			<div className="AddDocument-icon">+</div>
			<div className="AddDocument-text">Importer plus</div>
			<input
				type="file"
				hidden
				accept="application/pdf"
				multiple
				onChange={(event) => {
					if (event.target.files) onFileUploaded(event.target.files);
					event.target.value = null;
				}}
			/>
		</label>
	);
}
