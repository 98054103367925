import axios from "axios";

import { getRefreshToken, getToken, updateAccessToken } from "./common";

import { toast } from "react-toastify";
import { API_URL } from "../context";

const axiosApi = axios.create({ baseURL: API_URL });
axiosApi.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

axiosApi.interceptors.request.use(
	async (config) => {
		const token = getToken();

		config.headers = {
			Authorization: token,
		};
		return config;
	},
	(error) => {
		console.error(error);
	},
);

axiosApi.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const originalRequest = error.config;
		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;

			try {
				const rs = await refreshToken();
				const { accessToken } = rs.data;
				updateAccessToken(accessToken);
				axiosApi.defaults.headers.common["x-access-token"] = accessToken;
				return axiosApi(originalRequest);
			} catch (_error) {
				console.error(_error);
			}
		} else {
			if (Array.isArray(error?.response?.data?.errors)) {
				const errors = error.response.data.errors[0];
				if (errors) {
					for (const key in errors) {
						toast.error(errors[key]);
						break;
					}
				}
			}
		}
	},
);

function refreshToken() {
	return axiosApi.post("/auth/refresh", {
		refreshToken: getRefreshToken(),
	});
}

axiosApi.interceptors.response.use(
	(response) => response,
	(error) => {
		console.error(error);
	},
);

export async function get(url, config = {}) {
	return await axiosApi
		.get(url, {
			...config,
		})
		.then((response) => {
			return response.data;
		});
}

export async function post(url, data, config = {}) {
	return axiosApi
		.post(
			url,
			{
				...data,
			},
			{
				...config,
			},
		)
		.then((response) => response?.data);
}

export async function patch(url, data, config = {}) {
	return axiosApi
		.patch(
			url,
			{
				...data,
			},
			{
				...config,
			},
		)
		.then((response) => response?.data);
}

export async function put(url, data, config = {}) {
	return axiosApi
		.put(
			url,
			{
				...data,
			},
			{
				...config,
			},
		)
		.then((response) => response?.data);
}

export async function putWithFile(url, data) {
	const token = getToken();
	return axiosApi
		.put(url, data, {
			headers: {
				Authorization: token,
				"Content-Type": "multipart/form-data",
			},
		})
		.then((response) => response.data);
}

export async function postWithFile(url, data) {
	const token = getToken();
	const response = await axiosApi
		.post(url, data, {
			headers: {
				Authorization: token,
				"Content-Type": "multipart/form-data",
			},
		})

	return response?.data

}

export async function patchWithFile(url, data) {
	const token = getToken();
	return axiosApi
		.patch(url, data, {
			headers: {
				Authorization: token,
				"Content-Type": "multipart/form-data",
			},
		})
		.then((response) => response?.data);
}

export async function del(url, config = {}) {
	return await axiosApi
		.delete(url, {
			...config,
		})
		.then((response) => response?.data);
}
