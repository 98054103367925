import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createActivity, deleteActivity, updateActivity, updateActivityPdf, updateFolder, uploadActivityPdf } from "../../helpers/backend_helper";
import { useActivities, useFolders } from "../../hooks/useActivities";
import { useAppStore } from "../../store/store";
import DocumentDetail from '../ImportActivity/DocumentDetail/DocumentDetail';
import Spinner from "../Spinner/Spinner";
import UpdateDocumentGallery from "./DocumentGallery/UpdateDocumentGallery";


export function UpdateActivity() {
  const themes = useAppStore(state => state.themes)
  const activity = useAppStore(state => state.activity)
  const setActivity = useAppStore(state => state.setActivity)
  const { isOpen: isLoading, onOpen: startLoading, onClose: stopLoading } = useDisclosure();
  const { refresh: refreshActivities } = useActivities();
  const { refresh: refreshFolder } = useFolders();
  const [payload, setPayload] = useState({
    activityName: undefined,
    isGroupe: false,
    files: [],
    teacherId: undefined,
  });

  const [selectedActivity, setSelectedActivity] = useState();

  const onFileUploaded = async (fileList) => {
    let only = false
    const newFiles = [];

    if (payload.files.length && !payload.files[0]?.folderId) {
      const [file] = [...fileList]
      only = true
      if (!selectedActivity) return toast.error("Veuillez selectionner l'activité")
      const res = await updateActivityPdf(file, selectedActivity.file.id)
      if (res.success) {
        for (const fileAsActivity of payload.files) {
          if (fileAsActivity.file.id === res.data.id) {
            fileAsActivity.file.url = res.data.url
            fileAsActivity.name = file.name
            newFiles.push(fileAsActivity)
          }
        }
      }
    } else {
      await Promise.allSettled([...fileList].map(item => createActivityWithFile(item, newFiles)))

    }
    const filesToSet = only ? newFiles : [payload.files, newFiles].flat()
    setPayload({ ...payload, files: filesToSet });
    setSelectedActivity(filesToSet[filesToSet.length - 1])
  };

  const createActivityWithFile = async (file, newFiles) => {
    const newUploadedFile = await uploadActivityPdf(file)
    if (!newUploadedFile) toast.error(`Le fichier ${file.name} n'a pas été uploader`)
    const activityToAdd = await createActivity({
      folderId: activity.id,
      teacherId: payload.teacherId,
      status: 'imported',
      fileId: newUploadedFile.id,
      name: file.name,
      subThemeId: themes[0].subThemes[0].id
    })


    if (activityToAdd?.data) newFiles.push(activityToAdd.data)
  }

  const onDocumentAction = (activity) => {
    if (activity.id === selectedActivity?.id) return setSelectedActivity(undefined);
    setSelectedActivity(activity);
  };

  const onRemoveDocument = async (id) => {
    if (payload.isGroupe) {
      await deleteActivity(id, false)
      setPayload({ ...payload, files: payload.files.filter(item => item.id !== id) })
      return
    }
    const newFiles = []
    for (const file of payload.files) {
      if (file.id === id) file.file.url = undefined
      newFiles.push(file)
    }
    setPayload({ ...payload, files: newFiles });
  };

  const commitActivityChange = (activity) => {
    const activities = [...payload.files];

    const index = activities.findIndex((item) => item.id === activity.id);

    if (index === -1) throw new Error(`can't find activity '${activity.id}'`);
    if (!selectedActivity) throw new Error("Missing selectedActivity");
    if (activities[index].id !== selectedActivity.id) throw new Error("Bad activity");
    activities[index] = activity;
    setSelectedActivity(activity);
    setPayload({ ...payload, files: activities });
  };

  const toggleGroupe = () => {
    if (!payload.isGroupe === false)
      return setPayload({ ...payload, activityName: undefined, isGroupe: !payload.isGroupe });

    setPayload({ ...payload, isGroupe: !payload.isGroupe });
  };

  const handleSubmit = async () => {
    for (const activity of payload.files) {
      if (!activity.name) {
        return toast.error(`Veuillez remplir le champs titre du document ${activity.file.name}`);
      }

      if (!activity.subThemeId) {
        return toast.error(
          `Veuillez remplir le champs thème et sous-theme du document ${activity.file.name}`,
        );
      }
    }

    if (payload.isGroupe && !payload.activityName) {
      return toast.error("Veuillez remplir le champs nom du dossier");
    }
    startLoading()
    for (const activityToEdit of payload.files) {
      await updateActivity(activityToEdit.id, {
        name: activityToEdit.name,
        description: activityToEdit.description,
        subThemeId: activityToEdit.subThemeId
      })
    }


    if (payload.activityName) await updateFolder(activity.id, { name: payload.activityName })

    await refreshActivities()
    await refreshFolder()
    stopLoading()
    setActivity(undefined)
  };

  const setActivityName = (activityName) => {
    setPayload({ ...payload, activityName });
  };

  useEffect(() => {
    if (activity) {
      const isGroupe = Boolean(activity.activities) && !activity.folderId
      const files = isGroupe ? activity.activities : [activity]

      console.log("isGroupe", isGroupe)
      setPayload({
        activityName: activity.name,
        files: files.map(item => ({ ...item, themeId: item.subTheme.themeId })),
        isGroupe,
        teacherId: activity.teacherId
      })

      if (isGroupe) {
        const [activeActivity] = activity.activities.slice(-1)
        setSelectedActivity(activeActivity)
      } else {

        setSelectedActivity({ ...activity, themeId: activity.subTheme.themeId })
      }
    }
  }, [activity])


  return (
    <Modal isOpen={Boolean(activity)} onClose={() => setActivity(undefined)} isCentered size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <div className="flex p-2">
            <UpdateDocumentGallery
              selectedActivity={selectedActivity}
              activities={payload.files}
              onFileUploaded={onFileUploaded}
              onDocumentAction={onDocumentAction}
              onRemoveDocument={onRemoveDocument}
              toggleGroupe={toggleGroupe}
              isGroupe={payload.isGroupe}
              setActivityName={setActivityName}
              activityName={payload.activityName}
            />
            {selectedActivity && (
              <DocumentDetail
                activity={selectedActivity}
                setActivity={setSelectedActivity}
                commitChange={commitActivityChange}
              />
            )}
          </div>

          {isLoading ? (
            <Spinner />
          ) : (
            <button className="Button primary" onClick={handleSubmit}>
              Enregistrer
            </button>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
