import styled from "styled-components";
import SigUpBgImg from "../../../assets/images/sigup-student.png";

export const DefaultContainer = styled.div`
max-width: 400px;
padding: 40px 60px;
background: url(${SigUpBgImg}) no-repeat center center fixed; 
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
border-radius: 16px;
`;
