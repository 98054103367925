import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

import MaskValidImg from "../../assets/images/SCIBOT.png";
import CheckImg from "../../assets/images/icon-check.png";
import SigUpBgImg from "../../assets/images/sigup-student.png";

import { verifyEmailAction } from "../../store/auth/register/actions";

const DefaultContainer = styled.div`
    max-width: 400px;
    padding: 40px 60px;
    background: url(${SigUpBgImg}) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 16px;
`;

const AlertContainer = styled.div`
    max-width: 400px;
    padding: 10px 15px;
    background: #F4DC62 no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 10px;
    margin-bottom: 20px;
`;

const Index = () => {
	const { verifyEmailStatus } = useSelector((state) => ({
		verifyEmailStatus: state.verifyEmailState.verifyEmailStatus,
	}));
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { token } = useParams();

	useEffect(() => {
		if (token && token !== undefined) {
			dispatch(verifyEmailAction(token));
		}
	}, [dispatch]);

	useEffect(() => {
		if (verifyEmailStatus === "success") {
			navigate("/account-confirmed", { replace: true });
		}
	}, [verifyEmailStatus]);

	return (
		<CSSTransition in={true} appear={true} timeout={300} classNames="fade">
			<div className="flex flex-col justify-start items-center -mt-40">
				<AlertContainer className="md:w-[400px]">
					<div className="w-full flex flex-row justify-start items-center text-[12px] bg-notifbg text-notiftext">
						<img src={CheckImg} alt="" className="mr-5" />
						<div>
							<p>
								Félicitations ! Compte validé avec <strong>succès !</strong>
							</p>
							<p>
								Pour vous connecter cliquez{" "}
								<Link to="/login">
									<strong>ici</strong>
								</Link>
							</p>
						</div>
					</div>
				</AlertContainer>
				<DefaultContainer className="flex flex-col justify-center items-center md:w-[400px]">
					<img src={MaskValidImg} alt="" className="w-52" />
				</DefaultContainer>
			</div>
		</CSSTransition>
	);
};

export default Index;
