import { useFormik } from "formik";
import React, { useState } from "react";
import { BsEye } from "react-icons/bs";
import { GoInfo } from "react-icons/go";
import { MdOutlineModeEdit } from "react-icons/md";
import { TiPlus } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { CSSTransition } from "react-transition-group";
import * as Yup from "yup";

import { getSubThemesByTheme } from "../../../helpers/backend_helper";

import { map } from "lodash";
import { Checkbox } from "primereact/checkbox";
import { Editor } from "primereact/editor";
import { InputTextarea } from "primereact/inputtextarea";
import { IoMdClose } from "react-icons/io";

const Index = ({ themesList }) => {
	let navigate = useNavigate();
	const [actionIndex, setActionIndex] = useState(0);
	const [showAddCompetence, setShowAddCompetence] = useState(false);
	const [competences, setCompetences] = useState([]);
	const [subThemeMetiers, setSubThemeMetiers] = useState([]);
	const [subThemeInclusions, setSubThemeInclusions] = useState([]);
	const [typeLecteurs, setTypeLecteurs] = useState([]);
	const [categoryArticles, setCategoryArticles] = useState([]);
	const [editors, setEditors] = useState([]);
	const [nombreMot, setNombreMot] = useState([]);
	const [subThemesList, setSubThemesList] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [title, setTitle] = useState("");
	const [selectedTheme, setSelectedTheme] = useState({ value: "", label: "" });
	const [selectedSubTheme, setSelectedSubTheme] = useState({ value: "", label: "" });
	const [materielListError, setMaterielListError] = useState(false);

	const [existsInMaterielList, setExistsInMaterielList] = useState(false);
	const [materielList, setMaterielList] = useState([]);
	const [materiel, setMateriel] = useState("");
	const customStyles = {
		control: (base) => ({
			...base,
			border: "1px solid rgba(50, 202,188, 0.47)",
			background: "#fff",
			color: "#303972",
			paddingBottom: "1px",
			paddingTop: "1px",
			height: "40px",
			boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
			filter: "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
			borderRadius: "0.375rem",
			"&:hover": {
				border: "1px solid rgba(50, 202,188, 0.47)",
			},
		}),
		option: (provided, state) => ({
			...provided,
			fontSize: "12px",
		}),
		placeholder: (defaultStyles) => {
			return {
				...defaultStyles,
				color: "#303972",
				fontSize: "12px",
				padding: "1px",
			};
		},
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = "opacity 300ms";
			const fontSize = "12px";
			const color = "#303972";
			return { ...provided, color, fontSize, opacity, transition };
		},
		input: (styles, { data }) => ({
			...styles,
			outline: "none",
			border: "none",
			focus: "none",
			color: "#303972",
			fontSize: "12px",
		}),
		indicatorSeparator: (base) => ({
			...base,
			display: "none",
		}),
		dropdownIndicator: (base) => ({
			...base,
			color: "#303972",
			"&:hover": {
				color: "#303972",
			},
		}),
		menuList: (base) => ({
			...base,
			"::-webkit-scrollbar": {
				width: "4px",
				height: "0px",
				color: "#303972",
			},
			"::-webkit-scrollbar-track": {
				background: "#f1f1f1",
			},
			"::-webkit-scrollbar-thumb": {
				background: "#888",
			},
			"::-webkit-scrollbar-thumb:hover": {
				background: "#555",
			},
		}),
	};

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			title: "",
			theme: "",
			subtheme: "",
			editor: "",
			placeOfBrief: "",
			wordNbr: "",
			subThemeMetier: "",
			linkOnisep: "",
			subThemeInc: "",
			materiels: "",
			typeReader: "",
			topic: "",
			articleTitle: "",
			metaDescription: "",
			slug: "",
			tags1: "",
			tags2: "",
			semLex: "",
			category: "",
			introduction: "",
			content: "",
			anonymous: true,
		},
		validationSchema: Yup.object({
			title: Yup.string().required("Veuillez saisir le titre !"),
			theme: Yup.object().required("Veuillez sélectionner un thème !"),
			subtheme: Yup.object().required("Veuillez sélectionner un sous thème !"),
			wordNbr: Yup.object().required("Veuillez sélectionner le nombre de mots !"),
			editor: Yup.object().required("Veuillez sélectionner un rédacteur !"),
			subThemeMetier: Yup.object().required("Veuillez sélectionner un sous thème !"),
			subThemeInc: Yup.object().required("Veuillez sélectionner un sous thème !"),
			materiels: Yup.array().of(Yup.string()),
			typeReader: Yup.object().required("Veuillez sélectionner un type !"),
			category: Yup.object().required("Veuillez sélectionner une catégorie !"),
			placeOfBrief: Yup.string().required("Veuillez saisir le lieu !"),
			linkOnisep: Yup.string().required("Veuillez saisir le lien !"),
			articleTitle: Yup.string().required("Veuillez saisir le titre de l'article !"),
			metaDescription: Yup.string().required("Veuillez saisir la description !"),
			slug: Yup.string().required("Veuillez saisir le slug !"),
			tags1: Yup.string().required("Veuillez saisir les tags !"),
			tags2: Yup.string().required("Veuillez saisir les tags !"),
			semLex: Yup.string().required("Veuillez saisir le sémantiaue !"),
			introduction: Yup.string().required("Veuillez saisir l'introduction !"),
			content: Yup.string().required("Veuillez saisir le contenu !"),
			topic: Yup.string().required("Veuillez saisir le sujet !"),
		}),
		onSubmit: (values) => {
			if (materielList.length > 0) {
				values = { ...values, materiels: materielList };
				navigate("/activites", { replace: true });
			} else {
				setMaterielListError(true);
			}
		},
	});

	const handleChangeTheme = (e) => {
		validation.setFieldValue("subtheme", { value: "", label: "" });
		validation.setFieldValue("theme", e);
		if (e.value !== validation.values.theme) {
			getSubThemesByTheme(e.value).then((data) => {
				setSubThemesList(data.data);
			});
		}
	};

	const handleChangeSubTheme = (e) => {
		validation.setFieldValue("subtheme", e);
	};

	// useEffect(() => {
	// 	let user = getUser();
	// 	if (user) {
	// 		let ed = { value: user.id, label: `${user.firstName} ${user.lastName}` };
	// 		let data = [ed];
	// 		setEditors(data);
	// 	}
	// 	getNombreMot().then((data) => {
	// 		setNombreMot(data.data);
	// 	});
	// 	getSubThemeMetiers().then((data) => {
	// 		setSubThemeMetiers(data.data);
	// 	});
	// 	getSubThemeInclusions().then((data) => {
	// 		setSubThemeInclusions(data.data);
	// 	});
	// 	getTypeLecteurs().then((data) => {
	// 		setTypeLecteurs(data.data);
	// 	});
	// 	getCategoryArticles().then((data) => {
	// 		setCategoryArticles(data.data);
	// 	});
	// }, []);

	const handleAddMateriel = () => {
		{
			setExistsInMaterielList(false);
			setMaterielListError(false);
			let cmps = materielList;
			if (materiel !== "" && !cmps.includes(materiel)) {
				cmps.push(materiel);
				setMaterielList(cmps);
				setMateriel("");
				setExistsInMaterielList(false);
			} else {
				setExistsInMaterielList(true);
			}
		}
	};

	const handleRemoveMateriel = (c) => {
		let cmps = materielList.filter((cmp) => cmp !== c);
		setMaterielList(cmps);
		if (materielList.length === 0) {
			setMaterielListError(true);
		} else {
			setMaterielListError(false);
		}
	};

	const materielModal = () => {
		return showModal ? (
			<CSSTransition in={true} appear={true} timeout={300} classNames="fade">
				<div className="fixed inset-0 z-50 overflow-y-auto">
					<div
						className="fixed inset-0 w-full h-full bg-black opacity-70"
						onClick={() => setShowModal(false)}
					/>
					<div className="flex items-center min-h-screen px-4">
						<div className="relative w-full max-w-xs px-4 py-5 mx-auto bg-white rounded-md shadow-lg">
							<div className="w-full flex flex-col justify-center items-start gap-3">
								<p className="text-3lg font-bold text-iwingreen gap-4"> Ajouter matériel</p>
								<input
									type="text"
									value={materiel}
									onChange={(e) => setMateriel(e.target.value)}
									id="age"
									name="age"
									className='focus:border-inputbg[0.47] px-3 py-2 w-full'
								/>
								{existsInMaterielList ? (
									<small className="text-[12px] text-red-400"> Ce matériel exite déjà!</small>
								) : null}
								<button
									onClick={() => handleAddMateriel()}
									type='button'
									className='bg-iwingreen rounded px-3 py-2 text-white font-semibold float-right'
								>
									Ajouter
								</button>
							</div>
						</div>
					</div>
				</div>
			</CSSTransition>
		) : null;
	};

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				validation.handleSubmit();
				return false;
			}}
			className='w-full'
		>
			<div className='w-full flex flex-col justify-start items-center'>
				<div className='w-full flex justify-between items-center gap-3'>
					<div className='flex justify items-center'>
						<div className='w-56 flex flex-col justify-start items-start gap-1'>
							<label className=''>Titre</label>
							<input
								onChange={validation.handleChange}
								onBlur={validation.handleBlur}
								value={validation.values.title}
								invalid={validation.touched.title && validation.errors.title}
								type="text"
								id="title"
								name="title"
								placeholder="Saisir le titre de l'activité"
								className='text-[12px] w-20 px-2 py-2 border border-iwingreen/[.4] rounded-lg'
							/>
							{validation.touched.title && validation.errors.title ? (
								<small className="text-[9px] text-red-400">{validation.errors.title}</small>
							) : null}
						</div>
						<div className='w-36 ml-2 flex flex-col justify-start items-start gap-1'>
							<label className=''>Thème</label>
							<Select
								id="theme"
								name="theme"
								styles={customStyles}
								options={themesList}
								placeholder="---"
								className="w-full"
								classNamePrefix="select"
								onChange={(e) => {
									handleChangeTheme(e);
								}}
								value={validation.values.theme}
								invalid={validation.touched.theme && validation.errors.theme}
							/>
							{validation.touched.theme && validation.errors.theme ? (
								<small className="text-[9px] text-red-400">{validation.errors.theme}</small>
							) : null}
						</div>
						<div className='w-36 ml-2 flex flex-col justify-start items-start gap-1'>
							<label className=''>Sous-thème</label>
							<Select
								id="mode"
								name="mode"
								styles={customStyles}
								options={subThemesList}
								placeholder="---"
								className="w-full"
								classNamePrefix="select"
								onChange={(e) => {
									handleChangeSubTheme(e);
								}}
								value={validation.values.subtheme}
								invalid={validation.touched.subtheme && validation.errors.subtheme}
							/>
							{validation.touched.subtheme && validation.errors.subtheme ? (
								<small className="text-[9px] text-red-400">{validation.errors.subtheme}</small>
							) : null}
						</div>
						<div className='w-32 ml-2 flex flex-col justify-start items-start gap-1'>
							<label className=''>Arrière-plan</label>
							<div className='w-[30px] px-5 py-[6px] shadow-in border border-gray-300 bg-gray-400 rounded-lg cursor-pointer'>
								<input type="color" className='w-full h-full invisible' />
							</div>
						</div>
					</div>
					<div className='flex justify-end items-center gap-10 pt-3'>
						<div className='p-1 rounded-3xl border border-gray-300 bg-gray-200 flex justify-between items-center gap-4'>
							<div
								className={`flex justify-center items-center p-1  rounded-full cursor-pointer ${
									actionIndex === 0 ? "bg-iwingreen text-white" : "text-gray-400"
								}`}
								onClick={() => setActionIndex(0)}
							>
								<MdOutlineModeEdit className='w-4 h-4' />
							</div>
							<div
								className={`flex justify-center items-center p-1 rounded-full cursor-pointer ${
									actionIndex === 1 ? "bg-iwingreen text-white" : "text-gray-400"
								}`}
								onClick={() => setActionIndex(1)}
							>
								<BsEye className='w-4 h-4' />
							</div>
						</div>
						<button
							type='submit'
							className='px-10 py-3 text-sm font-semibold bg-gray-500 rounded-lg text-white'
						>
							Créer
						</button>
					</div>
				</div>
				<div className={"w-full bg-gray-100 rounded-lg px-3 py-2} mt-3"}>
					<div className="w-full flex justify-start items-center gap-3">
						<div className='w-[22%] h-[62vh] border-r-2 border-gray-300 pr-4 '>
							<label className='w-full flex justify-start items-center gap-2'>
								<span className='text-[12px]'>Auteur anonyme </span>
								<Checkbox
									inputId="anonyme"
									name="anonyme"
									value={validation.values.anonymous}
									onBlur={validation.handleBlur}
									className=''
									onChange={() => {
										validation.setFieldValue("anonymous", !validation.values.anonymous);
									}}
									checked={validation.values.anonymous}
								/>
							</label>
						</div>
						<div className='scroller2 w-full py-3'>
							<div className='w-full flex justify-between items-center gap-5'>
								<div className='flex-1 flex flex-col justify-center items-start gap-2'>
									<div className='w-full flex justify-between items-center'>
										<label className='font-medium' htmlFor='editor'>
											Rédacteur/Rédactrice<span className='text-red-500'>*</span>
										</label>
									</div>
									<Select
										id="editor"
										name="editor"
										styles={customStyles}
										options={editors}
										placeholder=""
										className="w-full"
										classNamePrefix="select"
										onChange={(e) => validation.setFieldValue("editor", e)}
										onBlur={validation.handleBlur}
										value={validation.values.editor}
										invalid={validation.touched.editor && validation.errors.editor}
									/>
									{validation.touched.editor && validation.errors.editor ? (
										<small className="text-[9px] text-red-400">{validation.errors.editor}</small>
									) : null}
								</div>
								<div className='flex-1 flex flex-col justify-center items-start gap-2'>
									<div className='w-full flex justify-between items-center'>
										<label className='font-medium' htmlFor='placeOfBrief'>
											Lieu du brief<span className='text-red-500'>*</span>
										</label>
									</div>
									<input
										type="text"
										id="placeOfBrief"
										name="placeOfBrief"
										value={validation.values.placeOfBrief}
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										invalid={validation.touched.placeOfBrief && validation.errors.placeOfBrief}
										className='w-full border-iwingreen/[0.4] border px-2 py-2 text-[12px] rounded-md'
									/>
									{validation.touched.placeOfBrief && validation.errors.placeOfBrief ? (
										<small className="text-[9px] text-red-400">
											{validation.errors.placeOfBrief}
										</small>
									) : null}
								</div>
							</div>
							<div className='w-full flex justify-between items-center gap-5 mt-5'>
								<div className='flex-1 flex flex-col justify-center items-start gap-2'>
									<div className='w-full flex justify-between items-center'>
										<label className='font-medium' htmlFor='wordNbr'>
											Nombre de mots<span className='text-red-500'>*</span>
										</label>
									</div>
									<Select
										id="wordNbr"
										name="wordNbr"
										styles={customStyles}
										options={nombreMot}
										placeholder=""
										className="w-full"
										classNamePrefix="select"
										value={validation.values.wordNbr}
										onChange={(e) => validation.setFieldValue("wordNbr", e)}
										onBlur={validation.handleBlur}
										invalid={validation.touched.wordNbr && validation.errors.wordNbr}
									/>
									{validation.touched.wordNbr && validation.errors.wordNbr ? (
										<small className="text-[9px] text-red-400">{validation.errors.wordNbr}</small>
									) : null}
								</div>
								<div className='flex-1 flex flex-col justify-center items-start gap-2'>
									<div className='w-full flex justify-between items-center'>
										<label className='font-medium' htmlFor='subThemeMetier'>
											Sous-thème pour les metiers<span className='text-red-500'>*</span>
										</label>
									</div>
									<Select
										id="subThemeMetier"
										name="subThemeMetier"
										styles={customStyles}
										options={subThemeMetiers}
										placeholder=""
										className="w-full"
										classNamePrefix="select"
										value={validation.values.subThemeMetier}
										onChange={(e) => validation.setFieldValue("subThemeMetier", e)}
										onBlur={validation.handleBlur}
										invalid={validation.touched.subThemeMetier && validation.errors.subThemeMetier}
									/>
									{validation.touched.subThemeMetier && validation.errors.subThemeMetier ? (
										<small className="text-[9px] text-red-400">
											{validation.errors.subThemeMetier}
										</small>
									) : null}
								</div>
							</div>
							<div className='w-full flex justify-between items-center gap-5 mt-5'>
								<div className='flex-1 flex flex-col justify-center items-start gap-2'>
									<div className='w-full flex justify-between items-center'>
										<label className='font-medium' htmlFor='linkOnisep'>
											Liens Onisep fiches métier<span className='text-red-500'>*</span>
										</label>
									</div>
									<input
										type="text"
										id="linkOnisep"
										name="linkOnisep"
										value={validation.values.linkOnisep}
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										invalid={validation.touched.linkOnisep && validation.errors.linkOnisep}
										className='w-full border-iwingreen/[0.4] border px-2 py-2 text-[12px] rounded-md'
									/>
									{validation.touched.linkOnisep && validation.errors.linkOnisep ? (
										<small className="text-[9px] text-red-400">
											{validation.errors.linkOnisep}
										</small>
									) : null}
								</div>
								<div className='flex-1 flex flex-col justify-center items-start gap-2'>
									<div className='w-full flex justify-between items-center'>
										<label className='font-medium' htmlFor='subThemeInc'>
											Sous-thème pour l'inclusion<span className='text-red-500'>*</span>
										</label>
									</div>
									<Select
										id="subThemeInc"
										name="subThemeInc"
										styles={customStyles}
										options={subThemeInclusions}
										placeholder=""
										className="w-full"
										classNamePrefix="select"
										value={validation.values.subThemeInc}
										onChange={(e) => validation.setFieldValue("subThemeInc", e)}
										onBlur={validation.handleBlur}
										invalid={validation.touched.subThemeInc && validation.errors.subThemeInc}
									/>
									{validation.touched.subThemeInc && validation.errors.subThemeInc ? (
										<small className="text-[9px] text-red-400">
											{validation.errors.subThemeInc}
										</small>
									) : null}
								</div>
							</div>
							<div className='w-full flex justify-between items-center gap-5 mt-5'>
								<div className='flex-1 flex flex-col justify-center items-start gap-2'>
									<div className='w-full flex justify-between items-center'>
										<label className='font-medium' htmlFor='title'>
											Liste materiels<span className='text-red-500'>*</span>
										</label>
									</div>
									<div className='w-full flex justify-between items-center px-2 py-1 border border-iwingreen/[0.4] rounded-md bg-white shadow'>
										<div className="grid grid-cols-3 gap-2 items-center">
											{map(materielList, (cmp, i) => (
												<div
													key={`comp-${i}`}
													className="p-2 rounded-lg flex justify-between items-center text-white gap-1 bg-iwingreen"
												>
													<span className='text-xs font-medium'>{cmp}</span>
													<IoMdClose
														className="w-4 h-4 font-black cursor-pointer"
														onClick={() => handleRemoveMateriel(cmp)}
													/>
												</div>
											))}
										</div>
										<button
											className='p-2 rounded-lg bg-gray-300'
											onClick={() => setShowModal(!showModal)}
											type="button"
										>
											<TiPlus className="w-4 h-4 text-iwinblue" />
										</button>
									</div>
									{materielListError ? (
										<small className="text-[9px] text-red-400">
											Veuillez ajouter les matériels !
										</small>
									) : null}
								</div>
								<div className='flex-1 flex flex-col justify-center items-start gap-2'>
									<div className='w-full flex justify-between items-center'>
										<label className='font-medium' htmlFor='typeReader'>
											Type de lecteurs<span className='text-red-500'>*</span>
										</label>
									</div>
									<Select
										id="typeReader"
										name="typeReader"
										styles={customStyles}
										options={typeLecteurs}
										placeholder=""
										className="w-full"
										classNamePrefix="select"
										onChange={(e) => validation.setFieldValue("typeReader", e)}
										onBlur={validation.handleBlur}
										value={validation.values.typeReader}
										invalid={validation.touched.typeReader && validation.errors.typeReader}
									/>
									{validation.touched.typeReader && validation.errors.typeReader ? (
										<small className="text-[9px] text-red-400">
											{validation.errors.typeReader}
										</small>
									) : null}
								</div>
							</div>

							<div className='w-full flex justify-between items-center gap-5 mt-5'>
								<div className='flex-1 flex flex-col justify-center items-start gap-2'>
									<div className='w-full flex justify-between items-center'>
										<label className='font-medium' htmlFor='topic'>
											Sujet de l'article<span className='text-red-500'>*</span>
										</label>
									</div>
									<input
										type="text"
										id="topic"
										name="topic"
										value={validation.values.topic}
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										invalid={validation.touched.topic && validation.errors.topic}
										className='w-full border-iwingreen/[0.4] border px-2 py-2 text-[12px] rounded-md'
									/>
									{validation.touched.topic && validation.errors.topic ? (
										<small className="text-[9px] text-red-400">{validation.errors.topic}</small>
									) : null}
								</div>
								<div className='flex-1 flex flex-col justify-center items-start gap-2'>
									<div className='w-full flex justify-between items-center'>
										<label className='font-medium' htmlFor='articleTitle'>
											Titre de l'article<span className='text-red-500'>*</span>
										</label>
									</div>
									<input
										type="text"
										id="articleTitle"
										name="articleTitle"
										value={validation.values.articleTitle}
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										invalid={validation.touched.articleTitle && validation.errors.articleTitle}
										className='w-full border-iwingreen/[0.4] border px-2 py-2 text-[12px] rounded-md'
									/>
									{validation.touched.articleTitle && validation.errors.articleTitle ? (
										<small className="text-[9px] text-red-400">
											{validation.errors.articleTitle}
										</small>
									) : null}
								</div>
							</div>
							<div className='w-full flex justify-between items-center gap-5 mt-5'>
								<div className='flex-1 flex flex-col justify-center items-start gap-2'>
									<div className='w-full flex justify-between items-center'>
										<label className='font-medium' htmlFor='metaDescription'>
											Meta description de l'article
											<span className='text-red-500'>*</span>
										</label>
										<GoInfo className='w-4 h-4 text-yellow-500 cursor-pointer' />
									</div>
									<input
										type="text"
										id="metaDescription"
										value={validation.values.metaDescription}
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										invalid={
											validation.touched.metaDescription && validation.errors.metaDescription
										}
										className='w-full border-iwingreen/[0.4] border px-2 py-2 text-[12px] rounded-md'
									/>
									{validation.touched.metaDescription && validation.errors.metaDescription ? (
										<small className="text-[9px] text-red-400">
											{validation.errors.metaDescription}
										</small>
									) : null}
								</div>
								<div className='flex-1 flex flex-col justify-center items-start gap-2'>
									<div className='w-full flex justify-between items-center'>
										<label className='font-medium' htmlFor='slug'>
											Slug<span className='text-red-500'>*</span>
										</label>
									</div>
									<input
										type="text"
										id="slug"
										name="slug"
										value={validation.values.slug}
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										invalid={validation.touched.slug && validation.errors.slug}
										className='w-full border-iwingreen/[0.4] border px-2 py-2 text-[12px] rounded-md'
									/>
									{validation.touched.slug && validation.errors.slug ? (
										<small className="text-[9px] text-red-400">{validation.errors.slug}</small>
									) : null}
								</div>
							</div>
							<div className='w-full flex justify-between items-center gap-5 mt-5'>
								<div className='flex-1 flex flex-col justify-center items-start gap-2'>
									<div className='w-full flex justify-between items-center'>
										<label className='font-medium' htmlFor='tags1'>
											Mot(s)-clé(s) - Requête principale
											<span className='text-red-500'>*</span>
										</label>
									</div>
									<input
										type="text"
										id="tags1"
										value={validation.values.tags1}
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										invalid={validation.touched.tags1 && validation.errors.tags1}
										className='w-full border-iwingreen/[0.4] border px-2 py-2 text-[12px] rounded-md'
									/>
									{validation.touched.tags1 && validation.errors.tags1 ? (
										<small className="text-[9px] text-red-400">{validation.errors.tags1}</small>
									) : null}
								</div>
								<div className='flex-1 flex flex-col justify-center items-start gap-2'>
									<div className='w-full flex justify-between items-center'>
										<label className='font-medium' htmlFor='tags2'>
											Mot(s)-clé(s) - Requête secondaire
											<span className='text-red-500'>*</span>
										</label>
									</div>
									<input
										type="text"
										id="tags2"
										value={validation.values.tags2}
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										invalid={validation.touched.tags2 && validation.errors.tags2}
										className='w-full border-iwingreen/[0.4] border px-2 py-2 text-[12px] rounded-md'
									/>
									{validation.touched.tags2 && validation.errors.tags2 ? (
										<small className="text-[9px] text-red-400">{validation.errors.tags2}</small>
									) : null}
								</div>
							</div>
							<div className='w-full flex flex-col justify-center items-start gap-1 mt-5'>
								<label className='w-full flex justify-between items-center gap-3'>
									<span className='font-medium'>
										Champs sémantique/lexical<span className='text-red-500'>*</span>
									</span>
									<GoInfo className='w-4 h-4 text-yellow-500 cursor-pointer' />
								</label>
								<InputTextarea
									id="semLex"
									value={validation.values.semLex}
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									invalid={validation.touched.semLex && validation.errors.semLex}
									className='w-full border border-iwingreen/[0.4] rounded-md bg-white p-2 focus:outline-none text-sm'
									rows={2}
									autoResize
								/>
								{validation.touched.semLex && validation.errors.semLex ? (
									<small className="text-[9px] text-red-400">{validation.errors.semLex}</small>
								) : null}
							</div>
							<div className='w-full flex flex-col justify-center items-start gap-2 mt-5'>
								<div className='w-full flex justify-between items-center'>
									<label className='font-medium' htmlFor='category'>
										Catégorie de l'article<span className='text-red-500'>*</span>
									</label>
								</div>
								<Select
									id="category"
									name="category"
									styles={customStyles}
									options={categoryArticles}
									placeholder=""
									className="w-full"
									classNamePrefix="select"
									value={validation.values.category}
									onChange={(e) => validation.setFieldValue("category", e)}
									onBlur={validation.handleBlur}
									invalid={validation.touched.category && validation.errors.category}
								/>
								{validation.touched.category && validation.errors.category ? (
									<small className="text-[9px] text-red-400">{validation.errors.category}</small>
								) : null}
							</div>
							<div className='w-full flex flex-col justify-center items-start gap-1 mt-5'>
								<label className='w-full flex justify-between items-center gap-3'>
									<span className='font-medium'>
										Introduction<span className='text-red-500'>*</span>{" "}
										<small>(1840 caractères.)</small>
									</span>
								</label>
								<InputTextarea
									id="introduction"
									value={validation.values.introduction}
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									invalid={validation.touched.introduction && validation.errors.introduction}
									className='w-full border border-iwingreen/[0.4] rounded-md bg-white p-2 focus:outline-none text-sm'
									rows={2}
									autoResize
								/>
								{validation.touched.introduction && validation.errors.introduction ? (
									<small className="text-[9px] text-red-400">
										{validation.errors.introduction}
									</small>
								) : null}
							</div>
							<div className='w-full flex flex-col justify-center items-start gap-1 mt-5'>
								<label className='w-full flex justify-between items-center gap-3'>
									<span className='font-medium'>
										Contenu<span className='text-red-500'>*</span> <small>(1840 caractères.)</small>
									</span>
								</label>
								<Editor
									className=''
									value={validation.values.content}
									onTextChange={(e) => {
										validation.setFieldValue("content", e.htmlValue);
									}}
								/>
								{validation.touched.content && validation.errors.content ? (
									<small className="text-[9px] text-red-400">{validation.errors.content}</small>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
			{materielModal()}
		</form>
	);
};

export default Index;
