import placeholder from '../../../assets/images/activity-placeholder.png';
import "./UpdatePdfCard.scss";
import fileGrey from "./file-grey.png";
import fileWhite from "./file-white.png";

import { RiDeleteBinLine, RiEdit2Fill } from "react-icons/ri";

export default function UpdatePdfCard({ selected, file, onRemoveDocument, onDocumentAction, isGroupe, onFileUploaded }) {
	return (
		// rome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
		<div className={`PdfCard ${selected ? "active" : ""}`.trim()} onClick={(event) => {
			event.stopPropagation()
			onDocumentAction()
		}}>
			{isGroupe ?
				<RiDeleteBinLine className="PdfCard-delete cursor-pointer" onClick={(event) => {
					event.stopPropagation()
					onRemoveDocument()
					// rome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
				}} /> : <label onClick={(event) => event.stopPropagation()}>
					<RiEdit2Fill className="PdfCard-delete cursor-pointer" />
					<input type="file" hidden onChange={(event) => onFileUploaded(event.target.files)} />
				</label>
			}
			<div>
				{file.file.url ? <img src={selected ? fileWhite : fileGrey} alt="fileWhite" /> :
					<img src={placeholder} alt="fileWhite" className='h-10' />}


			</div>
			<div className="PdfCard-caption">{file.name}</div>
		</div>
	);
}
