import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { BiFilter } from "react-icons/bi";
import { CgHashtag } from "react-icons/cg";
import { GoSearch } from "react-icons/go";
import { MdPlayArrow } from "react-icons/md";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { ScrollContainer } from "../../components/common";
// import components
import Profile from "../../components/common/Profile";

import ContainerMesDevoirs from "../../assets/images/Content-mes-devoirs.png";
import Mask2 from "../../assets/images/icons/Mask2.png";
import PuzzlePieceImg from "../../assets/images/icons/PuzzlePiece-white.png";
import TimerImg from "../../assets/images/icons/Timer.png";
import RessourceImg from "../../assets/images/icons/iconResources.png";
import BackgroundImg from "../../assets/images/jumbotronbg.png";

const JumbotronConatiner = styled.div`
    background: url(${BackgroundImg}) no-repeat center center fixed; 
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
    height: 100%;
`;

const Index = () => {
	const [activeIndex, setActiveIndex] = useState(0);

	const HashBodyTemplate = (rowData) => {
		return (
			<div className='flex justify-center items-center text-gray-400 rounded-md bg-gray-200'>
				<CgHashtag className='w-5 h-5 ' />
			</div>
		);
	};

	return (
		<div className="w-full flex flex-row justify-between items-start">
			<div className="w-full flex flex-col justify-start items-start mt-5 px-5">
				<div className="w-full flex justify-between items-center">
					<h1 className='text-iwinblue font-bold text-left text-[26px]'>
						Bienvenue, <i className='text-iwingreen'>Abdoulaye</i> !
					</h1>
					<div className="relative w-[300px]">
						<input
							type="text"
							placeholder='Trouver une ressource ...'
							className="w-full bg-white rounded-[46px] border-white bottom-0 px-[50px] py-3"
							style={{ border: "none", borderRadius: "40px" }}
						/>
						<GoSearch className="w-6 h-6 absolute top-[15px] left-[20px] text-asprimary" />
						<BiFilter className="w-8 h-8 absolute top-[10px] right-[15px] text-gray-700" />
					</div>
				</div>
				<ScrollContainer className='w-full mt-4 px-2'>
					<div className='w-full flex flex-col justify-start items-start'>
						<JumbotronConatiner className="w-full mt-5 rounded-lg px-9 flex flex-row justify-start items-center">
							<img src={Mask2} className="h-[75px] -mt-2" alt="" />
							<div className='w-full flex flex-col justify-center items-center font-bold ml-5 mb-2 py-2'>
								<h4 className="w-full text-[18px]">
									{" "}
									Découvrez nos nouvelles histoires dans tes activités
								</h4>
								<p className="w-full font-medium text-[12px]">
									Dans l’univers « Le mirroir de l’étranger » deux nouvelles histoires à découvrir
								</p>
							</div>
							<button className='px-5 py-2 bg-iwincolor2 rounded-lg text-white text-md'>
								Découvrir
							</button>
						</JumbotronConatiner>
						<h2 className='text-lg font-black text-iwingreen my-5'>Statistiques</h2>
						<div className='w-full flex flex-row justify-between items-center gap-3'>
							<div className='flex justify-between items-center px-5 py-2 shadow-lg bg-white rounded-lg'>
								<div className='bg-iwingreen rounded-full w-14 w-[50px] max-w-[50px] h-11 flex justify-center items-center p-2'>
									<img src={RessourceImg} className="w-5 h-5" alt="" />
								</div>
								<div className='w-full flex flex-col justify-center items-start gap-1 px-3 py-1'>
									<h2 className='font-bold text-sm'>Activités réalisées</h2>
									<b className='text-xl font-black text-iwinblue -my-1'>14</b>
									<p className='text-md font-thin text-gray-600'>
										<span className='text-iwingreen font-semibold'>+3</span> cette semaine
									</p>
								</div>
							</div>
							<div className='flex justify-between items-center px-5 py-2 shadow-lg bg-white rounded-lg'>
								<div className='bg-iwinpurple rounded-full w-[50px] max-w-[50px] h-11 flex justify-center items-center p-2'>
									<img src={TimerImg} className="w-5 h-5" alt="" />
								</div>
								<div className='w-full flex flex-col justify-center items-start gap-1 px-3 py-1'>
									<h2 className='font-bold text-sm'>Temps de travail</h2>
									<b className='text-xl font-black text-iwinblue -my-1'>1h27min.</b>
									<p className='text-md font-thin text-gray-600'>
										<span className='text-iwingreen font-semibold'>10min</span> cette semaine
									</p>
								</div>
							</div>
							<div className='flex justify-between items-center px-5 py-2 shadow-lg bg-white rounded-lg'>
								<div className='bg-iwincolor2 rounded-full w-[50px] max-w-[50px] h-11 flex justify-center items-center p-1'>
									<img src={PuzzlePieceImg} className='w-5 h-5' alt="" />
								</div>
								<div className='w-full flex flex-col justify-center items-start gap-1 px-3 py-1'>
									<h2 className='font-bold text-sm'>Complétion des activités assignées</h2>
									<b className='text-xl font-black text-iwinblue -my-1'>75%</b>
									<p className='text-md font-thin text-gray-600'>
										<span className='text-red-500 font-semibold'>-5%</span> cette semaine
									</p>
								</div>
							</div>
						</div>
						<div className='w-full mt-3 flex justify-start items-start gap-3'>
							<div className='flex flex-col justify-center items-center p-5 bg-white rounded-lg shadow-lg'>
								<p className='p-0 m-0 text-sm text-center'>
									Tu as ({"00"}) devoir{false ? "s" : ""} en attente
								</p>
								<Link to=''>
									<img src={ContainerMesDevoirs} className="w-full " alt="Me devoirs" />
								</Link>
							</div>
							<div className='w-full flex flex-col justify-start items-start px-5 py-3 shadow-lg bg-white rounded-lg'>
								<div className='flex justify-start items-center gap-7'>
									<button
										onClick={() => setActiveIndex(0)}
										className={`text-lg text-iwinblue ${
											activeIndex === 0 ? "border-b-4 border-iwingreen" : "border-b-0"
										}`}
									>
										Connaissances
									</button>
									<button
										onClick={() => setActiveIndex(1)}
										className={`text-lg text-iwinblue ${
											activeIndex === 1 ? "border-b-4 border-iwingreen" : "border-b-0"
										}`}
									>
										Savoir
									</button>
									<button
										onClick={() => setActiveIndex(2)}
										className={`text-lg text-iwinblue ${
											activeIndex === 2 ? "border-b-4 border-iwingreen" : "border-b-0"
										}`}
									>
										Savoir-faire
									</button>
								</div>
								<div className='w-full mt-4'>
									<DataTable value={[]} emptyMessage="Aucune donnée">
										<Column field="image" header={HashBodyTemplate} />
										<Column field="fisrtName" header="Champ 1" />
										<Column field="code" header="Champ 2" />
										<Column field="code" header="Champ 3" />
										<Column field="action" header="Actions" />
									</DataTable>
								</div>
							</div>
						</div>
					</div>
				</ScrollContainer>
			</div>
			<div className="w-[300px] bg-white px-75 pt-5 h-screen">
				<Profile />
				<div className='border-b border-gray-200 -mt-1' />

				<div className='w-full flex justify-end items-center'>
					<button className='text-[10px] font-medium text-iwingreen px-2 py-1 border border-iwingreen rounded-lg mt-4 flex justify-start items-center'>
						Tous les tutoriels <MdPlayArrow className='w-4 h-4 ml-1' />{" "}
					</button>
				</div>
			</div>
		</div>
	);
};

export default Index;
