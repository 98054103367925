import React from "react";
import { Link } from "react-router-dom";

// import helpers
import { getTwoFirstLetter, getUser } from "../../../helpers/common";

import Notification from "../../../assets/images/bell.png";

const Index = () => {
	const getImageProfile = () => {
		if (getUser().account.profilePath) {
			return (
				<img src={getUser().account.profilePath} alt="" className='w-full h-full rounded-full' />
			);
		} else {
			return getTwoFirstLetter(getUser().firstName);
		}
	};

	return (
		<div className="w-[300px] bg-white p-2 rounded-xl">
			<div className="w-full flex flex-row justify-between items-center mb-2">
				<div className="w-[64px] bg-white  rounded-[40px] shadow-radius relative">
					<img src={Notification} alt="" className='w-[24px]' />
					<div className='h-[6px] w-[6px] rounded-full d-none absolute left-5 -top-1' />
				</div>
				<Link to={`${"/profile"}`} className="flex flex-row justify-between items-center">
					<div className="flex flex-col justify-start items-start">
						<h4 className="font-bold text-[10px] mb-1 text-gray-600 capitalize">
							{`${getUser().firstName} ${getUser().lastName.substring(0, 1)}`}.
						</h4>
						<small className="text-gray-400 text-[8px]">
							{getUser().account.role === "TEACHER" ? "Enseignant" : null}
						</small>
						<small className="text-gray-400 text-[12px]">
							{getUser().account.role === "PARENT" ? "Parent" : null}
						</small>
						<small className="text-gray-400 text-[12px]">
							{getUser().account.role === "STUDENT" ? "Elève" : null}
						</small>
					</div>
					<div className='h-11 w-11 ml-3 rounded-full border-2 border-iwingreen flex justify-center items-center font-black text-lg'>
						{getImageProfile()}
					</div>
				</Link>
			</div>
		</div>
	);
};

export default Index;
